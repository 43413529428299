<mat-card
	[ngClass]="{
		'card-disabled': disabled
	}">
	<mat-card-header>
		<mat-card-title>Get Ahead - Start Your Searches NOW </mat-card-title>
	</mat-card-header>
	<mat-card-content>
		<p>
			Clear Chain offers a comprehensive search package that includes Regulated
			Local Search, Regulated Water / Drainage Search, and Groundsure AVISTA
			(Including Coal) for only:
		</p>
		<h2 style="text-align: center; margin-bottom: 0; padding-bottom: 0">
			<span
				style="
					background-color: #d43680;
					padding: 0.5rem 1.2rem;
					color: white;
					border-radius: 2rem;
				"
				>£ 350
			</span>
			<!-- <span style="font-size: 0.8rem">(inc. VAT)</span> -->
		</h2>
		<ul style="padding-left: 0rem; list-style: none">
			<li class="item">
				<span class="bold-under">SAVE TIME</span>
				<span class="detail">
					Save yourself weeks of time by starting these searches now and not
					waiting until the contract has been sent to the buyer's solicitor.
				</span>
			</li>
			<li class="item">
				<span class="bold-under"> REVIEW IN REAL TIME</span
				><span class="detail">
					See the results of the searches as soon as they are returned and share
					them straight away to review with your Conveyancer.
				</span>
			</li>
			<li class="item">
				<span class="bold-under"> SAVE MONEY AND DON’T PAY TWICE </span>
				<span class="detail">
					Clear Chain offers not only a competitive price point for these
					searches but will renew them for
					<span style="font-weight: bold">FREE</span> if they expire before the
					transaction can complete.
				</span>
			</li>
		</ul>
		<!-- <p style="text-align: center">Price: £350</p> -->

		<div style="text-align: center; margin: 2rem 0">
			<button
				mat-raised-button
				color="primary"
				[disabled]="disabled"
				(click)="getStripUrl()">
				Start Searches
			</button>
		</div>
	</mat-card-content>
</mat-card>
