<div class="container">
	<h1 class="header">Inbox</h1>
	<!-- <div class="center-center" *ngIf="loading">
		<mat-spinner></mat-spinner>
	</div> -->
	<!-- <mat-card appearance="outlined">
		<mat-card-content> -->
	<div class="top-row">
		<div>
			<mat-button-toggle-group
				name="favoriteColor"
				aria-label="Favorite Color"
				[value]="filter"
				(change)="setFilter($event)">
				<mat-button-toggle value="SENT">Sent</mat-button-toggle>
				<mat-button-toggle value="RECEIVED">Received</mat-button-toggle>
				<mat-button-toggle value="All">All</mat-button-toggle>
			</mat-button-toggle-group>
		</div>

		<div class="search">
			<!-- <mat-form-field appearance="outline">
				<mat-label>Search</mat-label>
				<input matInput type="text" />
			</mat-form-field> -->
			<button mat-icon-button>
				<mat-icon>search</mat-icon>
			</button>
			<button mat-icon-button (click)="loadEMails()">
				<mat-icon>refresh</mat-icon>
			</button>
		</div>
	</div>
	<!-- </mat-card-content>
	</mat-card> -->
	<app-loading-tombstone *ngIf="loading"></app-loading-tombstone>
	<mat-card appearance="outlined" *ngIf="!loading && emails?.length == 0">
		<mat-card-content>
			<div class="center">You Have No Emails Right Now</div>
		</mat-card-content>
	</mat-card>
	<mat-card
		appearance="outlined"
		*ngFor="let email of emails; let i = index; trackBy: emailTrackBy"
		(click)="openEmail(email)"
		class="email">
		<mat-card-content>
			<div class="top-row">
				<div class="to-from">
					<div>
						<span class="name">{{ email.from.name }}</span>
					</div>

					<mat-icon
						style="
							font-size: 1rem;
							height: 1rem;
							width: 1rem;
							margin-top: 0.2rem;
						"
						>arrow_forward</mat-icon
					>
					<div>
						<span class="name" *ngFor="let to of email.to; let i = index">{{
							to.name
						}}</span>
					</div>
				</div>
				<div class="chips">
					<span
						class="chip clicked"
						*ngIf="email.clicks.length > 0"
						[matBadge]="email.clicks.length"
						matBadgeOverlap="true"
						matBadgeSize="small"
						><mat-icon
							style="
								font-size: 1rem;
								height: 1rem;
								width: 1rem;
								margin-top: 0rem;
							"
							>link</mat-icon
						></span
					>
					<span
						class="chip opened"
						*ngIf="email.opens.length > 0"
						[matBadge]="email.opens.length"
						matBadgeOverlap="true"
						matBadgeSize="small"
						><mat-icon
							style="
								font-size: 1rem;
								height: 1rem;
								width: 1rem;
								margin-top: 0rem;
							"
							>visibility</mat-icon
						>
					</span>

					<!-- <span class="chip not-delivered" *ngIf="!email.delivered"
						>Not Delivered</span
					> -->
					<span
						class="chip attachment"
						*ngIf="email.attachments.length > 0"
						[matBadge]="email.attachments.length"
						matBadgeOverlap="true"
						matBadgeSize="small"
						><mat-icon
							style="
								font-size: 1rem;
								height: 1rem;
								width: 1rem;
								margin-top: 0rem;
							"
							>attachment</mat-icon
						>
					</span>
					<span class="chip">
						{{ email.sent | date : "short" }}
					</span>
				</div>
			</div>
			<div class="second-row">{{ email.subject }}</div>
		</mat-card-content>
	</mat-card>

	<div *ngIf="_lastKey" class="center">
		<button mat-raised-button color="primary" (click)="loadMore()">
			Load More
		</button>
	</div>
</div>
