import { Component, Input, OnInit } from '@angular/core';
import { animate, style, transition, trigger } from '@angular/animations';

@Component({
	selector: 'app-line-progress',
	templateUrl: './line-progress.component.html',
	styleUrls: ['./line-progress.component.css'],
})
export class LineProgressComponent implements OnInit {
	@Input() completed: number = 0;
	@Input() inProgress: number = 0;
	@Input() notStarted: number = 0;
	@Input() disabled: number = 0;
	@Input() showPercent: boolean = true;

	get total() {
		return this.completed + this.inProgress + this.notStarted + this.disabled;
	}

	get completedPercent() {
		return (this.completed / this.total) * 100;
	}

	get inProgressPercent() {
		return (this.inProgress / this.total) * 100;
	}

	get notStartedPercent() {
		return (this.notStarted / this.total) * 100;
	}

	get disabledPercent() {
		return (this.disabled / this.total) * 100;
	}

	constructor() {}

	ngOnInit(): void {}

	round(val: number) {
		return Math.round(val);
	}
}
