import { ChangeDetectionStrategy, Component, type OnInit } from '@angular/core';
import { EmailBackendService } from '../services/email-backend.service';

@Component({
	selector: 'app-admin-reports',
	templateUrl: './admin-reports.component.html',
	styleUrls: ['./admin-reports.component.css'],
	//changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AdminReportsComponent implements OnInit {
	days: {
		date: Date;
		numberOfEmails: number;
		numberOfOpened: number;
		numberOfClicked: number;
		numberOfBounced: number;
		numberOfComplaint: number;
		numberOfUnsubscribed: number;
	}[] = [];

	displayedColumns: string[] = [
		'date',
		'numberOfEmails',
		'numberOfOpened',
		'numberOfClicked',
		'numberOfBounced',
		'numberOfComplaint',
		'numberOfUnsubscribed',
	];

	constructor(private emailBackend: EmailBackendService) {}

	async ngOnInit() {
		this.days = (await this.emailBackend.getReport()).data;
	}

	round(value: number) {
		if (!value || Number.isNaN(value)) return 0;
		return Math.round(value * 100);
	}
}
