<div class="container">
	<h1 class="header">Unsubscribe</h1>
	<!-- <div class="center-center" *ngIf="loading">
		<mat-spinner></mat-spinner>
	</div> -->

	<mat-card appearance="outlined" *ngIf="!completed">
		<mat-card-content>
			<div class="center">
				<div style="margin: 1rem">
					Unsubscribe from update emails related to property transactions you
					are a part of.
				</div>

				<mat-form-field appearance="outline">
					<mat-label>Email</mat-label>
					<input matInput type="email" [(ngModel)]="userEmail" />
				</mat-form-field>
				<button mat-raised-button color="primary" (click)="unsubscribe()">
					Unsubscribe
				</button>
			</div>
		</mat-card-content>
	</mat-card>

	<mat-card appearance="outlined" *ngIf="completed">
		<mat-card-content>
			<div class="center">
				You have successfully unsubscribed from update emails.
			</div>
		</mat-card-content>
	</mat-card>
</div>
