import {
	ChangeDetectionStrategy,
	Component,
	Input,
	type OnInit,
} from '@angular/core';
import {
	AMLcheck,
	AMLcheckResponse,
	credasStatus,
} from 'src/app/services/AML-backend.service';

@Component({
	selector: 'app-task-aml',
	templateUrl: './task-AML.component.html',
	styleUrls: ['./task-AML.component.css'],
})
export class TaskAMLComponent implements OnInit {
	@Input() AMLs?: AMLcheck[] = [];
	@Input() state: string | undefined = 'disabled';
	credasStatuses = credasStatus;
	ngOnInit(): void {}
}
