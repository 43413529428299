import { Component, Input, OnInit } from '@angular/core';
import { ConfirmDialogService } from 'src/app/services/confirm-dialog.service';
import {
	RequestsBackendService,
	Request,
} from 'src/app/services/requests-backend.service';

@Component({
	selector: 'app-request-list',
	templateUrl: './request-list.component.html',
	styleUrls: ['./request-list.component.css'],
})
export class RequestListComponent implements OnInit {
	@Input() requests: Request[] = [];
	@Input() loading: boolean = false;
	constructor(
		public requestBackend: RequestsBackendService,
		public confirmDialogService: ConfirmDialogService
	) {}

	ngOnInit(): void {}

	async deleteRequest(request: any) {
		if (
			!(await this.confirmDialogService.confirm({
				title: 'Delete Request?',
				message: 'Are you sure you want to delete this request?',
			}))
		)
			return;
		await this.requestBackend.delete(request.id);

		this.requests = this.requests.filter((r: any) => r.id != request.id);
	}

	async acceptRequest(request: any) {
		if (
			!(await this.confirmDialogService.confirm({
				title: 'Accept Request?',
				message: 'Are you sure you want to accept this request?',
			}))
		)
			return;
		await this.requestBackend.accept(request.id);
		this.requests = this.requests.filter((r: any) => r.id != request.id);
	}

	canAccept(request: Request) {
		if (request.email == '********') return false;
		return true;
	}
}
