import { Injectable } from '@angular/core';
import { BackendService, backendResponse } from './backend.service';
import { TaskDocument } from './task-backend.service';
import { userType } from '../setup/httpTypes';

@Injectable({
	providedIn: 'root',
})
export class InquiryBackendService {
	constructor(public backend: BackendService) {}

	async getOne(inquiryId: string): Promise<backendResponse<Inquiry>> {
		return (await this.backend.get(
			`inquiry/${inquiryId}`
		)) as backendResponse<Inquiry>;
	}

	async getList(options?: {
		taskId?: string;
	}): Promise<backendResponse<Inquiry[]>> {
		let queryString = ``;
		if (options?.taskId) {
			queryString += `?taskId=${options.taskId}`;
		}

		return (await this.backend.get(
			`inquiries${queryString}`
		)) as backendResponse<Inquiry[]>;
	}

	async put(Inquiry: Partial<Inquiry>): Promise<backendResponse<Inquiry>> {
		let queryString = ``;
		return (await this.backend.put(
			`inquiry`,
			Inquiry
		)) as backendResponse<Inquiry>;
	}

	async patch(
		inquiryId: string,
		inquiry: Partial<Inquiry>
	): Promise<backendResponse<Inquiry>> {
		return (await this.backend.patch(
			`inquiry/${inquiryId}`,
			inquiry
		)) as backendResponse<Inquiry>;
	}
}

export interface InquiryResponse {
	id: string;
	response?: string;
	documents: TaskDocument[];
	userEmail: string;
	userType?: userType;
	userFirstName?: string;
	userLastName?: string;
	date: Date;
}

export interface Inquiry {
	id: string;
	chainId: string;
	propertyId: string;
	taskId: string;
	inquiry: string;
	documents: TaskDocument[];
	status: 'unresolved' | 'resolved' | 'deleted';
	created: Date;
	responses: InquiryResponse[];
	userType: userType;
	createdByUserType?: userType;
	createdByFirstName?: string;
	createdByLastName?: string;
	createdBy?: string;
	filtered?: boolean;
}
