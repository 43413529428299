<div class="container">
	<h1 class="header">Error</h1>
	<mat-card appearance="outlined" style="margin: 3rem 0">
		<mat-card-header>
			<mat-card-title>404 - Page Not Found</mat-card-title>
		</mat-card-header>
		<mat-card-content>
			<p>The page you are looking for does not exist.</p>
			<p>
				If you think this is a mistake, please contact us at
				<a
					href="mailto:info@clearchain.house?subject=Customer%20Service%20Inquiry"
					>info@clearchain.house</a
				>
				or call us at 0.203.830.0411.
			</p>
		</mat-card-content>
	</mat-card>
</div>
