import Dexie, { Table } from 'dexie';

export interface IPostCodeAddresses {
	postCode: string;
	addresses: {
		addressline1: string;
		addressline2: string;
		summaryline: string;
		number: string;
		premise: string;
		street: string;
		county: string;
		postcode: string;
		townname: string;
		latitude: string;
		longitude: string;
		grideasting: string;
		gridnorthing: string;
		localauthoritycode: string;
		uprn: string;
		usrn: string;
		changecode: string;
		toid: string;
		classificationcode: string;
		rpc: string;
		gsscode: string;
		lastupdatedate: string;
	}[];
}

export interface IEmailVerification {
	email: string;
	valid: boolean;
}

export class AppDB extends Dexie {
	postCodeAddresses!: Table<IPostCodeAddresses, string>;
	emailVerification!: Table<IEmailVerification, string>;

	constructor() {
		super('offlineAndCachingDb');
		this.version(4).stores({
			postCodeAddresses: 'postCode',
			emailVerification: 'email',
		});
	}
}

export const db = new AppDB();
