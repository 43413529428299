<h3 [class.card-completed]="completed">{{ title }}</h3>
<div
	*ngFor="let personForm of form.controls; let i = index; even as isEven"
	[ngClass]="{ even: isEven }"
	[formGroup]="$any(personForm)">
	<div class="row">
		<mat-form-field appearance="outline" floatLabel="always">
			<mat-label> Company </mat-label>
			<input matInput placeholder="Company" formControlName="company" />
		</mat-form-field>
	</div>
	<div class="row" *ngIf="showOccupation">
		<mat-form-field appearance="outline" floatLabel="always">
			<mat-label> Occupation </mat-label>
			<input matInput placeholder="Occupation" formControlName="occupation" />
		</mat-form-field>
	</div>
	<div class="row">
		<mat-form-field appearance="outline" floatLabel="always">
			<mat-label> First Name </mat-label>
			<input matInput placeholder="First Name" formControlName="firstName" />
		</mat-form-field>

		<mat-form-field appearance="outline" floatLabel="always">
			<mat-label> Last Name </mat-label>
			<input matInput placeholder="Last Name" formControlName="lastName" />
		</mat-form-field>
	</div>
	<div class="row">
		<mat-form-field appearance="outline" floatLabel="always">
			<mat-label> Email </mat-label>
			<input matInput placeholder="Email" formControlName="email" />
		</mat-form-field>
		<!-- <div *ngIf="personForm.get('email')?.invalid">
			{{ personForm.get("email")?.errors | json }}
		</div> -->
		<div style="flex-grow: 1; display: flex; gap: 1rem">
			<mat-form-field
				style="width: 6rem"
				appearance="outline"
				floatLabel="always">
				<mat-label>Code</mat-label>
				<mat-select
					style="display: inline; max-width: 3rem"
					value="+44"
					formControlName="phoneCountryCode">
					<mat-option
						style="
							min-width: 8rem;
							display: flex;
							flex-direction: row;
							align-items: center;
							justify-content: center;
						"
						*ngFor="let code of phoneCountryCodes; let i = index"
						[value]="code">
						<!-- <img style="width: 2rem" [src]="code.image" /> -->
						{{ code }}
					</mat-option>
				</mat-select>
			</mat-form-field>
			<mat-form-field
				style="width: 9rem"
				appearance="outline"
				floatLabel="always">
				<mat-label> Phone </mat-label>

				<input
					matInput
					appLocalPhoneNumber
					placeholder="1234567890"
					formControlName="phoneInCountryNumber" />
			</mat-form-field>
		</div>
		<div class="row" *ngIf="!hideEmailSend">
			<mat-checkbox formControlName="dontSendEmail"
				>Don't Send Invite Email</mat-checkbox
			>
		</div>
	</div>

	<div *ngIf="!(atLeastOne && form.controls.length < 2)" class="row at-end">
		<button type="button" mat-button (click)="removePerson(i)">
			<mat-icon>delete</mat-icon>
			Remove Person
		</button>
	</div>
</div>
<div class="row at-end" *ngIf="!onlyOne">
	<button type="button" mat-raised-button color="primary" (click)="addPerson()">
		<mat-icon>add</mat-icon>
		<span>Add Person</span>
	</button>
</div>
