import { Component, OnInit } from '@angular/core';
import { environment } from 'src/environments/environment';
import { SwUpdate } from '@angular/service-worker';
import { TitleService } from '../services/title.service';

@Component({
	templateUrl: './about.component.html',
	styleUrls: ['./about.component.css'],
})
export class AboutComponent implements OnInit {
	version = environment.version;
	constructor(public SwUpdate: SwUpdate, public titleService: TitleService) {}

	ngOnInit(): void {
		this.titleService.title = 'About';
	}

	async checkForUpdate() {
		await this.SwUpdate.checkForUpdate();
	}
}
