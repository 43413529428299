import { Component, OnInit } from '@angular/core';
import { BugsService } from '../services/bugs.service';
import { TitleService } from '../services/title.service';

@Component({
	templateUrl: './contact.component.html',
	styleUrls: ['./contact.component.css'],
})
export class ContactComponent implements OnInit {
	constructor(
		public bugsService: BugsService,
		public titleService: TitleService
	) {}

	ngOnInit(): void {
		this.titleService.title = 'Contact';
	}

	async newBug() {
		await this.bugsService.newBug();
	}
}
