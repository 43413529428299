import { ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import {
	AbstractControl,
	FormArray,
	FormBuilder,
	FormGroup,
	Validators,
} from '@angular/forms';
import { Subscription, lastValueFrom } from 'rxjs';
import {
	PeopleBackendService,
	Person,
} from 'src/app/services/people-backend.service';
import { userType } from 'src/app/setup/httpTypes';
import { animate, style, transition, trigger } from '@angular/animations';
import { ConfirmExitFrom } from 'src/app/services/forms.service';
import { ConfirmDialogService } from 'src/app/services/confirm-dialog.service';
import { environment } from 'src/environments/environment';
import { MatDialog } from '@angular/material/dialog';
import { EditPersonDialogComponent } from './edit-person-dialog/edit-person-dialog.component';
import { AddPersonDialogComponent } from './add-person-dialog/add-person-dialog.component';
import { Clipboard } from '@angular/cdk/clipboard';
import { MatSnackBar } from '@angular/material/snack-bar';
import { AuthService } from 'src/app/services/auth.service';

@Component({
	selector: 'app-people-form',
	templateUrl: './people-form.component.html',
	styleUrls: ['./people-form.component.css'],
	animations: [
		trigger('inOutAnimation', [
			transition(':enter', [
				style({ height: 0, opacity: 0, overflow: 'hidden' }),
				animate('300ms ease-out', style({ height: '*', opacity: 1 })),
			]),
			transition(':leave', [
				style({ height: '*', opacity: 1, overflow: 'hidden' }),
				animate('300ms ease-in', style({ height: 0, opacity: 0 })),
			]),
		]),
	],
})
export class PeopleFormComponent implements OnInit {
	@Input() title: string = 'People Control';
	@Input() OnlyOne: boolean = false;
	@Input() AtLeastOne: boolean = true;
	@Input() chainId?: string;

	@Input() canEdit = false;
	@Input() deleteFromEverywhere = false;
	@Input() state?:
		| 'completed'
		| 'inProgress'
		| 'notStarted'
		| 'disabled'
		| 'cancelled';

	_propertyId: string | undefined = undefined;
	_buyerSellerId: string | undefined = undefined;
	_taskId: string | undefined = undefined;
	_userType: userType | undefined = undefined;
	_onlyType: boolean = true;

	@Input() set propertyId(val: string | undefined) {
		this._propertyId = val;
		this.getPeople();
	}
	@Input() set buyerSellerId(val: string | undefined) {
		this._buyerSellerId = val;
		this.getPeople();
	}
	@Input() set taskId(val: string | undefined) {
		this._taskId = val;
		this.getPeople();
	}
	@Input() set onlyType(val: boolean) {
		this._onlyType = val;
		this.getPeople();
	}

	@Input() set userType(val: userType | undefined) {
		this._userType = val;
		this.getPeople();
	}

	get propertyId(): string | undefined {
		return this._propertyId;
	}
	get buyerSellerId(): string | undefined {
		return this._buyerSellerId;
	}
	get taskId(): string | undefined {
		return this._taskId;
	}
	get userType(): userType | undefined {
		return this._userType;
	}
	get onlyType(): boolean {
		return this._onlyType;
	}

	people: Person[] = [];

	loading = true;
	saving = false;

	constructor(
		public cd: ChangeDetectorRef,
		public personBackend: PeopleBackendService,
		public confirmDialog: ConfirmDialogService,
		public dialog: MatDialog,
		public clipboard: Clipboard,
		public snackbar: MatSnackBar,
		public AuthService: AuthService
	) {}

	ngOnInit(): void {}

	allEmailsList() {
		return this.people.map((p) => p.ccEmail).join(', ');
	}

	async getPeople() {
		this.loading = true;
		if (
			(!this.propertyId && !this.buyerSellerId && !this.taskId) ||
			!this.userType
		) {
			this.people = [];
			return;
		}
		let r = await this.personBackend
			.getList({
				propertyId: this._propertyId,
				buyerSellerId: this._buyerSellerId,
				taskId: this._taskId,
				chainId: this.chainId,
				userType: this.onlyType ? this._userType || undefined : undefined,
			})
			.catch((err) => {
				return {
					data: [],
					canEdit: false,
				};
			});
		this.people = r?.data || [];
		this.canEdit = r?.canEdit || false;
		//console.log(this.people, this._userType);

		this.loading = false;
		this.cd.detectChanges();
	}

	async addPerson() {
		let ref = this.dialog.open(AddPersonDialogComponent, {
			data: {
				chainId: this.chainId,
				propertyId: this.propertyId,
				buyerSellerId: this.buyerSellerId,
				taskId: this.taskId,
				userType: this.userType,
				title: this.title,
			},
		});
		let r = await lastValueFrom(ref.afterClosed());
		if (r) {
			this.people.push(r);
		}
	}

	async removePerson(index: number) {
		let person = this.people[index];
		let r = await this.confirmDialog.confirm({
			title: `Remove ${this.title}`,
			message: `Are you sure you want to remove ${person.firstName} ${person.lastName} from this ${this.title} list?`,
			confirmText: 'Remove',
		});
		if (!r) return;

		try {
			let r = await this.personBackend.delete(
				person.id,
				this.deleteFromEverywhere
			);
			if (!r) return;
			this.people.splice(index, 1);
		} catch (error) {}
	}

	async editPerson(index: number) {
		let person = this.people[index];
		let ref = this.dialog.open(EditPersonDialogComponent, { data: person });
		let r = await lastValueFrom(ref.afterClosed());
		if (r) {
			this.people[index] = r;
		}
	}

	async copyEmail(email: string) {
		if (!email) return;
		if (email == '') return;
		if (email.includes('*')) return;
		if (!this.canEdit) return;
		this.clipboard.copy(email);
		this.snackbar.open(`Email ${email} copied to clipboard`, 'Dismiss', {
			duration: 3000,
			verticalPosition: 'top',
		});
	}

	async copyAllEmails() {
		let emails = this.allEmailsList();
		if (!emails) return;
		if (emails == '') return;
		if (emails.includes('*')) return;
		if (!this.canEdit) return;
		this.clipboard.copy(emails);
		this.snackbar.open(`Emails copied to clipboard`, 'Dismiss', {
			duration: 3000,
			verticalPosition: 'top',
		});
	}

	async setSpoof(email: string) {
		let isAdmin = await this.AuthService.isAdmin();
		if (!isAdmin) return;
		this.AuthService.spoofAs = email;
	}

	toolTipText(email: string) {
		if (email.includes('*'))
			return 'This persons details are hidden from you for security reasons.';
		return 'Click to copy email to clipboard';
	}

	async reSendInvite(id: string) {
		let r = await this.confirmDialog.confirm({
			title: 'Resend Invite',
			message: 'Are you sure you want to resend this invite?',
		});

		if (!r) return;
		let invite = await this.personBackend.reSendInvite(id);
		if (invite) {
			this.snackbar.open('Invite sent', 'Dismiss', { duration: 3000 });
		}
	}

	async savePeople() {}
}
