import { Component, Input, Optional, Self } from '@angular/core';
import { ControlValueAccessor, NgControl } from '@angular/forms';
import { notificationTypePreference } from 'src/app/services/auth.service';

@Component({
	selector: '[app-notification-field]',
	templateUrl: './notification-field.component.html',
	styleUrls: ['./notification-field.component.css'],
	host: {
		'(change)': '_onChange($event.target.value)',
	},
})
export class NotificationFieldComponent implements ControlValueAccessor {
	@Input() Conveyancer: string = '';
	_value?: notificationTypePreference = undefined;
	@Input() set value(val: notificationTypePreference | undefined) {
		this._value = val;
		//this.onChange(val);
		//this.onTouched();
		// this.ngControl.control?.markAsDirty();
		// this.ngControl.control?.markAsTouched();
		// this.ngControl.control?.updateValueAndValidity();
	}
	get value(): notificationTypePreference | undefined {
		return this._value;
	}

	_disabled: boolean = false;
	@Input() set disabled(val: boolean) {
		this._disabled = val;
	}
	get disabled(): boolean {
		return this._disabled;
	}

	notificationTypePreference = notificationTypePreference;

	onChange: (value: any) => void = () => {};
	onTouched: () => void = () => {};

	constructor(@Optional() @Self() public ngControl: NgControl) {
		if (this.ngControl != null) {
			// Setting the value accessor directly (instead of using
			// the providers) to avoid running into a circular import.
			this.ngControl.valueAccessor = this;
			//this.value = this.ngControl.control?.value;
			//console.log('this.ngControl.control', this.ngControl.control);
			// this.ngControl.control?.valueChanges.subscribe((val) => {
			// 	console.log('val', val);
			// 	this.value = val;
			// });
		}
	}

	writeValue(val: notificationTypePreference): void {
		this.value = val;
	}

	registerOnChange(fn: any): void {
		this.onChange = fn;
	}

	registerOnTouched(fn: any): void {
		this.onTouched = fn;
	}

	setDisabledState?(isDisabled: boolean): void {
		this.disabled = isDisabled;
	}
}
