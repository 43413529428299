import { HttpClient } from '@angular/common/http';
import { Component, EventEmitter, OnDestroy, OnInit } from '@angular/core';
import {
	FormArray,
	FormBuilder,
	FormControl,
	FormGroup,
	Validators,
} from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { firstValueFrom, lastValueFrom, switchMap } from 'rxjs';
import { atLeastOneRequired } from 'src/app/form-fields/form-validators';
import { ConfirmExitFrom } from 'src/app/services/forms.service';
import { PropertiesBackendService } from 'src/app/services/properties-backend.service';
import { TitleService } from 'src/app/services/title.service';
import { environment } from 'src/environments/environment';

@Component({
	selector: 'app-new-property',
	templateUrl: './new-property.component.html',
	styleUrls: ['./new-property.component.css'],
})
export class NewPropertyComponent implements OnInit, OnDestroy {
	@ConfirmExitFrom() propertyFormGroup = new FormGroup({
		agents: this.buildPersonFormArray(false),
		address: this.buildAddressForm(),
	});

	$prams: any;
	$parentPrams: any;
	buyersId: undefined | string;
	sellersId: undefined | string;
	chainId = '';
	update = new EventEmitter();
	saving = false;

	constructor(
		public Http: HttpClient,
		public route: ActivatedRoute,
		public PropertiesBackend: PropertiesBackendService,
		public fb: FormBuilder,
		public Router: Router,
		public titleService: TitleService
	) {}

	ngOnInit(): void {
		this.titleService.title = 'New Property';
		this.$prams = this.route.params.subscribe((params) => {
			if (params['userType'] == 'Seller') {
				this.buyersId = params['buyerSellerId'];
				this.sellersId = undefined;
			}
			if (params['userType'] == 'Buyer') {
				this.sellersId = params['buyerSellerId'];
				this.buyersId = undefined;
			}
		});

		this.$parentPrams = this.route.parent?.params.subscribe((params) => {
			this.chainId = params['chainId'];
		});
	}

	ngOnDestroy(): void {
		if (this.$prams?.unsubscribe) this.$prams?.unsubscribe();
		if (this.$parentPrams?.unsubscribe) this.$parentPrams?.unsubscribe();
	}

	saveAgentsForm() {}

	removeAgent(index: number) {}

	resetAgentsFormGroup() {}

	addAgent() {}

	async save() {
		this.saving = true;
		this.propertyFormGroup.disable();
		this.propertyFormGroup.get('address')?.disable();
		this.propertyFormGroup.get('agents')?.disable();
		let data = this.propertyFormGroup.value;

		let property: any = {
			chainId: this.chainId,
			address: data.address?.address,
			postCode: data.address?.postcode,
			addressline1: data.address?.addressline1,
			addressline2: data.address?.addressline2,
			number: data.address?.number,
			premise: data.address?.premise,
			street: data.address?.street,
			posttown: data.address?.posttown,
			county: data.address?.county,
			UPRN: '' + data.address?.address + data.address?.postcode,
			agents: data.agents || [],
		};
		if (this.buyersId) {
			property.buyersId = this.buyersId;
		}
		if (this.sellersId) {
			property.sellersId = this.sellersId;
		}

		//let agents = this.agentsFormGroup.get('agents')?.value
		let r = await this.PropertiesBackend.put(property);
		this.propertyFormGroup.enable();
		this.propertyFormGroup.markAsUntouched();
		this.propertyFormGroup.markAsPristine();
		this.update.emit();
		this.saving = false;

		this.Router.navigate(['/chain', r.data.chainId, 'property', r.data.id]);
	}

	buildAddressForm() {
		return this.fb.group({
			postcode: [environment.postcode, [Validators.required]],
			address: ['', [Validators.required]],
			addressline1: [null],
			addressline2: [null],
			number: [null],
			premise: [null],
			street: [null],
			posttown: [null],
			county: [null],
			UPRN: ['', [Validators.required]],
		});
	}

	buildPersonFormArray(_atLeastOneRequired = true) {
		let validators = [atLeastOneRequired];
		if (!_atLeastOneRequired) validators = [];
		return this.fb.array([], validators);
	}
}
