import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import { FormBuilder, FormControl, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';

export interface workData {
	description?: string;
	completionDate?: Date;
}

export interface worksDialogData {
	workData: workData;
	actions: 'edit' | 'add';
}

@Component({
	selector: 'app-edit-works',

	templateUrl: './edit-works.component.html',
	styleUrls: ['./edit-works.component.css'],
})
export class EditWorksComponent {
	form = this.buildForm(this._data.workData);

	types: string[] = [];

	constructor(
		@Inject(MAT_DIALOG_DATA)
		public _data: worksDialogData,
		public fb: FormBuilder
	) {}

	ngOnInit() {
		this.form = this.buildForm(this._data.workData);
	}

	ngOnDestroy() {}

	buildForm(data?: workData) {
		let fb = this.fb.group<{
			description: FormControl<string | null>;
			completionDate: FormControl<Date | null>;
		}>({
			description: this.fb.control(null, [Validators.required]),
			completionDate: this.fb.control(null, [Validators.required]),
		});

		if (data) {
			fb.patchValue(data);
		}

		return fb;
	}
}
