import { HttpClient } from '@angular/common/http';
import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { FormArray, FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { lastValueFrom } from 'rxjs';
import { BuyerSellersBackendService } from 'src/app/services/buyer-sellers-backend.service';
import { ConfirmExitFrom } from 'src/app/services/forms.service';
import { TitleService } from 'src/app/services/title.service';
import { Unsubscribe } from 'src/app/services/unsubscribe';
import { userType } from 'src/app/setup/httpTypes';
import { environment } from 'src/environments/environment';

@Component({
	selector: 'app-new-buyer-seller',
	templateUrl: './new-buyer-seller.component.html',
	styleUrls: ['./new-buyer-seller.component.css'],
})
export class NewBuyerSellerComponent implements OnInit {
	update = new EventEmitter();
	chainId = '';
	propertyId = '';
	userType = userType.buyerSeller;

	@ConfirmExitFrom() buyerSellerForm = new FormGroup({
		people: new FormArray([
			new FormGroup({
				id: new FormControl(''),
				firstName: new FormControl('', [Validators.required]),
				lastName: new FormControl('', [Validators.required]),
				phoneCountryCode: new FormControl('+44', [Validators.required]),
				phoneInCountryNumber: new FormControl('', [
					Validators.required,
					Validators.pattern('^[1-9]\\d{9}'),
				]),
				email: new FormControl('', [Validators.required]),
				company: new FormControl(''),
				dontSendEmail: new FormControl(false),
			}),
		]),
	});

	loadingPeople = false;

	@Unsubscribe() $prams = this.route.params.subscribe((params) => {
		this.propertyId = params['propertyId'];
		this.userType = params['userType'];
	});

	@Unsubscribe() $parentPrams = this.route.parent?.params.subscribe(
		(params) => {
			this.chainId = params['chainId'];
		}
	);

	constructor(
		public route: ActivatedRoute,
		public BuyerSellersBackend: BuyerSellersBackendService,
		public router: Router,
		public titleService: TitleService
	) {}

	ngOnInit(): void {
		this.titleService.title = 'New Buyer/Seller';
	}

	// addPerson() {
	// 	(<FormArray>this.buyerSellerForm.get('people')).push(
	// 		new FormGroup({
	// 			id: new FormControl(''),
	// 			firstName: new FormControl(''),
	// 			lastName: new FormControl(''),
	// 			phone: new FormControl(''),
	// 			email: new FormControl(''),
	// 			company: new FormControl(''),
	// 		})
	// 	);
	// }

	// removePerson(index: number) {
	// 	(<FormArray>this.buyerSellerForm.get('people')).removeAt(index);
	// 	(<FormArray>this.buyerSellerForm.get('people')).markAsDirty();
	// 	if ((<FormArray>this.buyerSellerForm.get('people')).length == 0) {
	// 		this.resetPeopleFormGroup();
	// 	}
	// }

	async savePeopleForm() {
		this.loadingPeople = true;

		let people = this.buyerSellerForm.controls.people.controls
			.filter((_fm: FormGroup) => _fm.dirty)
			.map((personController: FormGroup) => {
				return {
					id: personController.controls['id']?.value || '',
					firstName: personController.controls['firstName']?.value,
					lastName: personController.controls['lastName']?.value,
					phoneCountryCode:
						personController.controls['phoneCountryCode']?.value,
					phoneInCountryNumber:
						personController.controls['phoneInCountryNumber']?.value,
					email: personController.controls['email']?.value,
					company: personController.controls['company']?.value,
					dontSendEmail: personController.controls['dontSendEmail']?.value,
				};
			});

		let data = {
			people,
			chainId: this.chainId,
			propertyId: this.propertyId,
			userType: this.userType,
		};

		console.log(data);

		let r = await this.BuyerSellersBackend.put(data).catch(() => {
			this.loadingPeople = false;
			return {
				data: undefined,
				canEdit: false,
			};
		});

		this.loadingPeople = false;
		this.buyerSellerForm.markAsUntouched();
		this.buyerSellerForm.markAsPristine();
		this.update.emit();
		this.router.navigate(['buyer-seller', r.data?.id], {
			relativeTo: this.route.parent,
		});
	}

	resetPeopleFormGroup() {
		this.buyerSellerForm.setControl(
			'people',
			new FormArray([
				new FormGroup({
					id: new FormControl(''),
					firstName: new FormControl(''),
					lastName: new FormControl(''),
					phoneCountryCode: new FormControl('+44', [Validators.required]),
					phoneInCountryNumber: new FormControl('', [
						Validators.required,
						Validators.pattern('^[1-9]\\d{9}'),
					]),
					email: new FormControl(''),
					company: new FormControl(''),
					dontSendEmail: new FormControl(false),
				}),
			])
		);
	}
}
