import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import {
	ConfirmDialogComponent,
	ConfirmDialogData,
} from '../form-fields/confirm-dialog/confirm-dialog.component';
import { lastValueFrom } from 'rxjs';

@Injectable({
	providedIn: 'root',
})
export class ConfirmDialogService {
	constructor(public dialog: MatDialog) {}

	async confirm(options?: ConfirmDialogData): Promise<boolean> {
		const dialogRef = this.dialog.open(ConfirmDialogComponent, {
			data: {
				title: options?.title || 'Confirm',
				message: options?.message || 'Are you sure?',
				confirmText: options?.confirmText || 'Confirm',
				cancelText: options?.cancelText || 'Cancel',
			},
			disableClose: false,
		});

		let r = await lastValueFrom(dialogRef.afterClosed());
		return r == 'true';
	}
}
