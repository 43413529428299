<mat-card
	appearance="outlined"
	[ngClass]="{
		disabled: state == 'disabled',
		completed: state == 'completed'
	}">
	<mat-card-header>
		<mat-card-title>
			Issues ({{ issuesArray.controls.length }})
		</mat-card-title>
	</mat-card-header>
	<mat-card-content *ngIf="formOpen" [@inOutAnimation]>
		<form [formGroup]="issuesForm" (ngSubmit)="save()">
			<app-issue
				class="row"
				[issueFrom]="$any(issueForm)"
				[issueTypes]="issueTypes"
				[taskId]="taskId"
				(delete)="removeIssue(i)"
				*ngFor="
					let issueForm of issuesArray.controls;
					trackBy: trackByFn;
					let i = index
				">
			</app-issue>
			<div class="no-issues" *ngIf="issuesArray.controls.length == 0">
				There are no issues currently attached to this task.
			</div>
			<div class="actions">
				<button
					mat-button
					type="button"
					[disabled]="issuesForm.disabled"
					(click)="addIssue()">
					Add Issue
				</button>
			</div>
			<div class="actions">
				<button
					mat-button
					[disabled]="issuesForm.pristine || issuesForm.disabled"
					type="button"
					(click)="buildForm()">
					Reset
				</button>
				<button
					mat-button
					[disabled]="
						issuesForm.invalid ||
						(issuesForm.pristine && removeIssueList.length == 0) ||
						issuesForm.disabled
					"
					type="submit">
					Save
				</button>
			</div>
		</form>
	</mat-card-content>
</mat-card>
