import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, type OnInit } from '@angular/core';
import { OfferBackendService } from '../services/offer-backend.service';
import { ActivatedRoute, Router } from '@angular/router';
import { Property } from '../services/properties-backend.service';
import {
	FormArray,
	FormBuilder,
	FormControl,
	FormGroup,
	ValidationErrors,
	Validators,
} from '@angular/forms';
import { environment } from 'src/environments/environment';
import { ChangeDetectorRef } from '@angular/core';
import { PeopleBackendService } from '../services/people-backend.service';

type IFromeErrors = {
	key: string;
	subErrors?: IFromeErrors[];
	errors?: ValidationErrors;
};

@Component({
	selector: 'app-offer',
	standalone: false,
	templateUrl: './offer.component.html',
	styleUrls: ['./offer.component.css'],
	changeDetection: ChangeDetectionStrategy.Default,
})
export class OfferComponent implements OnInit {
	loading = false;
	propertyId?: string;
	property?: Property;
	$prams: any;
	$queryPrams: any;

	form = this.fb.group<{
		offerAmount: FormControl<number | null>;
		offerStipulations: FormControl<string | null>;
		buyers: FormArray<any>;
		mortgage: FormControl<boolean | null>;
		mortgageInPrincipal: FormControl<boolean | null>;
		usingMortgageBroker: FormControl<boolean | null>;
		depositPercentage: FormControl<number | null>;
		confirmFunds: FormControl<boolean | null>;
		mortgageBroker: FormArray<any>;
		currentAddress: FormGroup<any>;
		onwardChain: FormControl<boolean | null>;
		sellingCurrentAddress: FormControl<boolean | null>;
		sellingAddress: FormGroup<any>;
		statusOfSale: FormControl<string | null>;
		agent: FormArray<any>;
		propertiesInChain: FormControl<number | null>;
		chainComplete: FormControl<boolean | null>;
		haveAConveyancer: FormControl<boolean | null>;
		conveyancer: FormArray<any>;
		additionalInformation: FormControl<string | null>;
		confirmed: FormControl<boolean | null>;
		confirmedContract: FormControl<boolean | null>;
		confirmedTerms: FormControl<boolean | null>;
		signature: FormControl<string | null>;
		fullName: FormControl<string | null>;
	}>({
		offerAmount: this.fb.control(null, [Validators.required]),
		offerStipulations: this.fb.control(''),
		buyers: this.fb.array([
			this.fb.group({
				firstName: this.fb.control('', [Validators.required]),
				lastName: this.fb.control('', [Validators.required]),
				email: this.fb.control('', [Validators.required, Validators.email]),
				phoneCountryCode: this.fb.control('+44', [Validators.required]),
				phoneInCountryNumber: this.fb.control('', [
					Validators.required,
					Validators.pattern('^[1-9]\\d{9}'),
				]),
				company: this.fb.control(null),
				occupation: this.fb.control(null),
				dontSendEmail: this.fb.control(false),
			}),
		]),
		mortgage: this.fb.control(false, [Validators.required]),
		depositPercentage: this.fb.control(null),
		confirmFunds: this.fb.control(false, [Validators.requiredTrue]),
		mortgageInPrincipal: this.fb.control(false),
		usingMortgageBroker: this.fb.control(false),
		mortgageBroker: this.fb.array([]),
		currentAddress: this.fb.group({
			postcode: [environment.postcode, [Validators.required]],
			address: ['', [Validators.required]],
			addressline1: [null],
			addressline2: [null],
			number: [null],
			premise: [null],
			street: [null],
			posttown: [null],
			county: [null],
			UPRN: ['', [Validators.required]],
		}),
		onwardChain: this.fb.control(null, [Validators.required]),
		sellingCurrentAddress: this.fb.control(true),
		sellingAddress: this.fb.group({
			postcode: [environment.postcode],
			address: [''],
			addressline1: [null],
			addressline2: [null],
			number: [null],
			premise: [null],
			street: [null],
			posttown: [null],
			county: [null],
			UPRN: [''],
		}),
		statusOfSale: this.fb.control(null),
		agent: this.fb.array([]),
		propertiesInChain: this.fb.control(null),
		chainComplete: this.fb.control(null),
		haveAConveyancer: this.fb.control(null),
		conveyancer: this.fb.array([]),
		additionalInformation: this.fb.control(''),
		confirmed: this.fb.control(null, [Validators.requiredTrue]),
		confirmedContract: this.fb.control(null, [Validators.requiredTrue]),
		confirmedTerms: this.fb.control(null, [Validators.requiredTrue]),
		signature: this.fb.control(null, [Validators.required]),
		fullName: this.fb.control('', [Validators.required]),
	});

	$changesMortgageInPrincipal?: any;
	$changesUsingMortgageBroker?: any;
	$changesOnwardChain?: any;
	$changesStatusOfSale?: any;
	$changesHaveAConveyancer?: any;

	offerId?: string;

	constructor(
		public OfferBackend: OfferBackendService,
		public route: ActivatedRoute,
		public router: Router,
		public fb: FormBuilder,
		public peopleBackend: PeopleBackendService,
		public changeDetectorRef: ChangeDetectorRef
	) {}
	ngOnInit(): void {
		this.loading = true;

		// Subscribe to route params to get propertyId and offerId
		if (this.$prams?.unsubscribe) this.$prams.unsubscribe();
		this.$prams = this.route.params.subscribe(async (params: any) => {
			this.propertyId = params['propertyId'];
			this.offerId = this.route.snapshot.queryParams['offer'];

			// Fetch property data from the backend
			await this.OfferBackend.getOne(this.propertyId || '', this.offerId)
				.then((res) => {
					this.property = res.data;
					this.loading = false;
				})
				.catch((err) => {
					this.loading = false;
					console.log(err);
					this.property = undefined;
				});
		});

		// Subscribe to query params to get offerId
		if (this.$queryPrams?.unsubscribe) this.$queryPrams.unsubscribe();
		this.$queryPrams = this.route.queryParams.subscribe((params: any) => {
			this.offerId = params['offer'];
		});

		// Subscribe to value changes of mortgageInPrincipal form control
		if (this.$changesUsingMortgageBroker?.unsubscribe)
			this.$changesUsingMortgageBroker.unsubscribe();
		this.$changesUsingMortgageBroker = this.form
			.get('usingMortgageBroker')
			?.valueChanges.subscribe((value: any) => {
				this.setUpMortgageInPrincipal(value);
			});

		// Subscribe to value changes of onwardChain form control
		if (this.$changesOnwardChain?.unsubscribe)
			this.$changesOnwardChain.unsubscribe();
		this.$changesOnwardChain = this.form
			.get('onwardChain')
			?.valueChanges.subscribe((value: any) => {
				this.setUpOnwardChain(value);
			});

		// Subscribe to value changes of statusOfSale form control
		if (this.$changesStatusOfSale?.unsubscribe)
			this.$changesStatusOfSale.unsubscribe();
		this.$changesStatusOfSale = this.form
			.get('statusOfSale')
			?.valueChanges.subscribe((value: any) => {
				this.setUpStatusOfSale(value);
			});

		// Subscribe to value changes of haveAConveyancer form control
		if (this.$changesHaveAConveyancer?.unsubscribe)
			this.$changesHaveAConveyancer.unsubscribe();
		this.$changesHaveAConveyancer = this.form
			.get('haveAConveyancer')
			?.valueChanges.subscribe((value: any) => {
				this.setUpHaveAConveyancer(value);
			});

		// Subscribe to value changes of the entire form
		this.form.valueChanges.subscribe((value) => {
			this.save();
		});

		// Load previous form data from local storage
		let oldData = localStorage.getItem('offer');
		if (oldData) {
			let data = JSON.parse(oldData);
			this.form.patchValue(data);
		}
	}
	ngOnDestroy() {
		if (this.$prams?.unsubscribe) this.$prams.unsubscribe();
		if (this.$changesMortgageInPrincipal?.unsubscribe)
			this.$changesMortgageInPrincipal.unsubscribe();
		if (this.$changesOnwardChain?.unsubscribe)
			this.$changesOnwardChain.unsubscribe();
		if (this.$changesStatusOfSale?.unsubscribe)
			this.$changesStatusOfSale.unsubscribe();
		if (this.$changesHaveAConveyancer?.unsubscribe)
			this.$changesHaveAConveyancer.unsubscribe();
	}

	ngAfterViewChecked(): void {
		this.changeDetectorRef.detectChanges();
	}

	buildPerson() {
		return this.fb.group({
			firstName: [environment.firstName, [Validators.required]],
			lastName: [environment.lastName, [Validators.required]],
			email: [
				environment.email,
				[Validators.required, Validators.email],
				//[this.peopleBackend.validEmail()],
			],
			phoneCountryCode: ['+44', [Validators.required]],
			phoneInCountryNumber: [
				'',
				[Validators.required, Validators.pattern('^[1-9]\\d{9}')],
			],
			company: [null],
			occupation: [null],
			dontSendEmail: [false],
		});
	}

	setUpMortgageInPrincipal(value: any) {
		let mortgageBroker: FormArray | undefined = this.form.get(
			'mortgageBroker'
		) as FormArray;
		if (!mortgageBroker) return;
		if (value) {
			mortgageBroker.push(this.buildPerson());
		} else if (mortgageBroker.value.length > 0) {
			mortgageBroker.clear();
		}
	}

	setUpOnwardChain(value: any) {
		let statusOfSale: FormControl | undefined = this.form.get(
			'statusOfSale'
		) as FormControl;
		if (!statusOfSale) return;
		if (value) {
			statusOfSale.setValidators([Validators.required]);
		} else {
			statusOfSale.clearValidators();
			statusOfSale.updateValueAndValidity();
			let agent: FormArray | undefined = this.form.get('agent') as FormArray;
			agent?.clear();
			let propertiesInChain: FormControl | undefined = this.form.get(
				'propertiesInChain'
			) as FormControl;
			propertiesInChain?.clearValidators();
			propertiesInChain.updateValueAndValidity();
		}
	}

	setUpStatusOfSale(value: any) {
		let agent: FormArray | undefined = this.form.get('agent') as FormArray;
		if (!agent) return;
		if (
			(value == 'listed' || value == 'offerAccepted') &&
			agent.value.length == 0
		) {
			agent.push(this.buildPerson());
		} else if (
			!(value == 'listed' || value == 'offerAccepted') &&
			agent.value.length > 0
		) {
			agent.clear();
		}
		let propertiesInChain: FormControl | undefined = this.form.get(
			'propertiesInChain'
		) as FormControl;
		if (!propertiesInChain) return;
		if (value == 'offerAccepted') {
			propertiesInChain.setValidators([Validators.required]);
		} else {
			propertiesInChain.clearValidators();
			propertiesInChain.updateValueAndValidity();
		}
	}

	setUpHaveAConveyancer(value: any) {
		let conveyancer: FormArray | undefined = this.form.get(
			'conveyancer'
		) as FormArray;
		if (!conveyancer) return;
		if (value) {
			conveyancer.push(this.buildPerson());
		} else if (conveyancer.value.length > 0) {
			conveyancer.clear();
		}
	}

	allErrors() {
		let errors = this.findErrorsRecursive(this.form);
		if (!errors) return null;
		let buyers = errors?.subErrors?.find((e) => e.key === 'Buyer/s');
		if (buyers?.subErrors) {
			errors.subErrors = errors?.subErrors?.filter((e) => e.key !== 'Buyer/s');
			buyers.subErrors?.forEach((e) => {
				errors?.subErrors?.push(e);
			});
		}
		//Estate Agent
		let agent = errors?.subErrors?.find((e) => e.key === 'Estate Agent');
		if (agent?.subErrors) {
			errors.subErrors = errors?.subErrors?.filter(
				(e) => e.key !== 'Estate Agent'
			);
			agent.subErrors?.forEach((e) => {
				errors?.subErrors?.push(e);
			});
		}
		//Mortgage Broker
		let mortgageBroker = errors?.subErrors?.find(
			(e) => e.key === 'Mortgage Broker'
		);
		if (mortgageBroker?.subErrors) {
			errors.subErrors = errors?.subErrors?.filter(
				(e) => e.key !== 'Mortgage Broker'
			);
			mortgageBroker.subErrors?.forEach((e) => {
				errors?.subErrors?.push(e);
			});
		}
		return errors;
	}

	/**
	 * Recursively finds errors in a FormGroup or FormArray.
	 * @param group - The FormGroup or FormArray to check for errors.
	 * @param key - The key name for the current group or control.
	 * @returns An IFromeErrors object representing the errors found, or null if no errors are found.
	 */
	findErrorsRecursive(
		group: FormGroup | FormArray,
		key: string = 'form'
	): IFromeErrors | null {
		// Map of key names for different controls
		const keyNames = new Map([
			['form', 'Offer Form Errors'],
			['offerAmount', 'Offer Amount'],
			['buyers', `Buyer/s`],
			[`firstName`, `First Name`],
			[`lastName`, `Last Name`],
			[`email`, `Email`],
			[`phoneInCountryNumber`, `Phone Number`],
			['currentAddress', 'Current Address'],
			['address', 'Street Address'],
			['postcode', 'Postcode'],
			['UPRN', 'Confirmed Address'],
			['confirmed', 'Confirmed Information Provided'],
			[
				'confirmedContract',
				'Confirmed Understand That A Contract May Be Generated',
			],
			['confirmedTerms', 'Agreed To The Terms & Conditions'],
			['signature', 'Your Signature'],
			['fullName', 'Your Full Name'],
			['mortgageBroker', 'Mortgage Broker'],
			['agent', 'Estate Agent'],
			['sellingAddress', `Property Im Selling's Address`],
			['confirmFunds', 'Confirm You Have The Funds Required'],
			['0', 'First'],
			['1', 'Second'],
			['2', 'Third'],
			['3', 'Fourth'],
			['4', 'Fifth'],
			['5', 'Sixth'],
			['6', 'Seventh'],
			['7', 'Eighth'],
			['8', 'Ninth'],
			['9', 'Tenth'],
		]);

		// Set of numbers
		const numbers = new Set(['0', '1', '2', '3', '4', '5', '6', '7', '8', '9']);

		// Object to store the errors
		const errors: IFromeErrors = {
			key: keyNames.get(key) || key,
		};

		// If the group is null, return null
		if (group == null) {
			return null;
		}

		let idx = 0;
		// Iterate over the controls in the group
		Object.keys(group.controls).forEach((name: string) => {
			idx++;
			const control = group.get(name);
			// If the control is a FormArray or FormGroup, recursively call findErrorsRecursive
			if (control instanceof FormArray || control instanceof FormGroup) {
				let _key = keyNames.get(name) || name;
				// If the name is a number, append the key name of the parent group
				if (numbers.has(name.toString())) {
					_key = (keyNames.get(name) || name) + ' ' + errors.key;
				}

				const tmpErrors = this.findErrorsRecursive(
					control as FormArray | FormGroup,
					_key
				);
				if (tmpErrors != null) {
					if (!errors.subErrors) errors.subErrors = [];
					errors.subErrors.push(tmpErrors);
				}
			}
			// If the control is a FormControl and has errors, add it to the errors object
			else if (control instanceof FormControl && control.errors != null) {
				if (group instanceof FormArray) {
					if (!errors.subErrors) errors.subErrors = [];
					errors.subErrors.push({
						key: idx.toString(),
						errors: control.errors,
					});
				} else {
					if (!errors.subErrors) errors.subErrors = [];
					errors.subErrors.push({
						key: keyNames.get(name) || name,
						errors: control.errors,
					});
				}
			}
		});

		// If there are no subErrors and no errors, return null
		return (!errors.subErrors || errors.subErrors.length === 0) &&
			!errors.errors
			? null
			: errors;
	}

	summery() {
		let s = `This is an offer for the amount of £${
			this.form.get('offerAmount')?.value
		}.`;

		let stipulations = this.form.get('offerStipulations')?.value;
		if (stipulations) {
			s += ` The following stipulations apply: ${stipulations}.`;
		}

		let buyers = this.form.get('buyers') as FormArray;
		let onwardChain = this.form.get('onwardChain')?.value;
		let chainComplete = this.form.get('chainComplete')?.value;
		let propertiesInChain = this.form.get('propertiesInChain')?.value;
		let mortgage = this.form.get('mortgage')?.value;
		let _mortgage = mortgage ? '' : 'cash ';
		let _chain = onwardChain
			? `with ${
					chainComplete
						? `a complete chain of ${propertiesInChain} onward properties.`
						: propertiesInChain
						? `an incomplete chain of at least ${propertiesInChain} onward properties.`
						: `an onward chain.`
			  }`
			: `and they are chain free.`;
		if (onwardChain) {
		}
		s += ` There ${buyers.controls.length == 1 ? 'is' : 'are'} ${
			buyers.controls.length
		} ${_mortgage}buyer${buyers.controls.length == 1 ? '' : 's'} ${_chain}`;

		let additionalInformation = this.form.get('additionalInformation')?.value;
		if (additionalInformation && additionalInformation.length > 0) {
			s += `  Additionally: ${additionalInformation}`;
		}

		s = s.trim();

		if (s[s.length - 1] != '.') s += '.';

		return s;
	}

	save() {
		let data = this.form.value;
		data = { ...data };
		delete data.offerAmount;
		delete data.signature;
		delete data.confirmed;
		delete data.confirmedContract;
		delete data.confirmedTerms;
		delete data.fullName;
		delete data.confirmFunds;

		localStorage.setItem('offer', JSON.stringify(data));
	}

	async submit() {
		if (this.form.valid) {
			let data = {
				...this.form.value,
				summery: this.summery(),
			};
			this.loading = true;
			try {
				await this.OfferBackend.patch(
					this.propertyId || '',
					data as any,
					this.offerId
				);
				this.router.navigate([`/make-an-offer/${this.propertyId}/submitted`], {
					queryParams: { offer: this.offerId, hideSide: 'true' },
				});
			} catch (error) {}

			this.loading = false;
		}
	}

	materialInfo() {
		this.router.navigate([`/material-information/${this.propertyId}`], {
			queryParams: { hideSide: 'true' },
		});
	}
}
