import { ChangeDetectionStrategy, Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import {
	MaterialInfoBackendService,
	materialInfoData,
} from '../services/materialInfoBackend.service';
import { switchMap, tap } from 'rxjs';
import { PropertiesBackendService } from '../services/properties-backend.service';

@Component({
	selector: 'app-material-information',
	templateUrl: './materialInformation.component.html',
	styleUrls: ['./materialInformation.component.css'],
})
export class MaterialInformationComponent {
	loading = true;
	$prams: any;
	propertyId?: string = '';
	materialInfo?: materialInfoData;
	constructor(
		public route: ActivatedRoute,
		public router: Router,
		public materialBackend: MaterialInfoBackendService
	) {
		this.$prams = this.route.params
			.pipe(
				tap((params) => {
					this.propertyId = params['propertyId'];
				}),
				switchMap(async (prams) => {
					let materialR = await this.materialBackend.getOne(
						prams['propertyId']
					);
					this.materialInfo = materialR.data;
					this.loading = false;
					return prams;
				})
			)
			.subscribe((params) => {});
	}

	ngOnDestroy() {
		if (this.$prams?.unsubscribe) this.$prams.unsubscribe();
	}

	get indoorSpaces() {
		if (this.materialInfo?.spacesForm?.spaces) {
			return this.materialInfo.spacesForm.spaces.filter(
				(space) => space.indoorOutdoor === 'indoor'
			);
		}
		return [];
	}

	additionalInfo(room: {
		indoorOutdoor?: string;
		type?: string;
		width?: number;
		length?: number;
		ceilingHeight?: number;
		ceilingType?: string;
		shape?: string;
		shower?: boolean;
		bath?: boolean;
		toilet?: boolean;
		name?: string;
	}) {
		let infos = [];
		if (room.shape == 'irregular') {
			infos.push(`Irregularly Shaped`);
		}

		if ((room.ceilingType || []).length > 1) {
			infos.push(`Partially Sloped Ceiling`);
		}
		if (
			(room.ceilingType || []).length == 1 &&
			room.ceilingType?.includes('sloped')
		) {
			infos.push(`Sloped Ceiling`);
		}

		if (room.shower) {
			infos.push(`Shower`);
		}
		if (room.bath) {
			infos.push(`Bath`);
		}
		if (room.toilet) {
			if (room.bath || room.shower) infos.push(`Toilet`);
			else infos.push(`Only A Toilet`);
		}

		return infos;
	}

	get numberOfBedrooms() {
		return this.indoorSpaces.filter((r) => r.type === 'Bedroom').length;
	}

	get numberOfBathrooms() {
		return this.indoorSpaces.filter((r) => r.type === 'Bathroom').length;
	}

	get outdoorSpaces() {
		if (this.materialInfo?.spacesForm?.spaces) {
			return this.materialInfo.spacesForm.spaces.filter(
				(space) => space.indoorOutdoor === 'outdoor'
			);
		}
		return [];
	}

	goTo(id: string) {
		const element = document.getElementById(id);

		if (element)
			element.scrollIntoView({
				behavior: 'smooth',
				block: 'start',
				inline: 'nearest',
			});
	}

	requestViewing() {
		//template email to agents
		let emails = (this.materialInfo?.agents || [])
			.map((a) => a.email)
			.join('; ');
		if (emails.length == 0) throw new Error('No agents found');
		let names = (this.materialInfo?.agents || [])
			.map((a) => a.firstName)
			.join(', ')
			.replace(/, ((?:.(?!, ))+)$/, ' and $1');
		let subject = `Request Viewing for ${this.materialInfo?.address}`;
		let body = `Hi ${names}, 
I would like to request a viewing for the property at ${this.materialInfo?.address}. Please let me know when you are available. 

Thank you.`;
		let url = `mailto:${emails}?subject=${subject}&body=${body}`;
		let urlEncoded = encodeURI(url);
		window.open(urlEncoded, '_blank');
	}

	askQuestion() {
		//template email to agents
		let emails = (this.materialInfo?.agents || [])
			.map((a) => a.email)
			.join('; ');
		if (emails.length == 0) throw new Error('No agents found');
		let names = (this.materialInfo?.agents || [])
			.map((a) => a.firstName)
			.join(', ')
			.replace(/, ((?:.(?!, ))+)$/, ' and $1');
		let subject = `Question about ${this.materialInfo?.address}`;
		let body = `Hi ${names},
I have a question about the property at ${this.materialInfo?.address}. Could you please help me with this?

Thank you.`;
		let url = `mailto:${emails}?subject=${subject}&body=${body}`;
		let urlEncoded = encodeURI(url);
		window.open(urlEncoded, '_blank');
	}

	submitOffer() {
		this.router.navigate(['/make-an-offer', this.propertyId], {
			queryParams: { hideSide: true },
		});
	}
}
