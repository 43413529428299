import { Component } from '@angular/core';
import { Email, EmailBackendService } from '../services/email-backend.service';
import { TitleService } from '../services/title.service';
import { Router } from '@angular/router';

@Component({
	selector: 'app-admin-inbox',
	templateUrl: './admin-inbox.component.html',
	styleUrls: ['./admin-inbox.component.css'],
})
export class AdminInboxComponent {
	loading = false;
	emails: Email[] = [];
	filter: 'ALL' | 'NEEDSREVIEW' | 'REVIEWED' = 'ALL';
	pool?: any;
	lastKey?: string | undefined;
	_lastKey?: string | undefined;

	constructor(
		public emailBackend: EmailBackendService,
		public titleService: TitleService,
		public router: Router
	) {}

	async ngOnInit() {
		this.titleService.title = 'Admin Inbox';
		await this.loadEMails();
		// this.pool = setInterval(async () => {
		// 	await this.loadEMails(false);
		// }, 1000 * 10);
	}

	ngOnDestroy() {
		//clearInterval(this.pool);
	}

	async loadEMails(showLoading = true) {
		if (showLoading) this.emails = [];
		if (showLoading) this.loading = true;
		let emailR = await this.emailBackend.getAdminList(
			this.filter,
			this.lastKey
		);
		if (!this.lastKey) this.emails = emailR.data || [];
		else this.emails = [...this.emails, ...(emailR.data || [])];
		this._lastKey = emailR.lastKey;
		if (showLoading) this.loading = false;
	}

	openEmail(email: Email) {
		this.router.navigate(['/', 'Admin', 'Inbox', email.id]);
	}

	async setFilter(ev: any) {
		this.filter = ev.value;
		this._lastKey = undefined;
		this.lastKey = undefined;
		await this.loadEMails();
	}

	emailTrackBy(index: number, email: Email) {
		return email.id;
	}

	async loadMore() {
		//clearInterval(this.pool);
		this.lastKey = this._lastKey;
		await this.loadEMails(false);
	}
}
