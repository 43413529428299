<h1 mat-dialog-title>
	<mat-icon>{{
		issueForm.get("type")?.value == "bug" ? "bug_report" : "tips_and_updates"
	}}</mat-icon>
	{{ issueForm.get("type")?.value == "bug" ? "Report Bug" : "Suggest Feature" }}
</h1>
<mat-dialog-content>
	<form [formGroup]="issueForm" style="margin-top: 1rem">
		<div>
			<mat-form-field appearance="outline" floatLabel="always">
				<mat-label>Issue Type</mat-label>
				<mat-select formControlName="type">
					<mat-option value="bug"> Bug </mat-option>
					<mat-option value="enhancement"> New Feature </mat-option>
				</mat-select>
			</mat-form-field>
		</div>
		<div>
			<mat-form-field appearance="outline" floatLabel="always">
				<mat-label>Title</mat-label>
				<input
					matInput
					formControlName="title"
					name="title"
					placeholder="Subject line the likes of 'Can't add a conveyancer to my property'.." />
			</mat-form-field>
		</div>
		<div>
			<mat-form-field appearance="outline" floatLabel="always">
				<mat-label>Description</mat-label>
				<textarea
					matInput
					class="description"
					placeholder="Describe the bug or feature request. Give as much detail as possible. What were you doing? What happened? What did you expect to happen?"
					formControlName="description"
					name="description"></textarea>
			</mat-form-field>
		</div>

		<div *ngIf="!issueForm.get('screenShot')?.disabled">
			<section class="example-section">
				<mat-checkbox formControlName="screenShot"
					>Include Screenshot</mat-checkbox
				>
			</section>
			<div>
				Including a screenshot gives us a better idea of what you're seeing. A
				model will pop up asking you to select this window/tab and "Share" it.
			</div>
		</div>
	</form>
</mat-dialog-content>
<mat-dialog-actions>
	<button mat-button [mat-dialog-close]="false" type="button">Cancel</button>
	<button
		mat-button
		[mat-dialog-close]="issueForm.value"
		[disabled]="issueForm.disabled || issueForm.invalid"
		type="button"
		cdkFocusInitial>
		Report
	</button>
</mat-dialog-actions>
