import { animate, style, transition, trigger } from '@angular/animations';
import { Component, Input } from '@angular/core';
import { Viewer } from 'src/app/services/task-backend.service';
import { Unsubscribe } from 'src/app/services/unsubscribe';
import { userType } from 'src/app/setup/httpTypes';
import { BreakpointObserver } from '@angular/cdk/layout';

@Component({
	selector: 'app-viewers',
	templateUrl: './viewers.component.html',
	styleUrls: ['./viewers.component.css'],
	animations: [
		trigger('inOutAnimation', [
			transition(':enter', [
				style({ height: 0, opacity: 0, overflow: 'hidden' }),
				animate('300ms ease-out', style({ height: '*', opacity: 1 })),
			]),
			transition(':leave', [
				style({ height: '*', opacity: 1, overflow: 'hidden' }),
				animate('300ms ease-in', style({ height: 0, opacity: 0 })),
			]),
		]),
	],
})
export class ViewersComponent {
	@Input() state?:
		| 'completed'
		| 'inProgress'
		| 'notStarted'
		| 'disabled'
		| 'cancelled' = 'notStarted';
	formOpen = true;

	@Input() viewers?: Viewer[];
	@Input() viewableBy?: userType[];

	@Unsubscribe() $isSmall?: any;
	constructor(public BreakpointObserver: BreakpointObserver) {}

	ngOnInit() {
		this.$isSmall = this.BreakpointObserver.observe(
			'(max-width: 800px)'
		).subscribe((res) => {
			if (res.matches) {
				this.displayedColumns = ['userType', 'rights'];
			} else {
				this.displayedColumns = [
					'name',
					// 'email',
					// 'phone',
					'company',
					'userType',
					'rights',
				];
			}
		});
	}

	toggleForm() {
		this.formOpen = !this.formOpen;
	}

	displayedColumns: string[] = [
		'name',
		'email',
		'phone',
		'company',
		'userType',
		'rights',
	];
}
