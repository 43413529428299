// export enum propertyStates {
//     Looking = 'looking',
//     OfferAccepted = 'offerAccepted',
//     OfferPutIn = 'offerPutIn',
//     RequestingInvite = 'requestingInvite',
//     NoProperty = 'noProperty',
// }

// export interface person {
//     firstName?: string;
//     lastName?: string;
//     email: string;
//     phone?: string;
//     company?: string;
//     isPrimary?: boolean;
// }

export enum userType {
	buyer = 'Buyer',
	seller = 'Seller',
	buyerSeller = 'Buyer/Seller',
	sellersAgent = 'Estate Agent',
	buyersAgent = 'Buyers Agent',
	mortgageBroker = 'Mortgage Broker',
	BuyersConveyancer = 'Buyers Conveyancer',
	SellersConveyancer = 'Sellers Conveyancer',
	Other = 'Other',
}

// export interface body {
//     userType: userType;
//     firstName: string
//     lastName?: string
//     phone?: string
// }

// export interface potentialBuyerBody extends body {
//     userType: userType.potentialBuyer;
//     otherBuyers: person[];
//     mortgageBrokers: person[];
//     preMortgage?:number;
//     requireMortgage?:boolean;
// }

// export interface buyerBody extends body {
//     userType: userType.buyer;
//     otherBuyers: person[];
//     mortgageBrokers: person[];
//     agents: person[];
//     conveyancers: person[];
//     address?:string;
//     postCode?:string;
//     UPRN?:string;
//     agreedPrice?:number;
//     preMortgage?:number;
//     offerDate?:number;
//     requireMortgage?:boolean;
// }

// export interface sellerBody extends body {
//     userType: userType.seller;
//     otherSellers: person[];
//     agents: person[];
//     conveyancers: person[];
//     address?:string;
//     postCode?:string;
//     UPRN?:string;
//     agreedPrice?:number;
//     offerDate?:number;
// }

// export interface buyerSellerBody extends body {
//     userType: userType.buyerSeller;
//     otherBuyersSellers: person[];
//     selling:{
//         agents: person[];
//         address?:string;
//         postCode?:string;
//         UPRN?:string;
//         agreedPrice?:number;
//         offerDate?:number;
//     };
//     buying:{
//         agents: person[];
//         address?:string;
//         postCode?:string;
//         UPRN?:string;
//         agreedPrice?:number;
//         offerDate?:number;
//         mortgageBrokers: person[];
//         requireMortgage?:boolean;
//         preMortgage?:number;
//     }
//     conveyancers: person[];
// }
