<mat-spinner *ngIf="saving"></mat-spinner>
<mat-card *ngIf="!saving" appearance="outlined">
	<mat-card-header>
		<mat-card-title>
			<h3>
				New Property At The {{ this.sellersId ? "Start" : "End" }} Of The Chain
			</h3>
		</mat-card-title>
	</mat-card-header>
	<mat-card-content>
		<form [formGroup]="propertyFormGroup" (ngSubmit)="save()">
			<div class="row row-bottom-padding">
				<app-address
					[canMerge]="this.sellersId ? 'seller' : 'buyer'"
					[chainId]="this.chainId"
					[buyerSellerId]="this.sellersId || this.buyersId"
					[form]="$any(propertyFormGroup.get('address'))"></app-address>
				<app-people
					[form]="$any(propertyFormGroup.get('agents'))"
					[onlyOne]="false"
					[atLeastOne]="false"
					title="Estate Agent"></app-people>
			</div>
		</form>
	</mat-card-content>
	<mat-card-actions>
		<div class="actions">
			<button
				mat-button
				[disabled]="propertyFormGroup.disabled || propertyFormGroup.pristine">
				Rest
			</button>
			<button
				mat-raised-button
				(click)="save()"
				[disabled]="
					propertyFormGroup.disabled ||
					propertyFormGroup.invalid ||
					propertyFormGroup.pristine
				">
				<span *ngIf="!saving"> Save </span>
				<span *ngIf="saving">
					Saving<app-loading-dots></app-loading-dots>
				</span>
			</button>
		</div>
	</mat-card-actions>
</mat-card>
