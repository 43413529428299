import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { AuthService } from 'src/app/services/auth.service';
import { ConfirmDialogService } from 'src/app/services/confirm-dialog.service';

import {
	Inquiry,
	InquiryResponse,
} from 'src/app/services/inquiry-backend.service';

@Component({
	selector: 'app-inquiry',
	templateUrl: './inquiry.component.html',
	styleUrls: ['./inquiry.component.css'],
})
export class InquiryComponent {
	shouldYouBeResponding = false;
	_inquiry: Inquiry | undefined;
	@Input() set inquiry(val: Inquiry | undefined) {
		this._inquiry = val;
		this.filterResponses(val?.responses || []);
		let lastResponse = val?.responses[val?.responses.length - 1];
		if (this.canEdit) {
			if (!lastResponse) {
				this.shouldYouBeResponding = false;
			} else if (lastResponse.userType != val?.createdByUserType) {
				this.shouldYouBeResponding = true;
			} else {
				this.shouldYouBeResponding = false;
			}
		} else {
			if (!lastResponse) {
				this.shouldYouBeResponding = true;
			} else if (lastResponse.userType != val?.createdByUserType) {
				this.shouldYouBeResponding = true;
			} else {
				this.shouldYouBeResponding = false;
			}
		}
	}
	get inquiry(): Inquiry | undefined {
		return this._inquiry;
	}
	_canEdit = false;
	@Input() set canEdit(val: boolean) {
		this._canEdit = val;
		let lastResponse =
			this._inquiry?.responses[this._inquiry?.responses.length - 1];
		if (this.canEdit) {
			if (!lastResponse) {
				this.shouldYouBeResponding = false;
			} else if (lastResponse.userType != this._inquiry?.createdByUserType) {
				this.shouldYouBeResponding = true;
			} else {
				this.shouldYouBeResponding = false;
			}
		} else {
			if (!lastResponse) {
				this.shouldYouBeResponding = true;
			} else if (lastResponse.userType != this._inquiry?.createdByUserType) {
				this.shouldYouBeResponding = true;
			} else {
				this.shouldYouBeResponding = false;
			}
		}
	}
	get canEdit() {
		return this._canEdit;
	}
	@Output() newResponse = new EventEmitter<string>();
	@Output() resolved = new EventEmitter<string>();
	@Output() unresolved = new EventEmitter<string>();
	@Output() deleted = new EventEmitter<string>();

	_showAllResponses = false;
	get showAllResponses() {
		return this._showAllResponses;
	}
	set showAllResponses(val: boolean) {
		this._showAllResponses = val;
		this.filterResponses(this.inquiry?.responses || []);
	}

	responses: InquiryResponse[] = [];

	newResourceForm = this.fb.group({
		response: ['', [Validators.required]],
	});

	//currentUserType: any;

	constructor(
		public fb: FormBuilder,
		public confirmDialog: ConfirmDialogService
	) {
		// this.authService.getUserAttributes().then((attributes) => {
		// 	this.currentUserType = attributes['custom:user_type'];
		// });
	}

	async respond() {
		this.newResponse.emit(
			this.newResourceForm.value.response?.replace(/(\r\n|\n|\r)/gm, '\n') || ''
		);

		this.newResourceForm.reset();
	}

	async resolve() {
		this.resolved.emit(this.inquiry?.id || '');
	}

	async unResolve() {
		this.unresolved.emit(this.inquiry?.id || '');
	}

	async delete() {
		let r = await this.confirmDialog.confirm({
			title: 'Delete Inquiry',
			message: `Are you sure you want to delete the "${this.inquiry?.inquiry?.substring(
				0,
				100
			)}${(this.inquiry?.inquiry?.length || 0) > 100 ? '...' : ''}" inquiry?`,
			confirmText: 'Delete',
		});
		if (!r) return;

		this.deleted.emit(this.inquiry?.id || '');
	}

	filterResponses(responses: InquiryResponse[]) {
		if (responses.length <= 1) {
			this.responses = responses;
		} else if (this.showAllResponses) {
			this.responses = responses;
		} else this.responses = [responses[responses.length - 1]];
	}

	trackByFn(index: number, item: InquiryResponse) {
		return item.id;
	}

	getTimeUntilDate(date: Date | any) {
		date = new Date(date);
		const now = new Date();
		const timeDiff = date.getTime() - now.getTime();
		const days = Math.floor(timeDiff / (1000 * 60 * 60 * 24));
		if (days < 0) return `${Math.abs(days)} day${days < -1 ? 's' : ''} ago`;
		if (days == 0) return `today`;
		if (days < 7) {
			return `in ${days} day${days > 1 ? 's' : ''}`;
		} else {
			const weeks = Math.floor(days / 7);
			return `in ${weeks} weeks`;
		}
	}
}
