<mat-drawer-container>
	<mat-drawer
		#drawer
		mode="side"
		opened="true"
		style="background-color: #1e1e2c; color: #fff">
		<div class="left-drawer">
			<div
				style="text-align: center; margin: 2rem auto; min-width: 10rem"
				*ngIf="!mobile">
				<!-- <button
					mat-icon-button
					class="example-icon"
					aria-label="icon-button with menu icon for side nav"
					(click)="drawer.toggle()"
					style="margin: 0 8px">
					<mat-icon>menu</mat-icon>
				</button> -->
				<a routerLink="/"
					><img
						class="logo"
						src="/assets/logo-svg-sm.svg"
						alt="clear chain logo"
				/></a>
				<span><a class="name" routerLink="/">Clear Chain</a></span>
			</div>
			<div style="display: flex; align-items: center; margin: 1rem">
				<button
					*ngIf="mobile"
					mat-icon-button
					[matMenuTriggerFor]="menu"
					class="example-icon"
					aria-label="Menu for sign out and settings">
					<mat-icon style="color: #8a8a8a">account_circle</mat-icon>
				</button>
				<div
					class="user-info"
					[matMenuTriggerFor]="menu"
					*ngIf="mobile && hasLoggedIn">
					<div>
						{{ user?.attributes?.given_name }}
						{{ user?.attributes?.family_name }}
					</div>
					<div class="email">{{ user?.attributes?.email }}</div>
				</div>
			</div>

			<mat-nav-list>
				<a mat-list-item routerLink="/" (click)="closeDrawer()">
					<img
						src="/assets/figma-icons/akar-icons_grid.svg"
						class="link-icons" />
					Home</a
				>
				<a mat-list-item routerLink="/chains" (click)="closeDrawer()">
					<img
						src="/assets/figma-icons/akar-icons_link-chain.svg"
						class="link-icons" />Chains</a
				>
				<a mat-list-item routerLink="/properties" (click)="closeDrawer()"
					><img
						src="/assets/figma-icons/akar-icons_home-alt1.svg"
						class="link-icons" />Properties</a
				>
				<a mat-list-item routerLink="/tasks" (click)="closeDrawer()"
					><img
						src="/assets/figma-icons/akar-icons_check-box.svg"
						class="link-icons" />Tasks</a
				>
				<a mat-list-item routerLink="/requests" (click)="closeDrawer()"
					><img
						src="/assets/figma-icons/akar-icons_chat-add.svg"
						class="link-icons" />Access Requests</a
				>
				<a mat-list-item routerLink="/inbox" (click)="closeDrawer()"
					><img
						src="/assets/figma-icons/akar-icons_mail.svg"
						class="link-icons" />Inbox</a
				>
				<a
					*ngIf="isAgent"
					mat-list-item
					routerLink="/aml"
					(click)="closeDrawer()"
					><img
						src="/assets/figma-icons/akar-icons_person-check.svg"
						class="link-icons" />AML</a
				>
				<mat-list-item
					routerLink="/setup"
					style="text-align: center"
					(click)="closeDrawer()">
					<!-- <span style="margin-right: 0.5rem"> New Chain </span> -->

					<button
						mat-fab
						extended
						color="accent"
						aria-label="button to start a new chain">
						<mat-icon>add</mat-icon>
						New Chain
					</button>
				</mat-list-item>
			</mat-nav-list>

			<div class="spacer"></div>
			<mat-nav-list>
				<a mat-list-item routerLink="/about" (click)="closeDrawer()"
					><span style="margin-left: 1rem">About</span></a
				>
				<a mat-list-item routerLink="/contact" (click)="closeDrawer()"
					><span style="margin-left: 1rem">Contact</span></a
				>
				<a mat-list-item routerLink="/faq" (click)="closeDrawer()"
					><span style="margin-left: 1rem">FAQs</span></a
				>
			</mat-nav-list>
		</div>
	</mat-drawer>
	<mat-drawer-content [@routeAnimations]="getRouteAnimationData()">
		<mat-toolbar
			style="
				padding-left: 0;
				min-height: 64px;
				flex-shrink: 0;
				box-shadow: -2px -6px 9px 0px black;
				z-index: 1;
			">
			<mat-toolbar-row
				*ngIf="branch != 'app'"
				style="background-color: #f71481; height: 1.5rem; font-size: 1rem">
				<span class="example-spacer" style="flex: 1 1 auto"></span>
				<span> This is a {{ branch }} release! </span>
				<span class="hide-md" style="margin-left: 0.25rem">
					Please report any issues using the
					<a [routerLink]="['contact']">contact</a> page.</span
				>
				<span
					*ngIf="branch != 'beta'"
					class="hide-md"
					style="margin-left: 0.25rem">
					Data may be deleted without warning!</span
				>
				<span class="example-spacer" style="flex: 1 1 auto"></span>
			</mat-toolbar-row>

			<mat-toolbar-row
				*ngIf="!!AuthService.spoofAs"
				style="
					background-color: #f98f40;
					height: 1.5rem;
					font-size: 1rem;
					cursor: pointer;
				"
				[routerLink]="['/settings']">
				<span class="example-spacer" style="flex: 1 1 auto"></span>
				<span> You Are Spoofing The User {{ AuthService.spoofAs }} </span>
				<!-- <span class="hide-md" style="margin-left: 0.25rem">
			Please report any issues using the contact page. Data may be deleted
			without warning!</span
		> -->
				<span class="example-spacer" style="flex: 1 1 auto"></span>
			</mat-toolbar-row>
			<mat-toolbar-row class="main-toolbar" [class.mobile-toolbar]="mobile">
				<button
					*ngIf="mobile"
					mat-icon-button
					class="example-icon"
					aria-label="icon-button with menu icon for side nav"
					(click)="drawer.toggle()"
					style="margin: 0 8px">
					<mat-icon>menu</mat-icon>
				</button>
				<span class="example-spacer" style="flex: 1 1 auto"></span>
				<span *ngIf="mobile" class="title">{{ title }}</span>
				<span class="example-spacer" style="flex: 1 1 auto"></span>
				<button
					*ngIf="hasLoggedIn"
					mat-button
					class="example-icon hide-md"
					(click)="newFeature()"
					matTooltip="We love to hear your ideas, so let us know what you think."
					aria-label="Suggest Feature">
					<span>Suggest Feature</span>
					<mat-icon>tips_and_updates</mat-icon>
				</button>
				<button
					*ngIf="hasLoggedIn"
					mat-button
					class="example-icon hide-small"
					matTooltip="We like to squash them big or small, so let us know when you see one."
					(click)="newBug()"
					aria-label="Report Bugs">
					<span>Report Bugs</span>
					<mat-icon>bug_report</mat-icon>
				</button>
				<button
					*ngIf="hasLoggedIn"
					class="example-icon hide-large"
					mat-icon-button
					(click)="newBug()"
					aria-label="Report Bugs">
					<mat-icon>bug_report</mat-icon>
				</button>
				<button
					*ngIf="hasLoggedIn"
					mat-icon-button
					routerLink="/settings"
					class="example-icon hide-small"
					aria-label="Menu for sign out and settings"
					matTooltip="Settings">
					<mat-icon style="color: #8a8a8a">settings</mat-icon>
				</button>
				<button
					mat-icon-button
					[matMenuTriggerFor]="menu"
					matTooltip="Sign Out Menu"
					class="example-icon"
					aria-label="Menu for sign out and settings">
					<mat-icon style="color: #8a8a8a">account_circle</mat-icon>
				</button>
				<mat-menu #menu="matMenu">
					<!-- <button mat-menu-item (click)="getToken()">
			<mat-icon>generating_tokens</mat-icon>
			<span>JWT</span>
		</button> -->
					<button mat-menu-item (click)="signOut()" [disabled]="!hasLoggedIn">
						<mat-icon>logout</mat-icon>
						<span>Sign Out</span>
					</button>
					<button
						mat-menu-item
						routerLink="/settings"
						[disabled]="!hasLoggedIn">
						<mat-icon>manage_accounts</mat-icon>
						<span>Settings</span>
					</button>
					<button
						mat-menu-item
						(click)="checkForUpdate()"
						matTooltip="Click to check for updates">
						<mat-icon>upgrade</mat-icon>
						<span>Version: {{ version }}</span>
					</button>
				</mat-menu>
				<div
					class="user-info"
					[matMenuTriggerFor]="menu"
					*ngIf="!mobile && hasLoggedIn">
					<div>
						{{ user?.attributes?.given_name }}
						{{ user?.attributes?.family_name }}
					</div>
					<div class="email">{{ user?.attributes?.email }}</div>
				</div>
			</mat-toolbar-row>
		</mat-toolbar>
		<router-outlet></router-outlet>
	</mat-drawer-content>
</mat-drawer-container>
