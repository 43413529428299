import { ChangeDetectionStrategy, Component, inject } from '@angular/core';
import { FormBuilder, FormControl, Validators } from '@angular/forms';
import { MatButton } from '@angular/material/button';
import {
	MAT_DIALOG_DATA,
	MatDialogActions,
	MatDialogClose,
	MatDialogContent,
	MatDialogTitle,
} from '@angular/material/dialog';

@Component({
	standalone: false,
	templateUrl: './newAML.dialog.html',
	styleUrls: ['./newAML.dialog.css'],
})
export class NewAMLDialog {
	form = this.fb.group<{
		email: FormControl<string | null>;
		firstName: FormControl<string | null>;
		lastName: FormControl<string | null>;
		emailBody?: FormControl<string | null>;
	}>({
		email: this.fb.control('', [Validators.required]),
		firstName: this.fb.control('', [Validators.required]),
		lastName: this.fb.control('', [Validators.required]),
		emailBody: this.fb.control(null, []),
	});

	constructor(public fb: FormBuilder) {}
}
