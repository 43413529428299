<div class="container">
	<h1 class="header">Contact</h1>
	<mat-card appearance="outlined" style="margin: 3rem 0">
		<mat-card-content>
			<div class="card-icon">
				<img src="assets/Icon Email.png" alt="Email" />
			</div>
			<div class="card-content">
				<h2>Email</h2>
				<p>
					We check our emails regularly, so if you have any questions or run
					into any issues, please get in touch and one of our in-house customer
					service team will get back to you.
				</p>
				<p>
					Mail to:
					<a
						href="mailto:info@clearchain.house?subject=Customer%20Service%20Inquiry"
						>info@clearchain.house</a
					>
				</p>
			</div>
		</mat-card-content>
	</mat-card>
	<mat-card appearance="outlined" style="margin-bottom: 3rem">
		<mat-card-content>
			<div class="card-icon">
				<img src="assets/Icon Phone2.png" alt="Phone" />
			</div>
			<div class="card-content">
				<h2>Phone</h2>
				<p>
					If you prefer to talk to someone, please call us at 0203 8300 411.
				</p>

				<p>
					At Clear Chain we strive to give a human touch to a procedural
					process. With our in-house customer service team you can call us and
					talk to a real, UK based person, who understands our services and the
					UK property transaction process.
				</p>
				<p>
					Feel free to leave a message if we don't pick up, we will call you
					back ASAP.
				</p>
			</div>
		</mat-card-content>
	</mat-card>
	<mat-card appearance="outlined" style="margin-bottom: 3rem">
		<mat-card-content>
			<div class="card-icon">
				<img src="assets/Icon Bug.png" alt="Bugs" />
			</div>
			<div class="card-content">
				<h2>Bugs</h2>
				<p>Found a bug?</p>
				<p>
					Please
					<a (click)="newBug()"> CLICK HERE </a>
					and be sure to include as much information as possible including
					screen shots and links. We are constantly improving and look to fix
					any problems as soon as possible.
				</p>
			</div>
		</mat-card-content>
	</mat-card>
	<mat-card appearance="outlined" style="margin-bottom: 3rem">
		<mat-card-content>
			<div class="card-icon">
				<img src="assets/Icon Features.png" alt="Features" />
			</div>
			<div class="card-content">
				<h2>Feature Requests</h2>
				<p>
					We are looking for new features to add to the app too, so if you have
					any ideas please let us know.
					<a (click)="newBug()"> CLICK HERE </a>
					to request a feature.
				</p>
			</div>
		</mat-card-content>
	</mat-card>
</div>
