import { Injectable } from '@angular/core';
import { backendResponse, BackendService } from './backend.service';
import { Router } from '@angular/router';

@Injectable({
	providedIn: 'root',
})
export class ChainsBackendService {
	constructor(public backend: BackendService, private Router: Router) {}

	async getOne(chainId: string): Promise<backendResponse<Chain>> {
		return (await this.backend.get(`chain/${chainId}`).catch((e) => {
			//console.log(e);
			if (e?.status === 402 && e?.error?.needsToPay) {
				this.Router.navigate(['payment'], {
					queryParams: {
						chainId,
						buyerSellerId: e.error?.buyerSellerId,
					},
				});
				return { data: {} as any, canEdit: false } as backendResponse<Chain>;
			} else throw e;
		})) as backendResponse<Chain>;
	}

	async getList(options?: {}): Promise<backendResponse<Chain[]>> {
		let queryString = ``;
		return (await this.backend.get(`chains${queryString}`)) as backendResponse<
			Chain[]
		>;
	}

	async patch(
		chainId: string,
		data: Partial<Chain>
	): Promise<backendResponse<Chain>> {
		return (await this.backend.patch(
			`chain/${chainId}`,
			data
		)) as backendResponse<Chain>;
	}

	async merge(body: {
		chain1Id: string;
		chain2Id: string;
	}): Promise<backendResponse<Chain>> {
		return (await this.backend.put(
			`chain/merge`,
			body
		)) as backendResponse<Chain>;
	}

	async count(): Promise<backendResponse<number>> {
		return (await this.backend.get(`chains/count`)) as backendResponse<number>;
	}

	async dates(): Promise<
		backendResponse<
			{
				chainId: string;
				properties: {
					id: string;
					address: string;
				}[];
				date?: Date;
				type: 'exchange' | 'completion';
			}[]
		>
	> {
		return (await this.backend.get(`chains/dates`)) as backendResponse<
			{
				chainId: string;
				properties: {
					id: string;
					address: string;
				}[];
				date?: Date;
				type: 'exchange' | 'completion';
			}[]
		>;
	}

	async delete(chainId: string): Promise<backendResponse<Chain>> {
		return (await this.backend.delete(
			`chain/${chainId}`
		)) as backendResponse<Chain>;
	}
}

export interface VoteDate {
	currentDate?: [number, number, number];
	suggestedDates?: {
		date: [number, number, number]; // year (full year ie 2020) / month (starts with 0) / day (starts with 1)
		votes: {
			[buyerSellerId: string]: boolean | undefined;
		};
	}[];
}

export interface VoteNumber {
	number?: number;
	suggestedDates?: {
		number: number;
		votes: {
			[buyerSellerId: string]: boolean | undefined;
		};
	}[];
}

export interface Chain {
	id: string;
	propertyIds: string[];
	links?: any[];
	exchangeDate?: VoteDate;
	completionDate?: VoteDate;
	completionDaysGoal?: VoteNumber;
	daysToCompletionGoal?: number;
	dateCompleted?: number | Date;
	isComplete?: Date;
	estimatedCompletionDate?: number | Date;
	estimatedExchangeDate?: number | Date;
}
