<div class="container">
	<h1 class="header">FAQs</h1>
	<!-- <img class="faq-icon" src="assets/Icon FAQ2.png" alt="FAQ" /> -->
	<mat-card>
		<mat-card-content>
			<div style="display: flex; justify-content: left">
				<img class="faq-icon-small" src="assets/Icon FAQ2.png" alt="FAQ" />
			</div>
			<div class="faq">
				<h2>What info will others be able to see about me?</h2>
				<p>
					We follow the least required principal. Only those that absolutely
					need to know your name and email like your conveyancer will be able so
					see PII. As for tasks only people involved in a given task can see the
					details of the task. Others can only see if a task has been completed
					and if there are warnings/issues with it not what the issues are.
				</p>
			</div>

			<div class="faq">
				<h2>Can I share my access with my partner or others?</h2>
				<p>
					You can add other buyers or sellers if they are also owners or buying
					with you. You can also invite your estate agent, mortgage broker and
					others involved in the process.
				</p>
			</div>

			<div class="faq">
				<h2>What should I do if others aren't keeping the chain up to date?</h2>
				<p>
					We recommend you reach out to the rest of the chain when you decide to
					go with Clear Chain or when you accept/put in an offer that you
					stipulate the whole chain use Clear Chain. If others aren't keeping up
					with their updates the system will reach out with automated reminders. We
					find once people have used the system they are keen to keep it up to
					date.
				</p>
			</div>

			<div class="faq">
				<h2>What if my chain breaks?</h2>
				<p>
					This can happen, but with Clear Chain we aim to let you know as early
					as possible if there are any issues to try and prevent this. With Clear Chain you have the
					best chance of the chain healing and being able to complete the sale
					in the end. If the chain completely fails then you will be given a
					voucher for your next chain within 12 months.
				</p>
			</div>

			<div class="faq">
				<h2>Do I need to be part of a chain to use Clear Chain?</h2>
				<p>
					No, even for single buyer seller transactions Clear Chain can be
					useful to track your progress, especially for first time buyers as it
					can help them understand the overall buy/sell process and all of the admin that goes along with it.
				</p>
			</div>

			<div class="faq">
				<h2>Can I use Clear Chain even if the rest of the chain isn't?</h2>
				<p>
					You could, but you will get the most out of it if everyone is using
					it. You have the right to stipulate that everyone in the chain uses
					it.
				</p>
			</div>
		</mat-card-content>
	</mat-card>
</div>
