import { environment } from 'src/environments/environment';
//import * as dotenv from 'dotenv' // see https://github.com/motdotla/dotenv#how-do-i-use-dotenv-with-import
//dotenv.config()

export const awsconfig = {
	aws_cognito_region: process.env['NG_APP_AWS_COGNITO_REGION'] || 'eu-west-1', // (required) - Region where Amazon Cognito project was created
	aws_user_pools_id:
		process.env['NG_APP_AWS_USER_POOLS_ID'] || 'eu-west-1_g8a8XmmHw', // (optional) -  Amazon Cognito User Pool ID
	aws_user_pools_web_client_id:
		process.env['NG_APP_AWS_USER_POOLS_WEB_CLIENT_ID'] ||
		'4adrqns79qkm0ha1130t1oodsi', //'13eortmjo9jcuur09h1gkeigjq',//'20h5l2cfiqk6e7fb8emf1fpolq', // (optional) - Amazon Cognito App Client ID (App client secret needs to be disabled)
	aws_mandatory_sign_in: 'enable', // (optional) - Users are not allowed to get the aws credentials unless they are signed in
	oauth: {
		domain:
			process.env['NG_APP_AWS_OAUTH_DOMAIN'] ||
			'testchainlink01.auth.eu-west-1.amazoncognito.com', // (optional) - OAuth domain (default: https://paqd3xrcf1.execute-api.eu-west-1.amazonaws.com)
		scope: [
			'phone',
			'email',
			'openid',
			'aws.cognito.signin.user.admin',
			'profile',
		], // (optional) - OAuth scope (default: openid)
		redirectSignIn: environment.rootUrl, // 'http://localhost:4200/', // (optional) - Redirect URL after sign-in. If not specified, it will redirect to the app root.
		redirectSignOut: environment.rootUrl + '/login', // (optional) - Redirect URL after sign-out. If not specified, it will redirect to the app root.
		responseType: 'code', //'token', // (optional) - OAuth response type. Can be code or token.
	},
};
