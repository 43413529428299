<mat-stepper orientation="vertical" #stepper linear>
	<ng-template matStepperIcon="info">
		<mat-icon>info</mat-icon>
	</ng-template>

	<ng-template matStepperIcon="house">
		<mat-icon>house</mat-icon>
	</ng-template>

	<ng-template matStepperIcon="selling">
		<mat-icon>real_estate_agent</mat-icon>
	</ng-template>

	<ng-template matStepperIcon="buying">
		<mat-icon>monetization_on</mat-icon>
	</ng-template>

	<ng-template matStepperIcon="policy">
		<mat-icon>policy</mat-icon>
	</ng-template>

	<mat-step state="house" [stepControl]="propertyInfoForm">
		<ng-template matStepLabel>Property Information</ng-template>
		<form fxLayout="column" [formGroup]="propertyInfoForm">
			<div class="row row-bottom-padding">
				<app-address [form]="propertyInfoForm"></app-address>
			</div>
			<div fxLayout="row" fxLayoutAlign="end center">
				<button mat-button (click)="back.emit()" type="button">Back</button>
				<button
					mat-raised-button
					color="primary"
					[disabled]="propertyInfoForm.invalid"
					matStepperNext>
					Next
				</button>
			</div>
		</form>
	</mat-step>

	<mat-step state="selling" [stepControl]="sellerInfoForm">
		<ng-template matStepLabel>Seller Information</ng-template>
		<form fxLayout="column" [formGroup]="sellerInfoForm">
			<div class="row row-bottom-padding">
				<app-people
					[form]="$any(sellerInfoForm.get('seller'))"
					title="Property Owner/s"></app-people>
			</div>
			<div class="row row-bottom-padding">
				<label id="verified-label">
					Have the Owners/s already completed their Anti-Money Laundering
					checks?
				</label>
				<mat-radio-group
					aria-labelledby="verified-label"
					formControlName="verified">
					<mat-radio-button value="Y">Yes</mat-radio-button>
					<mat-radio-button value="N">No</mat-radio-button>
				</mat-radio-group>
			</div>
			<div class="row row-bottom-padding">
				<label id="buyingPropertyYN-label">
					Do the owners/s have a conveyancer?
				</label>
				<mat-radio-group
					aria-labelledby="doesSellerHaveConveyancer-label"
					formControlName="doesSellerHaveConveyancer">
					<mat-radio-button value="Y">Yes</mat-radio-button>
					<mat-radio-button value="N">No</mat-radio-button>
				</mat-radio-group>
			</div>

			<div
				class="row row-bottom-padding"
				*ngIf="doesSellerHaveConveyancer?.value == 'Y'">
				<app-people
					[form]="$any(sellerInfoForm.get('conveyancer'))"
					[onlyOne]="true"
					title="Conveyancer"></app-people>
			</div>
			<div fxLayout="row" fxLayoutAlign="end center">
				<button mat-button matStepperPrevious type="button">Back</button>
				<button
					mat-raised-button
					color="primary"
					[disabled]="sellerInfoForm.invalid"
					matStepperNext>
					Next
				</button>
			</div>
		</form>
	</mat-step>

	<mat-step state="buying" [stepControl]="buyerInfoForm">
		<ng-template matStepLabel>Buyer Information</ng-template>
		<form
			fxLayout="column"
			[formGroup]="buyerInfoForm"
			(ngSubmit)="submitForm()">
			<div class="row row-bottom-padding">
				<label id="acceptedOffer-label"> Has an offer been accepted? </label>
				<mat-radio-group
					aria-labelledby="acceptedOffer-label"
					formControlName="acceptedOffer">
					<mat-radio-button value="Y">Yes</mat-radio-button>
					<mat-radio-button value="N">No</mat-radio-button>
				</mat-radio-group>
			</div>
			<div class="row row-bottom-padding" *ngIf="acceptedOffer?.value == 'Y'">
				<app-people
					[form]="$any(buyerInfoForm.get('buyer'))"
					title="Property Buyer/s"></app-people>
			</div>
			<div class="row row-bottom-padding" *ngIf="acceptedOffer?.value == 'Y'">
				<mat-form-field appearance="outline" floatLabel="always">
					<mat-label>Date Offer Accepted</mat-label>
					<input
						matInput
						formControlName="dateOfferAccepted"
						[matDatepicker]="dp" />
					<mat-datepicker-toggle matSuffix [for]="dp"></mat-datepicker-toggle>
					<mat-datepicker #dp></mat-datepicker>
				</mat-form-field>

				<mat-form-field appearance="outline" floatLabel="always">
					<mat-label>Agreed Selling Price</mat-label>
					<span matTextPrefix>£&nbsp;</span>
					<input
						matInput
						autocomplete="off"
						[appMoneyInput]
						formControlName="agreedSellingPrice"
						type="string" />
				</mat-form-field>
			</div>
			<div class="row row-bottom-padding" *ngIf="acceptedOffer?.value == 'Y'">
				<label id="doesSellerHaveConveyancer-label">
					Do the buyer/s have a conveyancer?
				</label>
				<mat-radio-group
					aria-labelledby="doesBuyerHaveConveyancer-label"
					formControlName="doesBuyerHaveConveyancer">
					<mat-radio-button value="Y">Yes</mat-radio-button>
					<mat-radio-button value="N">No</mat-radio-button>
				</mat-radio-group>
			</div>
			<div
				class="row row-bottom-padding"
				*ngIf="
					doesBuyerHaveConveyancer?.value == 'Y' && acceptedOffer?.value == 'Y'
				">
				<app-people
					[form]="$any(buyerInfoForm.get('conveyancer'))"
					[onlyOne]="true"
					title="Conveyancer"></app-people>
			</div>
			<div fxLayout="row" fxLayoutAlign="end center">
				<button mat-button matStepperPrevious type="button">Back</button>
				<button
					mat-raised-button
					color="primary"
					[disabled]="buyerInfoForm.invalid"
					matStepperNext
					type="submit">
					Next
				</button>
			</div>
		</form>
	</mat-step>
</mat-stepper>
