import { Component, OnDestroy, OnInit } from '@angular/core';
import { UploadFilesService } from 'src/app/services/upload-files.service';

@Component({
	selector: 'app-upload-progress',
	templateUrl: './upload-progress.component.html',
	styleUrls: ['./upload-progress.component.css'],
})
export class UploadProgressComponent implements OnInit, OnDestroy {
	files$: any;
	que: {
		progress?: number;
		name?: string;
		id?: string;
	}[] = [];

	constructor(public uploadService: UploadFilesService) {}

	ngOnInit(): void {
		this.uploadService.que.forEach((file) => {
			this.que.push({
				progress: file.progress,
				name: file.name,
				id: file.id || '',
			});
		});

		this.files$ = this.uploadService.updates$.subscribe((_que) => {
			let que: {
				progress?: number;
				name?: string;
				id: string;
			}[] = [];
			_que.forEach((file) => {
				que.push({
					progress: file.progress,
					name: file.name,
					id: file.id || '',
				});
			});
			this.que = que;
		});
	}

	ngOnDestroy(): void {
		if (this.files$) {
			this.files$.unsubscribe();
		}
	}
}
