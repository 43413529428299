import { Pipe, type PipeTransform } from '@angular/core';

@Pipe({
	name: 'appStringList',
})
export class StringListPipe implements PipeTransform {
	transform(value: string[] | undefined | null, ...args: unknown[]): unknown {
		if (!value) return '';
		if (value.length === 0) return '';
		return value.join(', ').replace(/, ((?:.(?!, ))+)$/, ' and $1');
	}
}

@Pipe({
	name: 'appCapFirstLetters',
})
export class CapFirstLettersPipe implements PipeTransform {
	transform(value: string | undefined | null, ...args: unknown[]): unknown {
		if (!value) return '';
		// Regex explanation:
		// \b: word boundary
		// \w: word character (alphanumeric characters plus "_")
		// The function in the second argument transforms the matched character to uppercase.
		return value.replace(/\b\w/g, (char) => char.toUpperCase());
	}
}
