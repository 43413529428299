import { Injectable } from '@angular/core';

import { Auth, Hub } from 'aws-amplify';
import { AuthenticatorService } from '@aws-amplify/ui-angular';
import { BehaviorSubject } from 'rxjs';
import { Router } from '@angular/router';
import { environment } from 'src/environments/environment';
import { BackendService, backendResponse } from './backend.service';

@Injectable({
	providedIn: 'root',
})
export class AuthService {
	get state() {
		return this._auth.authStatus;
	}

	_spoofAs: string = localStorage.getItem('spoofAs') || '';
	set spoofAs(val: string | undefined) {
		this._spoofAs = val || '';
		localStorage.setItem('spoofAs', this._spoofAs);
	}
	get spoofAs() {
		return this._spoofAs == '' ? undefined : this._spoofAs;
	}

	public $loggedIn = new BehaviorSubject<string>(this._auth.authStatus);

	constructor(
		private _auth: AuthenticatorService,
		private Router: Router,
		public backend: BackendService
	) {
		Auth.wrapRefreshSessionCallback(async (data) => {
			console.log('refresh session', data);
		});

		Hub.listen('auth', (data) => {
			this.$loggedIn.next(data.payload.event);
		});
	}

	public getUser(): Promise<any> {
		return Auth.currentAuthenticatedUser();
	}

	public async signOut(): Promise<void> {
		//await Auth.signOut();
		this._groups = undefined;
		this._user = undefined;
		await this._auth.signOut();
		await this.waitForLogout();
		setTimeout(() => {
			window.open(
				`https://${environment.branch || 'localhost:4200'}.clearchain.house`,
				'_self'
			);
		}, 500);
	}

	public async getToken(): Promise<string> {
		const currentSession = await Auth.currentSession();
		const jwt = currentSession.getIdToken().getJwtToken();
		return jwt;
	}

	public async getUserAttributes(): Promise<any> {
		const user = await Auth.currentAuthenticatedUser().catch((err) => {
			return { attributes: {} };
		});
		return user.attributes;
	}

	public async updateAttributes(attributes: any): Promise<void> {
		const user = await Auth.currentAuthenticatedUser();
		await Auth.updateUserAttributes(user, attributes);
		return await this.getUserAttributes();
	}

	public async getNotificationPreferences(): Promise<INotificationPreferences> {
		let preferences = (await this.backend.get(
			'/notifications/preferences'
		)) as backendResponse<INotificationPreferences>;
		return preferences?.data || {};
	}

	public async getNotifications(): Promise<any[]> {
		let preferences = (await this.backend.get(
			'/notifications'
		)) as backendResponse<any>;
		return preferences?.data || [];
	}

	public async patchNotificationPreferences(
		preferences: Partial<INotificationPreferences>
	): Promise<INotificationPreferences> {
		let _preferences = (await this.backend.patch(
			'/notifications/preferences',
			preferences
		)) as backendResponse<INotificationPreferences>;
		return _preferences?.data || {};
	}

	async isSignedIn() {
		try {
			let info = await Auth.currentUserInfo().catch((err) => false);
			if (info) return true;
		} catch (e) {
			return false;
		}
		return false;
	}

	checkLogout(resolveFn: any, startTime = Date.now()) {
		if (this._auth.authStatus === 'unauthenticated' && !this._auth.isPending) {
			resolveFn();
			return;
		}
		if (startTime + 5000 < Date.now()) {
			resolveFn();
			return;
		}
		setTimeout(() => {
			this.checkLogout(resolveFn, startTime);
		}, 100);
	}

	async waitForLogout() {
		return new Promise((resolve) => {
			setTimeout(() => {
				this.checkLogout(resolve);
			}, 1);
		});
	}

	private _groups: string[] | undefined;
	private _user: any;
	async groups(): Promise<string[]> {
		if (!this._groups) {
			this._user = await this.getUser();
			this._groups =
				this._user?.signInUserSession?.accessToken?.payload?.[
					'cognito:groups'
				] || [];
		}
		return this._groups as string[];
	}

	async isAdmin() {
		return (await this.groups()).includes('admin');
	}

	// handleSignUp = (formData: Record<string, any>) => {
	// 	console.log('handleSignUp', formData);
	// 	let { username, password, attributes } = formData;
	// 	// custom username
	// 	username = username.toLowerCase();
	// 	attributes.email = attributes.email.toLowerCase();
	// 	//   return Auth.signUp({
	// 	//     username,
	// 	//     password,
	// 	//     attributes,
	// 	//     autoSignIn: {
	// 	//       enabled: true,
	// 	//     },
	// 	//   });
	// };

	validateCustomSignUp = async (formData: Record<string, string>) => {
		console.log('validateCustomSignUp', formData);

		let _phoneErrors = phoneErrors(formData);
		let _passwordErrors = passwordErrors(formData);
		return {
			..._phoneErrors,
			..._passwordErrors,
		};
	};
}

function phoneErrors(formData: Record<string, string>) {
	let phone = formData['phone_number'] || '';
	if (phone.length != 10 || phone.startsWith('0')) {
		return {
			phone_number:
				'Phone Numbers should not include a leading 0 or any other characters.',
		};
	}
	if (phone.startsWith('+44')) {
		return {
			phone_number:
				'The international code is already added to the phone number.',
		};
	}
	phone = phone.replace(/(\s|\.|\-)/g, '').trim();
	if (phone != (formData['phone_number'] || '')) {
		return {
			phone_number: 'Phone Numbers should only include numbers',
		};
	}
	return {};
}

function passwordErrors(formData: Record<string, string>) {
	//console.log(formData);
	if (!formData['password']) return {};
	let password = formData['password'] || '';
	let errors: string[] = [];
	if (password.length < 8) {
		errors.push(`Passwords must be at least 8 characters long.`);
	}
	if (!/[A-Z]/.test(password)) {
		errors.push(`Passwords must have at least one uppercase character.`);
	}
	if (!/[a-z]/.test(password)) {
		errors.push(`Passwords must have at least one lowercase character.`);
	}
	if (!/[0-9]/.test(password)) {
		errors.push(`Passwords must have at least one number.`);
	}
	if (!/[ `!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/.test(password)) {
		errors.push(`Passwords must have at least one special character.`);
	}
	let error: any = {};
	if (errors.length > 0) {
		error['password'] = errors.join(`
		`);
	}
	return error;
}

export enum notificationTypePreference {
	Immediately,
	OnceADay,
	OnceAWeek,
}

export interface IPreferenceLevels {
	ass?: notificationTypePreference;
	track?: notificationTypePreference;
	prop?: notificationTypePreference;
	chain?: notificationTypePreference;
}

export interface INotificationPreferences {
	tasks?: {
		unblocked?: IPreferenceLevels;
		completed?: IPreferenceLevels;
		updated?: IPreferenceLevels;
		overdue?: IPreferenceLevels;
		dueTomorrow?: IPreferenceLevels;
	};
	buyerSeller?: {
		misBuyingProp?: notificationTypePreference;
		misSellingProp?: notificationTypePreference;
	};
	prop?: {
		misBuyer?: notificationTypePreference;
		misSeller?: notificationTypePreference;
		misAgent?: notificationTypePreference;
		misSellerCon?: notificationTypePreference;
		misBuyerCon?: notificationTypePreference;
	};
	chain?: {
		complete?: notificationTypePreference;
		exComp?: notificationTypePreference;
	};
}
