import { Injectable } from '@angular/core';
import { userType } from '../setup/httpTypes';
import { backendResponse, BackendService } from './backend.service';

@Injectable({
	providedIn: 'root',
})
export class RequestsBackendService {
	constructor(public backend: BackendService) {}

	async getOne(requestId: string): Promise<backendResponse<Request>> {
		return (await this.backend.get(
			`requests/${requestId}`
		)) as backendResponse<Request>;
	}

	/**
	 *
	 * @param options {
	 * fullAccess: boolean, this will return all requests for the given ask but cleans the ones you don't have access to
	 * propertyId: string,
	 * chainId: string}
	 * @returns Promise<Request[]>
	 */
	async getList(options?: {
		chainId?: string;
		propertyId?: string;
		buyerSellerId?: string;
	}): Promise<backendResponse<Request[]>> {
		let queryString = ``;
		if (options?.chainId) {
			queryString += `?chainId=${options.chainId}`;
		}
		if (options?.propertyId) {
			if (queryString.length == 0) queryString += `?`;
			else queryString += `&`;
			queryString += `propertyId=${options.propertyId}`;
		}
		if (options?.buyerSellerId) {
			if (queryString.length == 0) queryString += `?`;
			else queryString += `&`;
			queryString += `buyerSellerId=${options.buyerSellerId}`;
		}

		return (await this.backend.get(
			`requests${queryString}`
		)) as any as backendResponse<Request[]>;
	}

	async accept(requestId: string): Promise<backendResponse<Request>> {
		return (await this.patch(requestId, {
			accepted: true,
		})) as backendResponse<Request>;
	}

	async patch(
		requestId: string,
		body: Partial<Request>
	): Promise<backendResponse<Request>> {
		return (await this.backend.patch(
			`requests/${requestId}`,
			body
		)) as backendResponse<Request>;
	}

	async put(body: {
		UPRN: string;
		userType: userType;
		message?: string;
		fromChainId?: string;
		fromBuyerSellerId?: string;
	}): Promise<backendResponse<Request>> {
		return (await this.backend.put(
			`request`,
			body
		)) as backendResponse<Request>;
	}

	async delete(requestId: string): Promise<backendResponse<Request>> {
		return (await this.backend.delete(
			`requests/${requestId}`
		)) as backendResponse<Request>;
	}
}

export enum requestEntity {
	property = 'property',
	person = 'person',
}

export interface Request {
	id: string;
	requestEntity: requestEntity;
	requestAction: userType;
	userType: string;
	email: string;
	firstName: string;
	lastName?: string;
	phone?: string;
	company?: string;
	propertyId?: string;
	buyerSellerId?: string;
	personId?: string;
	chainId: string;
	userCreated: string;
	accepted?: boolean;
	body: any;
	dateCreated: Date;
	emailSent?: Date;
	lastOpened?: Date;
	setupComplete?: Boolean;
	property?: {
		nickname?: string;
		address?: string;
		UPRN?: string;
	};
	message?: string;
}
