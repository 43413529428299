import { Injectable } from '@angular/core';
import { backendResponse, BackendService } from './backend.service';
import { linkedPerson } from './task-backend.service';

@Injectable({
	providedIn: 'root',
})
export class BuyerSellersBackendService {
	constructor(public backend: BackendService) {}

	async getOne(buyerSellerId: string): Promise<backendResponse<BuyerSeller>> {
		return (await this.backend.get(
			`buyer-seller/${buyerSellerId}`
		)) as backendResponse<BuyerSeller>;
	}

	async patch(
		buyerSellerId: string,
		data: Partial<BuyerSeller>
	): Promise<backendResponse<BuyerSeller>> {
		return (await this.backend.patch(
			`buyer-seller/${buyerSellerId}`,
			data
		)) as backendResponse<BuyerSeller>;
	}

	async put(data: Partial<BuyerSeller>): Promise<backendResponse<BuyerSeller>> {
		return (await this.backend.put(
			`buyer-seller`,
			data
		)) as backendResponse<BuyerSeller>;
	}

	async pay(data: {
		chainId: string;
		buyerSellerId: string;
		code?: string;
	}): Promise<{ url: string }> {
		return (await this.backend.put(`pay`, data)) as { url: string };
	}

	async paid(data: { sessionId: string }): Promise<{}> {
		return (await this.backend.patch(`paid`, data)) as {};
	}

	async putDiscount(data: {
		preCode: string;
		percentOff?: number;
		amountOff?: number;
	}): Promise<backendResponse<{ code: string }>> {
		return (await this.backend.put(`pay/discount`, data)) as backendResponse<{
			code: string;
		}>;
	}

	async getDiscount(data: { code: string }): Promise<
		backendResponse<{
			code?: string;
			discountPercent?: number;
			discountAmount?: number;
			codeUsed?: boolean;
			userNotAllowed?: boolean;
		}>
	> {
		return (await this.backend.get(
			`pay/discount/${data.code}`
		)) as backendResponse<{
			code?: string;
			discountPercent?: number;
			discountAmount?: number;
			codeUsed?: boolean;
			userNotAllowed?: boolean;
		}>;
	}
}

export enum propertyStates {
	Looking = 'looking',
	OfferAccepted = 'offerAccepted',
	OfferPutIn = 'offerPutIn',
	RequestingInvite = 'requestingInvite',
	Renting = 'renting',
	NoProperty = 'noProperty',
	NoSelection = 'noSelection',
}

export interface BuyerSeller {
	id: string;
	linkType: 'buyerSeller';
	sellingPropertyState: propertyStates;
	sellingPropertyId?: string;
	sellingPropertyUPRN?: string;
	buyingPropertyState: propertyStates;
	buyingPropertyId?: string;
	buyingPropertyUPRN?: string;
	chainId: string;
	userCreated: string;
	preMortgage?: number;
	requireMortgage?: boolean;
	active?: 'y' | undefined;
	persons: linkedPerson[];
}
