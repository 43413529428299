<div
	*ngIf="completedPercent > 0"
	class="bar completed"
	[class.short]="!showPercent"
	[style.width]="completedPercent + '%'"
	[matTooltip]="round(completedPercent) + '% Completed'">
	<span *ngIf="showPercent">
		{{ round(completedPercent) + "%" }}
	</span>
</div>
<div
	*ngIf="inProgressPercent > 0"
	class="bar in-progress"
	[class.short]="!showPercent"
	[style.width]="inProgressPercent + '%'"
	[matTooltip]="round(inProgressPercent) + '% In Progress'">
	<span *ngIf="showPercent">
		{{ round(inProgressPercent) + "%" }}
	</span>
</div>
<div
	*ngIf="notStartedPercent > 0"
	class="bar not-started"
	[class.short]="!showPercent"
	[style.width]="notStartedPercent + '%'"
	[matTooltip]="round(notStartedPercent) + '% To Do'">
	<span *ngIf="showPercent">
		{{ round(notStartedPercent) + "%" }}
	</span>
</div>
<div
	*ngIf="disabledPercent > 0"
	class="bar disabled"
	[class.short]="!showPercent"
	[style.width]="disabledPercent + '%'"
	[matTooltip]="round(disabledPercent) + '% Blocked'">
	<span *ngIf="showPercent">
		{{ round(disabledPercent) + "%" }}
	</span>
</div>
