import { Injectable } from '@angular/core';
import { backendResponse, BackendService } from './backend.service';
import { linkedPerson, TaskResult, TaskStatus } from './task-backend.service';
import { Property } from './properties-backend.service';

@Injectable({
	providedIn: 'root',
})
export class OfferBackendService {
	constructor(public backend: BackendService) {}

	async getOne(
		propertyId: string,
		offerId?: string
	): Promise<backendResponse<Property>> {
		let q = '';
		if (offerId) {
			q = `?offerId=${offerId}`;
		}
		return (await this.backend.get(`offer/${propertyId}${q}`, {
			noCredentials: true,
		})) as backendResponse<Property>;
	}

	async put(
		propertyId: string,
		data: {
			email: string;
			firstName: string;
			lastName: string;
			emailBody?: string;
		}
	): Promise<backendResponse<string>> {
		return (await this.backend.put(
			`offer/${propertyId}`,
			data
		)) as backendResponse<string>;
	}

	async patch(
		propertyId: string,
		data: {
			offerAmount: number;
			offerStipulations: string | null;
			buyers: {
				firstName: string;
				lastName: string;
				email: string;
				phoneCountryCode: string;
				phoneInCountryNumber: string;
				company: string;
			}[];
			mortgage: boolean | null;
			mortgageInPrincipal: boolean | null;
			mortgageBroker: {
				firstName: string;
				lastName: string;
				email: string;
				phoneCountryCode: string;
				phoneInCountryNumber: string;
				company: string;
			}[];
			currentAddress: {
				postcode: string;
				address: string;
				UPRN: string;
			};
			onwardChain: boolean | null;
			sellingCurrentAddress: boolean | null;
			sellingAddress: {
				postcode: string;
				address: string;
				UPRN: string;
			};
			statusOfSale: string | null;
			agent: {
				firstName: string;
				lastName: string;
				email: string;
				phoneCountryCode: string;
				phoneInCountryNumber: string;
				company: string;
			}[];
			propertiesInChain: number | null;
			chainComplete: boolean | null;
			confirmed: boolean | null;
			confirmedContract: boolean | null;
			confirmedTerms: boolean | null;
			signature: string | null;
			fullName: string | null;
			offerId?: string;
			summery?: string;
		},
		offerId?: string
	): Promise<backendResponse<Property>> {
		if (offerId) {
			data = { ...data, offerId: offerId };
		}
		return (await this.backend.patch(`offer/${propertyId}`, data, {
			noCredentials: true,
		})) as backendResponse<Property>;
	}
}
