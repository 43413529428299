import { Component, Inject } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import {
	PeopleBackendService,
	Person,
} from 'src/app/services/people-backend.service';
import { phoneCountryCodeList } from 'src/app/services/phoneCodes';
import { userType } from 'src/app/setup/httpTypes';

@Component({
	selector: 'app-edit-person-dialog',
	templateUrl: './edit-person-dialog.component.html',
	styleUrls: ['./edit-person-dialog.component.css'],
})
export class EditPersonDialogComponent {
	userTypes = userType;
	personFrom = this.fb.group({
		firstName: ['', [Validators.required]],
		lastName: ['', [Validators.required]],
		email: ['', [Validators.required, Validators.email]],
		phoneCountryCode: ['+44', [Validators.required]],
		phoneInCountryNumber: [
			'',
			[Validators.required, Validators.pattern('^[1-9]\\d{9}')],
		],
		company: [''],
		userType: ['', [Validators.required]],
		managerEmail: [null],
	});
	saving: boolean = false;
	phoneCountryCodes = phoneCountryCodeList;
	constructor(
		@Inject(MAT_DIALOG_DATA) public data: Person,
		public fb: FormBuilder,
		public personBackend: PeopleBackendService,
		public dialogRef: MatDialogRef<EditPersonDialogComponent>
	) {
		this.personFrom.patchValue(data);
		this.personFrom.get('email')?.disable();
	}

	async save() {
		this.saving = true;
		this.personFrom.disable();
		let personEdits: Partial<Person> = {};
		for (let key in this.personFrom.controls) {
			let control = this.personFrom.get(key);
			if (control?.dirty) {
				(personEdits as any)[key] = control?.value;
			}
		}

		try {
			let r = await this.personBackend.patch(this.data.id, personEdits);

			if (r) {
				this.data = r.data;
				this.dialogRef.close(this.data);
			}
		} catch (error) {}

		this.personFrom.enable();
		this.personFrom.get('email')?.disable();
		this.saving = false;
	}
}
