<div class="container">
	<h1 class="header">Access Requests</h1>
	<!-- <div class="center-center" *ngIf="loading">
		<mat-spinner></mat-spinner>
	</div> -->
	<app-loading-tombstone *ngIf="loading"></app-loading-tombstone>
	<div>
		<mat-card appearance="outlined" *ngIf="requests.length === 0 && !loading">
			<mat-card-content>
				<div class="center-center">
					<h2>No Access Requests</h2>
				</div>
			</mat-card-content>
		</mat-card>
	</div>

	<mat-card
		appearance="outlined"
		*ngFor="let request of requests"
		style="margin-top: 1rem">
		<mat-card-content [class.deleted]="deletedRequests[request.id]">
			<mat-icon
				[matTooltip]="request.property?.nickname || ''"
				style="align-self: baseline; margin-right: 1rem"
				>accessibility_new</mat-icon
			>
			<div>
				<div>
					<span> {{ request.firstName }} {{ request.lastName }} </span>
					<span> - </span>
					<span> {{ request.requestAction }} </span>
				</div>
				<div>{{ request.property?.nickname }}</div>
				<div>{{ request.property?.address || "" }}</div>
				<div *ngIf="request.message">{{ request.message }}</div>
			</div>
			<div class="spacer"></div>
			<!-- <div class="menu"> -->
			<div>
				<button
					mat-button
					*ngIf="!canAccept(request)"
					(click)="deleteRequest(request)">
					Cancel
				</button>
			</div>
			<div>
				<button
					mat-button
					*ngIf="canAccept(request)"
					(click)="deleteRequest(request)">
					Reject
				</button>
			</div>
			<div>
				<button
					mat-button
					*ngIf="canAccept(request)"
					(click)="acceptRequest(request)">
					Accept
				</button>
			</div>
		</mat-card-content>
	</mat-card>
	<!-- <div
		class="row card"
		style="flex-wrap: nowrap"
		*ngFor="let request of requests">
		<mat-icon
			[matTooltip]="request.property?.nickname || ''"
			style="align-self: baseline; margin-right: 1rem"
			>accessibility_new</mat-icon
		>
		<div>
			<div>
				<span> {{ request.firstName }} {{ request.lastName }} </span>
				<span> - </span>
				<span> {{ request.requestAction }} </span>
			</div>
			<div>{{ request.property?.nickname }}</div>
			<div>{{ cleanAddress(request.property?.UPRN || "") }}</div>
		</div>
		<div class="spacer"></div>
		<div>
			<button
				mat-button
				*ngIf="!canAccept(request)"
				(click)="deleteRequest(request)">
				Cancel
			</button>
		</div>
		<div>
			<button
				mat-button
				*ngIf="canAccept(request)"
				(click)="deleteRequest(request)">
				Reject
			</button>
		</div>
		<div>
			<button
				mat-button
				*ngIf="canAccept(request)"
				(click)="acceptRequest(request)">
				Accept
			</button>
		</div>


	</div> -->
</div>
