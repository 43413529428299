<td>{{ Conveyancer }}:</td>
<td>
	<mat-checkbox
		[checked]="value == notificationTypePreference.Immediately"
		(change)="
			onChange(
				$event.checked ? notificationTypePreference.Immediately : undefined
			);
			value = $event.checked
				? notificationTypePreference.Immediately
				: undefined
		"
		>Immediately</mat-checkbox
	>
</td>
<td>
	<mat-checkbox
		[checked]="value == notificationTypePreference.OnceADay"
		(change)="
			onChange(
				$event.checked ? notificationTypePreference.OnceADay : undefined
			);
			value = $event.checked ? notificationTypePreference.OnceADay : undefined
		"
		>Once A Day</mat-checkbox
	>
</td>
<td>
	<mat-checkbox
		[checked]="value == notificationTypePreference.OnceAWeek"
		(change)="
			onChange(
				$event.checked ? notificationTypePreference.OnceAWeek : undefined
			);
			value = $event.checked ? notificationTypePreference.OnceAWeek : undefined
		"
		>Once A Week</mat-checkbox
	>
</td>
