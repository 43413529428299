import { Injectable } from '@angular/core';
import { BackendService, backendResponse } from './backend.service';

@Injectable({
	providedIn: 'root',
})
export class SearchesService {
	constructor(public backend: BackendService) {}

	async getStripUrl(taskId: string): Promise<SearchURLResponse> {
		return (await this.backend.put(
			`searches`,
			{
				taskId,
			},
			{
				noCredentials: false,
			}
		)) as SearchURLResponse;
	}
}

interface SearchURLResponse {
	url: string;
}
