<!-- <mat-spinner *ngIf="loading"></mat-spinner> -->
<app-loading-tombstone
	*ngIf="loading"
	[morePadding]="true"></app-loading-tombstone>
<!-- <h1 *ngIf="!loading">
    Chain - <span *ngFor="let prop of propsFiltered(chain);let last = last;">{{prop.address}}<span *ngIf="!last" > - </span></span>
</h1> -->
<div *ngIf="!loading" class="main-container">
	<div class="props-container">
		<mat-card class="props-card">
			<mat-card-content class="props-inner-container">
				<a
					routerLinkActive="active-prop"
					[routerLink]="['overview']"
					matTooltip="Chain Overview"
					style="
						width: 2.5rem;
						height: 2.5rem;
						display: flex;
						align-items: baseline;
						justify-content: center;
						background-color: #80808033;
					">
					<!-- <mat-icon>link</mat-icon> -->
					<img
						style="width: 1.5rem"
						src="/assets/figma-icons/akar-icons_link-chain.svg"
						alt="chain" />
				</a>
				<span class="when-big" style="font-size: 0.75rem; font-weight: 300"
					>Navigation</span
				>
				<span
					class="when-small"
					style="
						font-size: 0.5rem;
						text-orientation: sideways-right;
						writing-mode: vertical-rl;
						font-weight: 300;
					"
					>Navigation</span
				>
				<mat-divider style="width: 75%" class="when-big"></mat-divider>
				<mat-divider
					style="height: 75%"
					class="when-small"
					[vertical]="true"></mat-divider>
				<ng-container
					*ngFor="
						let link of reverseChain();
						let i = index;
						let first = first;
						let last = last
					">
					<a
						class="item"
						routerLinkActive="active-prop"
						[class.purple-background-trans]="link.linkType != 'property'"
						[class.is-prop]="link.linkType == 'property'"
						[class.me-prop]="me(link)"
						[class.unknown]="link.id == 'unknown'"
						[class.red]="
							link.sellingPropertyState == 'noSelection' ||
							link.buyingPropertyState == 'noSelection' ||
							propColor(link) == 'red'
						"
						[class.red-background-trans]="
							link.sellingPropertyState == 'noSelection' ||
							link.buyingPropertyState == 'noSelection' ||
							link.id == 'unknown' ||
							propColor(link) == 'red' ||
							propColor(link) == 'yellow'
						"
						[routerLink]="likeUrl(link)">
						<mat-icon
							*ngIf="link.linkType !== 'property'"
							[matTooltip]="personToolTip(link, i)"
							>{{ link.id == "unknown" ? "person_add" : "person" }}</mat-icon
						>
						<!-- <mat-icon
							*ngIf="link.linkType == 'property'"
							[matTooltip]="link.address"
							>house</mat-icon
						> -->
						<mat-icon
							style="
								line-height: 1rem;
								font-size: 1rem;
								width: 1rem;
								position: absolute;
								top: 0.5rem;
								left: -0.1rem;
								font-weight: 600;
							"
							*ngIf="link.linkType == 'property' && link.id == 'unknown'"
							>add</mat-icon
						>
						<div
							*ngIf="link.linkType != 'property'"
							class="pill purple-background"
							[matTooltip]="personToolTip(link, i)"
							[class.red-background]="
								link.sellingPropertyState == 'noSelection' ||
								link.buyingPropertyState == 'noSelection' ||
								link.id == 'unknown' ||
								propColor(link) == 'red' ||
								propColor(link) == 'yellow'
							">
							{{ last ? "Buying" : "Selling" }}
						</div>
						<div
							*ngIf="link.linkType == 'property'"
							class="pill green-background"
							[class.red-background]="
								link.sellingPropertyState == 'noSelection' ||
								link.buyingPropertyState == 'noSelection' ||
								link.id == 'unknown' ||
								propColor(link) == 'red' ||
								propColor(link) == 'yellow'
							">
							Sold By
						</div>
						<img
							*ngIf="link.linkType == 'property'"
							[matTooltip]="link.nickName"
							style="
								width: 1.5rem;
								width: 1.5rem;
								margin-top: 0.25rem;
								margin-bottom: -0.25rem;
							"
							[ngClass]="{
								'unknown-prop': link.id == 'unknown',
								'yellow-prop': propColor(link) == 'yellow'
							}"
							[src]="logoURL(link)"
							alt="house" />
					</a>

					<mat-icon
						*ngIf="!last"
						class="arrow when-big"
						[class.green-text]="link.linkType == 'property'"
						[class.purple-text]="link.linkType != 'property'"
						[class.red-text]="
							link.sellingPropertyState == 'noSelection' ||
							link.buyingPropertyState == 'noSelection' ||
							link.id == 'unknown' ||
							propColor(link) == 'red' ||
							propColor(link) == 'yellow'
						"
						>north</mat-icon
					>
					<mat-icon
						*ngIf="!last"
						class="arrow when-small"
						[class.green-text]="link.linkType == 'property'"
						[class.purple-text]="link.linkType != 'property'"
						[class.red-text]="
							link.sellingPropertyState == 'noSelection' ||
							link.buyingPropertyState == 'noSelection' ||
							link.id == 'unknown' ||
							propColor(link) == 'red' ||
							propColor(link) == 'yellow'
						"
						>west</mat-icon
					>
					<!-- <a routerLinkActive="active-prop" [routerLink]="[link.agents?'property':'buyer-seller',link.id]" >
						<mat-icon *ngIf="!link.partOfProp" [matTooltip]="link.address" >house</mat-icon>
						<img *ngIf="link.partOfProp" [matTooltip]="link.address" style="width:1.5rem;align-self: baseline;" src="/assets/logo-svg-sm.svg" alt="house">
					</a>
					<mat-icon>south</mat-icon> 
					<mat-icon>person</mat-icon> -->
				</ng-container>
			</mat-card-content>
		</mat-card>
	</div>
	<div class="route-container">
		<div
			class="route-container-inner"
			[@routeAnimations]="getRouteAnimationData()">
			<router-outlet
				(activate)="routeActive($event)"
				(deactivate)="routeDeactivate()"></router-outlet>
		</div>
		<!-- <div style="flex-basis: 50%"></div> -->
	</div>
	<!-- <div class="spacer"></div> -->
</div>
