import { CommonModule } from '@angular/common';
import {
	ChangeDetectionStrategy,
	Component,
	ElementRef,
	Input,
	Optional,
	Self,
	ViewChild,
	forwardRef,
	type OnInit,
} from '@angular/core';
import {
	AbstractControl,
	ControlValueAccessor,
	NG_VALIDATORS,
	NG_VALUE_ACCESSOR,
	NgControl,
	ValidationErrors,
	Validator,
} from '@angular/forms';
import { fromEvent } from 'rxjs/internal/observable/fromEvent';
import { auditTime } from 'rxjs/internal/operators/auditTime';
import { distinctUntilChanged } from 'rxjs/internal/operators/distinctUntilChanged';
import { map } from 'rxjs/internal/operators/map';
import SignaturePad from 'signature_pad';

@Component({
	selector: 'app-signature',

	templateUrl: './signature.component.html',
	styleUrls: ['./signature.component.css'],
	providers: [
		// {
		// 	provide: NG_VALUE_ACCESSOR,
		// 	multi: true,
		// 	useExisting: forwardRef(() => SignatureComponent),
		// },
		// {
		// 	provide: NG_VALIDATORS,
		// 	multi: true,
		// 	useExisting: forwardRef(() => SignatureComponent),
		// },
	],
})
export class SignatureComponent implements ControlValueAccessor {
	//Validator
	signatureNeeded!: boolean;
	signaturePad!: SignaturePad;
	@ViewChild('canvas') canvasEl!: ElementRef;
	@ViewChild('groupTitle') groupTitle!: ElementRef;
	signatureImg!: string;
	@Input() required = true;

	$windowResizeEvents: any;

	constructor(@Optional() @Self() public ngControl: NgControl) {
		// Replace the provider from above with this.
		if (this.ngControl != null) {
			// Setting the value accessor directly (instead of using
			// the providers) to avoid running into a circular import.
			this.ngControl.valueAccessor = this;
		}

		this.$windowResizeEvents = fromEvent(window, 'resize')
			.pipe(
				auditTime(100),
				map(() => window.innerWidth),
				distinctUntilChanged()
			)
			.subscribe(() => this.onResize());
	}

	ngAfterViewInit() {
		this.signaturePad = new SignaturePad(this.canvasEl.nativeElement);
		if (this.disabled) this.signaturePad?.off();
		else this.signaturePad?.on();
		if (this.value) this.signaturePad.fromDataURL(this.value);
		else this.signaturePad.clear();
		this.signaturePad.addEventListener('endStroke', () => {
			this.markAsTouched();
			this.onChange(this.signaturePad.toDataURL());
		});
		this.onResize();
	}

	onResize() {
		let width = this.groupTitle.nativeElement.offsetWidth;
		this.canvasEl.nativeElement.width = width;
		let height = this.groupTitle.nativeElement.offsetHeight;
		this.canvasEl.nativeElement.height = height;
	}

	ngOnDestroy() {
		this.signaturePad.clear();
		this.signaturePad.off();
		this.signaturePad.removeEventListener('endStroke', () => {});
		if (this.$windowResizeEvents?.unsubscribe)
			this.$windowResizeEvents.unsubscribe();
	}

	startDrawing(event: Event) {
		// works in device not in browser
	}

	endDrawing(event: Event) {
		console.log('end');
	}

	moved(event: Event) {
		// works in device not in browser
	}

	clearPad() {
		this.signaturePad.clear();
		this.signatureNeeded = true;
		this.writeValue(null);
		this.ngControl?.control?.updateValueAndValidity();
	}

	savePad() {
		const base64Data = this.signaturePad.toDataURL();
		this.signatureImg = base64Data;
		this.signatureNeeded = this.signaturePad.isEmpty();
		if (!this.signatureNeeded) {
			this.signatureNeeded = false;
		}
	}

	value: string | undefined;

	onChange = (signature: string | undefined | null) => {};

	onTouched = () => {};

	touched = false;

	disabled = false;

	writeValue(signature: string | null | undefined) {
		//console.log(signature);
		this.value = signature || undefined;
		if (signature) this.signaturePad?.fromDataURL(signature);
		else this.signaturePad?.clear();
		this.onChange(signature);
	}

	registerOnChange(onChange: any) {
		this.onChange = onChange;
	}

	registerOnTouched(onTouched: any) {
		this.onTouched = onTouched;
	}

	markAsTouched() {
		if (!this.touched) {
			this.onTouched();
			this.touched = true;
		}
	}

	setDisabledState(disabled: boolean) {
		this.disabled = disabled;
		if (disabled) this.signaturePad?.off();
		else this.signaturePad?.on();
	}

	// validate(control: AbstractControl): ValidationErrors | null {
	// 	const quantity = control.value;
	// 	if (quantity.length <= 0) {
	// 		return {
	// 			mustBePositive: {
	// 				quantity,
	// 			},
	// 		};
	// 	}
	// 	return null;
	// }
}
