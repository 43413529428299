import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import { FormBuilder, FormControl, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';

export interface buildingIssueData {
	type?: string | null;
	additionalDetails?: string | null;
	hasBeenFixed?: string | null;
	stillNeedsFixing?: string | null;
	outstandingCost?: number | string | null;
	canReside?: boolean | null;
}

export interface buildingIssueDialogData {
	buildingIssueData: buildingIssueData;
	actions: 'edit' | 'add';
}

@Component({
	selector: 'app-edit-building-issue',
	templateUrl: './edit-building-issue.component.html',
	styleUrls: ['./edit-building-issue.component.css'],
})
export class EditBuildingIssueComponent {
	form = this.buildForm(this._data.buildingIssueData);

	types: {
		value: string;
		details: string;
	}[] = [
		{
			value: 'Unsafe cladding',
			details: '',
		},
		{
			value: 'Integrity of building materials used in construction',
			details: '(e.g., asbestos)',
		},
		{
			value: 'Risk of collapse',
			details: '(e.g., damaged roofs or structural failures)',
		},
		{
			value: 'At-risk wooden decking for external structures',
			details: '(including balconies)',
		},
		{
			value: 'Lack of emergency lighting where required',
			details: '',
		},
		{
			value: 'Insufficient fire/smoke alarm systems',
			details: '',
		},
		{
			value: 'Other',
			details: '',
		},
	];

	get typeDetails() {
		return (
			this.types.find((type) => type.value === this.form.get('type')?.value)
				?.details || ''
		);
	}

	constructor(
		@Inject(MAT_DIALOG_DATA)
		public _data: buildingIssueDialogData,
		public fb: FormBuilder
	) {}

	ngOnInit() {
		this.form = this.buildForm(this._data.buildingIssueData);
	}

	ngOnDestroy() {}

	buildForm(data?: buildingIssueData) {
		let fb = this.fb.group<{
			type: FormControl<string | null>;
			additionalDetails: FormControl<string | null>;
			hasBeenFixed: FormControl<string | null>;
			stillNeedsFixing: FormControl<string | null>;
			outstandingCost: FormControl<number | string | null>;
			canReside: FormControl<boolean | null>;
		}>({
			type: this.fb.control(null, [Validators.required]),
			additionalDetails: this.fb.control(null, []),
			hasBeenFixed: this.fb.control(null, []),
			stillNeedsFixing: this.fb.control(null, []),
			outstandingCost: this.fb.control(null, [Validators.required]),
			canReside: this.fb.control(null, [Validators.required]),
		});

		if (data) {
			fb.patchValue(data);
		}

		return fb;
	}
}
