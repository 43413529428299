import { Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import {
	BuyerSeller,
	BuyerSellersBackendService,
} from '../services/buyer-sellers-backend.service';
import {
	AbstractControl,
	AsyncValidatorFn,
	FormBuilder,
	ValidationErrors,
} from '@angular/forms';
import { environment } from 'src/environments/environment';
import { TitleService } from '../services/title.service';

@Component({
	selector: 'app-pay',
	templateUrl: './pay.component.html',
	styleUrls: ['./pay.component.css'],
})
export class PayComponent {
	buyerSellerId = '';
	buyerSeller?: BuyerSeller | undefined;

	discountForm = this.fb.group({
		code: ['', [], [this.validDiscountCode()]],
	});

	constructor(
		public activatedRoute: ActivatedRoute,
		public buyerSellerBackend: BuyerSellersBackendService,
		public fb: FormBuilder,
		public titleService: TitleService
	) {}

	async ngOnInit() {
		this.titleService.title = 'Payment';

		if (
			environment.branch == 'beta' &&
			this.discountForm.get('code')?.value == ''
		) {
			this.discountForm.get('code')?.setValue('BETA');
			this.discountForm.get('code')?.updateValueAndValidity();
		}

		if (new Date() < new Date('2024-03-01')) {
			this.discountForm.get('code')?.setValue('FREESEPBETA');
			this.discountForm.get('code')?.updateValueAndValidity();
		}

		this.buyerSellerId =
			this.activatedRoute.snapshot.queryParams['buyerSellerId'];

		this.buyerSeller = await this.buyerSellerBackend
			.getOne(this.buyerSellerId)
			.then((res) => res.data);
		this.buyerSeller?.sellingPropertyUPRN;
	}

	pay() {
		console.log('pay');
		this.buyerSellerBackend
			.pay({
				...this.activatedRoute.snapshot.queryParams,
				code: this.discountForm.get('code')?.value,
			} as any)
			.then((res) => {
				console.log(res);
				window.open(res.url, '_self');
			});
	}

	validDiscountCode(): AsyncValidatorFn {
		return (control: AbstractControl): Promise<ValidationErrors | null> => {
			return this._validDiscountCode(control);
		};
	}

	currentDiscountPercent = 0;
	async _validDiscountCode(
		control: AbstractControl
	): Promise<ValidationErrors | null> {
		if (!control.value || control.value.length < 1) {
			this.currentDiscountPercent = 0;
			return null;
		}
		let r = await this.buyerSellerBackend.getDiscount({ code: control.value });
		if (r.data.code) {
			this.currentDiscountPercent = r.data.discountPercent || 0;
			return null;
		}
		this.currentDiscountPercent = 0;
		return { invalidDiscountCode: true };
	}

	currentFee() {
		let sFee = 120;
		return (
			Math.round((sFee - (sFee * this.currentDiscountPercent) / 100) * 100) /
			100
		);
	}

	currentDiscount() {
		let sFee = 120;
		return Math.round(((sFee * this.currentDiscountPercent) / 100) * 100) / 100;
	}
}
