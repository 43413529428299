import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import { FormBuilder, FormControl, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';

export interface bikeStorageData {
	type?: string | null;
	cost?: string | null;
	numberOfSpots?: number | string | null;
	eBikes?: boolean | null;
	cargoBikes?: boolean | null;
	notes?: string | null;
}

export interface bikeStorageDialogData {
	bikeStorageData: bikeStorageData;
	actions: 'edit' | 'add';
}

@Component({
	selector: 'app-edit-bike-storage',
	templateUrl: './Edit-Bike-Storage.component.html',
	styleUrls: ['./Edit-Bike-Storage.component.css'],
})
export class EditBikeStorageComponent {
	form = this.buildForm(this._data.bikeStorageData);

	types: string[] = [];

	constructor(
		@Inject(MAT_DIALOG_DATA)
		public _data: bikeStorageDialogData,
		public fb: FormBuilder
	) {}

	ngOnInit() {
		this.form = this.buildForm(this._data.bikeStorageData);
	}

	ngOnDestroy() {}

	buildForm(data?: bikeStorageData) {
		let fb = this.fb.group<{
			type: FormControl<string | null>;
			cost: FormControl<string | null>;
			numberOfSpots: FormControl<number | string | null>;
			eBikes: FormControl<boolean | null>;
			cargoBikes: FormControl<boolean | null>;
			notes: FormControl<string | null>;
		}>({
			type: this.fb.control(null, [Validators.required]),
			cost: this.fb.control(null, [Validators.required]),
			numberOfSpots: this.fb.control(null, [Validators.required]),
			eBikes: this.fb.control(null, [Validators.required]),
			cargoBikes: this.fb.control(null, [Validators.required]),
			notes: this.fb.control(null, []),
		});

		if (data) {
			fb.patchValue(data);
		}

		return fb;
	}
}
