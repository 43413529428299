import { animate, style, transition, trigger } from '@angular/animations';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { ConfirmDialogService } from 'src/app/services/confirm-dialog.service';
import { MaterialInfoBackendService } from 'src/app/services/materialInfoBackend.service';
import {
	TaskBackendService,
	TaskDocument,
} from 'src/app/services/task-backend.service';
import { UploadFilesService } from 'src/app/services/upload-files.service';

@Component({
	selector: 'app-material-documents-form',

	templateUrl: './material-documents-form.component.html',
	styleUrls: ['./material-documents-form.component.css'],
})
export class MaterialDocumentsFormComponent {
	_canEdit: boolean = false;
	@Input() set canEdit(val: boolean) {
		this._canEdit = val;
		//this.buildFrom();
	}
	get canEdit() {
		return this._canEdit;
	}
	@Output('save') saveEvent = new EventEmitter<any>();

	@Input() disabled: boolean = false;
	@Input() title: string = 'Documents';
	@Input() taskId?: string = '';
	@Input() propertyId?: string = '';
	@Input() materialId?: string = '';

	@Input() state?:
		| 'completed'
		| 'inProgress'
		| 'notStarted'
		| 'disabled'
		| 'cancelled' = 'notStarted';

	@Input() location: 'task' | 'issue' | 'material' = 'material';
	@Input() docType?: string;

	formOpen = true;

	@Input() form?: FormArray<
		FormGroup<{
			id: FormControl<string | null>;
			name: FormControl<string | null>;
			date: FormControl<Date | null>;
		}>
	>;

	get docsArray() {
		return this.form as FormArray<
			FormGroup<{
				id: FormControl<string | null>;
				name: FormControl<string | null>;
				date: FormControl<Date | null>;
			}>
		>;
	}

	constructor(
		public uploadFilesService: UploadFilesService,
		public confirmDialog: ConfirmDialogService,
		public taskBackend: TaskBackendService,
		public materialInfoBackendService: MaterialInfoBackendService,
		public fb: FormBuilder,
		public router: Router,
		public confirmDialogService: ConfirmDialogService
	) {}

	ngOnInit(): void {}

	toggleForm() {
		this.formOpen = !this.formOpen;
	}

	// currentDoc(doc: any) {
	// 	return this.documents.some((d) => d.id === doc.get('id')?.value);
	// }

	trackByFn(index: number, item: any) {
		return item.get('id')?.value || index;
	}

	openDocument(doc: any) {
		if (!this.canEdit) return;
		if (this.disabled) return;
		this.materialInfoBackendService
			.getDocumentDownloadUrl(
				this.propertyId || '',
				this.materialId || '',
				doc.get('id').value,
				doc.get('name').value,
				'original'
			)
			.then((r) => {
				let link = document.createElement('a');
				document.body.appendChild(link);
				link.download = doc.get('name').value;
				link.href =
					r.data.pages[0].url +
					`&&response-content-disposition=attachment;filename=${
						doc.get('name').value
					}`;
				link.click();
				document.body.removeChild(link);
			});
	}

	async openPublic(doc: any) {
		//if (!this.canEdit) return;
		//if (this.disabled) return;
		if (this.form?.dirty) {
			let r = await this.confirmDialogService.confirm({
				title: 'Save Changes',
				message:
					'You have unsaved changes. You have to save them before you can view the document. Do you want to save them now?',
				confirmText: 'Save',
				cancelText: 'Cancel',
			});

			if (r) {
				this.save();
			} else return;
		}
		this.router.navigate([
			'/material-document',
			this.propertyId,
			this.materialId,
			doc.get('id').value,
		]);
	}

	docIcon(fileName: string | undefined | null) {
		if (!fileName) return 'insert_drive_file';
		if (fileName.endsWith('.pdf')) return 'picture_as_pdf';
		if (fileName.endsWith('.png')) return 'image';
		if (fileName.endsWith('.jpg')) return 'image';
		if (fileName.endsWith('.doc') || fileName.endsWith('.docx'))
			return 'description';
		return 'insert_drive_file';
	}

	async removeDocument(index: number) {
		if (!this.canEdit || this.disabled) return;
		let doc = this.docsArray.at(index) as FormGroup;

		let canRemove = await this.confirmDialog.confirm({
			title: 'Remove document',
			message: `Are you sure you want to remove the "${
				doc.get('name')?.value
			}" document?`,
		});

		if (!canRemove) return;

		this.docsArray.removeAt(index);
		this.docsArray.markAsDirty();
	}

	async addDocuments(files: FileList | null) {
		if (!this.canEdit || this.disabled) return;
		if (files && files.length > 0) {
			for (let i = 0; i < files.length; i++) {
				const file = files[i];

				let p: Promise<string | undefined>;
				if (this.location == 'material' && this.propertyId) {
					p = this.uploadFilesService.uploadMaterialFile({
						file,
						propertyId: this.propertyId,
					});
				} else {
					p = this.uploadFilesService.uploadFile({
						file,
						taskId: this.taskId || '',
					});
				}

				p.then((id) => {
					//if (!this.documents) this.documents = [];
					if (id) {
						const form = new FormGroup({
							id: new FormControl(id),
							name: new FormControl(file.name),
							date: new FormControl(new Date()),
						});
						this.docsArray.push(form);
						form.markAsDirty();
						this.docsArray.markAsDirty();
					}

					//this.buildFrom();
				});
			}
		}
	}

	async dragAddDocuments(file: TaskDocument) {
		if (!this.canEdit || this.disabled) return;
		if (!file?.id) return;
		const form = new FormGroup({
			id: new FormControl(file.id),
			name: new FormControl(file.name),
			date: new FormControl(file.date),
		});
		console.log(this.form?.value);
		this.docsArray.push(form);
		console.log(this.docsArray?.value);
		form.markAsDirty();
		this.docsArray.markAsDirty();
	}

	saving = false;
	async save() {
		// if (!this.canEdit || this.disabled) return;
		// this.saving = true;
		// this.docsForm.disable();
		// let docs = this.docsArray.controls.map((c) => {
		// 	return {
		// 		id: c.get('id')?.value,
		// 		name: c.get('name')?.value,
		// 		date: c.get('date')?.value,
		// 	};
		// });
		// let r = await this.taskBackend.patch(this.taskId || '', {
		// 	documents: docs,
		// });
		// let newTask = r?.data;
		// //if (newTask?.documents) this.documents = newTask.documents;
		// this.docsForm.enable();
		// this.saving = false;
		this.saveEvent.emit();
	}

	reset() {
		//this.buildFrom();
	}
}
