import {
	Component,
	EventEmitter,
	Input,
	OnDestroy,
	OnInit,
	Output,
} from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { TaskDocument } from 'src/app/services/task-backend.service';

@Component({
	selector: 'app-issue',
	templateUrl: './issue.component.html',
	styleUrls: ['./issue.component.css'],
})
export class IssueComponent implements OnInit {
	@Input() issueFrom?: FormGroup<{
		id?: FormControl<String>;
		message?: FormControl<String>;
		issueType?: FormControl<String>;
		level?: FormControl<'warning' | 'blocker'>;
		date?: FormControl<Date>;
		documents?: FormControl<TaskDocument[]>;
		currentDocs?: FormControl<TaskDocument[]>;
		dismissedBy?: FormControl<String | undefined>;
	}>;

	@Input() taskId?: string;

	@Input() issueTypes?: string[] = [];

	@Output() delete: EventEmitter<String | undefined | null> = new EventEmitter<
		String | undefined | null
	>();

	constructor() {}

	ngOnInit(): void {}

	test(val: any) {
		console.log('test');
	}

	addDocuments(document: TaskDocument) {
		console.log('addDocuments');
		console.log(document);
		let currentDocs: TaskDocument[] =
			this.issueFrom?.get('documents')?.value || [];
		currentDocs.push(document);

		this.issueFrom?.get('documents')?.setValue(currentDocs);
		this.issueFrom?.get('currentDocs')?.markAsDirty();
	}

	async acceptRisk() {
		this.issueFrom?.get('dismissedBy')?.setValue('true');
		this.issueFrom?.disable();
		this.issueFrom?.get('dismissedBy')?.enable();
		this.issueFrom?.get('dismissedBy')?.markAsDirty();
	}

	async unAcceptRisk() {
		this.issueFrom?.get('dismissedBy')?.setValue('false');
		this.issueFrom?.enable();
		this.issueFrom?.get('dismissedBy')?.markAsDirty();
	}
}
