<h1 mat-dialog-title>{{ title }}</h1>
<div mat-dialog-content>{{ message }}</div>
<div mat-dialog-actions>
	<button
		mat-button
		[mat-dialog-close]="false"
		type="button"
		*ngIf="!this.data.cancelText === false">
		{{ cancelText }}
	</button>
	<button mat-button [mat-dialog-close]="'true'" type="button" cdkFocusInitial>
		{{ confirmText }}
	</button>
</div>
