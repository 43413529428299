import { Injectable } from '@angular/core';
import { backendResponse, BackendService } from './backend.service';

@Injectable({
	providedIn: 'root',
})
export class AMLBackendService {
	constructor(public backend: BackendService) {}

	async put(data: {
		email: string;
		firstName: string;
		lastName: string;
		emailBody?: string;
	}): Promise<backendResponse<AMLcheckResponse>> {
		return (await this.backend.put(
			`AML/AMLcheck`,
			data
		)) as backendResponse<AMLcheckResponse>;
	}

	async getList(filter?: {
		myAMLchecks?: boolean;
	}): Promise<backendResponse<AMLcheckResponse[]>> {
		let q = '';
		if (filter?.myAMLchecks) {
			q = '?myChecks=true';
		}
		return (await this.backend.get('AML/AMLchecks' + q)) as backendResponse<
			AMLcheckResponse[]
		>;
	}

	async getReport(
		amlId: string
	): Promise<backendResponse<{ url: string; fileName: string }>> {
		return (await this.backend.get(
			`AML/AMLcheckReport/${amlId}`
		)) as backendResponse<{ url: string; fileName: string }>;
	}

	async patchAMLpermissions(
		amlId: string,
		data: {
			AMLpermissionIds: string[];
			status: 'granted' | 'denied';
		}
	): Promise<backendResponse<any>> {
		return (await this.backend.patch(
			`AML/AMLpermissions/${amlId}`,
			data
		)) as backendResponse<any>;
	}
}

export interface AMLcheck {
	id: string;
	credasId: string;
	userEmail: string;
	status: 'pending' | 'complete' | 'error';
	issues?: string[];
	error?: string;
	reportKey?: string;
	createdDate?: Date;
	completedDate?: Date;
	ExpDate?: Date;
	firstName?: string;
	lastName?: string;
	identityVerifications?: credasStatus;
	addressMortalityChecks?: credasStatus;
	sanctionPepChecks?: credasStatus;
	permission?: boolean;
	permissionState?: 'requested' | 'granted' | 'denied';
	url?: string;
}

export interface AMLPermission {
	id: string;
	userEmail?: string;
	firstName?: string;
	lastName?: string;
	company?: string;
	branchId?: string;
	groupId?: string;
	AMLid: string;
	status: 'requested' | 'granted' | 'denied';
}

export interface AMLcheckResponse {
	permissions: AMLPermission[];
	check: AMLcheck;
}

export enum credasStatus {
	notPreformed = 0,
	pass = 1,
	refer = 2,
	fail = 3,
	actionRequired = 4,
}
