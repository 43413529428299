<div class="container">
	<h1>Access Confirmation</h1>

	<mat-card>
		<mat-card-content>
			<div>
				<p>
					As a Buyer or Seller you will need to pay a small fee to use the Clear
					Chain Project Management Solution. Whether you have chosen to use
					Clear Chain yourself or it is a stipulation of your sale set by
					someone else in the property chain, there is a lot to be gained from
					using this service.
				</p>
				<p>
					This is a one time fee and allows access to the solution for you and
					any other joint owners or relevant parties involved in your part of
					the chain (e.g. mortgage broker, conveyancer or estate agent).
				</p>
				<p>
					<span style="font-weight: 600">Note:</span> You will not need to pay a
					second fee for an additional property transaction as long as it is in
					the same chain.
				</p>
			</div>
			<!-- <div
				style="
					margin-top: 2rem;
					text-align: center;
					font-size: 1.5rem;
					line-height: 2rem;
					font-weight: 600;
				">
				<div style="color: red">**FREE FOR SEPTEMBER**</div>
				<div>With code <span style="color: red">FREESEPBETA</span></div>
			</div>
			<div style="text-align: center; margin-bottom: 2rem">
				(make sure you click Pay Now to gain full access)
			</div> -->
			<div>
				<ul
					style="
						font-weight: bold;
						text-align: center;
						list-style-position: inside;
						padding-left: 0;
					">
					<!-- <li *ngIf="(buyerSeller?.buyingPropertyUPRN?.length || 0) > 0">
						Buying: {{ cleanAddress(buyerSeller?.buyingPropertyUPRN || "") }}
					</li>
					<li *ngIf="(buyerSeller?.sellingPropertyUPRN?.length || 0) > 0">
						Selling:
						{{ cleanAddress(buyerSeller?.sellingPropertyUPRN || "") }}
					</li> -->
				</ul>
			</div>
		</mat-card-content>
		<mat-card-actions class="center" [formGroup]="discountForm">
			<mat-form-field appearance="outline" floatLabel="always">
				<mat-label mat-label>Discount Code</mat-label>
				<input matInput placeholder="Discount Code" formControlName="code" />
				<mat-error *ngIf="this.discountForm.invalid"
					>Invalid Discount Code</mat-error
				>
			</mat-form-field>
			<table
				style="
					border-collapse: collapse;
					margin-top: 1rem;
					margin-bottom: 1.5rem;
				">
				<tr>
					<td style="padding-right: 2rem">CLEAR CHAIN SOLUTION FEE</td>
					<td style="text-align: right">£120.00</td>
				</tr>
				<tr *ngIf="currentDiscountPercent > 0">
					<td>DISCOUNT</td>
					<td style="text-align: right; color: red">
						-£{{ currentDiscount() | number : "1.2-2" }}
					</td>
				</tr>
				<tr
					style="
						border-top: 2px solid black;
						font-size: 1.25rem;
						font-weight: 600;
						line-height: 1.5rem;
					">
					<td>TOTAL</td>
					<td style="text-align: right">
						£{{ currentFee() | number : "1.2-2" }}
					</td>
				</tr>
			</table>
			<!-- <div *ngIf="currentDiscountPercent > 0">
				Your Fee: £120.00 (inc VAT) - ({{ currentDiscountPercent }}%) =
				<span style="font-weight: bold">£{{ currentFee() }} (inc VAT)</span>
			</div>
			<div *ngIf="currentDiscountPercent == 0">
				Your Fee: <span style="font-weight: bold">£120.00 (inc VAT)</span>
			</div> -->
			<br />
			<button
				[disabled]="this.discountForm.invalid"
				mat-raised-button
				color="primary"
				(click)="pay()">
				Pay Now
			</button>
		</mat-card-actions>
		<mat-card-content style="text-align: center; padding-top: 2rem">
			<img style="width: 70%" src="assets/stripe-badge-transparent.png" />
		</mat-card-content>
	</mat-card>
</div>
