import { Injectable } from '@angular/core';
import { backendResponse, BackendService } from './backend.service';

@Injectable({
	providedIn: 'root',
})
export class MaterialInfoBackendService {
	constructor(public backend: BackendService) {}

	async getOne(
		propertyId: string
		// offerId?: string
	): Promise<backendResponse<materialInfoData>> {
		let q = '';
		// if (offerId) {
		// 	q = `?offerId=${offerId}`;
		// }
		return (await this.backend.get(
			`property/${propertyId}/materialInformation${q}`,
			{
				noCredentials: true,
			}
		)) as backendResponse<materialInfoData>;
	}

	async getSavedOne(
		propertyId: string
		// offerId?: string
	): Promise<backendResponse<materialInfoData>> {
		let q = '';
		// if (offerId) {
		// 	q = `?offerId=${offerId}`;
		// }
		return (await this.backend.get(
			`property/${propertyId}/materialInformation/saved${q}`,
			{
				noCredentials: false,
			}
		)) as backendResponse<materialInfoData>;
	}

	async patch(
		propertyId: string,
		materialId: string,
		data: Partial<materialInfoData>
	): Promise<backendResponse<materialInfoData>> {
		return (await this.backend.patch(
			`property/${propertyId}/materialInformation/saved/${materialId}`,
			data
		)) as backendResponse<materialInfoData>;
	}

	async post(
		propertyId: string,
		materialId: string,
		data: Partial<materialInfoData>
	): Promise<backendResponse<materialInfoData>> {
		return (await this.backend.post(
			`property/${propertyId}/materialInformation/saved/${materialId}`,
			data
		)) as backendResponse<materialInfoData>;
	}

	async getDocumentUploadUrl(options: {
		propertyId: string;
		fileName: string;
		contentType: string;
	}): Promise<
		backendResponse<{ url: string; id: string; fields: { key: any } }>
	> {
		return (await this.backend.put(`document/material`, options)) as any;
	}

	async getDocumentDownloadUrl(
		propertyId: string,
		materialId: string,
		documentId: string,
		fileName: string,
		version: 'original' | 'public' = 'public'
	): Promise<
		backendResponse<{
			pages: { url: string; page: number }[];
			allReactions: boolean;
		}>
	> {
		//"/document/material/{propertyId}/{materialId}/{documentId}"
		return (await this.backend.get(
			`document/material/${propertyId}/${materialId}/${documentId}?version=${version}&fileName=${fileName}`
		)) as any;
	}
}

export interface materialInfoData {
	id?: string;
	propertyId?: string;
	askingPriceForm?: {
		askingPrice?: string;
		newBuild?: boolean;
		reservationRequired?: boolean;
		reservationFee?: string;
	};
	tenureForm?: {
		tenure?: string;
		ownership?: string;
		originLengthOfLease?: number;
		leftOnLease?: number;
		groundRentCost?: string;
		serviceChargesCost?: string;
		periodOfServiceCharges?: string;
		sinkingFund?: boolean;
		percentToSinkingFund?: number;
		sinkingFundAmount?: string;
		capitalWorksPlanned?: boolean;
		capitalWorksDetails?: string;
		hasManagingAgent?: boolean;
		managingAgents?: {
			firstName?: string;
			lastName?: string;
			email?: string;
			phoneCountryCode?: string;
			phoneInCountryNumber?: string;
			company?: string;
		}[];
		petRestrictions?: string;
		petRestrictionsDetails?: string;
	};
	leasingTenantForm?: {
		currentLeasingTenant?: boolean;
		leaseBeyondSale?: boolean;
		leaseEndDate?: Date | string | number;
	};
	propertyConstructionForm?: {
		originalConstructed?: string;
		propertyType?: string;
		otherPropertyType?: string;
		wallConstruction?: string[];
		otherWallConstruction?: string;
		roofType?: string[];
		roofMaterial?: string[];
		otherRoofMaterial?: string;
		leftBoundary?: string;
		rightBoundary?: string;
		rearBoundary?: string;
		frontBoundary?: string;
		irregularBoundary?: boolean;
		irregularBoundaryDetails?: string;
		boundaryMoved?: boolean;
		boundaryMovedDetails?: string;
	};
	spacesForm?: {
		spaces?: {
			indoorOutdoor?: string;
			type?: string;
			width?: number;
			length?: number;
			ceilingHeight?: number;
			ceilingType?: string;
			shape?: string;
			shower?: boolean;
			bath?: boolean;
			toilet?: boolean;
			name?: string;
		}[];
		floorSpace?: number;
		frontDoorLevel?: number;
		numberOfFloors?: number;
	};
	knownWorksForm?: {
		knowledgedOfWorks?: boolean;
		knownWorks?: {
			description?: string;
			completionDate?: Date | string | number;
		}[];
	};
	utilitiesForm?: {
		electricityCost?: string;
		electricityAltCost?: string;
		electricityAltDetails?: string;
		waterCost?: string;
		waterAltCost?: string;
		waterAltDetails?: string;
		sewerageCost?: string;
		sewerageType?: string;
		sewerageOther?: string;
		heatingSources?: {
			type?: string;
			cost?: string;
			otherDetails?: string;
		}[];
	};
	parkingBikeStorageForm?: {
		parkingAvailable: boolean;
		parkingSpots?: {
			type?: string;
			cost?: string;
			numberOfSpots?: number;
			notes?: string;
		}[];
		bikeStorageAvailable?: boolean;
		bikeStorage?: {
			type?: string;
			cost?: string;
			numberOfSpots?: number;
			eBikes?: boolean;
			cargoBikes?: boolean;
			notes?: string;
		}[];
	};
	buildingSafetyIssuesForm?: {
		anyKnownIssues?: boolean;
		issues?: {
			type?: string;
			additionalDetails?: string;
			hasBeenFixed?: string;
			stillNeedsFixing?: string;
			outstandingCost?: string;
			canReside?: boolean;
		}[];
	};
	rightsAndEasementsForm?: {
		rightsAndEasements?: boolean;
		rightsAndEasementsDetails?: string;
	};
	accessibilityForm?: {
		stepFreeAccess?: boolean;
		wetRoom?: boolean;
		lateralLiving?: boolean;
		details?: string;
	};
	confirmationForm?: {
		confirmAllIsAccurate?: boolean;
		confirmUnderstandPublic?: boolean;
		signature?: string;
		fullName?: string;
	};
	lastUpdate?: number;
	address?: string;
	agents?: {
		firstName?: string;
		lastName?: string;
		email?: string;
		phoneCountryCode?: string;
		phoneInCountryNumber?: string;
		company?: string;
	}[];
}
