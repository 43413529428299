import { Injectable, Injector } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { lastValueFrom } from 'rxjs';
import { BugsDialogComponent } from '../form-fields/bugs-dialog/bugs-dialog.component';
import { BackendService } from './backend.service';
import { takeScreenshot, checkIfBrowserSupported } from '@xata.io/screenshot';
import { MatSnackBar } from '@angular/material/snack-bar';
import { environment } from 'src/environments/environment';
//import { Router, RouterStateSnapshot } from '@angular/router';

@Injectable({
	providedIn: 'root',
})
export class BugsService {
	constructor(
		private injector: Injector,
		//public backend: BackendService,
		public dialog: MatDialog,
		private _snackBar: MatSnackBar
	) {}

	history: string[] = [];

	addToHistory(state: string) {
		this.history.unshift(state);
		if (this.history.length > 10) {
			this.history.pop();
		}
	}

	async newBug(type: 'bug' | 'feature' = 'bug') {
		let r = await this.BugDialog(type);
		if (!r || !r.title) return;
		if (r.screenShot && checkIfBrowserSupported()) {
			await takeScreenshot({
				type: 'image/webp' as any,
				quality: 0.9,
			})
				.then((screenshot) => {
					/**
					 * This is a base64-encoded string representing your screenshot.
					 * It can go directly into an <img>'s `src` attribute, or be sent to a server to store.
					 */
					r.screenShot = screenshot;
				})
				.catch((err) => {
					delete r.screenShot;
				});
		}

		//if((window as any).errors){
		r.errors = ((window as any).errors || []).map((e: Error) => {
			// return {
			// 	error: e.toString(),
			// 	stack: e.stack?.toString(),
			// };
			return {
				error: JSON.stringify(e.message || e.name || e.toString()),
				stack: JSON.stringify(e.stack),
			};
		});
		r.history = this.history;
		r.version = environment.version;

		//}
		let backend = this.injector.get(BackendService);
		r.apiHistory = [...backend.apiHistory];
		await backend.put(`bugs`, r);

		this._snackBar.open('Thanks, Your bug has been reported.', 'OK', {
			duration: 2000,
		});
	}

	async BugDialog(type: 'bug' | 'feature' = 'bug') {
		const dialogRef = this.dialog.open(BugsDialogComponent, {
			data: {
				type: type,
			},
			disableClose: false,
			width: '800px',
			maxWidth: '95vw',
		});

		let r = await lastValueFrom(dialogRef.afterClosed());
		return r;
	}
}
