import {
	ChangeDetectorRef,
	Component,
	EventEmitter,
	Input,
	OnDestroy,
	OnInit,
	Output,
} from '@angular/core';
import {
	FormArray,
	FormBuilder,
	FormControl,
	Validators,
} from '@angular/forms';
import {
	TaskBackendService,
	TaskWarning,
} from 'src/app/services/task-backend.service';
import { AuthService } from 'src/app/services/auth.service';
import { ConfirmDialogService } from 'src/app/services/confirm-dialog.service';
import { ConfirmExitFrom } from 'src/app/services/forms.service';
import { animate, style, transition, trigger } from '@angular/animations';

@Component({
	selector: 'app-issues',
	templateUrl: './issues.component.html',
	styleUrls: ['./issues.component.css'],
	animations: [
		trigger('inOutAnimation', [
			transition(':enter', [
				style({ height: 0, opacity: 0, overflow: 'hidden' }),
				animate('300ms ease-out', style({ height: '*', opacity: 1 })),
			]),
			transition(':leave', [
				style({ height: '*', opacity: 1, overflow: 'hidden' }),
				animate('300ms ease-in', style({ height: 0, opacity: 0 })),
			]),
		]),
	],
})
export class IssuesComponent implements OnInit, OnDestroy {
	@Input() formOpen = true;
	_state:
		| 'completed'
		| 'inProgress'
		| 'notStarted'
		| 'disabled'
		| 'cancelled'
		| undefined;
	@Input() set state(
		val:
			| 'completed'
			| 'inProgress'
			| 'notStarted'
			| 'disabled'
			| 'cancelled'
			| undefined
	) {
		this._state = val;
		if (
			val === 'completed' ||
			val === 'disabled' ||
			val === 'cancelled' ||
			!this.canEdit
		) {
			this.issuesForm.disable();
		} else {
			this.issuesForm.enable();
		}
	}
	get state() {
		return this._state;
	}

	_canEdit: boolean = false;
	@Input() set canEdit(val: boolean) {
		this._canEdit = val;

		if (
			this.state === 'completed' ||
			this.state === 'cancelled' ||
			this.state === 'disabled' ||
			!this._canEdit
		) {
			this.issuesForm.disable();
		} else {
			this.issuesForm.enable();
		}
	}
	get canEdit() {
		return this._canEdit;
	}

	_issues: TaskWarning[] = [];
	@Input() set issues(val: TaskWarning[]) {
		this._issues = val;
		this.buildForm();
	}

	@Output() saved = new EventEmitter<any>();

	get issues(): TaskWarning[] {
		return this._issues;
	}

	@Input() issueTypes?: string[] = [];

	@ConfirmExitFrom() issuesForm = this.fb.group({
		issues: this.fb.array([]),
	});

	get issuesArray() {
		return this.issuesForm.get('issues') as FormArray;
	}

	@Input() taskId? = '';

	userEmail: string = '';

	constructor(
		public fb: FormBuilder,
		public cd: ChangeDetectorRef,
		public AuthService: AuthService,
		public taskBackendService: TaskBackendService,
		public confirmDialogService: ConfirmDialogService
	) {}

	async ngOnInit() {
		this.userEmail = (await this.AuthService.getUser())?.attributes?.['email'];
	}

	ngOnDestroy() {
		console.log('ngOnDestroy IssuesComponent');
	}

	buildForm() {
		this.issuesForm = this.fb.group({
			issues: this.fb.array([]),
		});

		this.issues.forEach((issue) => {
			(this.issuesForm.get('issues') as FormArray)?.push(
				this.fb.group({
					id: issue.id,
					issueType: [issue.issueType, [Validators.required]],
					message: [issue.message, [Validators.required]],
					level: issue.level,
					date: issue.date,
					createdByEmail: issue.createdByEmail,
					documents: new FormControl([...(issue.documents || [])]),
					currentDocs: new FormControl(issue.documents || []),
					dismissedBy: new FormControl(issue.dismissedBy),
				})
			);
		});
		this.removeIssueList = [];

		this.issuesForm.enable();
		(this.issuesForm.get('issues') as FormArray).controls.forEach((issue) => {
			issue.enable();
			issue.get('documents')?.enable();

			if (issue.get('dismissedBy')?.value) {
				issue.disable();
				issue.get('dismissedBy')?.enable();
			}
		});

		if (
			this.state === 'completed' ||
			this.state === 'cancelled' ||
			this.state === 'disabled' ||
			!this.canEdit
		) {
			this.issuesForm.disable();
		}

		this.cd.detectChanges();
	}

	addIssue() {
		(this.issuesForm.get('issues') as FormArray)?.push(
			this.fb.group({
				id: '',
				issueType: ['', [Validators.required]],
				message: ['', [Validators.required]],
				level: 'warning',
				date: new Date(),
				createdByEmail: this.userEmail,
				dismissedBy: undefined,
				documents: [],
			})
		);

		this.cd.detectChanges();
	}

	removeIssueList: string[] = [];
	async removeIssue(index: number) {
		let canRemove = await this.confirmDialogService.confirm({
			title: 'Remove Issue',
			message: 'Are you sure you want to remove this issue?',
		});
		if (!canRemove) return;
		if (this.issuesArray.controls[index].value.id)
			this.removeIssueList.push(this.issuesArray.controls[index].value.id);
		this.issuesArray.removeAt(index);
		this.cd.detectChanges();
	}

	toggleForm() {
		this.formOpen = !this.formOpen;
		if (this.formOpen) {
			this.buildForm();
		}
		this.cd.detectChanges();
	}

	async save() {
		if (
			this.taskId &&
			this.issuesForm.valid &&
			!this.issuesForm.disabled &&
			this.canEdit
		) {
			this.issuesForm.disable();
			// get new issues without ids
			const newIssues = this.issuesArray.controls.filter(
				(issue) => !issue.get('id')?.value
			);
			// get updated issues with ids
			const updatedIssues = this.issuesArray.controls.filter(
				(issue) => issue.get('id')?.value
			);

			let qList: Promise<any>[] = [];
			// add new issues
			newIssues.forEach((issue) => {
				qList.push(
					this.taskBackendService.addWarning(
						this.taskId || '',
						issue.get('message')?.value,
						issue.get('issueType')?.value,
						issue.get('level')?.value,
						issue.get('documents')?.value,
						issue.get('dismissedBy')?.value
					)
				);
			});

			// update issues
			updatedIssues.forEach((issue) => {
				let update = {
					message: issue.get('message')?.value,
					issueType: issue.get('issueType')?.value,
					level: issue.get('level')?.value,
					documents: issue.get('documents')?.value || [],
					dismissedBy: issue.get('dismissedBy')?.value,
				};
				qList.push(
					this.taskBackendService.updateWarning(
						this.taskId || '',
						issue.get('id')?.value,
						update
					)
				);
			});

			// delete issues
			this.removeIssueList.forEach((issueId) => {
				qList.push(
					this.taskBackendService.deleteWarning(this.taskId || '', issueId)
				);
			});

			await Promise.all(qList);

			this.issuesForm.markAsUntouched();
			this.issuesForm.markAsPristine();

			this.saved.emit();
		}
	}

	trackByFn(index: number, item: any) {
		return item?.id || index;
	}
}
