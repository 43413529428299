import {
	AfterViewInit,
	ChangeDetectionStrategy,
	ChangeDetectorRef,
	Component,
	Directive,
	ElementRef,
	EventEmitter,
	HostListener,
	Input,
	NgZone,
	OnInit,
	Output,
	ViewChild,
} from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Component({
	selector: 'app-select-file-button',
	templateUrl: './select-file-button.component.html',
	styleUrls: ['./select-file-button.component.css'],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SelectFileButtonComponent implements OnInit, AfterViewInit {
	@Output() newFiles: EventEmitter<FileList | null> =
		new EventEmitter<FileList | null>(true);
	_disabled = false;
	@Input() set disabled(val: boolean) {
		this._disabled = val;
		this.cd.markForCheck();
	}

	@Input() text: string | undefined;
	@Input() accept: string | undefined;

	get disabled() {
		return this._disabled;
	}
	@ViewChild('fileInput') fileInput?: ElementRef;
	constructor(
		public elementRef: ElementRef,
		public cd: ChangeDetectorRef,
		public zone: NgZone
	) {}

	ngOnInit(): void {}

	ngAfterViewInit(): void {
		if (this.fileInput) {
			// this.fileInput.nativeElement.addEventListener(
			// 	'change',
			// 	this.onFileChange.bind(this)
			// );
			// this.fileInput.nativeElement.onchange = (ev: any) => {
			// 	this.onFileChange(ev);
			// };
		}
	}

	test() {
		//this.newFiles.next([]);
	}

	startSelect() {
		if (this.fileInput) {
			this.fileInput.nativeElement.value = null;
			let emitter = this.newFiles;
			this.fileInput.nativeElement.onchange = (ev: any) => {
				const files: FileList = ev.target.files;
				//console.log(files);
				//setTimeout(() => {
				this.newFiles.emit(files);
				//}, 2000);
				this.cd.detectChanges();
				this.cd.markForCheck();
				//}, 1000);
			};
			this.fileInput.nativeElement.click();
		}
	}

	async other() {
		this.newFiles.emit(null);
		await this.wait(1000);
		this.myEmit();
		this.cd.detectChanges();
		this.cd.markForCheck();
		//this.zone.run(() => {

		// await this.waitThen(() => {
		// 	this.newFiles.emit(null);
		// 	//this.cd.detectChanges();
		// });

		//this.newFiles.emit(null);

		//});
	}

	myEmit() {
		this.newFiles.emit(null);
	}

	async wait(time: number) {
		await new Promise((resolve) =>
			setTimeout(() => {
				resolve(true);
			}, time)
		);
	}

	async waitThen(fx: any) {
		await new Promise((resolve) =>
			setTimeout(() => {
				fx();
				resolve(true);
			}, 1000)
		);
	}

	onFileChange(event: any) {
		this.zone.run(() => {
			const files = event.target.files;
			this.newFiles.next(files);
			//this.cd.markForCheck();
		});
	}
}
