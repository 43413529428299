<h1 mat-dialog-title>{{ _data.actions == "edit" ? "Edit" : "Add" }} Space</h1>
<mat-dialog-content style="max-width: 600px; padding: 1rem">
	<form
		[formGroup]="form"
		style="
			display: flex;
			justify-content: center;
			flex-wrap: wrap;
			max-width: 600px;
			gap: 0 1rem;
		">
		<mat-form-field appearance="outline" floatLabel="always">
			<mat-label>Location</mat-label>
			<mat-select formControlName="indoorOutdoor">
				<mat-option value="indoor">Indoors</mat-option>
				<mat-option value="outdoor">Outdoors</mat-option>
			</mat-select>
		</mat-form-field>
		<mat-form-field appearance="outline" floatLabel="always">
			<mat-label>Type</mat-label>
			<mat-select formControlName="type">
				<mat-option *ngFor="let type of types; let i = index" [value]="type">{{
					type
				}}</mat-option>
			</mat-select>
		</mat-form-field>
		<mat-form-field appearance="outline" floatLabel="always">
			<mat-label>Name</mat-label>
			<input
				matInput
				type="text"
				placeholder="Master Bedroom"
				formControlName="name" />
		</mat-form-field>
		<mat-form-field appearance="outline" floatLabel="always">
			<mat-label>Space Length</mat-label>
			<input
				matInput
				type="number"
				placeholder="4.2"
				formControlName="length" />
			<span matTextSuffix>&nbsp;Meters</span>
		</mat-form-field>
		<mat-form-field appearance="outline" floatLabel="always">
			<mat-label>Space Width</mat-label>
			<input matInput type="number" placeholder="3.5" formControlName="width" />
			<span matTextSuffix>&nbsp;Meters</span>
		</mat-form-field>
		<mat-form-field
			appearance="outline"
			floatLabel="always"
			*ngIf="form.get('ceilingHeight')">
			<mat-label>Ceiling Height</mat-label>
			<input
				matInput
				type="number"
				placeholder="2.4"
				required
				formControlName="ceilingHeight" />
			<span matTextSuffix>&nbsp;Meters</span>
		</mat-form-field>
		<mat-form-field
			appearance="outline"
			floatLabel="always"
			*ngIf="form.get('ceilingType')">
			<mat-label>Ceiling Type</mat-label>
			<mat-select formControlName="ceilingType" multiple required>
				<mat-option value="flat">Flat</mat-option>
				<mat-option value="sloped">Sloped</mat-option>
			</mat-select>
		</mat-form-field>
		<mat-form-field appearance="outline" floatLabel="always">
			<mat-label>Shape</mat-label>
			<mat-select formControlName="shape">
				<mat-option value="rectangular">Rectangular</mat-option>
				<mat-option value="irregular">Irregular</mat-option>
			</mat-select>
		</mat-form-field>

		<div style="width: 100%" *ngIf="form.get('toilet')">
			<label>Is there a toilet?</label>
			<mat-radio-group formControlName="toilet">
				<mat-radio-button [value]="true">Yes</mat-radio-button>
				<mat-radio-button [value]="false">No</mat-radio-button>
			</mat-radio-group>
		</div>
		<div style="width: 100%" *ngIf="form.get('bath')">
			<label>Is there a bath?</label>
			<mat-radio-group formControlName="bath">
				<mat-radio-button [value]="true">Yes</mat-radio-button>
				<mat-radio-button [value]="false">No</mat-radio-button>
			</mat-radio-group>
		</div>
		<div style="width: 100%" *ngIf="form.get('shower')">
			<label>Is there a shower?</label>
			<mat-radio-group formControlName="shower">
				<mat-radio-button [value]="true">Yes</mat-radio-button>
				<mat-radio-button [value]="false">No</mat-radio-button>
			</mat-radio-group>
		</div>
	</form>
</mat-dialog-content>
<mat-dialog-actions>
	<button mat-button [mat-dialog-close]="false" type="button">Cancel</button>
	<button
		mat-button
		[mat-dialog-close]="form.value"
		[disabled]="form.disabled || form.invalid"
		type="button"
		cdkFocusInitial>
		Save
	</button>
</mat-dialog-actions>
