import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { SearchesService } from 'src/app/services/searches.service';

@Component({
	selector: 'app-cc-searches',
	templateUrl: './CC_Searches.component.html',
	styleUrls: ['./CC_Searches.component.css'],
})
export class CCSearchesComponent {
	@Input() disabled = false;
	@Input() taskId?: string;
	constructor(public SearchService: SearchesService) {}

	ngOnInit(): void {}

	async getStripUrl() {
		const response = await this.SearchService.getStripUrl(this.taskId || '');
		console.log(response);
		open(response.url, '_self');
	}
}
