import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
export interface ConfirmDialogData {
	title?: string;
	message?: string;
	confirmText?: string;
	cancelText?: string | false;
}

@Component({
	selector: 'app-confirm-dialog',
	templateUrl: './confirm-dialog.component.html',
	styleUrls: ['./confirm-dialog.component.css'],
})
export class ConfirmDialogComponent implements OnInit {
	title = 'Confirm?';
	message = 'Are you sure?';
	confirmText = 'Confirm';
	cancelText = 'Cancel';

	constructor(@Inject(MAT_DIALOG_DATA) public data: ConfirmDialogData) {}

	ngOnInit(): void {
		if (this.data) {
			this.title = this.data.title || this.title;
			this.message = this.data.message || this.message;
			if (this.data.cancelText === false) this.data.cancelText === false;
			else this.confirmText = this.data.confirmText || this.confirmText;
			this.cancelText = this.data.cancelText || this.cancelText;
		}
	}
}
