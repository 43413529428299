import { HttpClient } from '@angular/common/http';
import {
	ChangeDetectorRef,
	Component,
	EventEmitter,
	Input,
	OnDestroy,
	OnInit,
	Output,
	ViewChild,
} from '@angular/core';
import {
	FormArray,
	FormBuilder,
	FormControl,
	FormGroup,
	Validators,
} from '@angular/forms';
import { MatDatepicker } from '@angular/material/datepicker';
import { ActivatedRoute, Router } from '@angular/router';
import { first, lastValueFrom, map, switchMap } from 'rxjs';
import { AuthService } from 'src/app/services/auth.service';
import { ConfirmDialogService } from 'src/app/services/confirm-dialog.service';
import { ConfirmExitFrom } from 'src/app/services/forms.service';
import {
	PropertiesBackendService,
	Property,
} from 'src/app/services/properties-backend.service';
import {
	TaskResult,
	TaskStatus,
	TaskType,
	Task,
	TaskParentType,
	TaskBackendService,
} from 'src/app/services/task-backend.service';
import { Unsubscribe } from 'src/app/services/unsubscribe';
import { userType } from 'src/app/setup/httpTypes';
import { environment } from 'src/environments/environment';

@Component({
	selector: 'app-task',
	templateUrl: './task.component.html',
	styleUrls: ['./task.component.css'],
})
export class TaskComponent implements OnInit {
	//_task: Task | undefined;
	subTasks: Task[] = [];
	// @Input() set task(_task: Task | undefined) {
	// 	this._task = _task;
	// 	this.buildForm(_task);
	// }
	// get task(): Task | undefined {
	// 	return this._task;
	// }
	task: Task | undefined;

	showHideSubTasks = true;

	@Output() taskChanged = new EventEmitter<Task>();

	_loading = false;

	set loading(val: boolean) {
		this._loading = val;
		this.setDisabledForms();
	}
	get loading() {
		return this._loading;
	}

	TaskStatus = TaskStatus;
	TaskResult = TaskResult;
	TaskType = TaskType;

	@ConfirmExitFrom() taskFormGroup: FormGroup<any> = new FormGroup({});

	@Unsubscribe() $prams: any;
	taskId: string = '';

	userEmail: string = '';

	get notes() {
		return ((this.taskFormGroup.get('notes') as FormArray) || new FormArray([]))
			.controls as FormGroup[];
	}

	property?: Property;

	constructor(
		// public Http: HttpClient,
		public TaskBackend: TaskBackendService,
		public cd: ChangeDetectorRef,
		public route: ActivatedRoute,
		public Router: Router,
		public AuthService: AuthService,
		public confirmDialog: ConfirmDialogService,
		public fb: FormBuilder,
		public propertyBackend: PropertiesBackendService
	) {}

	userIsAgent() {
		return (
			this.property?.persons?.some(
				(p) => p.email == this.userEmail && p.userType == userType.sellersAgent
			) || false
		);
	}

	setDisabledForms() {
		if (this.loading || !this.canEdit) {
			this.taskFormGroup.disable();
			return;
		}
		if (this.task?.status == TaskStatus.completed) {
			this.taskFormGroup.disable();
			return;
		}
		if (this.task?.status == TaskStatus.disabled) {
			this.taskFormGroup.disable();
			return;
		}
		this.taskFormGroup.enable();

		(this.taskFormGroup.get('notes') as FormArray).controls.forEach(
			(_group) => {
				if (_group.get('noteType')?.value == 'system') {
					//_group.disable();
					_group.get('message')?.disable();
				}
			}
		);

		this.cd.detectChanges();
	}
	async ngOnInit() {
		this.$prams = this.route.params.subscribe(async (params) => {
			this.taskId = params['taskId'];
			// this.task = (await lastValueFrom(
			// 	this.Http.get(
			// 		`${environment.apiUrl}task/${this.taskId}?children=true`,
			// 		{
			// 			withCredentials: true,
			// 		}
			// 	)
			// )) as Task;
			await this.getTask();
		});
		if (this.AuthService.spoofAs && this.AuthService.spoofAs != '') {
			this.userEmail = this.AuthService.spoofAs;
		} else {
			this.AuthService.getUser().then((user) => {
				this.userEmail = user?.attributes?.email;
			});
		}
	}

	updateSet = new Set<symbol>();
	async completeSubTask(subTask: Partial<Task>) {
		if (subTask?.warnings?.some((warning) => !warning.dismissedBy)) return;
		let mySymbol = Symbol();
		this.updateSet.add(mySymbol);
		subTask.status = TaskStatus.completed;
		subTask.result = TaskResult.success;
		subTask.dateCompleted = new Date();
		await this.TaskBackend.patch(subTask.id || '', {
			status: TaskStatus.completed,
			result: TaskResult.success,
			dateCompleted: new Date(),
		});
		if (this.updateSet.size < 2) {
			await this.getTask(false);
		}
		this.updateSet.delete(mySymbol);
	}

	async InprogressSubTask(subTask: Partial<Task>) {
		let mySymbol = Symbol();
		this.updateSet.add(mySymbol);
		subTask.status = TaskStatus.inProgress;
		await this.TaskBackend.patch(subTask.id || '', {
			status: TaskStatus.inProgress,
		});
		if (this.updateSet.size < 2) {
			await this.getTask(false);
		}
		this.updateSet.delete(mySymbol);
	}

	async NotStartedSubTask(subTask: Partial<Task>) {
		let mySymbol = Symbol();
		this.updateSet.add(mySymbol);
		subTask.status = TaskStatus.notStarted;
		await this.TaskBackend.patch(subTask.id || '', {
			status: TaskStatus.notStarted,
		});
		if (this.updateSet.size < 2) {
			await this.getTask(false);
		}
		this.updateSet.delete(mySymbol);
	}

	get quickStartQuestionsCompleted() {
		return !(
			this.task?.initSubTasks?.questions?.some(
				(question) => !question.result
			) || false
		);
	}

	get currentQuickStartQuestion() {
		return this.task?.initSubTasks?.questions?.find(
			(question) => !question.result
		);
	}

	get totalQuickStartQuestions() {
		return this.task?.initSubTasks?.questions?.length || 0;
	}

	get currentQuickStartQuestionNumber() {
		return (
			(
				this.task?.initSubTasks?.questions?.filter(
					(question) => question.result
				) || []
			).length + 1
		);
	}

	get dataFrom(): FormGroup {
		return this.taskFormGroup.get('data') as FormGroup;
	}

	async answerQuickStartQuestion(question: any, answer: string) {
		if (!question) return;
		question.result = answer;
		this.addNote(
			`Quick Start Question Answered: ${question.question} - ${answer}`,
			'system'
		);
		await this.save({
			initSubTasks: this.task?.initSubTasks,
		});
	}

	dataControl(label: string) {
		return this.task?.data?.find((data) => data.label == label);
	}

	canEdit = false;
	async getTask(rebuild = true) {
		let r = await this.TaskBackend.getOne(this.taskId, {
			children: true,
			blockers: true,
		});
		if (r && this.updateSet.size < 2) {
			this.task = r.data;
			this.canEdit = r.canEdit;
		}

		if (this.updateSet.size < 2 && rebuild) this.buildForm(this.task);
		//this.setDisabledForms();
		//this.task?.children
		this.loading = false;
		let p = await this.propertyBackend.getOne(this.task?.propertyId || '');
		this.property = p.data;
	}

	getDataPointOptions(label: string) {
		return this.task?.data?.find((data) => data.label == label)?.options;
	}

	buildForm(task: Task | undefined) {
		this.noteIdsToRemove = [];
		if (!task) {
			this.taskFormGroup = new FormGroup({});
			return;
		}

		this.taskFormGroup = new FormGroup({
			notes: new FormArray([]),
		});
		if (task.taskType == TaskType.decider)
			this.taskFormGroup.addControl(
				'result',
				new FormControl(task.result, [Validators.required])
			);
		if (task.estimatedCompletionDate) {
			this.taskFormGroup.addControl(
				'estimatedCompletionDate',
				new FormControl(new Date(task.estimatedCompletionDate), [
					Validators.required,
				])
			);
		}

		if (task.notes) {
			for (let note of task.notes) {
				(this.taskFormGroup.get('notes') as FormArray)?.push(
					new FormGroup({
						id: new FormControl(note.id),
						message: new FormControl(note.message),
						noteType: new FormControl(note.noteType),
						date: new FormControl(new Date(note.date)),
						createdByEmail: new FormControl(note.createdByEmail),
					})
				);
			}
		}

		if ((task.data?.length || 0) > 0) {
			this.taskFormGroup.addControl('data', new FormGroup({}));
			task.data?.forEach((data) => {
				let validators = [];
				if (data.required) validators.push(Validators.required);
				if (data.dataType == 'number')
					validators.push(Validators.pattern('^[0-9]*$'));
				if (data.dataType == 'people') {
					let peopleArray = [];
					for (const person of data.value) {
						let p = this.fb.group({
							firstName: [person.firstName, [Validators.required]],
							lastName: [person.lastName, [Validators.required]],
							email: [person.email, [Validators.required, Validators.email]],
							phoneCountryCode: [
								person.phoneCountryCode,
								[Validators.required],
							],
							phoneInCountryNumber: [
								person.phoneInCountryNumber,
								[Validators.required, Validators.pattern('^[1-9]\\d{9}')],
							],
							company: [person.company],
							dontSendEmail: [person.dontSendEmail],
						});
						peopleArray.push(p);
					}

					(this.taskFormGroup.get('data') as FormGroup)?.addControl(
						data.label,
						new FormArray(peopleArray, validators)
					);
				} else if (data.dataType == 'address') {
					let addressGroup = this.fb.group({
						postcode: [data.value.postcode, [Validators.required]],
						address: [data.value.address || null, [Validators.required]],
						addressline1: [data.value.addressline1 || null],
						addressline2: [data.value.addressline2 || null],
						number: [data.value.number || null],
						premise: [data.value.premise || null],
						street: [data.value.street || null],
						posttown: [data.value.posttown || null],
						county: [data.value.county || null],
						UPRN: [data.value.UPRN || '', [Validators.required]],
					});

					(this.taskFormGroup.get('data') as FormGroup)?.addControl(
						data.label,
						addressGroup
					);
				} else if (data.dataType == 'checkList') {
					(this.taskFormGroup.get('data') as FormGroup)?.addControl(
						data.label,
						new FormGroup({})
					);
					let values = JSON.parse(data.value || '{}');
					data.options?.forEach((value: string) => {
						(
							this.taskFormGroup.get('data')?.get(data.label) as FormGroup
						)?.addControl(value, new FormControl(values[value]));
					});
				} else {
					(this.taskFormGroup.get('data') as FormGroup)?.addControl(
						data.label,
						new FormControl(data.value, validators)
					);
				}
			});
		}

		this.setDisabledForms();
	}

	addNote(message = '', noteType: 'user' | 'system' = 'user') {
		if (!this.taskFormGroup.get('notes'))
			this.taskFormGroup.addControl('notes', new FormArray([]));
		(this.taskFormGroup.get('notes') as FormArray)?.push(
			new FormGroup({
				message: new FormControl(message),
				date: new FormControl(new Date()),
				noteType: new FormControl(noteType),
				createdByEmail: new FormControl(this.userEmail),
			})
		);
		this.taskFormGroup.get('notes')?.markAsDirty();
	}

	noteIdsToRemove: string[] = [];
	removeNote(index: number) {
		let note = (this.taskFormGroup.get('notes') as FormArray)?.at(index);
		if (note?.get('id')?.value)
			this.noteIdsToRemove.push(note?.get('id')?.value);
		(this.taskFormGroup.get('notes') as FormArray)?.removeAt(index);
		this.taskFormGroup.get('notes')?.markAsDirty();
	}

	async complete(task: Task | undefined) {
		if (!task) return;
		if (task.warnings?.some((warning) => !warning.dismissedBy)) return;
		this.taskFormGroup.addControl(
			'status',
			new FormControl(TaskStatus.completed)
		);
		this.taskFormGroup.get('status')?.markAsDirty();
		if (this.task?.taskType != TaskType.decider) {
			this.taskFormGroup.addControl(
				'result',
				new FormControl(this.task?.defaultOption || TaskResult.success)
			);
			this.taskFormGroup.get('result')?.markAsDirty();
		}
		this.addNote(`Task completed.`, 'system');
		await this.save();
		this.openParent();
	}

	taskHasWarnings(task: Partial<Task>) {
		return task.warnings?.some((warning) => !warning.dismissedBy);
	}

	async changeStatus(status: TaskStatus) {
		if (!this.task || !this.canEdit) return;
		if (status == TaskStatus.completed) {
			if (
				this.task.taskType == TaskType.decider &&
				!this.taskFormGroup.get('result')?.value
			) {
				return;
			}
			return await this.complete(this.task);
		}
		if (status == TaskStatus.cancelled) {
			return this.delete();
		}
		this.taskFormGroup.addControl('status', new FormControl(status));
		this.taskFormGroup.get('status')?.markAsDirty();
		this.addNote(`Task status changed to ${status}.`, 'system');
		this.task.status = status;
		await this.save();
	}

	saving = false;
	async save(data?: Partial<Task>) {
		if (!this.task) return;
		if (this.taskFormGroup.pristine) return;

		this.saving = true;
		this.taskFormGroup.disable();
		//build update object
		let update: any = {};
		let notes: any[] = [];
		if (this.taskFormGroup.get('result')?.dirty)
			update.result = this.taskFormGroup.get('result')?.value;
		if (this.taskFormGroup.get('status')?.dirty)
			update.status = this.taskFormGroup.get('status')?.value;
		if (this.taskFormGroup.get('notes')?.dirty)
			notes = (this.taskFormGroup.get('notes') as FormArray)?.controls
				.filter(
					(note) =>
						(note.dirty && !!note.get('message')?.value) ||
						(!note.get('id')?.value && note.get('noteType')?.value == 'system')
				)
				.map((note) => {
					return {
						id: note.get('id')?.value,
						noteType: note.get('noteType')?.value,
						message: note.get('message')?.value,
						date: note.get('message')?.dirty
							? new Date().getTime()
							: note.get('date')?.value?.getTime(),
						createdByEmail: note.get('createdByEmail')?.value,
					};
				});
		let newNotes = notes.filter((note) => !note.id);
		let updatedNotes = notes.filter((note) => note.id);

		if (this.taskFormGroup.get('estimatedCompletionDate')?.dirty) {
			update.estimatedCompletionDate = this.taskFormGroup
				.get('estimatedCompletionDate')
				?.value?.getTime();
		}

		Object.keys(this.dataFrom?.controls || {}).forEach((key) => {
			let control = this.dataFrom.get(key);
			if (control?.dirty) {
				let currentData = this.task?.data?.find((d) => d.label == key);
				if (currentData) {
					if (currentData?.dataType == 'checkList') {
						currentData.value = JSON.stringify(control.value);
					} else currentData.value = control.value;
				}

				update.data = this.task?.data;
			}
		});

		if (data) update = { ...update, ...data };

		// let R = <any>await lastValueFrom(
		// 	this.Http.patch(
		// 		`${environment.apiUrl}/task/${this.task?.id}?children=true`,
		// 		update,
		// 		{
		// 			withCredentials: true,
		// 		}
		// 	)
		// ).catch(() => {
		// 	this.reset();
		// });

		let hadError = false;
		try {
			if (newNotes.length) {
				for (let note of newNotes) {
					await this.TaskBackend.addNote(
						this.task?.id,
						note.message,
						note.noteType
					);
				}
			}
			if (updatedNotes.length) {
				for (let note of updatedNotes) {
					await this.TaskBackend.updateNote(
						this.task?.id,
						note.id,
						note.message
					);
				}
			}
			if (this.noteIdsToRemove.length) {
				for (let id of this.noteIdsToRemove) {
					await this.TaskBackend.deleteNote(this.task?.id, id);
				}
			}
		} catch (error) {
			this.reset();
			hadError = true;
		}

		let R = await this.TaskBackend.patch(this.task?.id, update, {
			children: true,
		}).catch(() => {
			this.reset();
		});

		if (R && !hadError) this.task = R.data; //{...this.task, ...update}

		// this.task?.children?.sort((a: Task, b: Task) => {
		// 	if ((a?.estimatedCompletionDate || 0) < (b?.estimatedCompletionDate || 0))
		// 		return -1;
		// 	if ((b?.estimatedCompletionDate || 0) < (a?.estimatedCompletionDate || 0))
		// 		return 1;

		// 	if ((a?.dateCreated || 0) < (b?.dateCreated || 0)) return -1;
		// 	if ((b?.dateCreated || 0) < (a?.dateCreated || 0)) return 1;

		// 	if ((a?.dateCompleted || 0) < (b?.dateCompleted || 0)) return -1;
		// 	if ((b?.dateCompleted || 0) < (a?.dateCompleted || 0)) return 1;
		// 	return 0;
		// });

		if (R && !hadError) this.taskChanged.emit(R.data);
		if (R && !hadError) this.buildForm(this.task);
		this.saving = false;
		//if (R) this.buildForm(this.task);
		this.setDisabledForms();
		this.cd.detectChanges();
		//this.cd.markForCheck()
	}

	reset() {
		this.buildForm(this.task);
	}

	// async changeNotes(task: Task | undefined, value: string) {
	// 	if (!task) return;

	// 	//update the task in db
	// 	this.loading = true;
	// 	let R = <any>await lastValueFrom(
	// 		this.Http.patch(
	// 			`${environment.apiUrl}/task/${task?.id}`,
	// 			{
	// 				notes: value,
	// 			},
	// 			{
	// 				withCredentials: true,
	// 			}
	// 		)
	// 	);
	// 	this.loading = false;
	// }

	openParent() {
		if (this.task?.taskType == TaskType.milestone) {
			if (this.task?.parentType == TaskParentType.Property) {
				this.Router.navigate([
					'/chain',
					this.task?.chainId,
					'property',
					this.task?.parentId,
				]);
				return;
			}
		}
		if (this.task?.taskType != TaskType.milestone) {
			if (this.task?.parentType == TaskParentType.Task) {
				if (this.task?.parentId == this.task?.milestoneId) {
					this.Router.navigate([
						'/chain',
						this.task?.chainId,
						'property',
						this.task?.propertyId || '',
						'milestone',
						this.task?.parentId,
					]);
					return;
				} else {
					this.Router.navigate([
						'/chain',
						this.task?.chainId,
						'property',
						this.task?.propertyId || '',
						'milestone',
						this.task?.milestoneId,
						'task',
						this.task?.parentId,
					]);
					return;
				}
			}
		}
	}

	// @ViewChild('dueDatePicker') dueDatePicker?: MatDatepicker<any>;
	// openPicker() {
	// 	console.log(this.dueDatePicker);
	// 	//this.dueDatePicker?.open();
	// }

	async delete() {
		if (!this.task?.id || !this.canEdit) return;
		let confirm = await this.confirmDialog.confirm({
			title: `Delete Task`,
			message: `Are you sure you want to delete ${this.task.title}?`,
			confirmText: 'Delete',
		});
		if (!confirm) return;
		await this.TaskBackend.delete(this.task.id);
		this.openParent();
	}
}
