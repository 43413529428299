import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import {
	Task,
	TaskBackendService,
	TaskType,
} from 'src/app/services/task-backend.service';

@Component({
	selector: 'app-template-preferences',
	standalone: false,
	templateUrl: './templatePreferences.component.html',
	styleUrls: ['./templatePreferences.component.css'],
	changeDetection: ChangeDetectionStrategy.Default,
})
export class TemplatePreferencesComponent implements OnInit {
	templates?: Task[];
	constructor(public TaskBackend: TaskBackendService) {}

	async ngOnInit() {
		let templatesR = await this.TaskBackend.getTemplates();
		this.templates = templatesR.data.filter(
			(t) => t.taskType == TaskType.milestone
		);

		this.templates = this.sortTemplates(this.templates);

		this.templates.forEach((mileStone) => {
			mileStone.children = templatesR.data.filter(
				(t) => t.parentId == mileStone.id
			);
			mileStone.children = this.sortTemplates(mileStone.children);

			mileStone.children.forEach((task) => {
				task.children = templatesR.data.filter((t) => t.parentId == task.id);
				task.children = this.sortTemplates(task.children);
			});
		});
	}

	sortTemplates(templates: Task[]) {
		return templates.sort((a, b) => {
			if ((a.order || 0) > (b.order || 0)) {
				return 1;
			} else {
				return -1;
			}
		});
	}

	async updateEstimatedTime(template: Task, EstimatedTimeString: string) {
		let EstimatedTime = parseInt(EstimatedTimeString);
		if (isNaN(EstimatedTime)) {
			EstimatedTime = null as any;
		}
		if (
			template.estimatedBizDaysToComplete == EstimatedTime ||
			!EstimatedTime
		) {
			let r = await this.TaskBackend.patchTemplatePreferences(template.id, {
				estimatedBizDaysToComplete: null as any,
			});

			template.preferences = r.data.preferences;
		} else {
			let r = await this.TaskBackend.patchTemplatePreferences(template.id, {
				estimatedBizDaysToComplete: EstimatedTime,
			});

			template.preferences = r.data.preferences;
		}
	}
}
