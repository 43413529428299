import { HttpClient } from '@angular/common/http';
import { Component, EventEmitter, OnInit } from '@angular/core';
import { FormArray, FormControl, FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { lastValueFrom } from 'rxjs';
import { AuthService } from 'src/app/services/auth.service';
import { environment } from 'src/environments/environment';
import { userType } from '../setup/httpTypes';
import {
	animate,
	state,
	style,
	transition,
	trigger,
} from '@angular/animations';
import {
	TaskStatus,
	Task,
	TaskBackendService,
} from '../services/task-backend.service';
import { ConfirmExitFrom } from '../services/forms.service';
import {
	BuyerSeller,
	BuyerSellersBackendService,
	propertyStates,
} from '../services/buyer-sellers-backend.service';
import { Person } from '../services/people-backend.service';
import { oneOfTheseStates } from '../form-fields/form-validators';
import { ConfirmDialogService } from '../services/confirm-dialog.service';
import { TitleService } from '../services/title.service';
import {
	PropertiesBackendService,
	Property,
} from '../services/properties-backend.service';

@Component({
	templateUrl: './buyer-seller.component.html',
	styleUrls: ['./buyer-seller.component.css'],
	animations: [
		trigger('inOutAnimation', [
			transition(':enter', [
				style({ height: 0, opacity: 0, overflow: 'hidden' }),
				animate('300ms ease-out', style({ height: '*', opacity: 1 })),
			]),
			transition(':leave', [
				style({ height: '*', opacity: 1, overflow: 'hidden' }),
				animate('300ms ease-in', style({ height: 0, opacity: 0 })),
			]),
		]),
		trigger('hideInOutAnimation', [
			transition('true => false', [
				style({ height: 0, opacity: 0, overflow: 'hidden' }),
				animate('300ms ease-out', style({ height: '*', opacity: 1 })),
			]),
			transition('false => true', [
				style({ height: '*', opacity: 1, overflow: 'hidden' }),
				animate('300ms ease-in', style({ height: 0, opacity: 0 })),
			]),
			state('true', style({ height: 0, opacity: 0, overflow: 'hidden' })),
			state('false', style({ height: '*', opacity: 1 })),
		]),
	],
})
export class BuyerSellerComponent implements OnInit {
	loading = true;
	loadingPeople = false;
	loadingMortgageBrokers = false;
	loadingConveyancers = false;
	buyerSellerId = '';
	buyerSeller: BuyerSeller | undefined;
	$prams: any;
	canEdit = false;
	userType = userType;
	update = new EventEmitter();

	sellingProperty?: Property;
	buyingProperty?: Property;

	@ConfirmExitFrom() peopleFormGroup = new FormGroup({
		sellingPropertyState: new FormControl(propertyStates.NoProperty, [
			oneOfTheseStates([
				propertyStates.NoProperty,
				propertyStates.OfferAccepted,
				propertyStates.Looking,
			]),
		]),
		buyingPropertyState: new FormControl(propertyStates.NoProperty, [
			oneOfTheseStates([
				propertyStates.NoProperty,
				propertyStates.OfferAccepted,
				propertyStates.Looking,
			]),
		]),
	});

	propertyStates = propertyStates;

	constructor(
		public BuyerSellerBackend: BuyerSellersBackendService,
		public PropertyBackend: PropertiesBackendService,
		public AuthService: AuthService,
		public Router: Router,
		public route: ActivatedRoute,
		public confirmDialog: ConfirmDialogService,
		public titleService: TitleService
	) {}

	ngOnInit(): void {
		this.titleService.title = 'Buyer/Seller';
		this.$prams = this.route.params.subscribe((params) => {
			this.buyerSellerId = params['buyerSellerId'];
			this.getBuyerSeller();
		});
	}

	ngOnDestroy(): void {
		if (this.$prams) this.$prams.unsubscribe();
	}

	async getBuyerSeller() {
		this.loading = true;
		let r = await this.BuyerSellerBackend.getOne(this.buyerSellerId);
		this.canEdit = r.canEdit;
		let _buyerSeller = r.data;
		this.buyerSeller = _buyerSeller;
		this.resetPeopleFormGroup();

		//await this.getTasks();

		this.loading = false;
		if (this.canEdit) {
			if (
				this.buyerSeller?.sellingPropertyState != propertyStates.NoProperty &&
				this.buyerSeller?.sellingPropertyId
			) {
				let r = await this.PropertyBackend.getOne(
					this.buyerSeller?.sellingPropertyId
				);
				if (r?.data) this.sellingProperty = r.data;
			}
			if (
				this.buyerSeller?.buyingPropertyState != propertyStates.NoProperty &&
				this.buyerSeller?.buyingPropertyId
			) {
				let r = await this.PropertyBackend.getOne(
					this.buyerSeller?.buyingPropertyId
				);
				if (r?.data) this.buyingProperty = r.data;
			}
		}
	}

	saving = false;
	async savePeopleForm() {
		if (this.peopleFormGroup.dirty) {
			this.saving = true;
			let body: Partial<BuyerSeller> = {};

			if (
				this.peopleFormGroup.get('sellingPropertyState')?.value ==
					propertyStates.NoProperty &&
				this.buyerSeller?.sellingPropertyId
			) {
				let confirm = await this.confirmDialog.confirm({
					title: `No Longer Selling?`,
					message: `Are you sure you no longer want to sell ${
						this.sellingProperty?.address || ''
					}?`,
					confirmText: `Don't Sell`,
				});

				if (!confirm) {
					this.saving = false;
					return;
				}
			}

			if (
				this.peopleFormGroup.get('buyingPropertyState')?.value ==
					propertyStates.NoProperty &&
				this.buyerSeller?.buyingPropertyId
			) {
				let confirm = await this.confirmDialog.confirm({
					title: `No Longer Buying?`,
					message: `Are you sure you no longer want to buy ${
						this.buyingProperty?.address || ''
					}?`,
					confirmText: `Don't Buy`,
				});

				if (!confirm) {
					this.saving = false;
					return;
				}
			}

			if (
				this.peopleFormGroup.get('sellingPropertyState')?.value ==
					this.buyerSeller?.sellingPropertyId &&
				this.peopleFormGroup.get('buyingPropertyState')?.value ==
					this.buyerSeller?.buyingPropertyId
			) {
				let confirm = await this.confirmDialog.confirm({
					title: `No Longer Buying or Selling?`,
					message: `Are you sure you no longer want to buy or sell?  You will be removed from this property chain.`,
					confirmText: `Remove Me From Chain`,
				});

				if (!confirm) {
					this.saving = false;
					return;
				}
			}

			if (this.peopleFormGroup.controls.sellingPropertyState.dirty) {
				body.sellingPropertyState =
					this.peopleFormGroup.controls.sellingPropertyState.value ||
					propertyStates.NoSelection;
			}

			if (this.peopleFormGroup.controls.buyingPropertyState.dirty) {
				body.buyingPropertyState =
					this.peopleFormGroup.controls.buyingPropertyState.value ||
					propertyStates.NoSelection;
			}

			this.loadingPeople = true;
			this.peopleFormGroup.disable();

			if (Object.keys(body).length > 0) {
				let r = await this.BuyerSellerBackend.patch(this.buyerSellerId, body);
				this.buyerSeller = r.data;
				this.canEdit = r.canEdit;
			}

			await this.resetPeopleFormGroup();
			this.loadingPeople = false;
			this.peopleFormGroup.enable();
			this.saving = false;

			this.update.emit();
		}
	}

	async resetPeopleFormGroup() {
		this.peopleFormGroup.enable();
		this.peopleFormGroup.setControl(
			'sellingPropertyState',
			new FormControl(
				this.buyerSeller?.sellingPropertyState || propertyStates.NoSelection,
				[
					oneOfTheseStates([
						propertyStates.NoProperty,
						propertyStates.OfferAccepted,
						propertyStates.Looking,
					]),
				]
			)
		);
		this.peopleFormGroup.setControl(
			'buyingPropertyState',
			new FormControl(
				this.buyerSeller?.buyingPropertyState || propertyStates.NoSelection,
				[
					oneOfTheseStates([
						propertyStates.NoProperty,
						propertyStates.OfferAccepted,
						propertyStates.Looking,
					]),
				]
			)
		);
		if (!this.canEdit) this.peopleFormGroup.disable();
		this.peopleFormGroup.markAsPristine();
		if (
			this.peopleFormGroup.get('sellingPropertyState')?.value ==
			propertyStates.NoSelection
		) {
			this.peopleFormGroup.get('sellingPropertyState')?.markAsTouched();
			this.peopleFormGroup
				.get('sellingPropertyState')
				?.updateValueAndValidity();
		}
		if (
			this.peopleFormGroup.get('buyingPropertyState')?.value ==
			propertyStates.NoSelection
		) {
			this.peopleFormGroup.get('buyingPropertyState')?.markAsTouched();
			this.peopleFormGroup.get('buyingPropertyState')?.updateValueAndValidity();
		}
		this.peopleFormGroup.updateValueAndValidity();
		//buyingPropertyState
	}

	buyerSellerTile() {
		if (
			this.peopleFormGroup.get('sellingPropertyState')?.value ==
				propertyStates.NoProperty &&
			this.peopleFormGroup.get('buyingPropertyState')?.value !=
				propertyStates.NoSelection
		)
			return 'Buyer';
		if (
			this.peopleFormGroup.get('buyingPropertyState')?.value ==
				propertyStates.NoProperty &&
			this.peopleFormGroup.get('sellingPropertyState')?.value !=
				propertyStates.NoSelection
		)
			return 'Seller';
		return 'Buyer/Seller';
	}
}
