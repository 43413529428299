import { ChangeDetectionStrategy, Component, type OnInit } from '@angular/core';
import { PeopleBackendService } from '../services/people-backend.service';
import { ActivatedRoute } from '@angular/router';

@Component({
	selector: 'app-unsubscribe',
	templateUrl: './unsubscribe.component.html',
	styleUrls: ['./unsubscribe.component.css'],
	//changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UnsubscribeComponent implements OnInit {
	completed = false;
	userEmail = '';

	constructor(
		public personBackend: PeopleBackendService,
		public route: ActivatedRoute
	) {}

	ngOnInit(): void {
		this.userEmail = this.route.snapshot.queryParamMap.get('email') || '';
	}

	async unsubscribe() {
		await this.personBackend.unsubscribe(this.userEmail);
		this.userEmail = '';
		this.completed = true;
	}
}
