<!-- <mat-spinner *ngIf="loading"></mat-spinner> -->
<app-loading-tombstone
	*ngIf="loading"
	[morePadding]="true"></app-loading-tombstone>
<!-- <h2 *ngIf="!loading" class="title">{{property.address}}</h2> -->

<app-people-form
	*ngIf="!loading"
	[title]="buyerSellerTile()"
	[buyerSellerId]="buyerSellerId"
	[chainId]="buyerSeller?.chainId"
	[userType]="userType.buyerSeller"
	[deleteFromEverywhere]="true"
	[formOpen]="true"
	state="inProgress"
	[AtLeastOne]="false">
</app-people-form>

<mat-card appearance="outlined" *ngIf="!loading">
	<mat-card-header>
		<mat-card-title>
			<h3>{{ buyerSellerTile() }} Info</h3>
		</mat-card-title>
	</mat-card-header>
	<mat-card-content>
		<form [formGroup]="peopleFormGroup" (ngSubmit)="savePeopleForm()">
			<div class="row">
				<div class="fill">
					<mat-form-field appearance="outline" floatLabel="always">
						<mat-label>Current Home</mat-label>
						<mat-select formControlName="sellingPropertyState">
							<mat-option disabled="true" [value]="propertyStates.NoSelection">
								No Selection
							</mat-option>
							<mat-option [value]="propertyStates.Looking">
								Looking to Sell
							</mat-option>
							<mat-option [value]="propertyStates.OfferAccepted">
								Under Offer
							</mat-option>
							<mat-option [value]="propertyStates.NoProperty">
								Not Selling
							</mat-option>
							<mat-option [value]="propertyStates.Renting">
								Renting
							</mat-option>
						</mat-select>
					</mat-form-field>
					<div *ngIf="!!buyerSeller?.sellingPropertyUPRN && canEdit">
						<div>
							{{ this.sellingProperty?.address || "" }}
						</div>
					</div>
				</div>

				<div class="fill">
					<mat-form-field appearance="outline" floatLabel="always">
						<mat-label>New Home</mat-label>
						<mat-select formControlName="buyingPropertyState">
							<mat-option disabled="true" [value]="propertyStates.NoSelection">
								No Selection
							</mat-option>
							<mat-option [value]="propertyStates.Looking">
								Looking to Buy
							</mat-option>
							<mat-option [value]="propertyStates.OfferAccepted">
								Under Offer
							</mat-option>
							<mat-option [value]="propertyStates.NoProperty">
								Not Buying
							</mat-option>
							<mat-option [value]="propertyStates.Renting">
								Renting
							</mat-option>
						</mat-select>
					</mat-form-field>
					<div *ngIf="!!buyerSeller?.buyingPropertyUPRN && canEdit">
						<div>
							{{ this.buyingProperty?.address || "" }}
						</div>
					</div>
				</div>
			</div>

			<div class="actions">
				<button
					type="button"
					[disabled]="peopleFormGroup.pristine || loadingPeople"
					mat-button
					color=""
					(click)="resetPeopleFormGroup()">
					Reset
				</button>
				<button
					class="save-button"
					[disabled]="
						peopleFormGroup.pristine || loadingPeople || !peopleFormGroup.valid
					"
					mat-raised-button
					color="primary"
					type="submit">
					<span *ngIf="!saving"> Save </span>
					<span *ngIf="saving">
						Saving<app-loading-dots></app-loading-dots>
					</span>
				</button>
			</div>
		</form>
	</mat-card-content>
</mat-card>
