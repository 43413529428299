import { Component, Inject } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
//import { ConfirmDialogData } from '../confirm-dialog/confirm-dialog.component';
import { checkIfBrowserSupported } from '@xata.io/screenshot';

export interface bugDialogData {
	type?: 'bug' | 'feature';
}

@Component({
	selector: 'app-bugs-dialog',
	templateUrl: './bugs-dialog.component.html',
	styleUrls: ['./bugs-dialog.component.css'],
})
export class BugsDialogComponent {
	public get data(): bugDialogData {
		return this._data;
	}
	public set data(value: bugDialogData) {
		this._data = value;
	}

	issueForm = this.fb.group({
		title: ['', [Validators.required]],
		description: ['', [Validators.required]],
		screenShot: [true],
		type: ['bug'],
	});

	constructor(
		@Inject(MAT_DIALOG_DATA)
		private _data: bugDialogData,
		public fb: FormBuilder
	) {}

	ngOnInit(): void {
		if (!checkIfBrowserSupported()) {
			this.issueForm.get('screenShot')?.setValue(false);
			this.issueForm.get('screenShot')?.disable();
		}
		if (this.data?.type == 'feature') {
			this.issueForm.get('type')?.setValue('enhancement');
		}
	}
}
