import { Component, Inject } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Property } from 'src/app/services/properties-backend.service';
import { userType } from 'src/app/setup/httpTypes';

export interface AccessRequestData {
	UPRN: string;
	userType?: userType;
}

@Component({
	selector: 'app-new-request-dialog',
	templateUrl: './new-request-dialog.component.html',
	styleUrls: ['./new-request-dialog.component.css'],
})
export class NewRequestDialogComponent {
	public get data(): AccessRequestData {
		return this._data;
	}
	public set data(value: AccessRequestData) {
		this._data = value;
	}

	userTypes = [
		userType.seller,
		userType.buyer,
		userType.sellersAgent,
		userType.BuyersConveyancer,
		userType.SellersConveyancer,
		userType.mortgageBroker,
	];

	requestForm = this.fb.group({
		UPRN: [{ value: '', disabled: true }, [Validators.required]],
		userType: ['', [Validators.required]],
		message: [''],
	});

	constructor(
		@Inject(MAT_DIALOG_DATA)
		private _data: AccessRequestData,
		public fb: FormBuilder
	) {}

	ngOnInit(): void {
		//this.requestForm.get('UPRN')?.setValue(this.cleanAddress(this.data.UPRN));
		throw new Error('Method not implemented.');
		// if (this.data.userType)
		// 	this.requestForm.get('userType')?.setValue(this.data.userType);
		// else this.requestForm.get('userType')?.setValue(null);
	}
}
