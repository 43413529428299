<h1 class="header">
	<!-- <img
		style="width: 3rem"
		src="assets/Icon Chain.png"
		alt="contract exchange" /> -->
	My Chain
</h1>
<div class="voting" *ngIf="chain">
	<mat-card appearance="outlined" class="voting-card">
		<mat-card-header>
			<mat-card-title>
				Contract Exchange
				<button
					mat-icon-button
					(click)="tooltipCED.show()"
					#tooltipCED="matTooltip"
					matTooltip="Exchange relates to the signing of the contracts between the buyer and
				seller to confirm they are legally committed to complete the sale of a
				property.">
					<mat-icon>info</mat-icon>
				</button>
			</mat-card-title>
			<mat-card-subtitle>
				<div>
					Current agreed contract exchange date:
					<span class="current-date">{{
						arrayToDate(chain.exchangeDate?.currentDate) | date
					}}</span>
					<span *ngIf="chain.exchangeDate?.currentDate == null">
						No date set
					</span>
				</div>
				<div *ngIf="chain.estimatedExchangeDate && chain.isComplete">
					Earliest possible exchange date:
					<span class="current-date">{{
						chain.estimatedExchangeDate | date
					}}</span>
				</div>
			</mat-card-subtitle>
		</mat-card-header>

		<mat-card-content class="voting-content">
			<app-date-voting
				name="Contract Exchange Date"
				[VoteDate]="chain.exchangeDate"
				[buyerSellerIds]="buyerSellersIds"
				[numberOfTotalBuyerSellers]="numberOfBuyerSellers"
				(change)="save()"></app-date-voting>
		</mat-card-content>
	</mat-card>
	<mat-card appearance="outlined" class="voting-card">
		<mat-card-header>
			<mat-card-title>
				Completion
				<button
					mat-icon-button
					#tooltipCD="matTooltip"
					(click)="tooltipCD.show()"
					matTooltip="Completion occurs after these contracts have been exchanged and is
				when all remaining money is transferred from the buyer to seller and
				the buyer receives the keys to their new home.">
					<mat-icon>info</mat-icon>
				</button>
			</mat-card-title>
			<mat-card-subtitle>
				<div>
					Current agreed completion date:
					<span class="current-date">{{
						arrayToDate(chain.completionDate?.currentDate) | date
					}}</span
					><span *ngIf="chain.completionDate?.currentDate == null">
						No date set
					</span>
				</div>
				<div *ngIf="chain.estimatedCompletionDate && chain.isComplete">
					Earliest possible completion date:
					<span class="current-date">{{
						chain.estimatedCompletionDate | date
					}}</span>
				</div>
			</mat-card-subtitle>
		</mat-card-header>
		<mat-card-content class="voting-content">
			<app-date-voting
				name="Completion Date"
				[VoteDate]="chain.completionDate"
				[buyerSellerIds]="buyerSellersIds"
				[numberOfTotalBuyerSellers]="numberOfBuyerSellers"
				(change)="save()"></app-date-voting>
		</mat-card-content>
	</mat-card>
</div>

<mat-card appearance="outlined">
	<mat-card-header>
		<mat-card-title> Overview </mat-card-title>
	</mat-card-header>
	<mat-card-content>
		<div *ngIf="!chain?.isComplete">
			This Chain is yet to complete, so we are yet to know how many properties
			it will include and how many people will be involved.
		</div>
		<div *ngIf="chain?.isComplete">
			This Chain was completed (no more buyers or sellers will be added to this
			property chain) on {{ chain?.isComplete | date }}, so we know there are
			{{ numberOfProperties }} properties included in it and
			{{ numberOfBuyerSellers }} buying/selling parties. While it can take as
			little as 4 weeks from this point to exchange its more common for it to
			take 8 weeks or more. Right now it looks like the earliest everyone will
			be ready to exchange is {{ chain?.estimatedExchangeDate | date }}.
			Completion can happen on that very same day but it is often scheduled for
			2 weeks after exchange.
		</div>
		<div class="links-count">
			<div class="buyers-sellers count-item">
				<div class="count-icons">
					<img class="count-icon" src="assets/Icon Buyer.png" alt="buyer" />
					<img class="count-icon" src="assets/Icon Seller.png" alt="seller" />
				</div>

				<div class="count-text">=</div>
				<div class="count-text">{{ numberOfBuyerSellers }}</div>
			</div>
			<div class="properties count-item">
				<div class="count-icons">
					<app-logo class="count-icon"></app-logo>
				</div>

				<div class="count-text">=</div>
				<div class="count-text">{{ numberOfProperties }}</div>
			</div>
		</div>
	</mat-card-content>
</mat-card>

<mat-card appearance="outlined">
	<mat-card-header>
		<mat-card-title>Milestones</mat-card-title>
	</mat-card-header>
	<mat-card-content>
		<div class="legend">
			<div class="milestone">
				<mat-icon class="icon-in-progress" mat-list-icon>beenhere</mat-icon> In
				Progress
			</div>
			<div class="milestone">
				<mat-icon class="icon-not-started" mat-list-icon>beenhere</mat-icon> To
				Do
			</div>
			<div class="milestone">
				<mat-icon class="icon-disabled" mat-list-icon>beenhere</mat-icon>
				Blocked
			</div>
			<div class="milestone">
				<mat-icon class="icon-completed" mat-list-icon>beenhere</mat-icon>
				Completed
			</div>
			<div class="milestone">
				<mat-icon class="icon-canceled" mat-list-icon>beenhere</mat-icon>
				Removed
			</div>
		</div>
		<!-- <code>
            <pre>
                {{chain?.links | json }}
            </pre>
        </code>
        testing -->
		<div class="links">
			<mat-nav-list>
				<div class="link" *ngFor="let link of links || []; trackBy: fnTrackBy">
					<mat-divider></mat-divider>
					<a [routerLink]="['../', 'property', link.id]" class="property">
						<img
							mat-list-icon
							*ngIf="link.linkType == 'property'"
							[matTooltip]="link.address"
							style="width: 1.5rem"
							[src]="logoURL(link)"
							alt="house" />
						<span class="title">
							<ng-container *ngIf="link.nickName != link.UPRN">
								{{ link.address }} -
							</ng-container>
							{{ link.nickName }}
						</span>
					</a>
					<div class="tasks">
						<app-task-lists
							[taskList]="link.tasks"
							[defaultHideShow]="true"></app-task-lists>
					</div>
				</div>
			</mat-nav-list>
		</div>

		<!-- <pre>
            {{ BuyerSellers | json }}
        </pre> -->
	</mat-card-content>
</mat-card>

<mat-card appearance="outlined">
	<mat-card-content>
		<h2>Chain Access Requests</h2>
		<div class="requests">
			<!-- <div class="requests-icon">
				<img
					style="width: 100%"
					src="assets/Icon Access.png"
					alt="Access Request Icon" />
			</div> -->
			<app-request-list
				class="request-list"
				[requests]="requests"
				[loading]="loadingRequests"></app-request-list>
			<!-- <div *ngIf="!loadingRequests && requests.length == 0">
				No Access Requests at this time.
			</div> -->
		</div>
	</mat-card-content>
</mat-card>

<!-- <mat-card>
    <button mat-raised-button color="primary" (click)="getTasks()" >Refresh Tasks</button>
    <mat-card-header>
        <mat-card-title>
            <h3>Tasks</h3>
        </mat-card-title>
    </mat-card-header>
    <mat-card-content>
        <app-task-lists [taskList]="taskList"></app-task-lists>

    </mat-card-content>
</mat-card> -->
