import { ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import {
	AbstractControl,
	FormArray,
	FormBuilder,
	FormGroup,
	NG_VALIDATORS,
	NG_VALUE_ACCESSOR,
	Validators,
} from '@angular/forms';
import { Subscription } from 'rxjs';
import { PeopleBackendService } from 'src/app/services/people-backend.service';
import { phoneCountryCodeList } from 'src/app/services/phoneCodes';
import { TaskStatus } from 'src/app/services/task-backend.service';
import { environment } from 'src/environments/environment';

@Component({
	selector: 'app-people',
	templateUrl: './people.component.html',
	styleUrls: ['./people.component.css'],
	providers: [
		{
			provide: NG_VALUE_ACCESSOR,
			multi: true,
			useExisting: PeopleComponent,
		},
		{
			provide: NG_VALIDATORS,
			multi: true,
			useExisting: PeopleComponent,
		},
	],
})
export class PeopleComponent implements OnInit {
	phoneCountryCodes = phoneCountryCodeList;

	_form: FormArray<any> = this.fb.array([
		this.fb.group({
			firstName: ['', [Validators.required]],
			lastName: ['', [Validators.required]],
			email: [
				'',
				[Validators.required, Validators.email],
				//[this.personBackend.validEmail()],
			],
			phoneCountryCode: ['+44', [Validators.required]],
			phoneInCountryNumber: [
				'',
				[Validators.required, Validators.pattern('^[1-9]\\d{9}')],
			],
			company: [null, []],
			occupation: [null, []],
			dontSendEmail: [false],
		}),
	]);

	@Input() set form(value: FormArray<any>) {
		//console.log(value);
		if (value) this._form = value;
		this.cd.detectChanges();
	}

	get form() {
		return this._form;
	}

	@Input() onlyOne: boolean = false;
	@Input() atLeastOne: boolean = true;
	@Input() title: string = 'People Control';
	@Input() showOccupation: boolean = false;
	@Input() status?: TaskStatus;
	@Input() hideEmailSend: boolean = false;
	get completed() {
		return this.status === TaskStatus.completed;
	}

	constructor(
		private fb: FormBuilder,
		public cd: ChangeDetectorRef,
		public personBackend: PeopleBackendService
	) {}

	ngOnInit(): void {
		//this.form.parent
	}
	onTouched: Function = () => {};

	onChangeSubs: Subscription[] = [];

	ngOnDestroy() {
		for (let sub of this.onChangeSubs) {
			sub.unsubscribe();
		}
	}

	registerOnChange(onChange: any) {
		const sub = this.form.valueChanges.subscribe(onChange);
		this.onChangeSubs.push(sub);
	}

	registerOnTouched(onTouched: Function) {
		this.onTouched = onTouched;
	}

	setDisabledState(disabled: boolean) {
		if (disabled) {
			this.form.disable();
		} else {
			this.form.enable();
		}
	}

	writeValue(value: any) {
		if (value) {
			this.form.setValue(value, { emitEvent: false });
		}
	}

	validate(control: AbstractControl) {
		if (this.form.valid) {
			return null;
		}

		let errors: any = {};

		// errors = this.addControlErrors(errors, 'postcode');
		// errors = this.addControlErrors(errors, 'address');

		return this.form.errors;
	}

	addPerson() {
		this.form.push(
			this.fb.group({
				firstName: [environment.firstName, [Validators.required]],
				lastName: [environment.lastName, [Validators.required]],
				email: [
					environment.email,
					[Validators.required, Validators.email],
					//[this.personBackend.validEmail()],
				],
				phoneCountryCode: ['+44', [Validators.required]],
				phoneInCountryNumber: [
					'',
					[Validators.required, Validators.pattern('^\\d{10}')],
				],
				company: [null, []],
				occupation: [null, []],
				dontSendEmail: [false],
			})
		);
	}

	removePerson(index: number) {
		if (!this.atLeastOne || this.form.controls.length > 1)
			this.form.removeAt(index);
	}
}
