import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { filter, firstValueFrom, lastValueFrom, Observable } from 'rxjs';
import { AuthService } from '../services/auth.service';
import { ChainsBackendService } from '../services/chains-backend.service';
import { Location } from '@angular/common';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {
  constructor(public AuthService:AuthService, public Router:Router, public ChainBackend:ChainsBackendService, public location:Location) { }

  async canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Promise<boolean | UrlTree>  {
      let loggedIn = await this.AuthService.isSignedIn()
      if(loggedIn){
        let returnUrl = localStorage.getItem('returnUrl');
        //console.log(this.location.path())
        if(returnUrl && this.location.path() != '/setup' && state.url != '/setup' && state.url != '/login'){
          let numberOfChains = await this.ChainBackend.count();
          if(numberOfChains.data == 0){
            this.Router.navigate(['/setup']);
            return false;
          }
          else{
            localStorage.removeItem('returnUrl');
            this.Router.navigate([returnUrl]);
          }
          
        }
        return true;
      }
      else{
        localStorage.setItem('returnUrl', state.url);
        this.Router.navigate(['/login'], { queryParams: { returnUrl: state.url } });
        return false;
      }

  }
  
}
