<h1 mat-dialog-title>
	<img
		style="width: 3rem; vertical-align: middle"
		src="assets/Icon Access.png"
		alt="Access Request Icon" />
	Access Request
</h1>
<mat-dialog-content>
	<form [formGroup]="requestForm" style="margin-top: 1rem">
		<div>
			<mat-form-field appearance="outline" floatLabel="always">
				<mat-label>Address</mat-label>
				<input matInput formControlName="UPRN" name="address" />
			</mat-form-field>
		</div>
		<div>
			<mat-form-field appearance="fill">
				<mat-label>Access Type</mat-label>
				<mat-select placeholder="What is your role?" formControlName="userType">
					<mat-option *ngFor="let userType of userTypes" [value]="userType">
						{{ userType }}
					</mat-option>
				</mat-select>
			</mat-form-field>
		</div>
		<div>
			<mat-form-field appearance="outline" floatLabel="always">
				<mat-label>Message</mat-label>
				<textarea
					matInput
					class="description"
					placeholder="Add a message to your request explaining why you need access."
					formControlName="message"
					name="description"></textarea>
			</mat-form-field>
		</div>
	</form>
</mat-dialog-content>
<mat-dialog-actions>
	<button mat-button [mat-dialog-close]="false" type="button">Cancel</button>
	<button
		mat-button
		[mat-dialog-close]="requestForm.value"
		[disabled]="requestForm.disabled || requestForm.invalid"
		type="button"
		cdkFocusInitial>
		Request Access
	</button>
</mat-dialog-actions>
