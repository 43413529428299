<mat-stepper orientation="vertical" #stepper linear>
	<ng-template matStepperIcon="info">
		<mat-icon>info</mat-icon>
	</ng-template>

	<ng-template matStepperIcon="house">
		<mat-icon>house</mat-icon>
	</ng-template>

	<ng-template matStepperIcon="selling">
		<mat-icon>real_estate_agent</mat-icon>
	</ng-template>

	<ng-template matStepperIcon="buying">
		<mat-icon>monetization_on</mat-icon>
	</ng-template>

	<ng-template matStepperIcon="mortgage">
		<mat-icon>account_balance</mat-icon>
	</ng-template>

	<mat-step state="buying" [stepControl]="clientInfoForm">
		<ng-template matStepLabel>Client Information</ng-template>
		<form fxLayout="column" [formGroup]="clientInfoForm">
			<div class="row row-bottom-padding">
				<app-people
					[form]="$any(clientInfoForm.get('clients'))"
					[atLeastOne]="true"
					title="Client/s"></app-people>
			</div>

			<div fxLayout="row" fxLayoutAlign="end center">
				<button mat-button (click)="back.emit()" type="button">Back</button>
				<button
					mat-raised-button
					color="primary"
					[disabled]="clientInfoForm.invalid"
					matStepperNext>
					Next
				</button>
			</div>
		</form>
	</mat-step>

	<mat-step state="house" [stepControl]="purchaseInfoForm">
		<ng-template matStepLabel>Client Purchase Information</ng-template>
		<form fxLayout="column" [formGroup]="purchaseInfoForm">
			<div class="row row-bottom-padding">
				<label id="buyingPropertyYN-label">
					Is your client purchasing a property?
				</label>
				<mat-radio-group
					aria-labelledby="buyingPropertyYN-label"
					formControlName="buyingPropertyYN">
					<mat-radio-button value="Y">Yes</mat-radio-button>
					<mat-radio-button value="N">No</mat-radio-button>
				</mat-radio-group>
			</div>
			<div
				class="row row-bottom-padding"
				*ngIf="buyingPropertyYN?.value == 'Y'">
				<label id="acceptedOfferYN-label"
					>Have they identified a property and had an offer accepted?</label
				>
				<mat-radio-group
					aria-labelledby="acceptedOfferYN-label"
					formControlName="acceptedOfferYN">
					<mat-radio-button value="Y">Yes</mat-radio-button>
					<mat-radio-button value="N">No</mat-radio-button>
				</mat-radio-group>
			</div>
			<div
				class="row row-bottom-padding"
				*ngIf="buyingPropertyYN?.value == 'Y' && acceptedOfferYN?.value == 'Y'">
				<app-address
					[form]="$any(purchaseInfoForm.get('address'))"></app-address>
				<app-people
					[form]="$any(purchaseInfoForm.get('sellingAgent'))"
					[onlyOne]="true"
					title="Estate Agent"></app-people>
				<app-people
					[form]="$any(purchaseInfoForm.get('sellers'))"
					[atLeastOne]="false"
					title="Sellers"></app-people>
				<app-people
					[form]="$any(purchaseInfoForm.get('sellersConveyancer'))"
					[atLeastOne]="false"
					title="Sellers' Conveyancer"></app-people>
			</div>
			<div fxLayout="row" fxLayoutAlign="end center">
				<button mat-button matStepperPrevious type="button">Back</button>
				<button
					mat-raised-button
					color="primary"
					[disabled]="purchaseInfoForm.invalid"
					matStepperNext>
					Next
				</button>
			</div>
		</form>
	</mat-step>

	<mat-step state="house" [stepControl]="sellingInfoForm">
		<ng-template matStepLabel>Client Selling Information</ng-template>
		<form
			fxLayout="column"
			[formGroup]="sellingInfoForm"
			(ngSubmit)="submitForm()">
			<div class="row row-bottom-padding">
				<label id="sellingPropertyYN-label">
					Is your client selling a property?
				</label>
				<mat-radio-group
					aria-labelledby="sellingPropertyYN-label"
					formControlName="sellingPropertyYN">
					<mat-radio-button value="Y">Yes</mat-radio-button>
					<mat-radio-button value="N">No</mat-radio-button>
				</mat-radio-group>
			</div>
			<div
				class="row row-bottom-padding"
				*ngIf="sellingPropertyYN?.value == 'Y'">
				<app-address
					[form]="$any(sellingInfoForm.get('address'))"></app-address>
				<app-people
					[form]="$any(sellingInfoForm.get('sellingAgent'))"
					[onlyOne]="true"
					title="Estate Agent"></app-people>
			</div>
			<div
				class="row row-bottom-padding"
				*ngIf="sellingPropertyYN?.value == 'Y'">
				<label id="acceptedOfferYN-label"> Have they accepted an offer? </label>
				<mat-radio-group
					aria-labelledby="acceptedOfferYN-label"
					formControlName="acceptedOfferYN">
					<mat-radio-button value="Y">Yes</mat-radio-button>
					<mat-radio-button value="N">No</mat-radio-button>
				</mat-radio-group>
			</div>
			<div
				class="row row-bottom-padding"
				*ngIf="sellingAcceptedOfferYN?.value == 'Y'">
				<mat-form-field appearance="outline" floatLabel="always">
					<mat-label>Date Offer Accepted</mat-label>
					<input
						matInput
						formControlName="dateOfferAccepted"
						[matDatepicker]="dp" />
					<mat-datepicker-toggle matSuffix [for]="dp"></mat-datepicker-toggle>
					<mat-datepicker #dp></mat-datepicker>
				</mat-form-field>

				<mat-form-field appearance="outline" floatLabel="always">
					<mat-label>Agreed Selling Price</mat-label>
					<span matTextPrefix>£&nbsp;</span>
					<input
						matInput
						autocomplete="off"
						[appMoneyInput]
						formControlName="agreedSellingPrice"
						type="string" />
				</mat-form-field>
			</div>
			<div
				class="row row-bottom-padding"
				*ngIf="sellingAcceptedOfferYN?.value == 'Y'">
				<app-people
					[form]="$any(sellingInfoForm.get('buyers'))"
					[atLeastOne]="false"
					title="Buyers"></app-people>
				<app-people
					[form]="$any(sellingInfoForm.get('buyersConveyancer'))"
					[atLeastOne]="false"
					title="Buyers' Conveyancer"></app-people>
			</div>
			<div
				*ngIf="
					sellingPropertyYN?.value == 'N' && buyingPropertyYN?.value == 'N'
				"
				class="row row-bottom-padding warning">
				* You must be buying and or selling a property to continue.
			</div>
			<div fxLayout="row" fxLayoutAlign="end center">
				<button mat-button matStepperPrevious type="button">Back</button>
				<button
					mat-raised-button
					color="primary"
					[disabled]="sellingInfoForm.invalid"
					type="submit"
					matStepperNext>
					Next
				</button>
			</div>
		</form>
	</mat-step>
</mat-stepper>
