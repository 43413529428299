<!-- <button (click)="hideShow = !hideShow">Hide/Show</button> -->
<!-- <div  *ngIf="(taskList?.length || 0) > 0">
	<mat-progress-bar
		class="example-margin"
		color="accent"
		mode="buffer"
		[value]="progress"
		[bufferValue]="buffer">
	</mat-progress-bar>
</div> -->
<div *ngIf="(taskList?.length || 0) > 0" class="progress">
	<app-line-progress
		[completed]="completedTasks.length"
		[inProgress]="inProgressTasks.length"
		[notStarted]="notStartedTasks.length"
		[disabled]="disabledTasks.length"></app-line-progress>
</div>

<div style="margin-top: 1rem">
	<!-- <div>
		<div *ngIf="inProgressTasks.length > 0" matSubheader>In Progress</div>
		<div *ngFor="let task of inProgressTasks; trackBy: trackByFn">
			<app-task-item
				[task]="task"
				(markAsComplete)="markAsComplete($event, task)"
				(markAsNotStarted)="markAsNoStarted($event, task)"
				(open)="openTask(task)"></app-task-item>
		</div>

		<div *ngIf="notStartedTasks.length > 0" matSubheader>To Do</div>
		<div *ngFor="let task of notStartedTasks; trackBy: trackByFn">
			<app-task-item
				[task]="task"
				(markAsInprogress)="markAsInprogress($event, task)"
				(open)="openTask(task)"></app-task-item>
		</div>

		<div *ngIf="disabledTasks.length > 0" matSubheader>Blocked</div>
		<div *ngFor="let task of disabledTasks; trackBy: trackByFn">
			<app-task-item [task]="task" (open)="openTask(task)"></app-task-item>
		</div>

		<div *ngIf="completedTasks.length > 0" matSubheader>Completed</div>
		<div *ngFor="let task of completedTasks; trackBy: trackByFn">
			<app-task-item [task]="task" (open)="openTask(task)"></app-task-item>
		</div>

		<div *ngIf="cancelledTasks.length > 0" matSubheader>Canceled</div> -->
	<div *ngFor="let task of taskList; trackBy: trackByFn">
		<app-task-item
			[task]="task"
			[state]="state"
			[isAgent]="isAgent"
			(open)="openTask(task)"></app-task-item>
	</div>
	<!-- </div> -->
</div>
