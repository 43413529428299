<div class="container">
	<h1 class="header">Properties</h1>
	<!-- <div class="center-center" *ngIf="loading">
		<mat-spinner></mat-spinner>
	</div> -->
	<app-loading-tombstone *ngIf="loading"></app-loading-tombstone>
	<mat-card appearance="outlined" *ngIf="!loading && properties?.length == 0">
		<mat-card-content>
			<div class="center">
				You have no properties. Start a chain and add a property to get started.
			</div>
		</mat-card-content>
	</mat-card>

	<mat-card
		class="row property-card"
		style="flex-wrap: nowrap; cursor: pointer; margin-top: 1rem"
		*ngFor="let property of properties"
		(click)="goto(property)">
		<mat-card-content class="row" style="width: 100%">
			<img
				[matTooltip]="property.nickName || ''"
				style="width: 1.5rem; align-self: baseline; margin-right: 1rem"
				src="/assets/logo-svg-sm.svg"
				[src]="logoURL(property)"
				alt="house" />
			<div>
				<div *ngIf="property.address" style="font-weight: 600">
					{{ property.address || "" }}
				</div>
				<div>{{ property.nickName }}</div>
			</div>
			<div class="spacer"></div>
			<div class="menu">
				<button mat-icon-button>
					<mat-icon>launch</mat-icon>
				</button>
			</div>
		</mat-card-content>
	</mat-card>
</div>
