<app-group-title
	title="Sign Here{{ required ? ' *' : '' }}"
	[errors]="!!ngControl.errors">
	<div style="width: 100%; height: 6rem; margin-top: -1rem" #groupTitle>
		<canvas
			#canvas
			(touchstart)="startDrawing($event)"
			(touchend)="endDrawing($event)"
			(touchmove)="moved($event)"
			(touchcancel)="endDrawing($event)"></canvas>
	</div>

	<div
		style="
			display: flex;
			flex-direction: row;
			justify-content: end;
			position: absolute;
			bottom: 0;
			right: 0;
		">
		<button
			mat-button
			color="primary"
			(click)="clearPad()"
			style="margin-top: 1rem">
			Clear
		</button>
	</div>
</app-group-title>
