<div class="container">
	<h1 class="header">Make An Offer</h1>
	<!-- <div class="center-center" *ngIf="loading">
		<mat-spinner></mat-spinner>
	</div> -->

	<mat-card appearance="outlined" *ngIf="!loading && !property">
		<mat-card-content>
			<div class="center">
				An offer has already been accepted on this property or is no longer on
				the market.
			</div>
		</mat-card-content>
	</mat-card>

	<mat-card
		*ngIf="property"
		class="row property-card"
		style="flex-wrap: nowrap; cursor: pointer; margin-top: 1rem">
		<mat-card-content style="width: 100%">
			<h2>Property</h2>
			<h3>{{ property.address || "" }}</h3>
			<div
				style="display: flex; justify-content: center"
				*ngIf="!!property.postedMaterialId">
				<button mat-raised-button (click)="materialInfo()">
					View Material Information
				</button>
			</div>
		</mat-card-content>
	</mat-card>

	<app-loading-tombstone *ngIf="loading"></app-loading-tombstone>

	<mat-card
		*ngIf="property && !loading"
		class="row"
		style="flex-wrap: nowrap; margin-top: 1rem">
		<mat-card-content style="width: 100%" [formGroup]="form">
			<h2>Offer Details</h2>
			<div>
				<mat-form-field appearance="outline" floatLabel="always">
					<mat-label>Offer Amount</mat-label>
					<span matTextPrefix>£&nbsp;</span>
					<input
						matInput
						type="string"
						[appMoneyInput]
						placeholder="I.E. 500,000"
						required
						formControlName="offerAmount" />
					<!-- [appMoneyInput] -->
				</mat-form-field>
			</div>
			<div>
				<mat-form-field
					appearance="outline"
					floatLabel="always"
					style="width: 100%">
					<mat-label>Conditions & Stipulations</mat-label>
					<textarea
						matInput
						formControlName="offerStipulations"
						placeholder="I.E. This offer is stipulated on all parties in the property chain using Clear Chain and the Contract Exchange happening within 10 weeks."></textarea>
				</mat-form-field>
			</div>
			<div style="text-align: center">
				(<a
					href="https://www.clearchain.house/knowledge-bank/your-power-within-the-chain"
					target="_blank"
					>More Info in Conditions & Stipulations</a
				>)
			</div>
		</mat-card-content>
	</mat-card>

	<mat-card
		*ngIf="property && !loading"
		class="row"
		style="flex-wrap: nowrap; margin-top: 1rem"
		[formGroup]="form">
		<mat-card-content style="width: 100%">
			<h2>My Details</h2>
			<app-group-title title="Buyers Information">
				<div style="text-align: center">
					(If more then one buyer, please provide the details of the others as
					well.)
				</div>
				<app-people
					[form]="$any(form.get('buyers'))"
					style="margin-top: 1.5rem"
					[atLeastOne]="true"
					[showOccupation]="true"
					[hideEmailSend]="true"
					title="Buyer/s"></app-people>
			</app-group-title>
			<app-group-title title="Mortgage Information" style="margin-top: 1.5rem">
				<div>
					<label>Will you be using a mortgage?</label>
					<mat-radio-group formControlName="mortgage">
						<mat-radio-button [value]="true">Yes</mat-radio-button>
						<mat-radio-button [value]="false">No</mat-radio-button>
					</mat-radio-group>
				</div>

				<div *ngIf="form.get('mortgage')?.value">
					<div>
						<label>Do you received a Mortgage in principal?</label>
						<mat-radio-group formControlName="mortgageInPrincipal">
							<mat-radio-button [value]="true">Yes</mat-radio-button>
							<mat-radio-button [value]="false">No</mat-radio-button>
						</mat-radio-group>
					</div>
					<div style="margin-top: 0.5rem">
						<mat-form-field
							appearance="outline"
							floatLabel="always"
							style="width: 8rem">
							<mat-label>Percent Deposit</mat-label>
							<input
								style="text-align: right"
								matInput
								type="number"
								placeholder="16"
								formControlName="depositPercentage" />
							<span matTextSuffix>&nbsp;%</span>
							<!-- [appMoneyInput] -->
						</mat-form-field>
					</div>
					<div *ngIf="form.get('mortgageInPrincipal')?.value">
						<label>Are you using a Mortgage Broker?</label>
						<mat-radio-group formControlName="usingMortgageBroker">
							<mat-radio-button [value]="true">Yes</mat-radio-button>
							<mat-radio-button [value]="false">No</mat-radio-button>
						</mat-radio-group>
					</div>
					<div *ngIf="form.get('usingMortgageBroker')?.value">
						<app-people
							title="Mortgage Broker"
							style="margin-top: 1.5rem"
							[hideEmailSend]="true"
							[form]="$any(form.get('mortgageBroker'))"></app-people>
					</div>
				</div>
			</app-group-title>
			<app-group-title
				title="Buyers Current Address"
				style="margin-top: 1.5rem">
				<app-address
					[requirePropertyNotInUse]="false"
					[form]="$any(form.get('currentAddress'))"
					title="My Current Address"></app-address>
			</app-group-title>

			<app-group-title title="Onward Chain" style="margin-top: 1.5rem">
				<div class="has-left-info">
					<div>
						<label>Are you selling a house as a condition of this offer?</label>
						<mat-radio-group formControlName="onwardChain">
							<mat-radio-button [value]="true">Yes</mat-radio-button>
							<mat-radio-button [value]="false">No</mat-radio-button>
						</mat-radio-group>
					</div>
					<div class="left-info">
						(<a
							href="https://www.clearchain.house/knowledge-bank/blog-post-what-is-a-property-chain-uk"
							target="_blank"
							>What is a property chain?</a
						>)
					</div>
				</div>
				<div
					*ngIf="$any(form.get('onwardChain')?.value)"
					style="margin-top: -1rem">
					<div>
						<label>Is my current address the house im selling?</label>
						<mat-radio-group formControlName="sellingCurrentAddress">
							<mat-radio-button [value]="true">Yes</mat-radio-button>
							<mat-radio-button [value]="false">No</mat-radio-button>
						</mat-radio-group>
					</div>
					<app-group-title
						title="Selling Address"
						*ngIf="!form.get('sellingCurrentAddress')?.value"
						style="margin-top: 1.5rem">
						<app-address
							[requirePropertyNotInUse]="false"
							[form]="$any(form.get('sellingAddress'))"
							title="House Im Selling"></app-address>
					</app-group-title>

					<div style="margin-top: 2rem">
						<mat-form-field appearance="outline" floatLabel="always">
							<mat-label>Status of the sale?</mat-label>
							<mat-select formControlName="statusOfSale">
								<mat-option [value]="null" disabled>
									Select A Status
								</mat-option>
								<mat-option value="notListed">
									Not On The Market Yet
								</mat-option>
								<mat-option value="listed"> On The Market </mat-option>
								<mat-option value="offerAccepted"> Under Offer </mat-option>
							</mat-select>
						</mat-form-field>
					</div>

					<div
						*ngIf="
							form.get('statusOfSale')?.value == 'listed' ||
							form.get('statusOfSale')?.value == 'offerAccepted'
						">
						<app-people
							style="margin-top: 1.5rem"
							[form]="$any(form.get('agent'))"
							[atLeastOne]="true"
							[hideEmailSend]="true"
							title="Estate Agent"></app-people>
					</div>

					<div *ngIf="form.get('statusOfSale')?.value == 'offerAccepted'">
						<div class="has-left-info" style="margin-top: 2rem">
							<mat-form-field appearance="outline" floatLabel="always">
								<mat-label>Properties in the chain?</mat-label>
								<input
									matInput
									formControlName="propertiesInChain"
									type="number" />
							</mat-form-field>
							<div class="left-info">
								(Including the property your selling but excluding the property
								you are putting an offer on.)
							</div>
						</div>
						<div class="has-left-info">
							<div>
								<label>Is the other end of the chain is complete?</label>
								<mat-radio-group formControlName="chainComplete">
									<mat-radio-button [value]="true">Yes</mat-radio-button>
									<mat-radio-button [value]="false">No</mat-radio-button>
								</mat-radio-group>
							</div>
							<div class="left-info">
								(The party at the other end of the chain is not selling a
								property as part of this chain.)
							</div>
						</div>
					</div>
				</div>
			</app-group-title>

			<app-group-title title="Solicitor/Conveyancer" style="margin-top: 1.5rem">
				<div>
					<mat-checkbox formControlName="haveAConveyancer"
						>I have a Solicitor/Conveyancer I will be using for this
						transaction.
					</mat-checkbox>
				</div>
				<app-people
					style="margin-top: 1rem"
					*ngIf="form.get('haveAConveyancer')?.value"
					[form]="$any(form.get('conveyancer'))"
					[atLeastOne]="true"
					[hideEmailSend]="true"
					title=""></app-people>
			</app-group-title>

			<!-- <app-group-title
				title="Additional Information"
				style="margin-top: 1.5rem"> -->
			<div style="margin-top: 1.5rem">
				<mat-form-field
					appearance="outline"
					floatLabel="always"
					style="width: 100%">
					<mat-label>Additional Information</mat-label>
					<textarea
						matInput
						formControlName="additionalInformation"
						placeholder="I.E. I would like to be complete by September for the start of the school year..."></textarea>
				</mat-form-field>
			</div>
			<!-- </app-group-title> -->
		</mat-card-content>
	</mat-card>

	<mat-card
		*ngIf="property && !loading"
		class="row"
		style="flex-wrap: nowrap; margin-top: 1rem">
		<mat-card-content style="width: 100%" [formGroup]="form">
			<h2>Confirmation</h2>
			<app-group-title *ngIf="form.valid" title="Offer Summery">
				{{ summery() }}
			</app-group-title>
			<div>
				<mat-checkbox formControlName="confirmed"
					>I confirm that the information provided is correct to the best of my
					knowledge.
				</mat-checkbox>
			</div>
			<div *ngIf="!form.get('mortgage')?.value">
				<mat-checkbox formControlName="confirmFunds"
					>I confirm I have the full funds required to cover the Offer, Stamp
					Duty and all Legal Fees for this transaction.</mat-checkbox
				>
			</div>
			<div *ngIf="form.get('mortgage')?.value">
				<mat-checkbox formControlName="confirmFunds"
					>I confirm I have the funds required to cover the Deposit, Stamp Duty,
					Mortgage Fees and all Legal Fees for this transaction.</mat-checkbox
				>
			</div>
			<div>
				<mat-checkbox formControlName="confirmedContract"
					>I understand that a contract may be generated based on some of the
					information provided here.
				</mat-checkbox>
			</div>
			<div>
				<mat-checkbox formControlName="confirmedTerms"
					>I agree to the
					<a href="https://clearchain.house" target="_blank"
						>terms and conditions</a
					>
					of Clear Chian.
				</mat-checkbox>
			</div>
			<div>
				<app-signature formControlName="signature"></app-signature>
				<!--  -->
			</div>
			<div>
				<mat-form-field
					appearance="outline"
					floatLabel="always"
					style="width: 100%">
					<mat-label>Full Name</mat-label>
					<input matInput type="string" formControlName="fullName" />
					<!-- [appMoneyInput] -->
				</mat-form-field>
			</div>

			<div style="display: flex; align-items: end; flex-direction: column">
				<button
					mat-raised-button
					color="primary"
					[disabled]="!form.valid"
					(click)="submit()">
					Submit Offer
				</button>
			</div>

			<app-group-title
				*ngIf="form.invalid"
				title="Errors & Missing Information"
				style="margin-top: 1.5rem">
				<div *ngFor="let error of allErrors()?.subErrors; let i = index">
					<span class="error-label">{{ error.key }} </span>
					<span *ngIf="error.errors">
						<!-- <div>Errors:</div> -->
						<span class="error-message" *ngIf="error.errors['required']"
							>Is a required field</span
						>
						<span class="error-message" *ngIf="error.errors['pattern']">
							Doesn't match the required pattern.
						</span>
					</span>
					<div class="tab" *ngFor="let subError of error.subErrors">
						<span class="error-label">{{ subError.key }} </span>
						<span *ngIf="subError.errors">
							<!-- <div>Errors:</div> -->
							<span class="error-message" *ngIf="subError.errors['required']"
								>Is a required field</span
							>
							<span class="error-message" *ngIf="subError.errors['pattern']">
								Doesn't match the required pattern.
							</span>
						</span>
						<div class="tab" *ngFor="let subSubError of subError.subErrors">
							<span class="error-label">{{ subSubError.key }} </span>
							<span *ngIf="subSubError.errors">
								<!-- <span>Errors:</span> -->
								<span
									class="error-message"
									*ngIf="subSubError.errors['required']">
									Is a required field.
								</span>
								<span
									class="error-message"
									*ngIf="subSubError.errors['pattern']">
									Doesn't match the required pattern.
								</span>
							</span>
						</div>
					</div>
				</div>
			</app-group-title>

			<!-- <pre>

				{{ allErrors() | json }}

			</pre
			> -->
		</mat-card-content>
	</mat-card>
</div>
