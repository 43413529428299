import { ChangeDetectionStrategy, Component, type OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import {
	AMLBackendService,
	AMLcheckResponse,
	credasStatus,
} from 'src/app/services/AML-backend.service';
import { TitleService } from 'src/app/services/title.service';

@Component({
	selector: 'app-amlpermissions',
	templateUrl: './AMLpermissions.component.html',
	styleUrls: ['./AMLpermissions.component.css'],
	//changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AMLpermissionsComponent implements OnInit {
	loading = true;
	AMLchecks: AMLcheckResponse[] = [];
	credasStatuses = credasStatus;
	constructor(
		public AMLBackend: AMLBackendService,
		public dialog: MatDialog,
		public titleService: TitleService
	) {}
	ngOnInit(): void {
		this.titleService.title = 'AML Checks Permissions';
		this.AMLBackend.getList({
			myAMLchecks: true,
		}).then((res) => {
			this.AMLchecks = res.data;
			this.loading = false;
		});
	}

	async updatePermissions(
		amlId: string,
		permissionId: string,
		status: 'granted' | 'denied'
	) {
		await this.AMLBackend.patchAMLpermissions(amlId, {
			AMLpermissionIds: [permissionId],
			status,
		});
		this.AMLBackend.getList({
			myAMLchecks: true,
		}).then((res) => {
			this.AMLchecks = res.data;
		});
	}
}
