<div class="container">
	<h1 class="header">New Property Chain Setup</h1>
	<div class="center-center" *ngIf="loading">
		<mat-spinner></mat-spinner>
	</div>
	<mat-card appearance="outlined" *ngIf="!loading">
		<mat-stepper orientation="vertical" #stepper linear>
			<ng-template matStepperIcon="info">
				<mat-icon>info</mat-icon>
			</ng-template>

			<ng-template matStepperIcon="house">
				<mat-icon>house</mat-icon>
			</ng-template>

			<ng-template matStepperIcon="mortgage">
				<mat-icon>attach_money</mat-icon>
			</ng-template>

			<ng-template matStepperIcon="conveyancer">
				<mat-icon>gavel</mat-icon>
			</ng-template>

			<ng-template matStepperIcon="policy">
				<mat-icon>policy</mat-icon>
			</ng-template>

			<mat-step state="info" [stepControl]="nameBasicInfoFromGroup">
				<ng-template matStepLabel>Your Information</ng-template>
				<form fxLayout="column" [formGroup]="nameBasicInfoFromGroup">
					<div class="row">
						<mat-form-field appearance="outline" floatLabel="always">
							<mat-label>Given Name</mat-label>
							<input
								matInput
								name="firstName"
								autocomplete="given-name"
								formControlName="firstName" />
							<!-- <mat-hint align="end" *ngIf="!nameBasicInfoFromGroup.firstName"><span style="color:#F50057"><strong>Required</strong></span></mat-hint> -->
						</mat-form-field>
						<mat-form-field appearance="outline" floatLabel="always">
							<mat-label>Surname</mat-label>
							<input
								matInput
								name="lastName"
								autocomplete="family-name"
								formControlName="lastName" />
						</mat-form-field>
					</div>
					<div class="row">
						<div style="flex-grow: 1; display: flex; gap: 1rem">
							<mat-form-field
								style="width: 6rem"
								appearance="outline"
								floatLabel="always">
								<mat-label>Code</mat-label>
								<mat-select
									style="display: inline; max-width: 3rem"
									value="+44"
									formControlName="phoneCountryCode">
									<mat-option
										style="
											min-width: 8rem;
											display: flex;
											flex-direction: row;
											align-items: center;
											justify-content: center;
										"
										*ngFor="let code of phoneCountryCodes; let i = index"
										[value]="code">
										<!-- <img style="width: 2rem" [src]="code.image" /> -->
										{{ code }}
									</mat-option>
								</mat-select>
							</mat-form-field>
							<mat-form-field
								style="width: 9rem"
								appearance="outline"
								floatLabel="always">
								<mat-label> Phone </mat-label>

								<input
									matInput
									appLocalPhoneNumber
									placeholder="1234567890"
									formControlName="phoneInCountryNumber" />
							</mat-form-field>
						</div>
						<mat-form-field appearance="outline" floatLabel="always">
							<mat-label>Company</mat-label>
							<input
								matInput
								name="company"
								autocomplete="company"
								formControlName="company" />
						</mat-form-field>
					</div>
					<div class="row">
						<mat-form-field appearance="outline" floatLabel="always">
							<mat-label>Who are you?</mat-label>
							<mat-select formControlName="userType">
								<mat-option
									*ngFor="let _userType of typesOfUsers"
									[value]="_userType">
									{{ _userType }}
								</mat-option>
							</mat-select>
						</mat-form-field>
					</div>
					<div class="row" *ngIf="nameBasicInfoFromGroup.get('managerEmail')">
						<mat-form-field appearance="outline" floatLabel="always">
							<mat-label>Your Managers Email</mat-label>
							<input
								matInput
								name="managerEmail"
								formControlName="managerEmail" />
						</mat-form-field>
					</div>
					<div fxLayout="row" fxLayoutAlign="end center">
						<button
							type="submit"
							[disabled]="nameBasicInfoFromGroup.invalid"
							mat-raised-button
							color="primary"
							matStepperNext>
							Next
						</button>
					</div>
				</form>
			</mat-step>

			<mat-step state="info" [completed]="!!detailsData">
				<ng-template matStepLabel>Transaction Information</ng-template>
				<ng-container
					[ngSwitch]="nameBasicInfoFromGroup.get('userType')?.value">
					<app-buyer-seller-setup
						*ngSwitchCase="userTypes.buyerSeller"
						(data)="detailsData = $event"
						(submit)="nextStep()"
						(back)="lastStep()"></app-buyer-seller-setup>
					<app-selling-agent-setup
						*ngSwitchCase="userTypes.sellersAgent"
						(data)="detailsData = $event"
						(submit)="nextStep()"
						(back)="lastStep()"></app-selling-agent-setup>
					<app-mortgage-broker-setup
						*ngSwitchCase="userTypes.mortgageBroker"
						(data)="detailsData = $event"
						(submit)="nextStep()"
						(back)="lastStep()"></app-mortgage-broker-setup>
					<app-conveyancer
						*ngSwitchCase="'Conveyancer'"
						(data)="detailsData = $event"
						(submit)="nextStep()"
						(back)="lastStep()">
					</app-conveyancer>
					<div *ngSwitchDefault>
						ToDo: Add other user types like
						{{ nameBasicInfoFromGroup.get("userType")?.value }}
					</div>
				</ng-container>
			</mat-step>

			<mat-step state="policy" [stepControl]="conditionsFormGroup">
				<ng-template matStepLabel>Terms & Conditions</ng-template>
				<form [formGroup]="conditionsFormGroup">
					<div fxLayout="column">
						<div class="row row-bottom-padding">
							<div
								style="
									max-height: 15rem;
									overflow: auto;
									border: gray 1px inset;
									padding: 4px;
								">
								<app-t-and-c-s></app-t-and-c-s>
							</div>
						</div>
						<div class="row" style="margin-bottom: 5px">
							<mat-checkbox formControlName="conditionsAgree"
								>I agree to the conditions</mat-checkbox
							>
						</div>
						<div class="row">
							<mat-error
								*ngIf="conditionsFormGroup.get('conditionsAgree')?.invalid"
								>You must agree to the terms & conditions in order to
								continue</mat-error
							>
						</div>
						<div class="row" style="margin-bottom: 5px">
							<mat-checkbox formControlName="stipulatesUse"
								>{{
									nameBasicInfoFromGroup.get("userType")?.value ==
									userTypes.buyerSeller
										? "I"
										: "My client"
								}}
								stipulate{{
									nameBasicInfoFromGroup.get("userType")?.value ==
									userTypes.buyerSeller
										? ""
										: "s"
								}}
								all parties in this chain use Clear Chain.</mat-checkbox
							>
						</div>
						<div class="row">
							<mat-error
								*ngIf="conditionsFormGroup.get('stipulatesUse')?.value !== true"
								>WARNING We highly recommend stipulating that all parties use
								Clear Chain in order to maximise its benefits. If you choose to
								proceed without this stipulation you are accepting that you may
								lose some of its value as you won't be able to see and manage
								the rest of the chain.</mat-error
							>
						</div>
						<div fxLayout="row" fxLayoutAlign="end center">
							<button mat-button matStepperPrevious type="button">Back</button>
							<button
								mat-raised-button
								matStepperNext
								color="primary"
								type="submit"
								[disabled]="conditionsFormGroup.invalid"
								(click)="conditionsOnSubmit()">
								Complete
							</button>
						</div>
					</div>
				</form>
			</mat-step>
		</mat-stepper>
	</mat-card>
</div>
