import { Component, OnInit } from '@angular/core';
import { TitleService } from '../services/title.service';

@Component({
	templateUrl: './page-not-found.component.html',
	styleUrls: ['./page-not-found.component.css'],
})
export class PageNotFoundComponent implements OnInit {
	constructor(public titleService: TitleService) {}

	ngOnInit(): void {
		this.titleService.title = 'Error';
	}
}
