import { Directive, OnInit, OnDestroy } from '@angular/core';
import { NgControl } from '@angular/forms';
import { Subscription } from 'rxjs';

@Directive({
	selector: '[appLocalPhoneNumber]',
})
export class LocalPhoneNumberDirective implements OnInit, OnDestroy {
	valueSubscription: Subscription | undefined;
	constructor(public ngControl: NgControl) {}

	ngOnInit(): void {
		this.valueSubscription = this.ngControl?.control?.valueChanges.subscribe(
			(value) => {
				const newVal = this.transform(value);
				this.ngControl.control?.setValue(newVal, {
					emitEvent: false,
					emitModelToViewChange: true,
					emitViewToModelChange: false,
				});
			}
		);
	}

	ngOnDestroy(): void {
		this.valueSubscription?.unsubscribe();
	}

	//0749 639 4448

	transform(value: any) {
		if (value) {
			let _value = value + '';
			if (_value.length < 11) return _value;
			_value = _value.replace(/^0|^\+44/, '');
			_value = _value.replace(/ |-/g, '');
			return _value;
		}
		return '';
	}
}
