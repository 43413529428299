import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { NewDateDialogComponent } from './new-date-dialog/new-date-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { lastValueFrom } from 'rxjs';

@Component({
	selector: 'app-date-voting',
	templateUrl: './date-voting.component.html',
	styleUrls: ['./date-voting.component.css'],
})
export class DateVotingComponent implements OnInit {
	@Input() name: string = '';
	@Input() VoteDate?: {
		currentDate?: [number, number, number]; // year (full year ie 2020) / month (starts with 0) / day (starts with 1)
		suggestedDates?: {
			date: [number, number, number];
			votes: {
				[buyerSellerId: string]: boolean | undefined;
			};
		}[];
	} = {};
	@Input() buyerSellerIds: string[] = [];
	@Input() numberOfTotalBuyerSellers: number = 0;
	@Output() change = new EventEmitter();

	suggestNewDate = false;

	get currentDate() {
		if (!this.VoteDate?.currentDate) return undefined;
		return this.arrayToDate(this.VoteDate?.currentDate);
	}

	constructor(public dialog: MatDialog) {}

	ngOnInit(): void {}

	newDate: Date | undefined = undefined;
	changeNewDate(_date: any) {
		this.newDate = new Date(_date);
	}
	async suggestDate() {
		let ref = this.dialog.open(NewDateDialogComponent, {
			data: {
				name: this.name,
			},
		});
		let r = await lastValueFrom(ref.afterClosed());
		if (!!r) this.newDate = r;

		if (!this.newDate) return;
		if (!!this.VoteDate && !this.VoteDate?.suggestedDates)
			this.VoteDate.suggestedDates = [];
		let _vote = {
			date: this.dateToArray(this.newDate) || [0, 0, 0],
			votes: {},
		};
		this.VoteDate?.suggestedDates?.push(_vote);

		this.changeVote(_vote, true);

		this.newDate = undefined;
		this.suggestNewDate = false;
	}

	dateToArray(_date: Date | undefined): [number, number, number] | undefined {
		if (!_date) return undefined;
		return [_date.getFullYear(), _date.getMonth(), _date.getDate()];
	}

	arrayToDate(_date: [number, number, number] | undefined): Date | undefined {
		if (!_date) return undefined;
		let newDate = new Date();
		newDate.setFullYear(_date[0]);
		newDate.setMonth(_date[1]);
		newDate.setDate(_date[2]);
		return newDate;
	}

	voteCount(votes: { [buyerSellerId: string]: boolean | undefined }) {
		let count = 0;
		for (let key in votes) {
			if (votes[key]) count++;
		}
		return count;
	}

	voteNoCount(votes: { [buyerSellerId: string]: boolean | undefined }) {
		let count = 0;
		for (let key in votes) {
			if (votes[key] === false) count++;
		}
		return count;
	}

	voteResult(votes: { [buyerSellerId: string]: boolean | undefined }) {
		return votes[this.buyerSellerIds[0]];
	}

	everyOneVoted(vote: {
		date: [number, number, number];
		votes: {
			[buyerSellerId: string]: boolean | undefined;
		};
	}) {
		return Object.keys(vote.votes).length == this.numberOfTotalBuyerSellers;
	}

	isCurrentDate(date: [number, number, number]) {
		return this.arrayDatesEqual(date, this.VoteDate?.currentDate);
	}

	arrayDatesEqual(
		date1: [number, number, number] | undefined,
		date2: [number, number, number] | undefined
	) {
		return (
			date1 &&
			date2 &&
			date1[0] == date2[0] &&
			date1[1] == date2[1] &&
			date1[2] == date2[2]
		);
	}

	changeVote(
		vote: {
			date: [number, number, number];
			votes: {
				[buyerSellerId: string]: boolean | undefined;
			};
		},
		voteValue: boolean
	) {
		if (!this.isCurrentDate(vote.date) && voteValue) {
			if (
				this.voteCount(vote.votes) + this.buyerSellerIds.length >=
				this.numberOfTotalBuyerSellers
			) {
				this.VoteDate?.suggestedDates?.forEach((suggestedDate) => {
					if (!this.arrayDatesEqual(suggestedDate.date, vote.date)) {
						this.buyerSellerIds.forEach((buyerSellerId) => {
							delete suggestedDate.votes[buyerSellerId];
						});
					}
				});
			}
		}

		this.buyerSellerIds.forEach((buyerSellerId) => {
			vote.votes[buyerSellerId] = voteValue;
		});

		this.change.emit();
	}

	percentYes(vote: {
		date: [number, number, number];
		votes: {
			[buyerSellerId: string]: boolean | undefined;
		};
	}) {
		return (
			Math.round(
				(this.voteCount(vote.votes) / this.numberOfTotalBuyerSellers) * 100
			) + '%'
		);
	}

	percentNo(vote: {
		date: [number, number, number];
		votes: {
			[buyerSellerId: string]: boolean | undefined;
		};
	}) {
		return (
			Math.round(
				(this.voteNoCount(vote.votes) / this.numberOfTotalBuyerSellers) * 100
			) + '%'
		);
	}

	suggestedDatesOrdered() {
		if (!this.VoteDate?.suggestedDates) return [];
		return this.VoteDate.suggestedDates.sort((a, b) => {
			let _a = this.arrayToDate(a.date);
			let _b = this.arrayToDate(b.date);

			if (_a && _b) {
				if (_a > _b) return 1;
				if (_a < _b) return -1;
			}
			return 0;
		});
	}
}
