<div>
	<div
		class="vote"
		[ngClass]="{
			completed: isCurrentDate(vote.date),
			'icon-disabled': everyOneVoted(vote) && !isCurrentDate(vote.date)
		}"
		*ngFor="let vote of suggestedDatesOrdered()">
		<div class="date">
			{{ arrayToDate(vote.date) | date }}
		</div>
		<div class="vote-count">
			<img src="assets/figma-icons/akar-icons_person-check.svg" />
			{{ voteCount(vote.votes) }} votes
		</div>
		<div class="vote">
			<button
				mat-flat-button
				[disabled]="buyerSellerIds.length === 0"
				[color]="voteResult(vote.votes) ? 'accent' : ''"
				(click)="changeVote(vote, true)">
				Yes
			</button>
			<button
				mat-flat-button
				[disabled]="buyerSellerIds.length === 0"
				[color]="voteResult(vote.votes) === false ? 'primary' : ''"
				(click)="changeVote(vote, false)">
				No
			</button>
		</div>
	</div>
	<div
		*ngIf="suggestedDatesOrdered().length == 0"
		style="margin-bottom: 1rem; flex-grow: 1; align-items: center">
		No Dates have been suggested yet.
	</div>
	<div *ngIf="suggestedDatesOrdered().length > 0" style="flex-grow: 1"></div>
	<div *ngIf="buyerSellerIds.length === 0" class="warning">
		Only the buyer and seller can vote on the {{ name }}.
		<button
			class="warning"
			mat-icon-button
			color="primary"
			#tooltip="matTooltip"
			matTooltip="If you have an issue with a given date please contact the buyer/seller you represent."
			(click)="tooltip.show()">
			<mat-icon>info</mat-icon>
		</button>
	</div>
</div>
<div class="actions">
	<button
		color="primary"
		mat-button
		[disabled]="buyerSellerIds.length === 0"
		(click)="suggestDate()">
		<mat-icon>add</mat-icon>
		Suggest A New Date
	</button>
</div>
