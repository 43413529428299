import { HttpClient } from '@angular/common/http';
import {
	ChangeDetectionStrategy,
	ChangeDetectorRef,
	Component,
	OnDestroy,
	OnInit,
	ViewChild,
} from '@angular/core';
import { FormBuilder, FormControl, Validators } from '@angular/forms';
import { MatStepper } from '@angular/material/stepper';
import { ActivatedRoute, Router } from '@angular/router';
import { firstValueFrom, lastValueFrom } from 'rxjs';
import { environment } from 'src/environments/environment';
import { AuthService } from '../services/auth.service';
import { ConfirmExitFrom, FormsService } from '../services/forms.service';
import { userType } from './httpTypes';
import { TitleService } from '../services/title.service';
import { phoneCountryCodeList } from '../services/phoneCodes';

@Component({
	templateUrl: './setup.component.html',
	styleUrls: ['./setup.component.css'],
	changeDetection: ChangeDetectionStrategy.Default,
})
export class SetupComponent implements OnInit, OnDestroy {
	loading: boolean = false;
	@ViewChild('stepper') stepper?: MatStepper;

	detailsData: any = null;

	$nameBasicInfoFromGroup: any;
	$userType: any;
	@ConfirmExitFrom() nameBasicInfoFromGroup = this.fb.group<{
		firstName: FormControl<string | null>;
		lastName: FormControl<string | null>;
		phoneCountryCode: FormControl<string | null>;
		phoneInCountryNumber: FormControl<string | null>;
		company: FormControl<string | null>;
		userType: FormControl<string | null>;
		managerEmail?: FormControl<string | null>;
	}>({
		firstName: this.fb.control('', [Validators.required]),
		lastName: this.fb.control('', [Validators.required]),
		phoneCountryCode: this.fb.control('+44', [Validators.required]),
		phoneInCountryNumber: this.fb.control('', [
			Validators.required,
			Validators.pattern('^[1-9]\\d{9}'),
		]),
		company: this.fb.control('', []),
		userType: this.fb.control('', [Validators.required]),
	});

	// conditionsGroup = {
	// 	conditionsAgree: false,

	// };

	@ConfirmExitFrom() conditionsFormGroup = this.fb.group({
		conditionsAgree: [false, [Validators.requiredTrue]],
		stipulatesUse: [true, [Validators.required]],
	});

	typesOfUsers = [
		//userType.buyersAgent,
		userType.buyerSeller,
		'Conveyancer',
		userType.sellersAgent,
		userType.mortgageBroker,
	];

	userTypes = userType;

	$routePrams: any;
	inviteId: string | undefined = undefined;

	basicFormCompleted = false;

	phoneCountryCodes = phoneCountryCodeList;

	managerEmail?: string;

	constructor(
		public Http: HttpClient,
		public AuthService: AuthService,
		public ActivatedRoute: ActivatedRoute,
		public Router: Router,
		public cdRef: ChangeDetectorRef,
		public fb: FormBuilder,
		public FormsService: FormsService,
		public titleService: TitleService
	) {}

	ngAfterContentChecked(): void {
		this.cdRef.detectChanges();
	}

	ngOnInit(): void {
		this.titleService.title = 'New Chain';
		if (this.$routePrams?.unsubscribe) this.$routePrams.unsubscribe();
		this.$routePrams = this.ActivatedRoute.params.subscribe((params) => {
			this.inviteId = params['inviteId'];
			console.log(this.inviteId);
			if (!!this.inviteId) this.loading = true;
			if (!!this.inviteId)
				lastValueFrom(
					this.Http.get(`${environment.apiUrl}/setup/${this.inviteId}`, {
						withCredentials: true,
					})
				)
					.then((data: any) => {
						this.nameBasicInfoFromGroup
							.get('firstName')
							?.setValue(data.firstName);
						this.nameBasicInfoFromGroup
							.get('lastName')
							?.setValue(data.lastName);
						this.nameBasicInfoFromGroup
							.get('userType')
							?.setValue(data.userType);
						this.loading = false;
					})
					.catch((e) => {
						this.loading = false;
						console.log(e);
					});
		});

		if (this.$userType?.unsubscribe) this.$userType.unsubscribe();
		this.$userType = this.nameBasicInfoFromGroup
			.get('userType')
			?.valueChanges.subscribe((value) => {
				if (value == userType.sellersAgent) {
					this.nameBasicInfoFromGroup.addControl(
						'managerEmail',
						this.fb.control(this.managerEmail || '', [Validators.required])
					);
				} else {
					this.nameBasicInfoFromGroup.removeControl('managerEmail');
				}
			});

		this.AuthService.getUserAttributes().then((attributes) => {
			this.managerEmail = undefined;
			if (!!attributes?.given_name)
				this.nameBasicInfoFromGroup
					.get('firstName')
					?.setValue(attributes.given_name);
			if (!!attributes?.family_name)
				this.nameBasicInfoFromGroup
					.get('lastName')
					?.setValue(attributes.family_name);
			if (!!attributes['custom:phoneCountryCode'])
				this.nameBasicInfoFromGroup
					.get('phoneCountryCode')
					?.setValue(attributes['custom:phoneCountryCode']);
			else this.nameBasicInfoFromGroup.get('phoneCountryCode')?.setValue('+44');
			if (!!attributes['custom:phoneInCountryNumber'])
				this.nameBasicInfoFromGroup
					.get('phoneInCountryNumber')
					?.setValue(attributes['custom:phoneInCountryNumber']);
			if (!!attributes?.['custom:company'])
				this.nameBasicInfoFromGroup
					.get('company')
					?.setValue(attributes['custom:company']);
			if (!!attributes?.['custom:user_type']) {
				if (
					attributes['custom:user_type'] == userType.BuyersConveyancer ||
					attributes['custom:user_type'] == userType.SellersConveyancer
				)
					this.nameBasicInfoFromGroup.get('userType')?.setValue('Conveyancer');
				else
					this.nameBasicInfoFromGroup
						.get('userType')
						?.setValue(attributes['custom:user_type']);
			}
			if (!!attributes?.['custom:managerEmail']) {
				this.managerEmail = attributes['custom:managerEmail'];
				if (this.nameBasicInfoFromGroup.get('managerEmail')) {
					this.nameBasicInfoFromGroup
						.get('managerEmail')
						?.setValue(this.managerEmail);
				}
			}
		});

		let oldData = localStorage.getItem('oldNameBasicInfoFromGroup');
		if (oldData) {
			try {
				oldData = JSON.parse(oldData);
				this.nameBasicInfoFromGroup.patchValue(oldData as any);
			} catch (error) {}
		}
		this.$nameBasicInfoFromGroup =
			this.nameBasicInfoFromGroup.valueChanges.subscribe((value) => {
				try {
					localStorage.setItem(
						'oldNameBasicInfoFromGroup',
						JSON.stringify(value)
					);
				} catch (error) {}
			});
	}

	ngOnDestroy(): void {
		if (this.$routePrams?.unsubscribe) this.$routePrams.unsubscribe();
		if (this.$userType?.unsubscribe) this.$userType.unsubscribe();
		console.log('destroy');
		console.log(
			this.FormsService?.allFormComponents?.size,
			'this.FormsService.allFormComponents'
		);
		if (this.$nameBasicInfoFromGroup?.unsubscribe)
			this.$nameBasicInfoFromGroup?.unsubscribe();
	}

	// trackByUserTypes(i:number,value:string){
	//   return value
	// }

	// trackByAddress(i:number,value:any){
	//   return value?.DPA?.UPRN
	// }

	// basicOnSubmit(){
	//   // if(this.nameBasicInfoFromGroup.valid){
	//   //   this.basicFormCompleted = true
	//   // }
	// }

	//mortgageOnSubmit(){}

	//buyingOnSubmit(){}

	nameBasicInfoFromGroupInvalid() {
		return this.nameBasicInfoFromGroup.invalid;
	}

	nextStep() {
		if (this.stepper) this.stepper.next();
	}

	lastStep() {
		if (this.stepper) this.stepper.previous();
	}

	async conditionsOnSubmit() {
		let data: any = {
			firstName: this.nameBasicInfoFromGroup.get('firstName')?.value || '',
			lastName: this.nameBasicInfoFromGroup.get('lastName')?.value || undefined,
			phone: this.nameBasicInfoFromGroup.get('phone')?.value || undefined,
			company: this.nameBasicInfoFromGroup.get('company')?.value || undefined,
			userType: this.nameBasicInfoFromGroup.get('userType')?.value,
		}; //:potentialBuyerBody | buyerBody | sellerBody | buyerSellerBody

		switch (this.nameBasicInfoFromGroup.get('userType')?.value || '') {
			case userType.buyerSeller:
				data = {
					otherBuyersSellers: [],
					conveyancers: [],
					...data,
					...this.detailsData,
				};
				break;
			case userType.sellersAgent:
				data = { ...data, ...this.detailsData };
				if (
					this.nameBasicInfoFromGroup.get('managerEmail') &&
					this.nameBasicInfoFromGroup.get('managerEmail')?.value !== ''
				)
					data = {
						...data,
						managerEmail:
							this.nameBasicInfoFromGroup.get('managerEmail')?.value,
					};
				break;
			case userType.mortgageBroker:
				data = { ...data, ...this.detailsData };
				break;
			case 'Conveyancer':
				data = { ...data, ...this.detailsData };
				break;
			default:
				data = <any>{};
				break;
		}

		console.log(data);

		this.loading = true;
		let R: any = await lastValueFrom(
			this.Http.put(`${environment.apiUrl}/setup`, data, {
				withCredentials: true,
			})
		).catch((e) => {
			console.log(e);
			this.loading = false;
			return null;
		});

		console.log(R);
		this.nameBasicInfoFromGroup.markAsPristine();
		this.conditionsFormGroup.markAsPristine();

		if (R?.chainId?.length > 5) {
			//this.loading = false
			try {
				localStorage.removeItem('oldNameBasicInfoFromGroup');
			} catch (error) {}
			try {
				localStorage.removeItem('oldPurchaseInfoForm');
			} catch (error) {}
			try {
				localStorage.removeItem('oldMortgageInfoForm');
			} catch (error) {}
			try {
				localStorage.removeItem('oldSellingInfoForm');
			} catch (error) {}
			try {
				localStorage.removeItem('oldConveyancerInfoForm');
			} catch (error) {}

			setTimeout(() => {
				this.Router.navigate(['/chain', R?.chainId, 'overview']);
			}, 300);
		} else if (R) this.Router.navigate(['/chains']);

		return true;
	}

	// conditionsGroupChange(checked: boolean) {
	// 	this.conditionsGroup.conditionsAgree = checked;
	// 	this.cdRef.markForCheck();
	// }
}
