<div class="container">
	<h1 class="header">Material Information</h1>
	<div class="center-center" *ngIf="loading">
		<mat-spinner></mat-spinner>
	</div>

	<mat-card appearance="outlined" *ngIf="!loading && !materialInfo">
		<mat-card-content>
			<div class="center">Error</div>
		</mat-card-content>
	</mat-card>

	<mat-card
		*ngIf="materialInfo"
		class="row property-card"
		style="flex-wrap: nowrap; margin-top: 1rem">
		<mat-card-content style="width: 100%">
			<h2>Property</h2>
			<h3>{{ materialInfo.address || "" }}</h3>
			<div style="position: absolute; top: 1rem; right: 1rem">
				<button mat-button [matMenuTriggerFor]="menu">Index</button>
			</div>
			<mat-menu #menu="matMenu">
				<button mat-menu-item (click)="goTo('askingPriceCard')">
					Asking Price
				</button>
				<button mat-menu-item (click)="goTo('tenureCard')">Tenure</button>
				<button mat-menu-item (click)="goTo('tenantCard')">Tenant</button>
				<button mat-menu-item (click)="goTo('propertyConstructionCard')">
					Property Construction
				</button>
				<button mat-menu-item (click)="goTo('spacesCard')">
					Rooms/Gardens and Other Spaces
				</button>
				<button mat-menu-item (click)="goTo('knownWorksCard')">
					Known Works Completed
				</button>
				<button mat-menu-item (click)="goTo('utilitiesCard')">Utilities</button>
				<button mat-menu-item (click)="goTo('parkingBikeStorageCard')">
					Parking & Bike Storage
				</button>
				<button mat-menu-item (click)="goTo('buildingSafetyIssuesCard')">
					Building Safety Issues
				</button>
				<button mat-menu-item (click)="goTo('rightsEasementsCard')">
					Rights Of Ways And Easements
				</button>
				<button mat-menu-item (click)="goTo('accessibilityAdaptationsCard')">
					Accessibility/Adaptations
				</button>
			</mat-menu>
			<!-- <mat-nav-list>
				<a mat-list-item href="#askingPriceCard">Asking Price</a>
				<a mat-list-item href="#askingPriceCard">Tenure</a>
				<a mat-list-item href="#askingPriceCard">Leasing Tenant</a>
				<a mat-list-item href="#askingPriceCard">Property Construction</a>
			</mat-nav-list> -->
		</mat-card-content>
	</mat-card>

	<mat-card
		*ngIf="materialInfo"
		id="askingPriceCard"
		class=""
		style="flex-wrap: nowrap; margin-top: 1rem">
		<mat-card-header>
			<mat-card-title>Asking Price</mat-card-title>
		</mat-card-header>
		<mat-card-content style="width: 100%">
			<div>
				<label>Asking Price:</label>
				£{{ materialInfo.askingPriceForm?.askingPrice }}
			</div>
			<div *ngIf="materialInfo.askingPriceForm?.reservationFee">
				<label>Reservation Fee:</label>
				£{{ materialInfo.askingPriceForm?.reservationFee }}
				<p>
					A reservation fee is not the same as a deposit. It is a fee paid to
					reserve the property and take it off the market, which is usually
					non-refundable if the buyer decides not to proceed with the purchase.
				</p>
			</div>
		</mat-card-content>
	</mat-card>

	<mat-card
		id="tenureCard"
		*ngIf="materialInfo"
		class=""
		style="flex-wrap: nowrap; margin-top: 1rem">
		<mat-card-header>
			<mat-card-title>Tenure</mat-card-title>
		</mat-card-header>
		<mat-card-content style="width: 100%">
			<div>
				<label>Tenure:</label>
				{{ materialInfo.tenureForm?.ownership | appCapFirstLetters }}
				{{ materialInfo.tenureForm?.tenure | appCapFirstLetters }}
			</div>
			<div *ngIf="materialInfo.tenureForm?.tenure == 'Leasehold'">
				<div>
					<label>Lease Length:</label>
					{{ materialInfo.tenureForm?.leftOnLease }} years of an original
					{{ materialInfo.tenureForm?.originLengthOfLease }} years lease
				</div>
				<div>
					<label>Ground Rent:</label>
					£{{ materialInfo.tenureForm?.groundRentCost }} per annum
				</div>
				<div>
					<label>Service Charge:</label>
					£{{ materialInfo.tenureForm?.serviceChargesCost }} per annum (Paid
					{{ materialInfo.tenureForm?.periodOfServiceCharges }}).
					<span *ngIf="materialInfo.tenureForm?.sinkingFund"
						>There is also a Sinking Fund of £{{
							materialInfo.tenureForm?.sinkingFundAmount
						}}, where {{ materialInfo.tenureForm?.percentToSinkingFund }}% of
						service charges are deposited.</span
					>
				</div>
				<div *ngIf="materialInfo.tenureForm?.capitalWorksPlanned">
					<label>There are Capital Works planned:</label>

					{{ materialInfo.tenureForm?.capitalWorksDetails }}
				</div>
				<div *ngIf="materialInfo.tenureForm?.hasManagingAgent">
					<label>Managing Agent:</label>

					{{materialInfo.tenureForm?.managingAgents?.[0]?.company || 'Yes'}}
				</div>
				<div>
					<label>Pet Restrictions:</label>
					{{ materialInfo.tenureForm?.petRestrictions | appCapFirstLetters }}
					<p *ngIf="materialInfo.tenureForm?.petRestrictionsDetails">
						{{ materialInfo.tenureForm?.petRestrictionsDetails }}
					</p>
				</div>
			</div>
		</mat-card-content>
	</mat-card>

	<mat-card
		id="tenantCard"
		*ngIf="materialInfo && materialInfo.leasingTenantForm?.leaseBeyondSale"
		class=""
		style="flex-wrap: nowrap; margin-top: 1rem">
		<mat-card-header>
			<mat-card-title>Renting Tenant</mat-card-title>
		</mat-card-header>
		<mat-card-content style="width: 100%">
			<label>
				There is currently a Renting Tenant that will continue at least until
				{{
					materialInfo.leasingTenantForm?.leaseEndDate | date : "MMMM - yyyy"
				}}.
			</label>
		</mat-card-content>
	</mat-card>

	<mat-card
		id="propertyConstructionCard"
		*ngIf="materialInfo"
		class=""
		style="flex-wrap: nowrap; margin-top: 1rem">
		<mat-card-header>
			<mat-card-title>Property Construction</mat-card-title>
		</mat-card-header>
		<mat-card-content style="width: 100%">
			<p>
				The
				{{
					materialInfo.propertyConstructionForm?.propertyType
						| appCapFirstLetters
				}}
				was constructed in the
				{{ materialInfo.propertyConstructionForm?.originalConstructed }}. It was
				built with
				{{
					materialInfo.propertyConstructionForm?.wallConstruction
						| appStringList
				}}.
				<span
					*ngIf="materialInfo.propertyConstructionForm?.otherWallConstruction"
					>(Other:{{
						materialInfo.propertyConstructionForm?.otherWallConstruction
					}})</span
				>
				The roof is
				{{ materialInfo.propertyConstructionForm?.roofType | appStringList }}
				and made from
				{{
					materialInfo.propertyConstructionForm?.roofMaterial | appStringList
				}}.
				<span *ngIf="materialInfo.propertyConstructionForm?.otherRoofMaterial"
					>(Other:{{
						materialInfo.propertyConstructionForm?.otherRoofMaterial
					}})</span
				>
			</p>

			<div *ngIf="materialInfo.propertyConstructionForm?.leftBoundary">
				<label style="display: block; margin-bottom: 1rem">
					Looking towards the property from the road, these are who owns or
					accepts responsibility to maintain or repair the boundary features?
				</label>
				<div style="display: flex; justify-content: center">
					<div
						style="
							display: flex;
							align-content: center;
							justify-items: center;
							flex-direction: column;
							align-items: center;
							gap: 2rem;
							padding: 1rem;
						"
						class="mat-elevation-z1">
						<div>
							Rear Boundary:
							{{
								materialInfo.propertyConstructionForm?.rearBoundary
									| appCapFirstLetters
							}}
						</div>
						<div
							style="
								display: flex;
								align-content: center;
								justify-items: center;
								align-items: center;
								gap: 2rem;
							">
							<div>
								Left Boundary:
								{{
									materialInfo.propertyConstructionForm?.leftBoundary
										| appCapFirstLetters
								}}
							</div>
							<div>
								<mat-icon>house</mat-icon>
							</div>
							<div>
								Right Boundary:
								{{
									materialInfo.propertyConstructionForm?.rightBoundary
										| appCapFirstLetters
								}}
							</div>
						</div>
						<div>
							Front Boundary:
							{{
								materialInfo.propertyConstructionForm?.frontBoundary
									| appCapFirstLetters
							}}
						</div>
					</div>
				</div>
			</div>
			<div *ngIf="materialInfo.propertyConstructionForm?.irregularBoundary">
				<label>There are irregular boundaries to the property.</label>
				{{
					materialInfo.propertyConstructionForm?.irregularBoundaryDetails || ""
				}}
			</div>
			<div *ngIf="materialInfo.propertyConstructionForm?.boundaryMoved">
				<label>The boundaries have been recently moved or altered.</label>
				{{ materialInfo.propertyConstructionForm?.boundaryMovedDetails || "" }}
			</div>
		</mat-card-content>
	</mat-card>

	<mat-card
		id="spacesCard"
		*ngIf="materialInfo"
		class=""
		style="flex-wrap: nowrap; margin-top: 1rem">
		<mat-card-header>
			<mat-card-title>Rooms/Gardens and Other Spaces</mat-card-title>
		</mat-card-header>
		<mat-card-content style="width: 100%">
			<div>
				<label>Total Indoor Square Meters:</label>
				{{ materialInfo.spacesForm?.floorSpace }}m²
			</div>
			<div>
				<label>Number Of floors within the property:</label>

				{{ materialInfo.spacesForm?.numberOfFloors }}floors
			</div>
			<div>
				The Front door is located on the
				{{ materialInfo.spacesForm?.frontDoorLevel }} level.
			</div>
			<div><label>Number Of Bedrooms: </label> {{ numberOfBedrooms }}</div>
			<div><label>Number Of Bathrooms: </label> {{ numberOfBathrooms }}</div>
			<div *ngIf="indoorSpaces.length > 0" style="margin-top: 1.5rem">
				<label>Indoor Spaces</label>
				<table mat-table [dataSource]="indoorSpaces" class="mat-elevation-z1">
					<!--- Note that these columns can be defined in any order.
						  The actual rendered columns are set as a property on the row definition" -->

					<!-- Name Column -->
					<ng-container matColumnDef="name">
						<th mat-header-cell *matHeaderCellDef>Name</th>
						<td mat-cell *matCellDef="let element">
							{{ element.type | appCapFirstLetters }}
							<span *ngIf="(element.name || '').length > 0"
								>({{ element.name | appCapFirstLetters }})</span
							>
						</td>
					</ng-container>

					<!-- Weight Column -->
					<ng-container matColumnDef="size">
						<th mat-header-cell *matHeaderCellDef>Size (LxWxH)</th>
						<td mat-cell *matCellDef="let element">
							{{ element.length }}x{{ element.width }}x{{
								element.ceilingHeight
							}}m
						</td>
					</ng-container>

					<!-- Symbol Column -->
					<ng-container matColumnDef="details">
						<th mat-header-cell *matHeaderCellDef>Additional Info</th>
						<td mat-cell *matCellDef="let element">
							{{ additionalInfo(element) | appStringList }}
						</td>
					</ng-container>

					<tr
						mat-header-row
						*matHeaderRowDef="['name', 'size', 'details']"></tr>
					<tr
						mat-row
						*matRowDef="let row; columns: ['name', 'size', 'details']"></tr>
				</table>
			</div>

			<div *ngIf="outdoorSpaces.length > 0" style="margin-top: 1.5rem">
				<label>Outdoor Spaces</label>
				<table mat-table [dataSource]="outdoorSpaces" class="mat-elevation-z1">
					<!--- Note that these columns can be defined in any order.
					  The actual rendered columns are set as a property on the row definition" -->

					<!-- Name Column -->
					<ng-container matColumnDef="name">
						<th mat-header-cell *matHeaderCellDef>Name</th>
						<td mat-cell *matCellDef="let element">
							{{ element.type | appCapFirstLetters }}
							<span *ngIf="(element.name || '').length > 0"
								>({{ element.name | appCapFirstLetters }})</span
							>
						</td>
					</ng-container>

					<!-- Weight Column -->
					<ng-container matColumnDef="size">
						<th mat-header-cell *matHeaderCellDef>Size (LxW)</th>
						<td mat-cell *matCellDef="let element">
							{{ element.length }}x{{ element.width }}m
						</td>
					</ng-container>

					<tr mat-header-row *matHeaderRowDef="['name', 'size']"></tr>
					<tr mat-row *matRowDef="let row; columns: ['name', 'size']"></tr>
				</table>
			</div>
		</mat-card-content>
	</mat-card>
	<mat-card
		id="knownWorksCard"
		*ngIf="materialInfo?.knownWorksForm?.knowledgedOfWorks"
		class=""
		style="flex-wrap: nowrap; margin-top: 1rem">
		<mat-card-header>
			<mat-card-title>Known Works Completed</mat-card-title>
		</mat-card-header>
		<mat-card-content style="width: 100%">
			<table
				mat-table
				[dataSource]="materialInfo?.knownWorksForm?.knownWorks || []"
				class="mat-elevation-z1">
				<!--- Note that these columns can be defined in any order.
				  The actual rendered columns are set as a property on the row definition" -->

				<!-- Name Column -->
				<ng-container matColumnDef="description">
					<th mat-header-cell *matHeaderCellDef>Description</th>
					<td mat-cell *matCellDef="let element">
						{{ element.description }}
					</td>
				</ng-container>

				<!-- Weight Column -->
				<ng-container matColumnDef="completionDate">
					<th mat-header-cell *matHeaderCellDef>Date Completed</th>
					<td mat-cell *matCellDef="let element">
						{{ element.completionDate | date : "MMMM - yyyy" }}
					</td>
				</ng-container>

				<tr
					mat-header-row
					*matHeaderRowDef="['description', 'completionDate']"></tr>
				<tr
					mat-row
					*matRowDef="let row; columns: ['description', 'completionDate']"></tr>
			</table>
		</mat-card-content>
	</mat-card>

	<mat-card
		id="utilitiesCard"
		*ngIf="materialInfo"
		class=""
		style="flex-wrap: nowrap; margin-top: 1rem">
		<mat-card-header>
			<mat-card-title>Utilities</mat-card-title>
		</mat-card-header>
		<mat-card-content style="width: 100%">
			<div>
				<label> Annul Grid Electricity Cost: </label>
				£{{ materialInfo.utilitiesForm?.electricityCost }}
			</div>
			<div
				*ngIf="
					materialInfo.utilitiesForm?.electricityAltCost &&
					materialInfo.utilitiesForm?.electricityAltCost != '0'
				">
				<label> Annul Electricity Costs From Other Sources: </label>
				£{{ materialInfo.utilitiesForm?.electricityAltCost }}
			</div>
			<div *ngIf="materialInfo.utilitiesForm?.electricityAltDetails">
				Other Electricity Sources:
				{{ materialInfo.utilitiesForm?.electricityAltDetails }}
			</div>
			<div>
				<label>Annul Mains Water Cost:</label>
				£{{ materialInfo.utilitiesForm?.waterCost }}
			</div>
			<div
				*ngIf="
					materialInfo.utilitiesForm?.waterAltCost &&
					materialInfo.utilitiesForm?.waterAltCost != '0'
				">
				<label>Annul Water Costs From Other Sources:</label>
				£{{ materialInfo.utilitiesForm?.waterAltCost }}
			</div>
			<div *ngIf="materialInfo.utilitiesForm?.waterAltDetails">
				Other Water Sources: {{ materialInfo.utilitiesForm?.waterAltDetails }}
			</div>
			<div>
				<label
					>Annul Sewerage Cost From
					{{
						materialInfo.utilitiesForm?.sewerageOther ||
							materialInfo.utilitiesForm?.sewerageType
					}}
					:</label
				>
				£{{ materialInfo.utilitiesForm?.sewerageCost }}
			</div>

			<div
				style="margin-top: 1.5rem"
				*ngIf="(materialInfo.utilitiesForm?.heatingSources || []).length > 0">
				<label>Heating Sources</label>

				<table
					mat-table
					class="mat-elevation-z1"
					[dataSource]="materialInfo.utilitiesForm?.heatingSources || []">
					<!--- Note that these columns can be defined in any order.
					  The actual rendered columns are set as a property on the row definition" -->

					<!-- Name Column -->
					<ng-container matColumnDef="type">
						<th mat-header-cell *matHeaderCellDef>Type</th>
						<td mat-cell *matCellDef="let element">
							{{ element.type | appCapFirstLetters }}
							<span *ngIf="(element.otherDetails || '').length > 0">
								({{ element.otherDetails | appCapFirstLetters }})
							</span>
						</td>
					</ng-container>

					<!-- Weight Column -->
					<ng-container matColumnDef="cost">
						<th mat-header-cell *matHeaderCellDef>Cost Per Year</th>
						<td mat-cell *matCellDef="let element">£{{ element.cost }}</td>
					</ng-container>

					<tr mat-header-row *matHeaderRowDef="['type', 'cost']"></tr>
					<tr mat-row *matRowDef="let row; columns: ['type', 'cost']"></tr>
				</table>
			</div>
		</mat-card-content>
	</mat-card>

	<mat-card
		*ngIf="materialInfo"
		id="parkingBikeStorageCard"
		class=""
		style="flex-wrap: nowrap; margin-top: 1rem">
		<mat-card-header>
			<mat-card-title>Parking & Bike Storage</mat-card-title>
		</mat-card-header>
		<mat-card-content style="width: 100%">
			<label *ngIf="!materialInfo.parkingBikeStorageForm?.parkingAvailable">
				There is no parking available on or near the property.
			</label>

			<div
				style="margin-top: 1.5rem"
				*ngIf="
					(materialInfo.parkingBikeStorageForm?.parkingSpots || []).length > 0
				">
				<label>Parking Spots</label>

				<table
					mat-table
					class="mat-elevation-z1"
					[dataSource]="
						materialInfo.parkingBikeStorageForm?.parkingSpots || []
					">
					<!--- Note that these columns can be defined in any order.
					  The actual rendered columns are set as a property on the row definition" -->

					<!-- Name Column -->
					<ng-container matColumnDef="type">
						<th mat-header-cell *matHeaderCellDef>Location</th>
						<td mat-cell *matCellDef="let element">
							{{ element.type | appCapFirstLetters }}
						</td>
					</ng-container>

					<!-- Weight Column -->
					<ng-container matColumnDef="cost">
						<th mat-header-cell *matHeaderCellDef>Cost Per Year</th>
						<td mat-cell *matCellDef="let element">
							<span *ngIf="element.cost && element.cost != '0'"></span>
							£{{ element.cost }}
						</td>
					</ng-container>

					<ng-container matColumnDef="numberOfSpots">
						<th mat-header-cell *matHeaderCellDef>Number Of Spots Available</th>
						<td mat-cell *matCellDef="let element">
							{{ element.numberOfSpots }}
						</td>
					</ng-container>

					<ng-container matColumnDef="info">
						<th mat-header-cell *matHeaderCellDef>Additional Info</th>
						<td mat-cell *matCellDef="let element">
							{{ element.notes }}
						</td>
					</ng-container>

					<tr
						mat-header-row
						*matHeaderRowDef="['type', 'cost', 'numberOfSpots', 'info']"></tr>
					<tr
						mat-row
						*matRowDef="
							let row;
							columns: ['type', 'cost', 'numberOfSpots', 'info']
						"></tr>
				</table>
			</div>

			<label *ngIf="!materialInfo.parkingBikeStorageForm?.bikeStorageAvailable">
				There is no bike storage available on or near the property.
			</label>

			<div
				style="margin-top: 1.5rem"
				*ngIf="
					(materialInfo.parkingBikeStorageForm?.bikeStorage || []).length > 0
				">
				<label>Bike Storage</label>

				<table
					mat-table
					class="mat-elevation-z1"
					[dataSource]="materialInfo.parkingBikeStorageForm?.bikeStorage || []">
					<!--- Note that these columns can be defined in any order.
					  The actual rendered columns are set as a property on the row definition" -->

					<!-- Name Column -->
					<ng-container matColumnDef="type">
						<th mat-header-cell *matHeaderCellDef>Location</th>
						<td mat-cell *matCellDef="let element">
							{{ element.type }}
						</td>
					</ng-container>

					<!-- Weight Column -->
					<ng-container matColumnDef="cost">
						<th mat-header-cell *matHeaderCellDef>Cost Per Year</th>
						<td mat-cell *matCellDef="let element">
							<span *ngIf="element.cost && element.cost != '0'"></span>
							£{{ element.cost }}
						</td>
					</ng-container>

					<ng-container matColumnDef="numberOfSpots">
						<th mat-header-cell *matHeaderCellDef>Number Of Spots Available</th>
						<td mat-cell *matCellDef="let element">
							{{ element.numberOfSpots }}
						</td>
					</ng-container>

					<ng-container matColumnDef="info">
						<th mat-header-cell *matHeaderCellDef>Additional Info</th>
						<td mat-cell *matCellDef="let element">
							{{ element.notes }}
							<span *ngIf="!element.eBikes || element.cargoBikes">
								(
								<span *ngIf="!element.eBikes"
									>E-Bikes Not Allowed
									<span *ngIf="element.cargoBikes">, </span>
								</span>
								<span *ngIf="element.cargoBikes">Cargo Bikes Accessible</span>
								)</span
							>
						</td>
					</ng-container>

					<tr
						mat-header-row
						*matHeaderRowDef="['type', 'cost', 'numberOfSpots', 'info']"></tr>
					<tr
						mat-row
						*matRowDef="
							let row;
							columns: ['type', 'cost', 'numberOfSpots', 'info']
						"></tr>
				</table>
			</div>
		</mat-card-content>
	</mat-card>

	<mat-card
		id="buildingSafetyIssuesCard"
		*ngIf="materialInfo"
		class=""
		style="flex-wrap: nowrap; margin-top: 1rem">
		<mat-card-header>
			<mat-card-title>Building Safety Issues</mat-card-title>
		</mat-card-header>
		<mat-card-content style="width: 100%">
			<label *ngIf="!materialInfo.buildingSafetyIssuesForm?.anyKnownIssues">
				There is no known Building Safety Issues for this property.
			</label>

			<div
				style="margin-top: 1.5rem"
				*ngIf="
					(materialInfo.buildingSafetyIssuesForm?.issues || []).length > 0
				">
				<div
					*ngFor="
						let element of materialInfo.buildingSafetyIssuesForm?.issues || [];
						let i = index
					">
					<div
						*ngIf="i > 0"
						style="margin-top: 1rem; border-top: 1px solid #ccc">
						&nbsp;
					</div>
					<label>{{ element.type | appCapFirstLetters }}</label>
					<div style="margin-left: 3rem">
						<div>{{ element.additionalDetails }}</div>
						<div *ngIf="(element.stillNeedsFixing || '').length > 0">
							<label>Completed Remedies: </label>
							<span>{{ element.hasBeenFixed }}</span>
						</div>
						<div *ngIf="(element.stillNeedsFixing || '').length > 0">
							<label>In-Complete Remedies: </label>
							<span> {{ element.stillNeedsFixing }}</span>
						</div>
						<div
							*ngIf="element.outstandingCost && element.outstandingCost != '0'">
							<label>Outstanding Costs: </label>
							<span
								*ngIf="
									element.outstandingCost && element.outstandingCost != '0'
								">
								£{{ element.outstandingCost }}</span
							>
						</div>
						<div>
							<label>Effects the Habitable of the property?</label>
							<span *ngIf="element.canReside">Yes</span>
							<span *ngIf="!element.canReside">No</span>
						</div>
					</div>
				</div>
			</div>
		</mat-card-content>
	</mat-card>

	<mat-card
		id="rightsEasementsCard"
		*ngIf="materialInfo"
		class=""
		style="flex-wrap: nowrap; margin-top: 1rem">
		<mat-card-header>
			<mat-card-title>Rights Of Ways And Easements</mat-card-title>
		</mat-card-header>
		<mat-card-content style="width: 100%">
			<label *ngIf="!materialInfo.rightsAndEasementsForm?.rightsAndEasements">
				There are no known Rights Of Ways Or Easements for this property.
			</label>

			<div *ngIf="materialInfo.rightsAndEasementsForm?.rightsAndEasements">
				<label>There are Rights Of Ways Or Easements for this property.</label>
				<p>
					{{
						materialInfo.rightsAndEasementsForm?.rightsAndEasementsDetails || ""
					}}
				</p>
			</div>
		</mat-card-content>
	</mat-card>

	<mat-card
		id="accessibilityAdaptationsCard"
		*ngIf="materialInfo"
		class=""
		style="flex-wrap: nowrap; margin-top: 1rem">
		<mat-card-header>
			<mat-card-title>Accessibility/Adaptations</mat-card-title>
		</mat-card-header>
		<mat-card-content style="width: 100%">
			<div>
				<label>Step Free Access To The Property</label>
				<span *ngIf="materialInfo.accessibilityForm?.stepFreeAccess">
					Yes - Step-free access from the street to the inside the property
					(this can include ramps/lifts)
				</span>
				<span *ngIf="!materialInfo.accessibilityForm?.stepFreeAccess">
					No
				</span>
			</div>
			<div>
				<label>A Wet Room Or Level Access Shower</label>
				<span *ngIf="materialInfo.accessibilityForm?.wetRoom"> Yes </span>
				<span *ngIf="!materialInfo.accessibilityForm?.wetRoom"> No </span>
			</div>
			<div>
				<label>Essential Living Accommodation All On The Entrance Level</label>
				<span *ngIf="materialInfo.accessibilityForm?.lateralLiving">
					Yes - A single storey property, or a property that has essential
					facilities on the entrance level. There May be other rooms above the
					entrance level but a living room, kitchen, bathroom and at least one
					bedroom is located on the entry level.
				</span>
				<span *ngIf="!materialInfo.accessibilityForm?.lateralLiving"> No </span>
			</div>
			<div *ngIf="(materialInfo.accessibilityForm?.details || '').length > 0">
				<label>Additional Details</label>
				<div>
					{{ materialInfo.accessibilityForm?.details }}
				</div>
			</div>
		</mat-card-content>
	</mat-card>
	<mat-card
		*ngIf="materialInfo"
		class=""
		style="flex-wrap: nowrap; margin-top: 1rem">
		<mat-card-header>
			<mat-card-title>Next Steps</mat-card-title>
		</mat-card-header>
		<mat-card-content style="width: 100%">
			<div class="actions" style="gap: 1rem">
				<button mat-raised-button color="accent" (click)="requestViewing()">
					Request A Viewing
				</button>
				<button mat-raised-button (click)="askQuestion()">
					Ask A Question
				</button>

				<button mat-raised-button color="primary" (click)="submitOffer()">
					Submit An Offer
				</button>
			</div>
		</mat-card-content>
	</mat-card>
	<div style="height: 10rem"></div>
</div>
