<div class="container">
	<h1 class="header">About</h1>
	<mat-card appearance="outlined" style="margin: 3rem 0">
		<mat-card-content>
			<h3>OUR STORY</h3>
			<p>
				In 2017 the founders bought their first home, but it almost didn’t
				happen. The issue wasn’t finding a house to buy, although that wasn’t
				easy. Nor was it coming to an agreement with the seller. It was the
				chain of houses that all needed to be bought and sold on the same day.
			</p>
			<p>
				UK property chains are not a new thing, but they are unique to the UK.
				Whilst they may solve a problem they also add immense frustration and
				complexity to a transactional process.
			</p>
			<p>Clear Chain is here to help resolve this.</p>
		</mat-card-content>
	</mat-card>
	<div class="community">
		<h3>OUR COMMUNITY</h3>
		<div class="community-people">
			<div class="community-person">
				<img class="person-img" src="assets/Icon Buyer.png" alt="avatar" />
				<div class="community-person-info">
					<h4>BUYERS</h4>
					<p>
						Know all the steps from the start. Understand how the rest of the
						chain is progressing. Get into your new home faster.
					</p>
				</div>
			</div>
			<div class="community-person">
				<img
					class="person-img"
					src="assets/Icon Estate Agent.png"
					alt="avatar" />
				<div class="community-person-info">
					<h4>ESTATE AGENTS</h4>
					<p>
						One place to track your post offer sales. No more chasing others for
						status updates and arranging completion dates. Increased security of
						the sale going through.
					</p>
				</div>
			</div>
			<div class="community-person">
				<img class="person-img" src="assets/Icon Seller.png" alt="avatar" />
				<div class="community-person-info">
					<h4>SELLERS</h4>
					<p>
						Understand where your buyer is in the process and if there are any
						issues. Get the complete picture of the property chain.
					</p>
				</div>
			</div>
			<div class="community-person">
				<img
					class="person-img"
					src="assets/Icon Conveyancer.png"
					alt="avatar" />
				<div class="community-person-info">
					<h4>CONVEYANCERS</h4>
					<p>
						Keep your clients up to date and happy while organising your
						workload.
					</p>
				</div>
			</div>
			<div class="community-person">
				<img
					class="person-img"
					src="assets/Icon Mortgage Broker.png"
					alt="avatar" />
				<div class="community-person-info">
					<h4>MORTGAGE BROKERS</h4>
					<p>
						Shorter times to completion mean less broken chains. A secure place
						to exchange documents and track the chain.
					</p>
				</div>
			</div>
		</div>
	</div>
	<mat-card appearance="outlined" style="margin: 3rem 0">
		<mat-card-content>
			<h3>CLEAR CHAIN</h3>
			<p>
				Clear Chain is designed to help property owners and agents manage their
				property chains. It provides a comprehensive view of the entire chain,
				from the initial purchase to the final sale. The app also allows users
				to track progress on each transaction, set reminders for important dates
				and tasks.
			</p>
			<p>
				The app is easy to use, allowing users to quickly find the information
				they need. It also offers a range of customisable features, such as
				alerts for when a chain is at risk of breaking down or when a property
				has been sold.
			</p>
			<p>
				The app is available on all web enabled devices, so you can access it
				wherever you are. With its comprehensive features and intuitive design,
				Clear Chain makes managing your property chains easier than ever before.
			</p>
		</mat-card-content>
	</mat-card>
	<mat-card appearance="outlined">
		<mat-card-content class="update">
			<p>
				Your current version of the app is {{ version }}. <br />Clear Chain is
				updated regularly to improve the app and fix bugs. <br />You can check
				for updates by clicking the button below.
			</p>
			<p>
				<button mat-raised-button color="primary" (click)="checkForUpdate()">
					Check for updates
				</button>
			</p>
			<p>
				When a new update is ready to install, you will be notified in the top
				right hand corner.

				<br />
				You can then choose to update now or it will wait for the next refresh.
			</p></mat-card-content
		>
	</mat-card>
</div>
