<mat-card
	appearance="outlined"
	[ngClass]="{
		'card-disabled': state == 'disabled',
		'card-completed': state == 'completed'
	}">
	<mat-card-header>
		<mat-card-title>Permissions</mat-card-title>
	</mat-card-header>
	<mat-card-content *ngIf="formOpen" [@inOutAnimation]>
		<table
			mat-table
			style="background-color: inherit"
			[dataSource]="viewers || []"
			class="mat-elevation-z0">
			<!--- Note that these columns can be defined in any order.
                  The actual rendered columns are set as a property on the row definition" -->

			<!-- Position Column -->
			<ng-container matColumnDef="name">
				<th mat-header-cell *matHeaderCellDef>Name</th>
				<td mat-cell *matCellDef="let element">
					{{ element.firstName }} {{ element.lastName }}
				</td>
			</ng-container>

			<!-- Name Column -->
			<ng-container matColumnDef="email">
				<th mat-header-cell *matHeaderCellDef>Email</th>
				<td mat-cell *matCellDef="let element">{{ element.email }}</td>
			</ng-container>

			<!-- Weight Column -->
			<ng-container matColumnDef="phone">
				<th mat-header-cell *matHeaderCellDef>Phone</th>
				<td mat-cell *matCellDef="let element">{{ element.phone }}</td>
			</ng-container>

			<!-- Symbol Column -->
			<ng-container matColumnDef="company">
				<th mat-header-cell *matHeaderCellDef>Company</th>
				<td mat-cell *matCellDef="let element">{{ element.company }}</td>
			</ng-container>

			<ng-container matColumnDef="userType">
				<th mat-header-cell *matHeaderCellDef>Role</th>
				<td mat-cell *matCellDef="let element">{{ element.userType }}</td>
			</ng-container>

			<ng-container matColumnDef="rights">
				<th mat-header-cell *matHeaderCellDef>Rights</th>
				<td mat-cell *matCellDef="let element">
					{{ element.canEdit ? "Edit" : "View" }}
				</td>
			</ng-container>

			<tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
			<tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
		</table>
	</mat-card-content>
</mat-card>
