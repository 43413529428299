<div [formGroup]="form">
	<mat-form-field
		style="max-width: 8rem"
		appearance="outline"
		floatLabel="always">
		<mat-label> Postcode </mat-label>
		<input matInput placeholder="ABC 123" formControlName="postcode" required />
	</mat-form-field>
	<mat-form-field appearance="outline" floatLabel="always">
		<mat-label
			>Address
			<span *ngIf="loading">
				Loading <app-loading-dots></app-loading-dots>
			</span>
		</mat-label>
		<mat-select required formControlName="UPRN">
			<mat-option *ngFor="let option of addresses" [value]="option.value">{{
				option.label
			}}</mat-option>
		</mat-select>
	</mat-form-field>
	<div
		class="error"
		*ngIf="form.get('UPRN')?.errors?.['propertyInUse'] && form.get('UPRN')?.errors?.['noAccessToProperty']">
		This Property is already in a Chain you will need to request access to it.
		<button
			mat-raised-button
			type="button"
			color="warn"
			(click)="requestAccess(form.get('UPRN')?.value)">
			Request Access
		</button>
	</div>
	<div
		class="error"
		*ngIf="form.get('UPRN')?.errors?.['propertyInUse'] && !form.get('UPRN')?.errors?.['noAccessToProperty']">
		This Property is already in a Chain
		<button
			mat-raised-button
			type="button"
			color="accent"
			[routerLink]="[form.get('UPRN')?.errors?.['propertyInUse'].url]">
			Open Property
		</button>
	</div>
	<div
		class="error"
		*ngIf="form.get('UPRN')?.errors?.['propertyInUse'] && !form.get('UPRN')?.errors?.['noAccessToProperty'] && ((this.canMerge == 'seller' && !form.get('UPRN')?.errors?.['propertyInUse']?.hasSeller) || (this.canMerge == 'buyer' && !form.get('UPRN')?.errors?.['propertyInUse']?.hasBuyer))">
		Would you like to merge the two chains
		<button
			mat-raised-button
			type="button"
			color="accent"
			(click)="mergeChains(form.get('UPRN')?.errors?.['propertyInUse']?.chainId)">
			Merge Chains
		</button>
	</div>

	<div
		class="warning"
		*ngIf="
			addresses.length == 0 &&
			!loading &&
			form.get('postcode')?.value?.length > 4
		">
		* No addresses found in the postal code you entered.
	</div>
</div>
