import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
export interface NewDateDate {
	name: string;
}
@Component({
	selector: 'app-new-date-dialog',
	templateUrl: './new-date-dialog.component.html',
	styleUrls: ['./new-date-dialog.component.css'],
})
export class NewDateDialogComponent {
	constructor(@Inject(MAT_DIALOG_DATA) public data: NewDateDate) {}
	newDate: Date | undefined = undefined;
	changeNewDate(date: Date) {
		this.newDate = date;
	}
}
