<div class="container">
	<h1 class="header">Chains</h1>
	<!-- <div class="center-center" *ngIf="loading">
		<mat-spinner></mat-spinner>
	</div> -->
	<app-loading-tombstone *ngIf="loading"></app-loading-tombstone>
	<mat-card appearance="outlined" *ngIf="!loading && chains?.length == 0">
		<mat-card-content>
			<div class="center">
				You have no chains. Add a new chain to get started.
			</div>
		</mat-card-content>
	</mat-card>
	<mat-card
		style="flex-wrap: nowrap; margin-top: 1rem"
		class="chain-card-wrap"
		*ngFor="let chain of chains; trackBy: fnTrackBy"
		(click)="goto(chain)">
		<mat-card-content class="chain-card">
			<div class="chain-card-content">
				<div class="chain-icons">
					<!-- <a
						routerLinkActive="active-prop"
						[routerLink]="['overview']"
						style="
							width: 2.5rem;
							height: 2.5rem;
							display: flex;
							align-items: baseline;
							justify-content: center;
							background-color: #80808033;
						">

						<img
							style="width: 1.5rem"
							src="/assets/figma-icons/akar-icons_link-chain.svg"
							alt="chain" />
					</a>

					<mat-divider style="height: 2rem" [vertical]="true"></mat-divider> -->
					<ng-container
						*ngFor="
							let link of chain?.links || [];
							let first = first;
							let last = last
						">
						<a
							class="item"
							routerLinkActive="active-prop"
							[class.purple-background-trans]="link.linkType != 'property'"
							[class.me-prop]="me(link)"
							[class.unknown]="link.id == 'unknown'"
							[class.red]="
								link.sellingPropertyState == 'noSelection' ||
								link.buyingPropertyState == 'noSelection'
							"
							[class.red-background-trans]="
								link.sellingPropertyState == 'noSelection' ||
								link.buyingPropertyState == 'noSelection' ||
								link.id == 'unknown'
							"
							[routerLink]="likeUrl(chain, link)">
							<mat-icon *ngIf="link.linkType !== 'property'">{{
								link.id == "unknown" ? "person_add" : "person"
							}}</mat-icon>
							<!-- <mat-icon
							*ngIf="link.linkType == 'property'"
							[matTooltip]="link.address"
							>house</mat-icon
						> -->
							<mat-icon
								style="
									line-height: 1rem;
									font-size: 1rem;
									width: 1rem;
									position: absolute;
									top: 0rem;
									left: 0rem;
									font-weight: 600;
								"
								*ngIf="link.linkType == 'property' && link.id == 'unknown'"
								>add</mat-icon
							>
							<img
								*ngIf="link.linkType == 'property'"
								[matTooltip]="link.nickName"
								style="width: 1.5rem"
								[ngClass]="{ 'unknown-prop': link.id == 'unknown' }"
								[src]="logoURL(link)"
								alt="house" />
							<div
								*ngIf="link.linkType != 'property'"
								class="pill purple-background"
								[class.red-background]="
									link.sellingPropertyState == 'noSelection' ||
									link.buyingPropertyState == 'noSelection' ||
									link.id == 'unknown'
								">
								{{ last ? "Selling" : "Buying" }}
							</div>
							<div
								*ngIf="link.linkType == 'property'"
								class="pill green-background"
								[class.red-background]="
									link.sellingPropertyState == 'noSelection' ||
									link.buyingPropertyState == 'noSelection' ||
									link.id == 'unknown'
								">
								Sold By
							</div>
						</a>

						<!-- <mat-icon
							*ngIf="!last"
							class="arrow when-big"
							[class.green-text]="link.linkType == 'property'"
							[class.purple-text]="link.linkType != 'property'"
							[class.red-text]="
								link.sellingPropertyState == 'noSelection' ||
								link.buyingPropertyState == 'noSelection' ||
								link.id == 'unknown'
							"
							>south</mat-icon
						> -->
						<mat-icon
							*ngIf="!last"
							[class.green-text]="link.linkType == 'property'"
							[class.purple-text]="link.linkType != 'property'"
							[class.red-text]="
								link.sellingPropertyState == 'noSelection' ||
								link.buyingPropertyState == 'noSelection' ||
								link.id == 'unknown'
							"
							>east</mat-icon
						>
						<!-- <a routerLinkActive="active-prop" [routerLink]="[link.agents?'property':'buyer-seller',link.id]" >
						<mat-icon *ngIf="!link.partOfProp" [matTooltip]="link.address" >house</mat-icon>
						<img *ngIf="link.partOfProp" [matTooltip]="link.address" style="width:1.5rem;align-self: baseline;" src="/assets/logo-svg-sm.svg" alt="house">
					</a>
					<mat-icon>south</mat-icon> 
					<mat-icon>person</mat-icon> -->
					</ng-container>
				</div>

				<!-- <div class="spacer"></div> -->
				<div class="addresses">
					<div *ngFor="let property of propsFiltered(chain)" class="address">
						<div class="address-full">
							<ng-container *ngIf="property.nickName != property.UPRN">
								{{ property.address }}
							</ng-container>
						</div>

						<div>{{ property.nickName }}</div>
					</div>
				</div>
			</div>

			<!-- <div class="spacer"></div> -->
			<div class="menu">
				<button mat-icon-button (click)="goto(chain)">
					<mat-icon>launch</mat-icon>
				</button>
			</div>
		</mat-card-content>
	</mat-card>
</div>
