import { HttpClient } from '@angular/common/http';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { lastValueFrom } from 'rxjs';
import { environment } from 'src/environments/environment';
import {
	TaskStatus,
	Task,
	TaskResult,
	TaskType,
	TaskParentType,
	TaskBackendService,
} from '../services/task-backend.service';

@Component({
	selector: 'app-task-lists',
	templateUrl: './task-lists.component.html',
	styleUrls: ['./task-lists.component.css'],
})
export class TaskListsComponent implements OnInit {
	_taskList: Partial<Task>[] = [];
	@Input() state: TaskStatus = TaskStatus.inProgress;
	@Input() isAgent = false;
	@Input() set taskList(val: Partial<Task>[]) {
		if (!val) {
			this._taskList = [];
			return;
		}
		this._taskList =
			val
				?.filter((t) => !t.isTemplate)
				?.sort((a, b) => {
					if (b.order !== undefined && a.order !== undefined)
						return <any>a.order - <any>b.order;
					let tA = 0;
					let tB = 0;

					if (a.status == TaskStatus.disabled) tA = 1;
					if (b.status == TaskStatus.disabled) tB = 1;
					if (a.status == TaskStatus.completed) tA = 2;
					if (b.status == TaskStatus.completed) tB = 2;

					if (a.isTemplate) tA = 3;
					if (b.isTemplate) tB = 3;

					if (tA != tB) return tA - tB;

					return <any>b.dateCreated - <any>a.dateCreated;
				}) || [];
	}
	get taskList(): Partial<Task>[] {
		if (this.hideShow) return this._taskList || [];
		else return this._taskList || [];
	}

	get inProgressTasks(): Partial<Task>[] {
		return (
			this.taskList
				?.filter((t) => t.status == TaskStatus.inProgress)
				?.sort((a, b) => {
					return <any>b.dateCreated - <any>a.dateCreated;
				}) || []
		);
	}

	get notStartedTasks(): Partial<Task>[] {
		return (
			this.taskList
				?.filter((t) => t.status == TaskStatus.notStarted)
				?.sort((a, b) => {
					return <any>b.dateCreated - <any>a.dateCreated;
				}) || []
		);
	}

	get disabledTasks(): Partial<Task>[] {
		return (
			this.taskList
				?.filter((t) => t.status == TaskStatus.disabled)
				?.sort((a, b) => {
					return <any>b.dateCreated - <any>a.dateCreated;
				}) || []
		);
	}

	get completedTasks(): Partial<Task>[] {
		return (
			this.taskList
				?.filter((t) => t.status == TaskStatus.completed)
				?.sort((a, b) => {
					return <any>b.dateCreated - <any>a.dateCreated;
				}) || []
		);
	}

	get cancelledTasks(): Partial<Task>[] {
		return (
			this.taskList
				?.filter((t) => t.status == TaskStatus.cancelled)
				?.sort((a, b) => {
					return <any>b.dateCreated - <any>a.dateCreated;
				}) || []
		);
	}

	@Input() set defaultHideShow(val: boolean) {
		this.hideShow = val;
	}
	@Output() taskChanged = new EventEmitter<Partial<Task>>();
	@Output() taskCompleted = new EventEmitter<Partial<Task>>();
	@Output() taskInprogress = new EventEmitter<Partial<Task>>();
	@Output() taskNotStarted = new EventEmitter<Partial<Task>>();

	hideShow = false;
	TaskStatus = TaskStatus;
	TaskResult = TaskResult;
	TaskType = TaskType;

	loadingSet = new Set();
	loading(id: string) {
		return this.loadingSet.has(id);
	}

	constructor(public Router: Router, public TaskBackend: TaskBackendService) {}

	ngOnInit(): void {}

	// async complete(task: Task) {
	// 	await this.changeResult(task, TaskResult.success);
	// }

	// async changeResult(task: Task, result: TaskResult) {
	// 	if (!result) return;
	// 	//update the task in db
	// 	this.loadingSet.add(task.id);
	// 	let R = <any>await lastValueFrom(
	// 		this.Http.patch(
	// 			`${environment.apiUrl}/task/${task.id}`,
	// 			{
	// 				result: result,
	// 				status: TaskStatus.completed,
	// 			},
	// 			{
	// 				withCredentials: true,
	// 			}
	// 		)
	// 	);
	// 	console.log('R', R);
	// 	this.taskChanged.emit(R);
	// 	this.loadingSet.delete(task.id);
	// }

	get progress() {
		let total = this.taskList.reduce((acc, task) => {
			return acc + (task.status == TaskStatus.completed ? 1 : 0);
		}, 0);
		return Math.round((100 * total) / this.taskList.length);
	}

	get buffer() {
		let total = this.taskList.reduce((acc, task) => {
			return (
				acc +
				(task.status == TaskStatus.notStarted ||
				task.status === TaskStatus.inProgress ||
				task.status === TaskStatus.completed
					? 1
					: 0)
			);
		}, 0);
		return Math.round((100 * total) / this.taskList.length);
	}

	taskInProgressCount(task: Partial<Task>) {
		if (!task.children || task.children.length < 1) return 0;
		let total = task.children.reduce((acc, cTask) => {
			return acc + (cTask.status == TaskStatus.inProgress ? 1 : 0);
		}, 0);
		return total;
	}

	taskCompletedCount(task: Partial<Task>) {
		if (!task.children || task.children.length < 1) return 0;
		let total = task.children.reduce((acc, cTask) => {
			return acc + (cTask.status == TaskStatus.completed ? 1 : 0);
		}, 0);
		return total;
	}

	taskNotStartedCount(task: Partial<Task>) {
		if (!task.children || task.children.length < 1) return 0;
		let total = task.children.reduce((acc, cTask) => {
			return acc + (cTask.status == TaskStatus.notStarted ? 1 : 0);
		}, 0);
		return total;
	}

	taskDisabledCount(task: Partial<Task>) {
		if (!task.children || task.children.length < 1) return 0;
		let total = task.children.reduce((acc, cTask) => {
			return acc + (cTask.status == TaskStatus.disabled ? 1 : 0);
		}, 0);
		return total;
	}

	taskProgress(task: Partial<Task>) {
		if (!task.children || task.children.length < 1) return 0;
		let total = task.children.reduce((acc, cTask) => {
			return acc + (cTask.status == TaskStatus.completed ? 1 : 0);
		}, 0);
		return Math.round((100 * total) / task.children.length);
	}

	taskBuffer(task: Partial<Task>) {
		if (!task.children || task.children.length < 1) return 0;
		let total = task.children.reduce((acc, cTask) => {
			return (
				acc +
				(cTask.status == TaskStatus.notStarted ||
				cTask.status === TaskStatus.inProgress ||
				cTask.status === TaskStatus.completed
					? 1
					: 0)
			);
		}, 0);
		return Math.round((100 * total) / task.children.length);
	}

	taskResultDetails(task: Partial<Task>) {
		if (task.taskType != TaskType.decider) return;
		let r = task.options?.find((o) => o.value == task.result);
		if (!r) return;
		return r.description || r.label;
	}

	openTask(task: Partial<Task>) {
		if (task.taskType == TaskType.milestone) {
			if (task.parentType == TaskParentType.Property) {
				this.Router.navigate([
					'/chain',
					task.chainId,
					'property',
					task.parentId,
					'milestone',
					task.id,
				]);
				return;
			}
		}
		if (task.taskType != TaskType.milestone) {
			if (task.parentType == TaskParentType.Task) {
				if (task.parentId == task.milestoneId) {
					this.Router.navigate([
						'/chain',
						task.chainId,
						'property',
						task.propertyId || '',
						'milestone',
						task.parentId,
						'task',
						task.id,
					]);
					return;
				} else {
					this.Router.navigate([
						'/chain',
						task.chainId,
						'property',
						task.propertyId || '',
						'milestone',
						task.milestoneId,
						'task',
						task.parentId,
						'subtask',
						task.id,
					]);
					return;
				}
			}
		}
	}

	async markAsInprogress(event: any, task: Partial<Task>) {
		if (event?.stopPropagation) event?.stopPropagation();
		this.taskInprogress.emit(task);
	}

	async markAsNoStarted(event: any, task: Partial<Task>) {
		if (event?.stopPropagation) event?.stopPropagation();
		this.taskNotStarted.emit(task);
	}

	async markAsComplete(event: any, task: Partial<Task>) {
		if (event?.stopPropagation) event?.stopPropagation();
		this.taskCompleted.emit(task);
	}

	updateSet = new Set<symbol>();
	async _markAsInprogress(event: any, task: Partial<Task>) {
		//event.preventDefault();
		let mySymbol = Symbol();
		this.updateSet.add(mySymbol);
		if (event?.stopPropagation) event?.stopPropagation();
		task.status = TaskStatus.inProgress;
		await this.TaskBackend.patch(task.id || '', {
			status: TaskStatus.inProgress,
		});

		await this.TaskBackend.addNote(
			task.id || '',
			'Task status changed to In Progress.',
			'system'
		);
		this.updateSet.delete(mySymbol);
		if (this.updateSet.size == 0) this.taskChanged.emit(task);
	}

	async _markAsComplete(event: any, task: Partial<Task>) {
		//event.preventDefault();
		let mySymbol = Symbol();
		this.updateSet.add(mySymbol);
		if (event?.stopPropagation) event?.stopPropagation();
		task.status = TaskStatus.completed;
		task.result = TaskResult.success;
		await this.TaskBackend.patch(task.id || '', {
			status: TaskStatus.completed,
			result: TaskResult.success,
		});

		await this.TaskBackend.addNote(
			task.id || '',
			'Task status changed to Completed.',
			'system'
		);

		this.updateSet.delete(mySymbol);
		if (this.updateSet.size == 0) this.taskChanged.emit(task);
	}

	taskHasWarnings(task: Partial<Task>) {
		return task.warnings?.some((warning) => !warning.dismissedBy);
	}

	trackByFn(index: number, task: Partial<Task>): any {
		return task?.id || index;
	}
}
