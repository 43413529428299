<button
	*ngIf="!!text && text.length > 0"
	mat-button
	type="button"
	(click)="startSelect()"
	[disabled]="disabled">
	{{ text }}</button
><button
	mat-icon-button
	type="button"
	(click)="startSelect()"
	[disabled]="disabled">
	<mat-icon>attach_file</mat-icon>
</button>

<!-- <button (click)="other()">test</button> -->
<input
	#fileInput
	hidden
	type="file"
	(click)="$event.target.value = $any(null)"
	value=""
	(onFileSelected)="onFileChange($event)"
	[disabled]="disabled"
	[accept]="accept"
	multiple />
