import { CommonModule } from '@angular/common';
import {
	ChangeDetectionStrategy,
	ChangeDetectorRef,
	Component,
} from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';
import { switchMap } from 'rxjs';
import { MaterialInfoBackendService } from 'src/app/services/materialInfoBackend.service';

@Component({
	selector: 'app-docs-viewer',
	templateUrl: './docs-viewer.component.html',
	styleUrls: ['./docs-viewer.component.css'],
})
export class DocsViewerComponent {
	$prams: any;
	loading: boolean = false;
	propertyId: string = '';
	materialId: string = '';
	docId: string = '';
	imgs: { url: string; page: number }[] = [];

	constructor(
		public route: ActivatedRoute,
		public materialInfoBackendService: MaterialInfoBackendService,
		public domSanitizer: DomSanitizer,
		public cd: ChangeDetectorRef
	) {
		if (this.$prams?.unsubscribe) this.$prams.unsubscribe();
		this.$prams = this.route.params
			.pipe(
				switchMap(async (params: any) => {
					this.loading = true;
					this.propertyId = params['propertyId'];
					this.materialId = params['materialId'];
					this.docId = params['docId'];
					await this.loadImgs();
					return params;
				})
			)
			.subscribe(async (params: any) => {
				this.docId = params['docId'];
			});
	}

	ngOnDestroy() {
		if (this.$prams) {
			this.$prams.unsubscribe();
		}
	}

	async loadImgs() {
		let res = await this.materialInfoBackendService.getDocumentDownloadUrl(
			this.propertyId,
			this.materialId,
			this.docId,
			'',
			'public'
		);
		if (res.data) {
			this.imgs = res.data.pages || [];
			this.imgs.forEach((img) => {
				return this.transform(img.url) as string;
			});
			//sort by page
			this.imgs = this.imgs.sort((a, b) => a.page - b.page);
			if (this.imgs.length > 0 && res.data.allReactions) this.loading = false;
			else setTimeout(() => this.loadImgs(), 2000);
			this.cd.detectChanges();
		}
	}

	transform(url: string | undefined) {
		if (url && url.length > 0)
			return this.domSanitizer.bypassSecurityTrustResourceUrl(url);
		return '';
	}

	trackByFn(index: number, item: any) {
		return item.page;
	}
}
