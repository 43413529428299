<h1 mat-dialog-title>New Inquiry</h1>
<mat-dialog-content
	style="
		display: flex;
		justify-content: center;
		flex-wrap: wrap;

		padding: 1rem;
		gap: 0 1rem;
	"
	[formGroup]="inquiryForm">
	<div class="row">
		<mat-form-field
			appearance="outline"
			floatLabel="always"
			style="width: 100%">
			<mat-label>Inquiry</mat-label>
			<textarea
				style="min-height: 15rem"
				matInput
				placeholder="Question, Clarification or Additional Information needed..."
				name="inquiry"
				formControlName="inquiry"
				required></textarea>
		</mat-form-field>
	</div>
	<div class="row">
		<mat-form-field appearance="outline" floatLabel="always" style="width: 50%">
			<mat-label>Assigned To</mat-label>
			<mat-select
				appearance="outline"
				floatLabel="always"
				placeholder="Assigned To"
				name="status"
				formControlName="userType"
				required>
				<mat-option value="Select Who Should Respond" disabled="true">
					Select Who Should Respond
				</mat-option>
				<mat-option *ngFor="let user of userTypes" [value]="user">
					{{ user }}
				</mat-option>
			</mat-select>
		</mat-form-field>
	</div>
</mat-dialog-content>
<mat-dialog-actions style="justify-content: end">
	<button
		mat-button
		[mat-dialog-close]="false"
		type="button"
		[disabled]="saving">
		Cancel
	</button>
	<button
		mat-button
		(click)="save()"
		[disabled]="
			inquiryForm.invalid || inquiryForm.pristine || inquiryForm.disabled
		"
		type="button"
		cdkFocusInitial>
		<span *ngIf="!saving">Add</span>
		<span *ngIf="saving">Saving<app-loading-dots></app-loading-dots></span>
	</button>
</mat-dialog-actions>
