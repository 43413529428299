<mat-stepper orientation="vertical" #stepper linear>
	<ng-template matStepperIcon="info">
		<mat-icon>info</mat-icon>
	</ng-template>

	<ng-template matStepperIcon="house">
		<mat-icon>house</mat-icon>
	</ng-template>

	<ng-template matStepperIcon="selling">
		<mat-icon>real_estate_agent</mat-icon>
	</ng-template>

	<ng-template matStepperIcon="buying">
		<mat-icon>monetization_on</mat-icon>
	</ng-template>

	<ng-template matStepperIcon="mortgage">
		<mat-icon>account_balance</mat-icon>
	</ng-template>

	<mat-step state="buying" [stepControl]="buyerInfoForm">
		<ng-template matStepLabel>Buyer Information</ng-template>
		<form fxLayout="column" [formGroup]="buyerInfoForm">
			<div class="row row-bottom-padding">
				<app-people
					[form]="$any(buyerInfoForm.get('buyers'))"
					title="Buyer/s"></app-people>
			</div>
			<div class="row row-bottom-padding">
				<label id="doesBuyerHaveConveyancer-label">
					Do the buyer/s have a conveyancer you know about?
				</label>
				<mat-radio-group
					aria-labelledby="doesBuyerHaveConveyancer-label"
					formControlName="doesBuyerHaveConveyancer">
					<mat-radio-button value="Y">Yes</mat-radio-button>
					<mat-radio-button value="N">No</mat-radio-button>
				</mat-radio-group>
			</div>
			<div
				class="row row-bottom-padding"
				*ngIf="buyerInfoForm.get('conveyancer')">
				<app-people
					[form]="$any(buyerInfoForm.get('conveyancer'))"
					[onlyOne]="true"
					title="Conveyancer"></app-people>
			</div>
			<div fxLayout="row" fxLayoutAlign="end center">
				<button mat-button (click)="back.emit()" type="button">Back</button>
				<button
					mat-raised-button
					color="primary"
					[disabled]="buyerInfoForm.invalid"
					matStepperNext>
					Next
				</button>
			</div>
		</form>
	</mat-step>

	<mat-step state="house" [stepControl]="propertyInfoForm">
		<ng-template matStepLabel>Property Information</ng-template>
		<form fxLayout="column" [formGroup]="propertyInfoForm">
			<div class="row row-bottom-padding">
				<label id="offerAccepted-label"> Has an offer been accepted? </label>
				<mat-radio-group
					aria-labelledby="offerAccepted-label"
					formControlName="offerAccepted">
					<mat-radio-button value="Y">Yes</mat-radio-button>
					<mat-radio-button value="N">No</mat-radio-button>
				</mat-radio-group>
			</div>
			<div
				class="row row-bottom-padding"
				*ngIf="propertyInfoForm.get('address')">
				<app-address
					[form]="$any(propertyInfoForm.get('address'))"></app-address>
			</div>
			<div
				class="row row-bottom-padding"
				*ngIf="
					propertyInfoForm.get('dateOfferAccepted') &&
					propertyInfoForm.get('agreedSellingPrice')
				">
				<mat-form-field appearance="outline" floatLabel="always">
					<mat-label>Date Offer Accepted</mat-label>
					<input
						matInput
						formControlName="dateOfferAccepted"
						[matDatepicker]="dp" />
					<mat-datepicker-toggle matSuffix [for]="dp"></mat-datepicker-toggle>
					<mat-datepicker #dp></mat-datepicker>
				</mat-form-field>

				<mat-form-field appearance="outline" floatLabel="always">
					<mat-label>Agreed Selling Price</mat-label>
					<span matTextPrefix>£&nbsp;</span>
					<input
						matInput
						autocomplete="off"
						[appMoneyInput]
						formControlName="agreedSellingPrice"
						type="string" />
				</mat-form-field>
			</div>
			<div
				class="row row-bottom-padding"
				*ngIf="propertyInfoForm.get('sellingAgentKnown')">
				<label id="offerAccepted-label">
					Do you know who the selling estate agent is?
				</label>
				<mat-radio-group
					aria-labelledby="sellingAgentKnown-label"
					formControlName="sellingAgentKnown">
					<mat-radio-button value="Y">Yes</mat-radio-button>
					<mat-radio-button value="N">No</mat-radio-button>
				</mat-radio-group>
			</div>
			<div
				class="row row-bottom-padding"
				*ngIf="propertyInfoForm.get('sellingAgent')">
				<app-people
					[form]="$any(propertyInfoForm.get('sellingAgent'))"
					[onlyOne]="true"
					title="Selling Estate Agent"></app-people>
			</div>
			<div fxLayout="row" fxLayoutAlign="end center">
				<button mat-button matStepperPrevious type="button">Back</button>
				<button
					mat-raised-button
					color="primary"
					[disabled]="propertyInfoForm.invalid"
					matStepperNext>
					Next
				</button>
			</div>
		</form>
	</mat-step>
	<mat-step state="mortgage" [stepControl]="mortgageInfoForm">
		<ng-template matStepLabel>Mortgage Information</ng-template>
		<form
			fxLayout="column"
			[formGroup]="mortgageInfoForm"
			(ngSubmit)="submitForm()">
			<div class="row row-bottom-padding">
				<label id="offerAccepted-label">
					Has the buyer been pre-approved for a mortgage?
				</label>
				<mat-radio-group
					aria-labelledby="preApproved-label"
					formControlName="preApproved">
					<mat-radio-button value="Y">Yes</mat-radio-button>
					<mat-radio-button value="N">No</mat-radio-button>
				</mat-radio-group>
			</div>
			<div
				class="row row-bottom-padding"
				*ngIf="
					mortgageInfoForm.get('preApprovedAmount') &&
					mortgageInfoForm.get('preApprovedDate')
				">
				<mat-form-field appearance="outline" floatLabel="always">
					<mat-label>Date Of Pre-Approval</mat-label>
					<input
						matInput
						formControlName="preApprovedDate"
						[matDatepicker]="dp" />
					<mat-datepicker-toggle matSuffix [for]="dp"></mat-datepicker-toggle>
					<mat-datepicker #dp></mat-datepicker>
				</mat-form-field>

				<mat-form-field appearance="outline" floatLabel="always">
					<mat-label>Pre-Approved Amount</mat-label>
					<span matTextPrefix>£&nbsp;</span>
					<input
						matInput
						autocomplete="off"
						[appMoneyInput]
						formControlName="preApprovedAmount"
						type="string" />
				</mat-form-field>
			</div>
			<div
				class="row row-bottom-padding"
				*ngIf="
					mortgageInfoForm.get('preApprovedExpiry') &&
					mortgageInfoForm.get('preApprovedLender')
				">
				<mat-form-field appearance="outline" floatLabel="always">
					<mat-label>Pre-Approval Expiration</mat-label>
					<input
						matInput
						formControlName="preApprovedExpiry"
						[matDatepicker]="dp" />
					<mat-datepicker-toggle matSuffix [for]="dp"></mat-datepicker-toggle>
					<mat-datepicker #dp></mat-datepicker>
				</mat-form-field>

				<mat-form-field appearance="outline" floatLabel="always">
					<mat-label>Pre-Approval Lender</mat-label>
					<input
						matInput
						autocomplete="off"
						formControlName="preApprovedLender"
						type="string" />
				</mat-form-field>
			</div>
			<div fxLayout="row" fxLayoutAlign="end center">
				<button mat-button matStepperPrevious type="button">Back</button>
				<button
					mat-raised-button
					color="primary"
					type="submit"
					[disabled]="mortgageInfoForm.invalid"
					matStepperNext>
					Next
				</button>
			</div>
		</form>
	</mat-step>
</mat-stepper>
