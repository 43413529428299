<h1 mat-dialog-title>
	{{ _data.actions == "edit" ? "Edit" : "Add" }} Building Safety Issue
</h1>
<mat-dialog-content style="max-width: 600px">
	<form
		[formGroup]="form"
		style="
			display: flex;
			justify-content: center;
			flex-wrap: wrap;
			max-width: 600px;
			padding: 1rem;
			gap: 0 1rem;
		">
		<mat-form-field
			appearance="outline"
			floatLabel="always"
			style="margin-bottom: 0.5rem">
			<mat-label>Type</mat-label>
			<mat-select formControlName="type">
				<mat-option
					*ngFor="let item of types; let i = index"
					[value]="item.value"
					>{{ item.value }}</mat-option
				>
			</mat-select>
			<!-- [appMoneyInput] -->
			<mat-hint>{{ typeDetails }}</mat-hint>
		</mat-form-field>
		<mat-form-field
			appearance="outline"
			floatLabel="always"
			style="width: 100%">
			<mat-label>Additional information</mat-label>
			<textarea
				style="width: 100%"
				matInput
				type="text"
				placeholder=""
				formControlName="additionalDetails"></textarea>
		</mat-form-field>
		<mat-form-field
			appearance="outline"
			floatLabel="always"
			style="width: 100%">
			<mat-label
				>What Work Has Already Been Done To Remedy This Issue?</mat-label
			>
			<textarea
				style="width: 100%"
				matInput
				type="text"
				placeholder=""
				formControlName="hasBeenFixed"></textarea>
		</mat-form-field>
		<mat-form-field
			appearance="outline"
			floatLabel="always"
			style="width: 100%">
			<mat-label
				>What Work Still Needs To Be Done To Remedy This Issue?</mat-label
			>
			<textarea
				style="width: 100%"
				matInput
				type="text"
				placeholder=""
				formControlName="stillNeedsFixing"></textarea>
		</mat-form-field>
		<mat-form-field appearance="outline" floatLabel="always">
			<mat-label>Total Cost Renaming To Remedy This Issue</mat-label>
			<span matTextPrefix>£&nbsp;</span>
			<input
				matInput
				type="string"
				[appMoneyInput]
				placeholder="I.E. 600 or 0 if free"
				required
				formControlName="outstandingCost" />
			<!-- [appMoneyInput] -->
		</mat-form-field>
		<div>
			<label
				>Is This Issue Affecting The Buyer’s Ability To Reside Within The
				Property?</label
			>
			<mat-radio-group formControlName="canReside">
				<mat-radio-button [value]="true">Yes</mat-radio-button>
				<mat-radio-button [value]="false">No</mat-radio-button>
			</mat-radio-group>
		</div>
	</form>
</mat-dialog-content>
<mat-dialog-actions>
	<button mat-button [mat-dialog-close]="false" type="button">Cancel</button>
	<button
		mat-button
		[mat-dialog-close]="form.value"
		[disabled]="form.disabled || form.invalid"
		type="button"
		cdkFocusInitial>
		Save
	</button>
</mat-dialog-actions>
