<h1 mat-dialog-title>
	{{ _data.actions == "edit" ? "Edit" : "Add" }} Parking Location
</h1>
<mat-dialog-content style="max-width: 600px">
	<form
		[formGroup]="form"
		style="
			display: flex;
			justify-content: center;
			flex-wrap: wrap;
			max-width: 600px;
			padding: 1rem;
			gap: 0 1rem;
		">
		<mat-form-field appearance="outline" floatLabel="always">
			<mat-label>Location</mat-label>
			<mat-select formControlName="type">
				<mat-option value="Under Ground Garage">Under Ground Garage</mat-option>
				<mat-option value="Garage">Garage</mat-option>
				<mat-option value="Off Street On Property"
					>Off Street On Property</mat-option
				>
				<mat-option value="Off Street Near Property"
					>Off Street Near Property</mat-option
				>
				<mat-option value="On Street">On Street</mat-option>
			</mat-select>
			<!-- [appMoneyInput] -->
		</mat-form-field>
		<mat-form-field appearance="outline" floatLabel="always">
			<mat-label>Number Of Available Spots</mat-label>
			<mat-select formControlName="numberOfSpots">
				<mat-option value="1">1</mat-option>
				<mat-option value="2">2</mat-option>
				<mat-option value="3">3</mat-option>
				<mat-option value="4">4</mat-option>
				<mat-option value="More Then 5">5+</mat-option>
			</mat-select>
			<!-- [appMoneyInput] -->
		</mat-form-field>
		<mat-form-field appearance="outline" floatLabel="always">
			<mat-label>Annual Cost</mat-label>
			<span matTextPrefix>£&nbsp;</span>
			<input
				matInput
				type="string"
				[appMoneyInput]
				placeholder="I.E. 600 or 0 if free"
				required
				formControlName="cost" />
			<!-- [appMoneyInput] -->
		</mat-form-field>
		<mat-form-field
			appearance="outline"
			floatLabel="always"
			style="width: 100%">
			<mat-label>Notes/Details</mat-label>
			<textarea
				style="width: 100%"
				matInput
				type="text"
				placeholder="I.E. the parking spot is located in the back of the house. It is a tight spot."
				formControlName="notes"></textarea>
		</mat-form-field>
	</form>
</mat-dialog-content>
<mat-dialog-actions>
	<button mat-button [mat-dialog-close]="false" type="button">Cancel</button>
	<button
		mat-button
		[mat-dialog-close]="form.value"
		[disabled]="form.disabled || form.invalid"
		type="button"
		cdkFocusInitial>
		Save
	</button>
</mat-dialog-actions>
