<mat-card
	appearance="outlined"
	[ngClass]="{
		disabled: state == 'disabled',
		completed: state == 'completed'
	}">
	<mat-card-header>
		<mat-card-title>
			<!-- <h3> -->
			Inquiries ({{ inquiries.length }})
			<!-- </h3>
			<div class="spacer"></div>
			<div>
				<button mat-icon-button (click)="formOpen = !formOpen">
					<mat-icon>{{ formOpen ? "expand_less" : "expand_more" }}</mat-icon>
				</button>
			</div> -->
			<mat-icon
				#toolTip="matTooltip"
				style="cursor: pointer"
				(click)="toolTip.toggle()"
				matTooltip="Inquiries are questions asked to another party.  The people its assigned to will be notified and can respond.  A back and forth conversation can happen and when the originator is satisfied they can mark it as resolved."
				>info</mat-icon
			>
		</mat-card-title>
	</mat-card-header>
	<mat-card-content *ngIf="formOpen" [@inOutAnimation]>
		<app-inquiry
			*ngFor="let inquiry of inquiries; trackBy: trackByFn; let i = index"
			[inquiry]="inquiry"
			[canEdit]="canEdit && !inquiry.filtered"
			(newResponse)="respond(inquiry.id, $event)"
			(resolved)="resolve(inquiry.id)"
			(unresolved)="unResolve(inquiry.id)"
			(deleted)="delete(inquiry.id)">
		</app-inquiry>
		<div *ngIf="inquiries.length == 0" style="text-align: center">
			No Inquiries at this time.
		</div>
		<div
			*ngIf="canEdit && (state == 'notStarted' || state == 'inProgress')"
			style="text-align: center">
			<button
				mat-raised-button
				style="width: 100%; max-width: 25rem"
				color="primary"
				(click)="newInquiry()">
				New Inquiry
			</button>
			<!-- <label class="box-label">New Inquiry</label>
			<div class="row">
				<mat-form-field
					appearance="outline"
					floatLabel="always"
					style="width: 100%">
					<mat-label>Inquiry</mat-label>
					<textarea
						matInput
						placeholder="Question, Clarification or Additional Information needed..."
						name="inquiry"
						formControlName="inquiry"
						required></textarea>
				</mat-form-field>
			</div>
			<div class="row">
				<mat-form-field
					appearance="outline"
					floatLabel="always"
					style="width: 50%">
					<mat-label>Assigned To</mat-label>
					<mat-select
						appearance="outline"
						floatLabel="always"
						placeholder="Assigned To"
						name="status"
						formControlName="userType"
						required>
						<mat-option value="Select Who Should Respond" disabled="true">
							Select Who Should Respond
						</mat-option>
						<mat-option *ngFor="let user of userTypes" [value]="user">
							{{ user }}
						</mat-option>
					</mat-select>
				</mat-form-field>
			</div>
			<div class="actions">
				<button
					mat-raised-button
					[disabled]="!newInquiryForm.dirty || newInquiryForm.invalid"
					(click)="newInquiry()">
					New Inquiry
				</button>
			</div> -->
		</div>
	</mat-card-content>
</mat-card>
