import { Directive, OnInit, OnDestroy } from '@angular/core';
import { NgControl } from '@angular/forms';
import { Subscription } from 'rxjs';

@Directive({
	selector: '[appMoneyInput]',
})
export class MoneyInputDirective implements OnInit, OnDestroy {
	valueSubscription: Subscription | undefined;
	constructor(public ngControl: NgControl) {}

	ngOnInit(): void {
		this.valueSubscription = this.ngControl?.control?.valueChanges.subscribe(
			(value) => {
				const newVal = this.transform(value);
				this.ngControl.control?.setValue(newVal, {
					emitEvent: false,
					emitModelToViewChange: true,
					emitViewToModelChange: false,
				});
			}
		);
	}

	ngOnDestroy(): void {
		this.valueSubscription?.unsubscribe();
	}

	transform(value: any) {
		if (value) {
			let _value = value + '';
			_value = _value.replace(/[A-z]/g, '');
			if (_value == '') return '';
			let formattedValue = new Intl.NumberFormat('en-GB', {
				style: 'decimal',
				maximumFractionDigits: 2,
			}).format(parseFloat(_value.replace(/,/g, '')));
			if (_value.charAt(_value.length - 1) == '.') {
				formattedValue += '.';
			}

			return formattedValue;
		}
		return '';
	}
}
