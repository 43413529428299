import { Component } from '@angular/core';
import { Router } from '@angular/router';

@Component({
	selector: 'app-route-me',
	templateUrl: './route-me.component.html',
	styleUrls: ['./route-me.component.css'],
})
export class RouteMeComponent {
	constructor(public router: Router) {}
	ngOnInit() {
		this.router.navigate(['/']);
	}
}
