<div class="container">
	<h1 class="header">
		Anti-Money Laundering Id Checks
		<button
			style="float: right"
			mat-raised-button
			color="primary"
			(click)="createAMLcheck()">
			Create New ID Check
		</button>
	</h1>
	<!-- <div class="center-center" *ngIf="loading">
		<mat-spinner></mat-spinner>
	</div> -->
	<app-loading-tombstone *ngIf="loading"></app-loading-tombstone>
	<mat-card appearance="outlined" *ngIf="!loading && AMLchecks.length < 1">
		<mat-card-content>
			<div class="center">
				You have no Anti-Money Laundering Checks. Create a new Anti-Money
				Laundering Check to get started.
			</div>
		</mat-card-content>
	</mat-card>
	<mat-card
		style="flex-wrap: nowrap; margin-top: 1rem"
		class="chain-card-wrap"
		*ngFor="let amlCheck of AMLchecks; let i = index">
		<mat-card-content class="chain-card">
			<div class="chain-card-content">
				<div>
					{{ amlCheck.check.firstName }} {{ amlCheck.check.lastName }} -
					{{ amlCheck.check.userEmail }}
				</div>
				<div>
					{{ amlCheck.check.status }}
				</div>
				<div *ngIf="amlCheck.check.status == 'complete'">
					<span matTooltip="Identity Verifications">
						<mat-icon
							[ngClass]="
								amlCheck.check.identityVerifications == credasStatuses.pass
									? 'green-text'
									: 'red-text'
							"
							>{{
								amlCheck.check.identityVerifications == credasStatuses.pass
									? "check_circle"
									: "warning_amber"
							}}</mat-icon
						>
					</span>
					<span matTooltip="Address Mortality Checks">
						<mat-icon
							[ngClass]="
								amlCheck.check.addressMortalityChecks == credasStatuses.pass
									? 'green-text'
									: 'red-text'
							"
							>{{
								amlCheck.check.addressMortalityChecks == credasStatuses.pass
									? "check_circle"
									: "warning_amber"
							}}</mat-icon
						>
					</span>
					<span matTooltip="Sanction Pep Checks">
						<mat-icon
							[ngClass]="
								amlCheck.check.sanctionPepChecks == credasStatuses.pass
									? 'green-text'
									: 'red-text'
							"
							>{{
								amlCheck.check.sanctionPepChecks == credasStatuses.pass
									? "check_circle"
									: "warning_amber"
							}}</mat-icon
						>
					</span>
				</div>
				<div *ngIf="!amlCheck.check.permission">
					Permission: {{ amlCheck.check.permissionState }}
				</div>
				<div *ngIf="amlCheck.check.reportKey && amlCheck.check.permission">
					<a [routerLink]="['/', 'AMLReport', amlCheck.check.id]"
						>View Report</a
					>
				</div>
				<div>EXP:{{ amlCheck.check.ExpDate | date : "medium" }}</div>
			</div>

			<!-- <div class="spacer"></div> -->
			<div class="menu"></div>
		</mat-card-content>
	</mat-card>
</div>
