<div class="container">
	<!-- <div class="top-row">
		<div>
			<mat-button-toggle-group
				name="favoriteColor"
				aria-label="Favorite Color"
				[value]="filter"
				(change)="setFilter($event)">
				<mat-button-toggle value="NEEDSREVIEW">Needs Review</mat-button-toggle>
				<mat-button-toggle value="REVIEWED">Reviewed</mat-button-toggle>
				<mat-button-toggle value="ALL">All</mat-button-toggle>
			</mat-button-toggle-group>
		</div>

		<div class="search">

			<button mat-icon-button>
				<mat-icon>search</mat-icon>
			</button>
			<button mat-icon-button (click)="loadEMails()">
				<mat-icon>refresh</mat-icon>
			</button>
		</div>
	</div> -->

	<!-- <mat-card appearance="outlined" class="email">
		<mat-card-content>
			<div class="top-row">
				<div>Date</div>
				<div>Emails</div>
				<div>Opened</div>
				<div>Clicked</div>
				<div>Bounced</div>
				<div>Complaint</div>
				<div>Unsubscribed</div>
			</div>
		</mat-card-content>
	</mat-card>

	<mat-card
		appearance="outlined"
		*ngFor="let day of days; let i = index"
		class="email">
		<mat-card-content>
			<div class="top-row">
				<div>
					{{ day.date | date : "short" }}
				</div>
				<div>
					{{ day.numberOfEmails }}
				</div>
				<div>
					{{ day.numberOfOpened }}
				</div>
				<div>
					{{ day.numberOfClicked }}
				</div>
				<div>
					{{ day.numberOfBounced }}
				</div>
				<div>
					{{ day.numberOfComplaint }}
				</div>
				<div>
					{{ day.numberOfUnsubscribed }}
				</div>
			</div>
		</mat-card-content>
	</mat-card> -->

	<table mat-table [dataSource]="days" class="mat-elevation-z8">
		<!--- Note that these columns can be defined in any order.
              The actual rendered columns are set as a property on the row definition" -->

		<!-- Position Column -->
		<ng-container matColumnDef="date">
			<th mat-header-cell *matHeaderCellDef>Date</th>
			<td mat-cell *matCellDef="let element">
				{{ element.date | date : "shortDate" }}
			</td>
		</ng-container>

		<!-- Name Column -->
		<ng-container matColumnDef="numberOfEmails">
			<th mat-header-cell *matHeaderCellDef># Sent</th>
			<td mat-cell *matCellDef="let element">{{ element.numberOfEmails }}</td>
		</ng-container>

		<!-- Weight Column -->
		<ng-container matColumnDef="numberOfOpened">
			<th mat-header-cell *matHeaderCellDef># Opened</th>
			<td mat-cell *matCellDef="let element">
				{{ element.numberOfOpened }} ({{
					round(element.numberOfOpened / element.numberOfEmails)
				}}%)
			</td>
		</ng-container>

		<!-- Symbol Column -->
		<ng-container matColumnDef="numberOfClicked">
			<th mat-header-cell *matHeaderCellDef># Clicked</th>
			<td mat-cell *matCellDef="let element">
				{{ element.numberOfClicked }} ({{
					round(element.numberOfClicked / element.numberOfEmails)
				}}%)
			</td>
		</ng-container>

		<!-- Symbol Column -->
		<ng-container matColumnDef="numberOfBounced">
			<th mat-header-cell *matHeaderCellDef># Bounced</th>
			<td mat-cell *matCellDef="let element">
				{{ element.numberOfBounced }} ({{
					round(element.numberOfBounced / element.numberOfEmails)
				}}%)
			</td>
		</ng-container>

		<!-- Symbol Column -->
		<ng-container matColumnDef="numberOfComplaint">
			<th mat-header-cell *matHeaderCellDef># Complaints</th>
			<td mat-cell *matCellDef="let element">
				{{ element.numberOfComplaint }} ({{
					round(element.numberOfComplaint / element.numberOfEmails)
				}}%)
			</td>
		</ng-container>

		<!-- Symbol Column -->
		<ng-container matColumnDef="numberOfUnsubscribed">
			<th mat-header-cell *matHeaderCellDef># Unsubscribed</th>
			<td mat-cell *matCellDef="let element">
				{{ element.numberOfUnsubscribed }}
			</td>
		</ng-container>

		<tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
		<tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
	</table>
</div>
