import { Component, Input } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import {
	Inquiry,
	InquiryBackendService,
} from 'src/app/services/inquiry-backend.service';
import { userType } from 'src/app/setup/httpTypes';
import UUID from 'pure-uuid';
import { AuthService } from 'src/app/services/auth.service';
import { animate, style, transition, trigger } from '@angular/animations';
import { MatDialog } from '@angular/material/dialog';
import { NewInquiryComponent } from './new-inquiry/new-inquiry.component';
import { lastValueFrom } from 'rxjs';

@Component({
	selector: 'app-inquiries',
	templateUrl: './inquiries.component.html',
	styleUrls: ['./inquiries.component.css'],
	animations: [
		trigger('inOutAnimation', [
			transition(':enter', [
				style({ height: 0, opacity: 0, overflow: 'hidden' }),
				animate('300ms ease-out', style({ height: '*', opacity: 1 })),
			]),
			transition(':leave', [
				style({ height: '*', opacity: 1, overflow: 'hidden' }),
				animate('300ms ease-in', style({ height: 0, opacity: 0 })),
			]),
		]),
	],
})
export class InquiriesComponent {
	_taskId: string | undefined;
	@Input() set taskId(val: string | undefined) {
		this._taskId = val;
		this.getInquiries();
	}
	get taskId() {
		return this._taskId;
	}

	inquiries: Inquiry[] = [];
	canEdit = false;
	@Input() state: string | undefined = 'disabled';
	@Input() formOpen = true;

	newInquiryForm = this.fb.group({
		inquiry: ['', [Validators.required, Validators.minLength(5)]],
		userType: ['Select Who Should Respond', [Validators.required]],
	});

	userTypes = [
		userType.BuyersConveyancer,
		userType.SellersConveyancer,
		userType.buyer,
		userType.seller,
		userType.sellersAgent,
		userType.mortgageBroker,
	];

	constructor(
		public inquiryBackend: InquiryBackendService,
		public fb: FormBuilder,
		public AuthService: AuthService,
		public dialog: MatDialog
	) {}

	async getInquiries() {
		let response = await this.inquiryBackend.getList({ taskId: this.taskId });
		//console.log(response);
		this.canEdit = response?.canEdit || false;
		this.inquiries = response?.data || [];
	}

	async newInquiry() {
		let ref = this.dialog.open(NewInquiryComponent, {
			data: {
				taskId: this.taskId,
			},
			width: '800px',
			maxWidth: '95vw',
		});

		await lastValueFrom(ref.afterClosed());

		await this.getInquiries();
	}

	async respond(inquiryId: string, response: string) {
		let inquiry = this.inquiries.find((inquiry) => inquiry.id === inquiryId);
		if (!inquiry) return;
		if (!inquiry.responses) inquiry.responses = [];
		let user = await this.AuthService.getUser();
		inquiry.responses.push({
			id: new UUID(4).toString(),
			response,
			documents: [],
			userEmail: user?.attributes?.email || '',
			date: new Date(),
		});
		let response2 = await this.inquiryBackend.patch(inquiryId, {
			responses: inquiry.responses,
		});

		await this.getInquiries();
	}

	async resolve(inquiryId: string) {
		let inquiry = this.inquiries.find((inquiry) => inquiry.id === inquiryId);
		if (!inquiry) return;

		inquiry.status = 'resolved';
		let response = await this.inquiryBackend.patch(inquiryId, {
			status: inquiry.status,
		});

		await this.getInquiries();
	}

	async unResolve(inquiryId: string) {
		let inquiry = this.inquiries.find((inquiry) => inquiry.id === inquiryId);
		if (!inquiry) return;

		inquiry.status = 'unresolved';
		let response = await this.inquiryBackend.patch(inquiryId, {
			status: inquiry.status,
		});

		await this.getInquiries();
	}

	async delete(inquiryId: string) {
		let inquiry = this.inquiries.find((inquiry) => inquiry.id === inquiryId);
		if (!inquiry) return;

		inquiry.status = 'deleted';
		let response = await this.inquiryBackend.patch(inquiryId, {
			status: inquiry.status,
		});

		await this.getInquiries();
	}

	trackByFn(index: number, item: Inquiry) {
		return item.id;
	}
}
