<h1 mat-dialog-title>Start A New Anti-Money Laundering Id Check</h1>
<div mat-dialog-content>
	<div>
		This will send a request to the email for them to complete an Anti-Money
		Laundering Id Check via Credas.
	</div>
	<div [formGroup]="form" style="margin-top: 2rem">
		<div>
			<mat-form-field
				appearance="outline"
				floatLabel="always"
				style="width: 100%">
				<mat-label>Recipient Email</mat-label>
				<input
					matInput
					placeholder="Recipient Email"
					formControlName="email"
					required />
				<mat-error *ngIf="form.controls.email.hasError('required')">
					Email is required
				</mat-error>
				<mat-error *ngIf="form.controls.email.hasError('email')">
					Email is invalid
				</mat-error>
			</mat-form-field>
		</div>
		<div style="display: flex; gap: 1rem">
			<mat-form-field
				appearance="outline"
				floatLabel="always"
				style="width: 100%">
				<mat-label>First Name</mat-label>
				<input
					matInput
					placeholder="First Name"
					formControlName="firstName"
					required />
				<mat-error *ngIf="form.controls.firstName.hasError('required')">
					First Name is required
				</mat-error>
			</mat-form-field>
			<mat-form-field
				appearance="outline"
				floatLabel="always"
				style="width: 100%">
				<mat-label>Last Name</mat-label>
				<input
					matInput
					placeholder="Last Name"
					formControlName="lastName"
					required />
				<mat-error *ngIf="form.controls.lastName.hasError('required')">
					Last Name is required
				</mat-error>
			</mat-form-field>
		</div>
		<!-- <div>
			<mat-form-field
				appearance="outline"
				floatLabel="always"
				style="width: 100%">
				<mat-label>Email Body (Optional)</mat-label>
				<textarea
					matInput
					placeholder="Wite your personal message here..."
					formControlName="emailBody"></textarea>
			</mat-form-field>
		</div> -->
	</div>
</div>
<div mat-dialog-actions>
	<button mat-button [mat-dialog-close]="false" type="button">Cancel</button>
	<button
		mat-button
		[mat-dialog-close]="form.value"
		type="button"
		[disabled]="form.invalid"
		cdkFocusInitial>
		Create
	</button>
</div>
