/**
 * @description Decorator for unsubscribe from observable IN ANGULAR COMPONENTS
 * @returns
 */
export function Unsubscribe() {
	return (target: any, key: string) => {
		let originFn = target.ngOnDestroy;
		if (!target.__subscriptions) target.__subscriptions = new Set<any>();
		let value: any;
		if (!target.__subscriptions)
			target.ngOnDestroy = function () {
				for (const sub of this.__subscriptions) {
					sub?.unsubscribe();
				}
				if (originFn) originFn?.call(target);
			};

		function get() {
			//@ts-ignore
			return (<any>this)['__' + key];
		}

		function set(newValue: any) {
			if (value) {
				//@ts-ignore
				(<any>this).__subscriptions.delete(value);
			}
			if (newValue) {
				//@ts-ignore
				(<any>this).__subscriptions.add(newValue);
			}

			//@ts-ignore
			(<any>this)['__' + key] = newValue;
		}

		let init = (getter: boolean) => {
			return function (newValue: any) {
				//@ts-ignore
				Object.defineProperty(this, key, {
					get: get,
					set: set,
				});
				//@ts-ignore
				if (getter) return (<any>this)['__' + key];
				else {
					if (value) {
						//@ts-ignore
						(<any>this).__subscriptions.delete(value);
					}
					if (newValue) {
						//@ts-ignore
						(<any>this).__subscriptions.add(newValue);
					}

					//@ts-ignore
					(<any>this)['__' + key] = newValue;
				}
			};
		};

		Object.defineProperty(target, key, {
			get: init(true) as any,
			set: init(false) as any,
		});
	};
}
