<app-group-title [title]="title">
	<div
		[appDropFiles]
		[acceptExtensions]="['pdf', 'png', 'jpg', 'jpeg']"
		[taskId]="taskId"
		[location]="location"
		[propertyId]="propertyId"
		[disabled]="disabled || form?.disabled"
		(filesUploaded)="dragAddDocuments($event)">
		<ng-form (ngSubmit)="save()">
			<div *ngIf="docsArray?.controls?.length == 0">
				There are no documents currently attached.
			</div>
			<div
				*ngFor="
					let docFormGroup of docsArray.controls;
					let i = index;
					trackBy: trackByFn
				"
				class="docs-list-item"
				[class.disabled]="disabled || docFormGroup.disabled">
				<div (click)="openDocument(docFormGroup)">
					<mat-icon class="doc-icon accessibly">{{
						docIcon(docFormGroup.get("name")?.value)
					}}</mat-icon>
				</div>
				<div class="docs-name" (click)="openDocument(docFormGroup)">
					{{ docFormGroup.get("name")?.value }}
				</div>
				<div class="docs-actions">
					<button
						mat-icon-button
						(click)="openPublic(docFormGroup)"
						type="button">
						<mat-icon>preview</mat-icon>
					</button>
					<button
						mat-icon-button
						(click)="removeDocument(i)"
						[disabled]="disabled || docFormGroup.disabled"
						type="button">
						<mat-icon>delete_forever</mat-icon>
					</button>
				</div>
			</div>
			<div class="actions">
				<app-select-file-button
					text="Upload File"
					[disabled]="disabled || !!form?.disabled"
					accept="image/*, application/pdf"
					(newFiles)="addDocuments($event)"></app-select-file-button>
			</div>
		</ng-form>
	</div>
</app-group-title>
