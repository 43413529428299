import { ChangeDetectionStrategy, Component, type OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { switchMap } from 'rxjs';
import { AMLBackendService } from 'src/app/services/AML-backend.service';
import { TitleService } from 'src/app/services/title.service';

@Component({
	selector: 'app-aml-report',
	templateUrl: './AML-Report.component.html',
	styleUrls: ['./AML-Report.component.css'],
})
export class AMLReportComponent implements OnInit {
	loading = true;
	url: string = '';
	fileName: string = '';
	amlId: string = '';
	prams$: any;
	constructor(
		public titleService: TitleService,
		public AMLBackend: AMLBackendService,
		public activeRoute: ActivatedRoute
	) {}
	ngOnInit(): void {
		this.titleService.title = 'AML Report';
		this.prams$ = this.activeRoute.params
			.pipe(
				switchMap((params) => {
					this.amlId = params['amlId'];
					this.loading = true;
					return this.AMLBackend.getReport(this.amlId);
				})
			)
			.subscribe((res) => {
				this.url = res.data.url;
				this.fileName = res.data.fileName;
				this.loading = false;
			});
	}
	ngOnDestroy() {
		this.prams$?.unsubscribe();
	}
}
