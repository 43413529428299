import { Component } from '@angular/core';
import { TitleService } from '../services/title.service';

@Component({
	selector: 'app-faq',
	templateUrl: './faq.component.html',
	styleUrls: ['./faq.component.css'],
})
export class FAQComponent {
	constructor(public titleService: TitleService) {}

	ngOnInit() {
		this.titleService.title = 'FAQs';
	}
}
