import { HttpClient } from '@angular/common/http';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { lastValueFrom } from 'rxjs';
import { environment } from 'src/environments/environment';
import {
	TaskResult,
	TaskStatus,
	TaskType,
	Task,
	TaskParentType,
	TaskBackendService,
} from '../services/task-backend.service';
import { TitleService } from '../services/title.service';

@Component({
	templateUrl: './tasks.component.html',
	styleUrls: ['./tasks.component.css'],
})
export class TasksComponent implements OnInit, OnDestroy {
	tasks: Task[] = [];
	loading = true;

	TaskStatus = TaskStatus;
	TaskResult = TaskResult;
	TaskType = TaskType;

	constructor(
		public TaskBackend: TaskBackendService,
		public Router: Router,
		public titleService: TitleService
	) {}

	async ngOnInit() {
		this.titleService.title = 'Tasks';
		this.loading = true;
		this.tasks = (await this.TaskBackend.getList()).data;
		this.loading = false;
	}

	ngOnDestroy(): void {}

	goto(task: Task) {
		if (task.taskType == TaskType.milestone) {
			if (task.parentType == TaskParentType.Property) {
				this.Router.navigate([
					'/chain',
					task.chainId,
					'property',
					task.parentId,
					'milestone',
					task.id,
				]);
				return;
			}
		}
		if (task.taskType != TaskType.milestone) {
			if (task.parentType == TaskParentType.Task) {
				if (task.parentId == task.milestoneId) {
					this.Router.navigate([
						'/chain',
						task.chainId,
						'property',
						task.propertyId || '',
						'milestone',
						task.parentId,
						'task',
						task.id,
					]);
					return;
				} else {
					this.Router.navigate([
						'/chain',
						task.chainId,
						'property',
						task.propertyId || '',
						'milestone',
						task.milestoneId,
						'task',
						task.parentId,
						'subtask',
						task.id,
					]);
					return;
				}
			}
		}
	}
}
