<mat-card
	appearance="outlined"
	class="fade-in-out"
	[class.more-padding]="morePadding">
	<mat-card-header>
		<mat-card-title
			><h3 class="loading">Loading <app-loading-dots></app-loading-dots></h3
		></mat-card-title>
	</mat-card-header>
	<mat-card-content> </mat-card-content>
</mat-card>
