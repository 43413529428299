import {
	AbstractControl,
	ValidationErrors,
	ValidatorFn,
	Validators,
} from '@angular/forms';

export function requiredIfOtherFieldHasValue(
	otherField: string,
	otherFieldValue: string
): ValidatorFn {
	return (control: AbstractControl): ValidationErrors | null => {
		const otherFieldControl = control.parent?.get(otherField);
		if (otherFieldControl?.value === otherFieldValue) {
			return Validators.required(control);
		}
		return null;
	};
}

export function atLeastOneRequired(
	...controls: AbstractControl[]
): ValidatorFn {
	return (control: AbstractControl): ValidationErrors | null => {
		for (const c of controls) {
			if (c.value) {
				return null;
			}
		}
		return { atLeastOneRequired: true };
	};
}

export function oneOfTheseStates(states: any[]): ValidatorFn {
	return (control: AbstractControl): ValidationErrors | null => {
		if (states.includes(control.value)) {
			return null;
		}
		return { oneOfTheseStates: true };
	};
}
