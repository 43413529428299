<div class="container">
	<h1 class="header">Material Information Setup</h1>
	<!-- <div class="center-center" *ngIf="loading">
		<mat-spinner></mat-spinner>
	</div> -->

	<!-- <mat-card appearance="outlined" *ngIf="!loading && !property">
		<mat-card-content>
			<div class="center">Error</div>
		</mat-card-content>
	</mat-card> -->

	<mat-card
		*ngIf="property"
		class="row property-card"
		style="flex-wrap: nowrap; cursor: pointer; margin-top: 1rem">
		<mat-card-content style="width: 100%">
			<h2>Property</h2>
			<h3>{{ property.address || "" }}</h3>
		</mat-card-content>
	</mat-card>

	<mat-stepper orientation="vertical" #stepper>
		<mat-step
			[stepControl]="askingPriceForm"
			label="Asking Price"
			errorMessage="Missing Values">
			<ng-template matStepLabel
				>Asking Price
				<span *ngIf="!askingPriceForm.pristine" class="unsaved"
					>(Unsaved)</span
				></ng-template
			>
			<form [formGroup]="askingPriceForm">
				<div>
					<mat-form-field appearance="outline" floatLabel="always">
						<mat-label>Asking Price</mat-label>
						<span matTextPrefix>£&nbsp;</span>
						<input
							matInput
							type="string"
							[appMoneyInput]
							placeholder="I.E. 500,000"
							required
							formControlName="askingPrice" />
						<!-- [appMoneyInput] -->
					</mat-form-field>
				</div>

				<div>
					<label>Is This A New Build Property?</label>
					<mat-radio-group formControlName="newBuild">
						<mat-radio-button [value]="true">Yes</mat-radio-button>
						<mat-radio-button [value]="false">No</mat-radio-button>
					</mat-radio-group>
				</div>
				<div *ngIf="askingPriceForm.get('reservationRequired')">
					<label>Is There A Reservation Free Required For This Property?</label>
					<mat-radio-group formControlName="reservationRequired">
						<mat-radio-button [value]="true">Yes</mat-radio-button>
						<mat-radio-button [value]="false">No</mat-radio-button>
					</mat-radio-group>
				</div>
				<div *ngIf="askingPriceForm.get('reservationFee')">
					<mat-form-field appearance="outline" floatLabel="always">
						<mat-label>Reservations Fee</mat-label>
						<span matTextPrefix>£&nbsp;</span>
						<input
							matInput
							type="string"
							[appMoneyInput]
							placeholder="I.E. 1,000"
							formControlName="reservationFee" />
						<!-- [appMoneyInput] -->
					</mat-form-field>
				</div>
				<div class="actions">
					<!-- <button mat-button matStepperPrevious>Back</button> -->
					<div></div>
					<button
						mat-button
						[disabled]="form.pristine"
						(click)="saveAskingPrice()">
						Save
					</button>
					<button
						mat-raised-button
						[disabled]="askingPriceForm.invalid"
						matStepperNext>
						Next
					</button>
				</div>
			</form>
		</mat-step>
		<mat-step
			[stepControl]="tenureForm"
			label="Tenure"
			errorMessage="Missing Values">
			<ng-template matStepLabel
				>Tenure
				<span *ngIf="!tenureForm.pristine" class="unsaved"
					>(Unsaved)</span
				></ng-template
			>
			<form [formGroup]="tenureForm">
				<div>
					{{ tenureForm.get("ownership")?.value }}
					{{ tenureForm.get("tenure")?.value }}
				</div>
				<app-group-title
					title="Leasehold Details"
					*ngIf="tenureForm.get('originLengthOfLease')">
					<div class="row">
						<mat-form-field
							appearance="outline"
							floatLabel="always"
							*ngIf="tenureForm.get('originLengthOfLease')">
							<mat-label>Origin Length Of The Leasehold</mat-label>
							<span matTextSuffix>&nbsp;Years</span>
							<input
								matInput
								type="number"
								placeholder="99"
								style="text-align: right"
								formControlName="originLengthOfLease" />
						</mat-form-field>

						<mat-form-field
							appearance="outline"
							floatLabel="always"
							*ngIf="tenureForm.get('leftOnLease')">
							<mat-label>Years Left On The Leasehold</mat-label>
							<span matTextSuffix>&nbsp;Years</span>
							<input
								matInput
								type="number"
								placeholder="99"
								style="text-align: right"
								formControlName="leftOnLease" />
						</mat-form-field>
					</div>
					<app-material-documents-form
						*ngIf="tenureForm.get('leaseholdAgreement')"
						title="Upload Your Leasehold Agreement"
						[canEdit]="true"
						[materialId]="currentSavedForm?.id"
						[propertyId]="propertyId"
						[form]="$any(tenureForm.get('leaseholdAgreement'))"
						(save)="saveTenure()">
					</app-material-documents-form>
				</app-group-title>
				<app-group-title
					title="Ground Rent Details"
					*ngIf="tenureForm.get('groundRentCost')">
					<div *ngIf="tenureForm.get('groundRentCost')" class="row">
						<mat-form-field appearance="outline" floatLabel="always">
							<mat-label>Annual Ground Rent Costs</mat-label>
							<span matTextPrefix>£&nbsp;</span>
							<input
								matInput
								type="string"
								[appMoneyInput]
								placeholder="I.E. 1,000"
								formControlName="groundRentCost" />
							<span matTextSuffix>&nbsp;Per Year</span>
							<mat-hint
								>({{
									groundRentCostPerMonth()
										| currency : "GBP" : "symbol" : "1.0-0"
								}}/month)</mat-hint
							>
							<!-- [appMoneyInput] -->
						</mat-form-field>
						<!-- <span style="align-self: center">
							({{
								groundRentCostPerMonth()
									| currency : "GBP" : "symbol" : "1.0-0"
							}}/month)
						</span> -->
					</div>
				</app-group-title>
				<app-group-title
					title="Service Charges Details"
					*ngIf="tenureForm.get('serviceChargesCost')">
					<div *ngIf="tenureForm.get('serviceChargesCost')" class="row">
						<mat-form-field appearance="outline" floatLabel="always">
							<mat-label>Annual Service Charges</mat-label>
							<span matTextPrefix>£&nbsp;</span>
							<input
								matInput
								type="string"
								[appMoneyInput]
								placeholder="I.E. 1,000"
								formControlName="serviceChargesCost" />
							<span matTextSuffix>&nbsp;Per Year</span>
							<mat-hint
								>({{
									serviceChargesCostPerMonth()
										| currency : "GBP" : "symbol" : "1.0-0"
								}}/month)</mat-hint
							>
							<!-- [appMoneyInput] -->
						</mat-form-field>
						<!-- <span style="align-self: center">
							({{
								serviceChargesCostPerMonth()
									| currency : "GBP" : "symbol" : "1.0-0"
							}}/month)
						</span> -->
						<mat-form-field appearance="outline" floatLabel="always">
							<mat-label>Period Of Service Charges</mat-label>
							<mat-select formControlName="periodOfServiceCharges">
								<mat-option value="Monthly">Monthly</mat-option>
								<mat-option value="Quarterly">Quarterly</mat-option>
								<mat-option value="Bi-Annually">Bi-Annually</mat-option>
								<mat-option value="Annually">Annually</mat-option>
							</mat-select>
						</mat-form-field>
					</div>
					<div class="row">
						<div style="width: 100%">
							<label>Is There A Sinking Fund?</label>
							<mat-radio-group formControlName="sinkingFund">
								<mat-radio-button [value]="true">Yes</mat-radio-button>
								<mat-radio-button [value]="false">No</mat-radio-button>
							</mat-radio-group>
						</div>
						<mat-form-field
							*ngIf="tenureForm.get('percentToSinkingFund')"
							appearance="outline"
							floatLabel="always">
							<mat-label>% Of Charges That Go To The Sinking Fund?</mat-label>
							<input
								style="text-align: right"
								matInput
								type="number"
								placeholder="I.E. 25"
								formControlName="percentToSinkingFund" />
							<span matTextSuffix>&nbsp;%</span>
							<!-- [appMoneyInput] -->
						</mat-form-field>

						<mat-form-field
							appearance="outline"
							floatLabel="always"
							*ngIf="tenureForm.get('sinkingFundAmount')">
							<mat-label>How Much Is In The Sinking Fund?</mat-label>
							<span matTextPrefix>£&nbsp;</span>
							<input
								matInput
								type="string"
								[appMoneyInput]
								placeholder="I.E. 1,000"
								formControlName="sinkingFundAmount" />
						</mat-form-field>
					</div>
					<div class="row">
						<div>
							<label>Is There Any Capital Works Planned?</label>
							<mat-radio-group formControlName="capitalWorksPlanned">
								<mat-radio-button [value]="true">Yes</mat-radio-button>
								<mat-radio-button [value]="false">No</mat-radio-button>
							</mat-radio-group>
						</div>
						<mat-form-field
							*ngIf="tenureForm.get('capitalWorksDetails')"
							appearance="outline"
							floatLabel="always"
							style="width: 100%">
							<mat-label
								>What Capital Works Are Planned And When Will They
								Start?</mat-label
							>
							<textarea
								style="width: 100%"
								matInput
								type="text"
								placeholder=""
								formControlName="capitalWorksDetails"></textarea>
						</mat-form-field>
					</div>
				</app-group-title>

				<app-group-title title="Managing Agent Details">
					<div>
						<label>Is There A Managing Agent?</label>
						<mat-radio-group formControlName="hasManagingAgent">
							<mat-radio-button [value]="true">Yes</mat-radio-button>
							<mat-radio-button [value]="false">No</mat-radio-button>
						</mat-radio-group>
					</div>
					<app-people
						*ngIf="tenureForm.get('managingAgents')"
						title="Managing Agents"
						style="margin-top: 1.5rem"
						[form]="$any(tenureForm.get('managingAgents'))"></app-people>
				</app-group-title>
				<app-group-title
					title="Pet Restrictions"
					*ngIf="tenureForm.get('petRestrictions')">
					<div>
						<label>Are there any Pet Restrictions?</label>
						<mat-radio-group formControlName="petRestrictions">
							<mat-radio-button value="none">No Restrictions</mat-radio-button>
							<mat-radio-button value="restricted">Restricted</mat-radio-button>
							<mat-radio-button value="unknown">Unknown</mat-radio-button>
						</mat-radio-group>
					</div>
					<div *ngIf="tenureForm.get('petRestrictionsDetails')">
						<mat-form-field
							appearance="outline"
							floatLabel="always"
							style="width: 100%">
							<mat-label>Details</mat-label>
							<textarea
								style="width: 100%"
								matInput
								type="text"
								placeholder="Details"
								formControlName="petRestrictionsDetails"></textarea>
						</mat-form-field>
					</div>
				</app-group-title>

				<div class="actions" style="margin-top: 1.5rem">
					<button mat-button matStepperPrevious>Back</button>
					<button mat-button (click)="saveTenure()">Save</button>
					<button
						mat-raised-button
						[disabled]="tenureForm.invalid"
						matStepperNext>
						Next
					</button>
				</div>
			</form>
		</mat-step>
		<mat-step
			[stepControl]="leasingTenantForm"
			label="Leasing Tenant"
			errorMessage="Missing Values">
			<ng-template matStepLabel
				>Leasing Tenant
				<span *ngIf="!leasingTenantForm.pristine" class="unsaved"
					>(Unsaved)</span
				></ng-template
			>
			<form [formGroup]="leasingTenantForm">
				<div>
					<label>Is There A Current Tenant Leasing The Property?</label>
					<mat-radio-group formControlName="currentLeasingTenant">
						<mat-radio-button [value]="true">Yes</mat-radio-button>
						<mat-radio-button [value]="false">No</mat-radio-button>
					</mat-radio-group>
				</div>
				<div *ngIf="leasingTenantForm.get('leaseBeyondSale')">
					<label>Will This Lease Extend Beyond The Sale Of The property?</label>
					<mat-radio-group formControlName="leaseBeyondSale">
						<mat-radio-button [value]="true">Yes</mat-radio-button>
						<mat-radio-button [value]="false">No</mat-radio-button>
					</mat-radio-group>
				</div>
				<div
					*ngIf="leasingTenantForm.get('leaseEndDate')"
					style="margin-top: 0.75rem">
					<mat-form-field appearance="outline" floatLabel="always">
						<mat-label>Lease End/Renewal Date</mat-label>
						<input
							matInput
							[matDatepicker]="picker"
							formControlName="leaseEndDate" />
						<mat-hint>MM/DD/YYYY</mat-hint>
						<mat-datepicker-toggle
							matIconSuffix
							[for]="picker"></mat-datepicker-toggle>
						<mat-datepicker #picker></mat-datepicker>
					</mat-form-field>
				</div>

				<div class="actions" style="margin-top: 1.5rem">
					<button mat-button matStepperPrevious>Back</button>
					<button mat-button (click)="saveLeasingTenant()">Save</button>
					<button
						mat-raised-button
						[disabled]="leasingTenantForm.invalid"
						matStepperNext>
						Next
					</button>
				</div>
			</form>
		</mat-step>
		<mat-step
			[stepControl]="propertyConstructionForm"
			label="Property Construction"
			errorMessage="Missing Values">
			<ng-template matStepLabel
				>Property Construction
				<span *ngIf="!propertyConstructionForm.pristine" class="unsaved"
					>(Unsaved)</span
				></ng-template
			>
			<form [formGroup]="propertyConstructionForm">
				<div class="row">
					<mat-form-field appearance="outline" floatLabel="always">
						<mat-label>Originally Constructed</mat-label>
						<mat-select formControlName="originalConstructed">
							<mat-option value="2020s">2020 Or Newer</mat-option>
							<mat-option value="2010s">2010s</mat-option>
							<mat-option value="2000s">2000s</mat-option>
							<mat-option value="1990s">1990s</mat-option>
							<mat-option value="1980s">1980s</mat-option>
							<mat-option value="1970s">1970s</mat-option>
							<mat-option value="1960s">1960s</mat-option>
							<mat-option value="1950s">1950s</mat-option>
							<mat-option value="1940s">1940s</mat-option>
							<mat-option value="1930s">1930s</mat-option>
							<mat-option value="1930s or before">Older than 1930</mat-option>
						</mat-select>
					</mat-form-field>

					<mat-form-field appearance="outline" floatLabel="always">
						<mat-label>Property Type</mat-label>
						<mat-select formControlName="propertyType">
							<mat-option value="House">House</mat-option>
							<mat-option value="Semi-Detached">Semi-Detached</mat-option>
							<mat-option value="Terraced">Terraced</mat-option>
							<mat-option value="Flat">Flat</mat-option>
							<mat-option value="Bungalow">Bungalow</mat-option>
							<mat-option value="Studio">Studio</mat-option>
							<mat-option value="Other">Other</mat-option>
						</mat-select>
					</mat-form-field>
					<mat-form-field
						appearance="outline"
						floatLabel="always"
						*ngIf="propertyConstructionForm.get('otherPropertyType')">
						<mat-label>Other Property Type</mat-label>
						<input
							matInput
							type="text"
							placeholder=""
							formControlName="otherPropertyType" />
					</mat-form-field>
				</div>
				<div class="row">
					<mat-form-field appearance="outline" floatLabel="always">
						<mat-label>Walls Constructed From</mat-label>
						<mat-select formControlName="wallConstruction" multiple>
							<mat-option value="wood">Wood</mat-option>
							<mat-option value="brick">Brick</mat-option>
							<mat-option value="metal">Metal</mat-option>
							<mat-option value="concrete">Concrete</mat-option>
							<mat-option value="stone">Stone</mat-option>
							<mat-option value="other">Other</mat-option>
						</mat-select>
					</mat-form-field>
					<mat-form-field
						appearance="outline"
						floatLabel="always"
						*ngIf="propertyConstructionForm.get('otherWallConstruction')">
						<mat-label>Other Construction Materials</mat-label>
						<input
							matInput
							type="text"
							placeholder=""
							formControlName="otherWallConstruction" />
					</mat-form-field>
				</div>
				<div class="row">
					<mat-form-field appearance="outline" floatLabel="always">
						<mat-label>Roof Type</mat-label>
						<mat-select formControlName="roofType" multiple>
							<mat-option value="slope">Slope</mat-option>
							<mat-option value="flat">Flat</mat-option>
						</mat-select>
					</mat-form-field>
					<mat-form-field appearance="outline" floatLabel="always">
						<mat-label>Roof Materials</mat-label>
						<mat-select formControlName="roofMaterial" multiple>
							<mat-option value="metal">Metal (tin,steal...)</mat-option>
							<mat-option value="slate">Slate</mat-option>
							<mat-option value="clay">Clay</mat-option>
							<mat-option value="concrete">Concrete</mat-option>
							<mat-option value="asphaltShingles">Asphalt Shingles</mat-option>
							<mat-option value="solar">Solar Tiles</mat-option>
							<mat-option value="Thatched">Thatched</mat-option>
							<mat-option value="other">Other</mat-option>
						</mat-select>
					</mat-form-field>
					<mat-form-field
						appearance="outline"
						floatLabel="always"
						*ngIf="propertyConstructionForm.get('otherRoofMaterial')">
						<mat-label>Other Roof Materials</mat-label>
						<input
							matInput
							type="text"
							placeholder=""
							formControlName="otherRoofMaterial" />
					</mat-form-field>
				</div>

				<app-group-title
					title="Boundaries"
					*ngIf="propertyConstructionForm.get('leftBoundary')">
					<label style="display: block; margin-bottom: 1rem">
						Looking towards the property from the road, who owns or accepts
						responsibility to maintain or repair the boundary features?
					</label>
					<div class="row">
						<mat-form-field appearance="outline" floatLabel="always">
							<mat-label>Left Boundary</mat-label>
							<mat-select formControlName="leftBoundary">
								<mat-option value="seller">Seller</mat-option>
								<mat-option value="shared">Shared</mat-option>
								<mat-option value="neighbour">Neighbour</mat-option>
								<mat-option value="unknown">Unknown</mat-option>
							</mat-select>
						</mat-form-field>

						<mat-form-field appearance="outline" floatLabel="always">
							<mat-label>Right Boundary</mat-label>
							<mat-select formControlName="rightBoundary">
								<mat-option value="seller">Seller</mat-option>
								<mat-option value="shared">Shared</mat-option>
								<mat-option value="neighbour">Neighbour</mat-option>
								<mat-option value="unknown">Unknown</mat-option>
							</mat-select>
						</mat-form-field>
					</div>
					<div class="row">
						<mat-form-field appearance="outline" floatLabel="always">
							<mat-label>Rear Boundary</mat-label>
							<mat-select formControlName="rearBoundary">
								<mat-option value="seller">Seller</mat-option>
								<mat-option value="shared">Shared</mat-option>
								<mat-option value="neighbour">Neighbour</mat-option>
								<mat-option value="unknown">Unknown</mat-option>
							</mat-select>
						</mat-form-field>

						<mat-form-field appearance="outline" floatLabel="always">
							<mat-label>Front Boundary</mat-label>
							<mat-select formControlName="frontBoundary">
								<mat-option value="seller">Seller</mat-option>
								<mat-option value="shared">Shared</mat-option>
								<mat-option value="neighbour">Neighbour</mat-option>
								<mat-option value="unknown">Unknown</mat-option>
							</mat-select>
						</mat-form-field>
					</div>

					<div *ngIf="propertyConstructionForm.get('irregularBoundary')">
						<label>Are There Any Irregular Boundaries?</label>
						<mat-radio-group formControlName="irregularBoundary">
							<mat-radio-button [value]="true">Yes</mat-radio-button>
							<mat-radio-button [value]="false">No</mat-radio-button>
						</mat-radio-group>
					</div>

					<div *ngIf="propertyConstructionForm.get('irregularBoundaryDetails')">
						<mat-form-field
							appearance="outline"
							floatLabel="always"
							style="width: 100%">
							<mat-label>Irregular Boundary Details</mat-label>
							<textarea
								style="width: 100%"
								matInput
								type="text"
								placeholder="Details"
								formControlName="irregularBoundaryDetails"></textarea>
						</mat-form-field>
					</div>

					<div *ngIf="propertyConstructionForm.get('boundaryMoved')">
						<label style="display: block">
							Are You Aware Of Any Boundary Features Being Moved In The Last 10
							Years Or Since The Start Of Your Ownership If Longer?</label
						>
						<mat-radio-group formControlName="boundaryMoved">
							<mat-radio-button [value]="true">Yes</mat-radio-button>
							<mat-radio-button [value]="false">No</mat-radio-button>
						</mat-radio-group>
					</div>

					<div *ngIf="propertyConstructionForm.get('boundaryMovedDetails')">
						<mat-form-field
							appearance="outline"
							floatLabel="always"
							style="width: 100%">
							<mat-label>Boundary Movement Details</mat-label>
							<textarea
								style="width: 100%"
								matInput
								type="text"
								placeholder="Details"
								formControlName="boundaryMovedDetails"></textarea>
						</mat-form-field>
					</div>
				</app-group-title>

				<div class="actions" style="margin-top: 1.5rem">
					<button mat-button matStepperPrevious>Back</button>
					<button mat-button (click)="savePropertyConstruction()">Save</button>
					<button
						mat-raised-button
						[disabled]="propertyConstructionForm.invalid"
						matStepperNext>
						Next
					</button>
				</div>
			</form>
		</mat-step>

		<mat-step
			[stepControl]="spacesForm"
			label="Spaces"
			errorMessage="Missing Values">
			<ng-template matStepLabel
				>Spaces
				<span *ngIf="!spacesForm.pristine" class="unsaved"
					>(Unsaved)</span
				></ng-template
			>
			<form [formGroup]="spacesForm">
				<app-group-title
					title="Spaces, Rooms and Gardens..."
					style="margin-bottom: 1.5rem">
					<label style="display: block; margin-bottom: 1rem"
						>What spaces are in the property? Add all the space that are part of
						this property sale. Ensure to include bedrooms, bathrooms, gardens,
						kitchens and any other area that makes up the property.</label
					>

					<div
						*ngFor="
							let spaceItemForm of spacesArray.controls || [];
							let i = index
						"
						style="display: flex"
						[formGroup]="spaceItemForm">
						<span style="align-self: center; font-weight: 500"
							>{{
								spaceItemForm.get("name")?.value ||
									spaceItemForm.get("type")?.value ||
									"Space"
							}}
						</span>
						<span style="align-self: center" class="hide-small"> - </span>
						<span style="align-self: center" class="hide-small">
							{{
								spaceItemForm.get("shape")?.value == "rectangular"
									? "Rectangular"
									: "Irregular"
							}}
							{{ spaceItemForm.get("length")?.value }}M x
							{{ spaceItemForm.get("width")?.value }}M x
							<span *ngIf="spaceItemForm.get('ceilingHeight')?.value"
								>{{ spaceItemForm.get("ceilingHeight")?.value }}M</span
							></span
						>
						<span style="align-self: center" class="hide-small"> (L x W </span>
						<span
							style="align-self: center; margin-left: 0.15rem"
							class="hide-small"
							*ngIf="spaceItemForm.get('ceilingHeight')?.value">
							x H</span
						>
						<span class="hide-small" style="align-self: center">)</span>
						<span
							*ngIf="
								spaceItemForm.get('toilet')?.value ||
								spaceItemForm.get('shower')?.value ||
								spaceItemForm.get('bath')?.value
							"
							class="hide-small"
							style="align-self: center">
							(<span *ngIf="spaceItemForm.get('toilet')?.value"
								>Toilet{{
									spaceItemForm.get("shower")?.value ||
									spaceItemForm.get("bath")?.value
										? ","
										: ""
								}}</span
							>
							<span *ngIf="spaceItemForm.get('shower')?.value"
								>Shower{{ spaceItemForm.get("bath")?.value ? "," : "" }}</span
							>
							<span *ngIf="spaceItemForm.get('bath')?.value">Bath</span>)
						</span>
						<span style="flex-grow: 1"> </span>
						<button mat-icon-button (click)="editSpace(i)">
							<mat-icon>edit</mat-icon>
						</button>
						<button mat-icon-button (click)="removeSpace(i)">
							<mat-icon>delete</mat-icon>
						</button>
					</div>
					<div class="actions">
						<div></div>
						<button mat-raised-button color="primary" (click)="addSpace()">
							Add Space
						</button>
					</div>
				</app-group-title>
				<div class="row">
					<mat-form-field appearance="outline" floatLabel="always">
						<mat-label>Total Indoor Floor Area?</mat-label>
						<input
							matInput
							type="number"
							placeholder="103"
							required
							formControlName="floorSpace" />
						<span matTextSuffix>&nbsp;Square Meters</span>
					</mat-form-field>
					<mat-form-field appearance="outline" floatLabel="always">
						<mat-label>What Floor Is Your Front Door Located On?</mat-label>
						<input
							matInput
							type="number"
							placeholder="0"
							required
							formControlName="frontDoorLevel" />
						<span matTextSuffix>&nbsp;Floor</span>
					</mat-form-field>
					<mat-form-field appearance="outline" floatLabel="always">
						<mat-label
							>How Many Floors In The
							{{
								propertyConstructionForm.get("propertyType")?.value ||
									"Property"
							}}?</mat-label
						>
						<input
							matInput
							type="number"
							placeholder="0"
							required
							formControlName="numberOfFloors" />
						<span matTextSuffix>&nbsp;Floors</span>
					</mat-form-field>
				</div>

				<div class="actions">
					<button mat-button matStepperPrevious>Back</button>
					<button mat-button (click)="saveSpaces()">Save</button>
					<button
						mat-raised-button
						[disabled]="spacesForm.invalid"
						matStepperNext>
						Next
					</button>
				</div>
			</form>
		</mat-step>
		<mat-step
			[stepControl]="knownWorksForm"
			label="Known Works"
			errorMessage="Missing Values">
			<ng-template matStepLabel
				>Known Works
				<span *ngIf="!knownWorksForm.pristine" class="unsaved"
					>(Unsaved)</span
				></ng-template
			>
			<form [formGroup]="knownWorksForm">
				<div>
					<label style="display: block"
						>Are You Aware Of Any Works Completed On This Property While Under
						Your Ownership Or Before?</label
					>
					<mat-radio-group formControlName="knowledgedOfWorks">
						<mat-radio-button [value]="true"> Yes </mat-radio-button>
						<mat-radio-button [value]="false"> No </mat-radio-button>
					</mat-radio-group>
				</div>
				<app-group-title
					title="Works Completed"
					*ngIf="knownWorksForm.get('knownWorks')">
					<div
						*ngFor="let workItemFrom of worksArray.controls; let i = index"
						style="display: flex">
						<div style="flex-grow: 1; align-self: center">
							{{ workItemFrom.get("description")?.value }}
						</div>
						<div style="align-self: center" class="hide-small">
							{{
								workItemFrom.get("completionDate")?.value | date : "MMMM - yyyy"
							}}
						</div>
						<button mat-icon-button (click)="editWork(i)">
							<mat-icon>edit</mat-icon>
						</button>
						<button mat-icon-button (click)="removeWork(i)">
							<mat-icon>delete</mat-icon>
						</button>
					</div>
					<div class="actions">
						<div></div>
						<button mat-raised-button color="primary" (click)="addWork()">
							Add Works
						</button>
					</div>
				</app-group-title>

				<div class="actions" style="margin-top: 1rem">
					<button mat-button matStepperPrevious>Back</button>
					<button mat-button (click)="saveKnownWorks()">Save</button>
					<button
						mat-raised-button
						[disabled]="knownWorksForm.invalid"
						matStepperNext>
						Next
					</button>
				</div>
			</form>
		</mat-step>
		<mat-step
			[stepControl]="utilitiesForm"
			label="Utilities"
			errorMessage="Missing Values">
			<ng-template matStepLabel
				>Utilities
				<span *ngIf="!utilitiesForm.pristine" class="unsaved"
					>(Unsaved)</span
				></ng-template
			>
			<form [formGroup]="utilitiesForm">
				<app-group-title title="Electricity">
					<div class="row">
						<mat-form-field appearance="outline" floatLabel="always">
							<mat-label>Annual Cost For Grid Electricity</mat-label>
							<span matTextPrefix>£&nbsp;</span>
							<input
								matInput
								type="string"
								[appMoneyInput]
								placeholder="I.E. 1,500"
								required
								formControlName="electricityCost" />
							<!-- [appMoneyInput] -->
						</mat-form-field>
						<mat-form-field appearance="outline" floatLabel="always">
							<mat-label
								>Annual Cost For Non-Grid Electricity (Solar/Wind/Battery
								Storage)</mat-label
							>
							<span matTextPrefix>£&nbsp;</span>
							<input
								matInput
								type="string"
								[appMoneyInput]
								placeholder="I.E. -500"
								required
								formControlName="electricityAltCost" />
							<!-- [appMoneyInput] -->
						</mat-form-field>
						<mat-form-field
							appearance="outline"
							floatLabel="always"
							style="width: 100%">
							<mat-label
								>Description Of Any Non-Grid Electricity (Solar/Wind/Battery
								Storage)</mat-label
							>
							<textarea
								style="width: 100%"
								matInput
								type="text"
								placeholder="I.E. 3kW Solar Panels installed in 2019"
								formControlName="electricityAltDetails"></textarea>
						</mat-form-field>
					</div>
				</app-group-title>
				<app-group-title title="Water">
					<div class="row">
						<mat-form-field appearance="outline" floatLabel="always">
							<mat-label>Annual Cost For Town/City Water</mat-label>
							<span matTextPrefix>£&nbsp;</span>
							<input
								matInput
								type="string"
								[appMoneyInput]
								placeholder="I.E. 600"
								required
								formControlName="waterCost" />
							<!-- [appMoneyInput] -->
						</mat-form-field>
						<mat-form-field appearance="outline" floatLabel="always">
							<mat-label
								>Annual Cost For Non-Town/City Water
								(Well,Spring,Rain-Capture)</mat-label
							>
							<span matTextPrefix>£&nbsp;</span>
							<input
								matInput
								type="string"
								[appMoneyInput]
								placeholder="I.E. -500"
								required
								formControlName="waterAltCost" />
							<!-- [appMoneyInput] -->
						</mat-form-field>
						<mat-form-field
							appearance="outline"
							floatLabel="always"
							style="width: 100%">
							<mat-label
								>Description Of Any Non-Town/City Water
								(Well,Spring,Rain-Capture)</mat-label
							>
							<textarea
								style="width: 100%"
								matInput
								type="text"
								placeholder="I.E. A 20m deep well was dug in 2018 and it provides water for the property."
								formControlName="waterAltDetails"></textarea>
						</mat-form-field>
					</div>
				</app-group-title>
				<app-group-title title="Sewerage">
					<div class="row">
						<mat-form-field appearance="outline" floatLabel="always">
							<mat-label>Annual Cost Related To Sewerage</mat-label>
							<span matTextPrefix>£&nbsp;</span>
							<input
								matInput
								type="string"
								[appMoneyInput]
								placeholder="I.E. 600"
								required
								formControlName="sewerageCost" />
							<!-- [appMoneyInput] -->
						</mat-form-field>
						<mat-form-field appearance="outline" floatLabel="always">
							<mat-label>Sewerage Solution</mat-label>
							<mat-select formControlName="sewerageType">
								<mat-option value="town">Town/City Sewage</mat-option>
								<mat-option value="septic">Septic Systems</mat-option>
								<mat-option value="extendedAeration"
									>Extended Aeration System</mat-option
								>
								<mat-option value="RBC"
									>Trickling Filters/Rotating Biological Contractors
									(RBCs)</mat-option
								>
								<mat-option value="SBR"
									>Sequencing Batch Reactor (SBR)</mat-option
								>
								<mat-option value="MBR">Membrane Bioreactor (MBR)</mat-option>
								<mat-option value="constructedWetlands"
									>Constructed Wetlands</mat-option
								>
								<mat-option value="outhouse">Outhouse</mat-option>
								<mat-option value="other">Other</mat-option>
							</mat-select>
							<!-- [appMoneyInput] -->
						</mat-form-field>
						<mat-form-field
							*ngIf="utilitiesForm.get('sewerageOther')"
							appearance="outline"
							floatLabel="always"
							style="width: 100%">
							<mat-label>Description Of Other Sewerage Solution</mat-label>
							<textarea
								style="width: 100%"
								matInput
								type="text"
								placeholder=""
								formControlName="sewerageOther"></textarea>
						</mat-form-field>
					</div>
				</app-group-title>
				<app-group-title title="Heat Sources">
					<div
						*ngFor="
							let heatSourceItemFrom of utilitiesHeatingSources.controls;
							let i = index
						"
						style="display: flex">
						<div style="flex-grow: 1; align-self: center; font-weight: 500">
							{{
								heatSourceItemFrom.get("otherDetails")?.value ||
									heatSourceItemFrom.get("type")?.value
							}}
						</div>
						<div
							*ngIf="heatSourceItemFrom.get('cost')?.value"
							style="align-self: center">
							£{{ heatSourceItemFrom.get("cost")?.value }}
						</div>
						<button mat-icon-button (click)="editHeatingSource(i)">
							<mat-icon>edit</mat-icon>
						</button>
						<button mat-icon-button (click)="removeHeatingSource(i)">
							<mat-icon>delete</mat-icon>
						</button>
					</div>
					<label
						style="display: block; margin-top: 1rem"
						*ngIf="(utilitiesHeatingSources.controls || []).length == 0">
						What Heat Sources Are In The Property? Add All The heat Sources For
						The Property.
					</label>
					<div class="actions">
						<div></div>
						<button
							mat-raised-button
							color="primary"
							(click)="addHeatingSource()">
							Add Heat Source
						</button>
					</div>
				</app-group-title>
				<div class="actions" style="margin-top: 1.5rem">
					<button mat-button matStepperPrevious>Back</button>
					<button mat-button (click)="saveUtilities()">Save</button>
					<button
						mat-raised-button
						[disabled]="utilitiesForm.invalid"
						matStepperNext>
						Next
					</button>
				</div>
			</form>
		</mat-step>
		<mat-step
			label="Parking & Bike Storage"
			[stepControl]="parkingBikeStorageForm"
			errorMessage="Missing Values">
			<ng-template matStepLabel
				>Parking & Bike Storage
				<span *ngIf="!parkingBikeStorageForm.pristine" class="unsaved"
					>(Unsaved)</span
				></ng-template
			>
			<form [formGroup]="parkingBikeStorageForm">
				<app-group-title title="Parking">
					<div>
						<label>Parking Is Available On Premise Or Near By?</label>
						<mat-radio-group formControlName="parkingAvailable">
							<mat-radio-button [value]="true">Yes</mat-radio-button>
							<mat-radio-button [value]="false">No</mat-radio-button>
						</mat-radio-group>
					</div>
					<div
						*ngFor="let spotForm of parkingSpots?.controls || []; let i = index"
						style="display: flex">
						<span style="flex-grow: 1; font-weight: 500; align-self: center">
							{{ spotForm.get("type")?.value }}
							With {{ spotForm.get("numberOfSpots")?.value }} Spots
						</span>
						<span
							*ngIf="spotForm.get('cost')?.value != '0'"
							style="align-self: center"
							span>
							£{{ spotForm.get("cost")?.value }}/Year
						</span>
						<button mat-icon-button (click)="editParkingSpot(i)">
							<mat-icon>edit</mat-icon>
						</button>
						<button mat-icon-button (click)="removeParkingSpot(i)">
							<mat-icon>delete</mat-icon>
						</button>
					</div>
					<div class="actions" *ngIf="parkingSpots">
						<div></div>
						<button
							mat-raised-button
							color="primary"
							(click)="addParkingSpot()">
							Add Parking Location
						</button>
					</div>
				</app-group-title>
				<app-group-title title="Bike Storage">
					<div>
						<label>Is Bike Storage Available On Premise Or Near By?</label>
						<mat-radio-group formControlName="bikeStorageAvailable">
							<mat-radio-button [value]="true">Yes</mat-radio-button>
							<mat-radio-button [value]="false">No</mat-radio-button>
						</mat-radio-group>
					</div>
					<div
						*ngFor="
							let bikeStorageForm of bikeStorageSpots?.controls || [];
							let i = index
						"
						style="display: flex">
						<span style="flex-grow: 1; font-weight: 500; align-content: center">
							{{ bikeStorageForm.get("type")?.value }}
							With Room For
							{{ bikeStorageForm.get("numberOfSpots")?.value }} Bikes
							<span
								*ngIf="
									bikeStorageForm.get('eBikes')?.value ||
									bikeStorageForm.get('cargoBikes')?.value
								">
								(
								<span *ngIf="bikeStorageForm.get('eBikes')?.value"
									>E-Bikes Allowed</span
								>
								<span
									*ngIf="
										bikeStorageForm.get('eBikes')?.value &&
										bikeStorageForm.get('cargoBikes')?.value
									">
									And
								</span>
								<span *ngIf="bikeStorageForm.get('cargoBikes')?.value"
									>Cargo Style Bike Accessible</span
								>
								)
							</span>
						</span>
						<span
							*ngIf="bikeStorageForm.get('cost')?.value != '0'"
							style="align-content: center">
							£{{ bikeStorageForm.get("cost")?.value }}/Year
						</span>
						<button mat-icon-button (click)="editBikeStorageSpot(i)">
							<mat-icon>edit</mat-icon>
						</button>
						<button mat-icon-button (click)="removeBikeStorageSpot(i)">
							<mat-icon>delete</mat-icon>
						</button>
					</div>
					<div class="actions" *ngIf="bikeStorageSpots">
						<div></div>
						<button
							mat-raised-button
							color="primary"
							(click)="addBikeStorageSpot()">
							Add Bike Storage Location
						</button>
					</div>
				</app-group-title>
			</form>
			<div class="actions" style="margin-top: 1.5rem">
				<button mat-button matStepperPrevious>Back</button>
				<button mat-button (click)="saveParkingBikeStorage()">Save</button>
				<button
					mat-raised-button
					[disabled]="parkingBikeStorageForm.invalid"
					matStepperNext>
					Next
				</button>
			</div>
		</mat-step>
		<mat-step
			label="Building Safety Issues"
			errorMessage="Missing Values"
			[stepControl]="buildingSafetyIssuesForm">
			<ng-template matStepLabel
				>Building Safety Issues
				<span *ngIf="!buildingSafetyIssuesForm.pristine" class="unsaved"
					>(Unsaved)</span
				></ng-template
			>
			<form [formGroup]="buildingSafetyIssuesForm">
				<label>Are There Any Known Safety Issues With This Property?</label>
				<mat-radio-group formControlName="anyKnownIssues">
					<mat-radio-button [value]="true">Yes</mat-radio-button>
					<mat-radio-button [value]="false">No</mat-radio-button>
				</mat-radio-group>
				<div
					*ngFor="let issueForm of issuesArray?.controls || []; let i = index"
					style="display: flex">
					<span style="flex-grow: 1; font-weight: 500; align-self: center">
						{{ issueForm.get("type")?.value }}
					</span>
					<span
						*ngIf="issueForm.get('cost')?.value != '0'"
						style="align-self: center">
						£{{ issueForm.get("outstandingCost")?.value }}/Year
					</span>
					<button mat-icon-button (click)="editIssue(i)">
						<mat-icon>edit</mat-icon>
					</button>
					<button mat-icon-button (click)="removeIssue(i)">
						<mat-icon>delete</mat-icon>
					</button>
				</div>
				<div class="actions" *ngIf="issuesArray">
					<div></div>
					<button mat-raised-button color="primary" (click)="addIssue()">
						Add Building Safety Issue
					</button>
				</div>
				<div class="actions" style="margin-top: 1.5rem">
					<button mat-button matStepperPrevious>Back</button>
					<button mat-button (click)="saveBuildingSafetyIssues()">Save</button>
					<button
						mat-raised-button
						[disabled]="buildingSafetyIssuesForm.invalid"
						matStepperNext>
						Next
					</button>
				</div>
			</form>
		</mat-step>
		<mat-step
			label="Right Of Ways And Easements"
			[stepControl]="rightsAndEasementsForm">
			<ng-template matStepLabel
				>Right Of Ways And Easements
				<span *ngIf="!rightsAndEasementsForm.pristine" class="unsaved"
					>(Unsaved)</span
				></ng-template
			>
			<form [formGroup]="rightsAndEasementsForm">
				<div>
					<label>Are There Any Known Rights Of Ways Or Easements?</label>
					<mat-radio-group formControlName="rightsAndEasements">
						<mat-radio-button [value]="true">Yes</mat-radio-button>
						<mat-radio-button [value]="false">No</mat-radio-button>
					</mat-radio-group>
				</div>
				<mat-form-field
					*ngIf="rightsAndEasementsForm.get('rightsAndEasementsDetails')"
					appearance="outline"
					floatLabel="always"
					style="width: 100%">
					<mat-label>Details</mat-label>
					<textarea
						style="width: 100%"
						matInput
						type="text"
						placeholder="Details"
						formControlName="rightsAndEasementsDetails"></textarea>
				</mat-form-field>
				<div class="actions" style="margin-top: 1.5rem">
					<button mat-button matStepperPrevious>Back</button>
					<button mat-button (click)="saveRightsAndEasements()">Save</button>
					<button
						mat-raised-button
						[disabled]="rightsAndEasementsForm.invalid"
						matStepperNext>
						Next
					</button>
				</div>
			</form>
		</mat-step>
		<mat-step
			label="Property Accessibility/Adaptations"
			[stepControl]="accessibilityForm">
			<ng-template matStepLabel
				>Property Accessibility/Adaptations
				<span *ngIf="!accessibilityForm.pristine" class="unsaved"
					>(Unsaved)</span
				></ng-template
			>
			<form [formGroup]="accessibilityForm" class="row">
				<app-group-title title="Step Free Access">
					<label>Is There Step Free Access To The Property?</label>
					<mat-radio-group formControlName="stepFreeAccess">
						<mat-radio-button [value]="true">Yes</mat-radio-button>
						<mat-radio-button [value]="false">No</mat-radio-button>
					</mat-radio-group>
					<p>
						Step-free access from the street to the inside the property (this
						can include ramps/lifts). If there is a dropped kerb, it is
						advisable to disclose.
					</p>
				</app-group-title>
				<app-group-title title="Wet room/ level access shower">
					<label>Is There A Wet Room Or Level Access Shower?</label>
					<mat-radio-group formControlName="wetRoom">
						<mat-radio-button [value]="true">Yes</mat-radio-button>
						<mat-radio-button [value]="false">No</mat-radio-button>
					</mat-radio-group>
					<p>
						The presence of a wet floor shower room, level access or walk in
						shower, and/or specialist bath
					</p>
				</app-group-title>
				<app-group-title title="Lateral living">
					<label
						>Is There Essential Living Accommodation All On The Entrance
						Level?</label
					>
					<mat-radio-group formControlName="lateralLiving">
						<mat-radio-button [value]="true">Yes</mat-radio-button>
						<mat-radio-button [value]="false">No</mat-radio-button>
					</mat-radio-group>
					<p>
						Where there is a single storey property, or a property that has
						essential facilities on the entrance level. This my include
						properties where there might be other rooms above the entrance level
						but a living room, kitchen, bathroom and at least one bedroom is
						located on the entry level.
					</p>
				</app-group-title>
				<mat-form-field
					appearance="outline"
					floatLabel="always"
					style="width: 100%">
					<mat-label>Additional Details</mat-label>
					<textarea
						style="width: 100%"
						matInput
						type="text"
						placeholder=""
						formControlName="details"></textarea>
				</mat-form-field>
			</form>
			<div class="actions" style="margin-top: 1.5rem">
				<button mat-button matStepperPrevious>Back</button>
				<button mat-button (click)="saveAccessibility()">Save</button>
				<button
					mat-raised-button
					[disabled]="accessibilityForm.invalid"
					matStepperNext>
					Next
				</button>
			</div>
		</mat-step>
		<mat-step
			label="Confirmation"
			[stepControl]="confirmationForm"
			errorMessage="Missing Values">
			<ng-template matStepLabel
				>Confirmation
				<span *ngIf="!confirmationForm.pristine" class="unsaved"
					>(Unsaved)</span
				></ng-template
			>
			<form [formGroup]="confirmationForm">
				<div>
					<mat-checkbox formControlName="confirmAllIsAccurate">
						<span
							>I Confirm That The Information Provided Is Correct To The Best Of
							My Knowledge.</span
						>
					</mat-checkbox>
				</div>
				<div>
					<mat-checkbox formControlName="confirmUnderstandPublic">
						<span
							>I Understand That Data Entered Here Will Be Made Public And Have
							Not Shared Any Private/Personal Information (Mine Or That Of
							Other).</span
						>
					</mat-checkbox>
				</div>
				<div>
					<app-signature formControlName="signature"></app-signature>
					<!--  -->
				</div>
				<div>
					<mat-form-field
						appearance="outline"
						floatLabel="always"
						style="width: 100%">
						<mat-label>Full Name</mat-label>
						<input matInput type="string" formControlName="fullName" />
						<!-- [appMoneyInput] -->
					</mat-form-field>
				</div>
				<div class="actions" style="margin-top: 1.5rem">
					<button mat-button matStepperPrevious>Back</button>

					<button
						mat-raised-button
						color="primary"
						(click)="Submit()"
						[disabled]="form.invalid">
						Submit
					</button>
				</div>
			</form>
		</mat-step>
	</mat-stepper>
</div>
