<mat-card
	appearance="outlined"
	[ngClass]="{
		'card-disabled': state == 'disabled',
		'card-completed': state == 'completed'
	}">
	<mat-card-header>
		<mat-card-title>
			<!-- <h3> -->
			Documents ({{
				((docsArray || { controls: [] }).controls || []).length || 0
			}})
			<!-- </h3>
			<div class="spacer"></div>
			<div>
				<button mat-icon-button (click)="toggleForm()">
					<mat-icon>{{ formOpen ? "expand_less" : "expand_more" }}</mat-icon>
				</button>
			</div> -->
		</mat-card-title>
	</mat-card-header>
	<mat-card-content
		*ngIf="formOpen"
		[@inOutAnimation]
		[appDropFiles]
		[taskId]="taskId"
		[location]="location"
		[propertyId]="propertyId"
		[disabled]="disabled || docsForm.disabled"
		(filesUploaded)="dragAddDocuments($event)">
		<ng-form (ngSubmit)="save()">
			<div *ngIf="docsArray?.controls?.length == 0">
				There are no documents currently attached to this task.
			</div>
			<div
				*ngFor="
					let docFormGroup of docsArray.controls;
					let i = index;
					trackBy: trackByFn
				"
				class="docs-list-item"
				[class.disabled]="disabled || docFormGroup.disabled">
				<div (click)="openDocument(docFormGroup)">
					<mat-icon
						class="doc-icon"
						[class.accessibly]="currentDoc(docFormGroup)"
						[class.unaccessible]="!currentDoc(docFormGroup)"
						>{{ docIcon(docFormGroup.get("name")?.value) }}</mat-icon
					>
				</div>
				<div
					class="docs-name"
					[class.accessibly]="currentDoc(docFormGroup)"
					[class.unaccessible]="!currentDoc(docFormGroup)"
					(click)="openDocument(docFormGroup)">
					{{ docFormGroup.get("name")?.value }}
				</div>
				<div class="docs-actions">
					<button
						mat-icon-button
						(click)="removeDocument(i)"
						[disabled]="disabled || docFormGroup.disabled"
						type="button">
						<mat-icon>delete_forever</mat-icon>
					</button>
				</div>
			</div>
			<div class="actions">
				<app-select-file-button
					text="Upload File"
					[disabled]="disabled || docsForm.disabled"
					(newFiles)="addDocuments($event)"></app-select-file-button>
			</div>
			<div class="actions">
				<button
					mat-button
					type="button"
					[disabled]="docsForm.pristine || docsForm.disabled"
					(click)="reset()">
					Reset
				</button>
				<button
					mat-raised-button
					type="submit"
					(click)="save()"
					[disabled]="
						docsForm.pristine || docsForm.invalid || docsForm.disabled
					">
					<span *ngIf="!saving"> Save </span>
					<span *ngIf="saving">
						Saving<app-loading-dots></app-loading-dots>
					</span>
				</button>
			</div>
		</ng-form>
	</mat-card-content>
</mat-card>
