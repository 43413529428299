import { HttpClient } from '@angular/common/http';

import { ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { lastValueFrom, switchMap, tap } from 'rxjs';
import { AddPersonDialogComponent } from 'src/app/form-fields/people-form/add-person-dialog/add-person-dialog.component';
import { AuthService } from 'src/app/services/auth.service';
import { BuyerSellersBackendService } from 'src/app/services/buyer-sellers-backend.service';
import {
	Chain,
	ChainsBackendService,
} from 'src/app/services/chains-backend.service';
import { ConfirmDialogService } from 'src/app/services/confirm-dialog.service';
import { PeopleBackendService } from 'src/app/services/people-backend.service';
import {
	Request,
	RequestsBackendService,
} from 'src/app/services/requests-backend.service';
import {
	TaskBackendService,
	TaskParentType,
	TaskResult,
	TaskStatus,
	TaskType,
} from 'src/app/services/task-backend.service';
import { TitleService } from 'src/app/services/title.service';
import { userType } from 'src/app/setup/httpTypes';

import { environment } from 'src/environments/environment';

@Component({
	selector: 'app-chain-no-selection',
	templateUrl: './chain-no-selection.component.html',
	styleUrls: ['./chain-no-selection.component.css'],
})
export class ChainNoSelectionComponent implements OnInit, OnDestroy {
	TaskStatus = TaskStatus;
	TaskResult = TaskResult;
	TaskType = TaskType;

	$prams: any;
	chainId = '';
	taskList: any[] = [];
	chain?: Chain;
	userEmail: any;

	suggestNewDaysToCompletion = false;

	requests: Request[] = [];

	get links() {
		return (
			(this.chain?.links || [])?.filter(
				(link: any) => link.linkType == 'property'
			) || []
		)
			.reverse()
			.map((link: any) => {
				if (link.tasks)
					link.tasks =
						link.tasks
							?.filter((t: any) => !t.isTemplate)
							?.sort((a: any, b: any) => {
								if (b.order !== undefined && a.order !== undefined)
									return <any>a.order - <any>b.order;
								let tA = 0;
								let tB = 0;

								if (a.status == TaskStatus.disabled) tA = 1;
								if (b.status == TaskStatus.disabled) tB = 1;
								if (a.status == TaskStatus.completed) tA = 2;
								if (b.status == TaskStatus.completed) tB = 2;

								if (a.isTemplate) tA = 3;
								if (b.isTemplate) tB = 3;

								if (tA != tB) return tA - tB;

								return <any>b.dateCreated - <any>a.dateCreated;
							}) || [];
				return link;
			});
	}

	get numberOfBuyerSellers() {
		let dic = new Set();
		(this.chain?.links || [])
			?.filter((link: any) => link.linkType == 'buyerSeller')
			?.forEach((link: any) => {
				dic.add(link.id);
			});
		return dic.size;
	}

	get numberOfProperties() {
		let dic = new Set();
		(this.chain?.links || [])
			?.filter((link: any) => link.linkType == 'property')
			?.forEach((link: any) => {
				dic.add(link.id);
			});
		return dic.size;
	}

	get BuyerSellers() {
		return (
			(this.chain?.links || [])
				?.filter((link: any) => link.linkType == 'buyerSeller')
				?.filter((link: any) => {
					return link?.persons?.some(
						(person: any) =>
							person.email == this.userEmail &&
							(person.userType == userType.buyerSeller ||
								person.userType == userType.buyer ||
								person.userType == userType.seller)
					);
				}) || []
		);
	}

	get buyerSellersIds() {
		return this.BuyerSellers.map((buyerSeller: any) => buyerSeller.id);
	}

	constructor(
		public ChainsBackend: ChainsBackendService,
		public buyerSellerBackend: BuyerSellersBackendService,
		public AuthService: AuthService,
		public Router: Router,
		public route: ActivatedRoute,
		public cd: ChangeDetectorRef,
		public requestsBackend: RequestsBackendService,
		public TaskBackend: TaskBackendService,
		public PeopleBackend: PeopleBackendService,
		public titleService: TitleService,
		public Dialog: MatDialog,
		public ConfirmDialog: ConfirmDialogService
	) {}

	async ngOnInit() {
		this.titleService.title = 'My Chain';
		this.$prams = this.route.parent?.params
			.pipe(
				tap((params) => {
					this.chainId = params['chainId'];
				}),
				switchMap(async (params) => {
					if (this.route.snapshot.queryParams['session_id']) {
						await this.buyerSellerBackend.paid({
							sessionId: this.route.snapshot.queryParams['session_id'],
						});
					}
					return await this.getChain();
				})
			)
			.subscribe((params) => {});

		this.userEmail = (await this.AuthService.getUser())?.attributes?.['email'];
		if (this.AuthService.spoofAs && this.AuthService.spoofAs.length > 0)
			this.userEmail = this.AuthService.spoofAs;
	}

	ngOnDestroy(): void {
		if (this.$prams) this.$prams.unsubscribe();
	}

	loadingRequests = false;
	async getRequests() {
		this.loadingRequests = true;
		let r = await this.requestsBackend.getList({
			chainId: this.chainId,
		});

		this.requests = r?.data || [];
		this.loadingRequests = false;
	}

	async getChain() {
		let r = await this.ChainsBackend.getOne(this.chainId);
		this.chain = r?.data;
		this.chain?.links?.forEach((link: any) => {
			if (link.tasks)
				link.tasks = this.taskOrder(link.tasks, this.chainId, link.id);
		});

		if (!this.chain.id) return;

		await this.getRequests();

		//check for missing people
		let missingPeopleR = await this.PeopleBackend.missingPersons(this.chainId);
		let missingPeople = missingPeopleR.data;
		while (missingPeople.length > 0) {
			let dr = this.Dialog.open(AddPersonDialogComponent, {
				data: {
					title: `The Missing ${missingPeople[0].userType}`,
					description: `${missingPeople[0].propertyNickname} (${missingPeople[0].propertyAddress}) is missing a ${missingPeople[0].userType} please add them now.`,
					chainId: this.chainId,
					propertyId: missingPeople[0].propertyId,
					userType: missingPeople[0].userType,
				},
			});
			let p = await lastValueFrom(dr.afterClosed());
			if (p) {
				missingPeopleR = await this.PeopleBackend.missingPersons(this.chainId);
				missingPeople = missingPeopleR.data;
			} else {
				missingPeople.shift();
			}
		}

		//check for missing sellers or buyers

		if (this.chain?.links?.[0]?.linkType == 'property') {
			let inPeople = this.chain?.links?.[0]?.persons?.some((person: any) => {
				return person.email == this.userEmail;
			});
			if (inPeople) {
				let r = await this.ConfirmDialog.confirm({
					title: 'Missing Buyer',
					message: `The last property in the chain is missing a buyer. Please add them now.`,
					confirmText: 'Add Buyer',
				});

				if (r) {
					this.Router.navigate([
						'/chain',
						this.chainId,
						'new-buyer-seller',
						this.chain?.links?.[this.chain?.links?.length - 1]?.id,
						'Buyer',
					]);
				}
			}
		}
		if (
			this.chain?.links?.[this.chain?.links?.length - 1]?.linkType == 'property'
		) {
			let inPeople = this.chain?.links?.[
				this.chain?.links?.length - 1
			]?.persons?.some((person: any) => {
				return person.email == this.userEmail;
			});
			if (inPeople) {
				let r = await this.ConfirmDialog.confirm({
					title: 'Missing Seller',
					message: `The first property in the chain is missing a seller. Please add them now.`,
					confirmText: 'Add Seller',
				});
				///chain/c01f3990-7390-49b4-a296-2757f49eedbc/new-buyer-seller/aa97f27c-d0c2-436d-9a2f-d30cf3722002/Seller

				if (r) {
					this.Router.navigate([
						'/chain',
						this.chainId,
						'new-buyer-seller',
						this.chain?.links?.[0]?.id,
						'Seller',
					]);
				}
			}
		}

		//check if person is on any of the buyer sellers
		let buyerSellersIds = this.buyerSellersIds;

		if (buyerSellersIds.length > 0) {
			//check if they have voted on an exchange date
			let hasVoted = this.chain?.exchangeDate?.suggestedDates?.some(
				(vote: any) => {
					if (vote.votes[buyerSellersIds[0]] != undefined) {
						return true;
					}
					return false;
				}
			);

			if (!hasVoted) {
				let hasDates =
					(this.chain?.exchangeDate?.suggestedDates?.length || 0) > 0;

				let textForNoDates = `You have not submitted an Exchange Date yet. Please add your preferred Exchange Date at the top of this page.`;
				let textForDatesButNotVoted = `You have not voted on an exchange date. Please vote now at the top of this page or submit one that works for you.`;

				let r = await this.ConfirmDialog.confirm({
					title: `${
						hasDates ? 'Submit' : 'Vote For'
					} Your Preferred Exchange Date`,
					message: hasDates ? textForDatesButNotVoted : textForNoDates,
					confirmText: 'OK',
				});

				// if (r) {
				// 	document.body.scrollTop = 0; // For Safari
				// 	document.documentElement.scrollTop = 0;
				// }
			}
		}
	}

	otherNumber: number | undefined = undefined;
	otherInputChange(target: any) {
		this.otherNumber = parseInt(target.value);
		if (this.otherNumber != undefined) {
			if (this.otherNumber < 28) this.otherNumber = 28;
			if (this.otherNumber > 120) this.otherNumber = 120;
		}
	}

	voteCount(votes: { [buyerSellerId: string]: boolean | undefined }) {
		let dic = new Set();
		Object.keys(votes)
			?.filter((key) => !!votes[key])
			?.forEach((key) => {
				dic.add(key);
			});

		return dic.size;
	}

	everyOneVoted(vote: {
		number: number;
		votes: {
			[buyerSellerId: string]: boolean | undefined;
		};
	}) {
		return Object.keys(vote.votes).length == this.numberOfBuyerSellers;
	}

	taskOrder(
		tasks: {
			id: string;
			title: string;
			status?: TaskStatus;
			result?: TaskResult;
			issueCount?: number;
			estimatedCompletionDate?: Date;
			dateCreated: Date;
			dateCompleted?: Date;
			canBeOpened?: boolean;
			taskType?: TaskType;
			parentType?: TaskParentType;
			parentId?: string;
			chainId?: string;
		}[],
		chainId: string,
		parentId: string
	) {
		return tasks
			.map((_t) => {
				_t.canBeOpened = true;
				_t.taskType = TaskType.milestone;
				_t.parentType = TaskParentType.Property;
				_t.parentId = parentId;
				_t.chainId = chainId;
				return _t;
			})
			.sort((a, b) => {
				let OrdA = 0;
				let OrdB = 0;
				if (a.status == TaskStatus.inProgress) OrdA = 0;
				if (a.status == TaskStatus.inProgress) OrdA = 0;

				if (a.status == TaskStatus.notStarted) OrdA = 1;
				if (b.status == TaskStatus.notStarted) OrdB = 1;

				if (a.status == TaskStatus.disabled) OrdA = 2;
				if (b.status == TaskStatus.disabled) OrdB = 2;

				if (a.status == TaskStatus.completed) OrdA = 3;
				if (b.status == TaskStatus.completed) OrdB = 3;

				if (a.status == TaskStatus.cancelled) OrdA = 4;
				if (b.status == TaskStatus.cancelled) OrdB = 4;

				if (OrdA != OrdB) return OrdA - OrdB;

				if (a?.issueCount || (0 > 0 && b?.issueCount) || 0 == 0) return -1;
				if (b?.issueCount || (0 > 0 && a?.issueCount) || 0 == 0) return 1;

				if (
					(a?.estimatedCompletionDate || 0) < (b?.estimatedCompletionDate || 0)
				)
					return -1;
				if (
					(b?.estimatedCompletionDate || 0) < (a?.estimatedCompletionDate || 0)
				)
					return 1;

				if ((a?.dateCreated || 0) < (b?.dateCreated || 0)) return -1;
				if ((b?.dateCreated || 0) < (a?.dateCreated || 0)) return 1;

				if ((a?.dateCompleted || 0) < (b?.dateCompleted || 0)) return -1;
				if ((b?.dateCompleted || 0) < (a?.dateCompleted || 0)) return 1;

				return a.title.localeCompare(b.title);

				//return 0;
			});
	}

	async save() {
		let update: {
			exchangeDate?: any;
			completionDate?: any;
		} = {};

		if (this.chain?.exchangeDate)
			update.exchangeDate = this.chain?.exchangeDate;
		if (this.chain?.completionDate)
			update.completionDate = this.chain?.completionDate;

		let r = await this.ChainsBackend.patch(this.chainId, update);
		this.chain = r.data;
	}

	voteNoCount(votes: { [buyerSellerId: string]: boolean | undefined }) {
		let dic = new Set();
		Object.keys(votes || {})
			?.filter((key) => votes[key] === false)
			?.forEach((key) => {
				dic.add(key);
			});

		return dic.size;
	}

	percentYes(vote: {
		number: number;
		votes: {
			[buyerSellerId: string]: boolean | undefined;
		};
	}) {
		return (
			Math.round(
				(this.voteCount(vote.votes) / this.numberOfBuyerSellers) * 100
			) + '%'
		);
	}

	percentNo(vote: {
		number: number;
		votes: {
			[buyerSellerId: string]: boolean | undefined;
		};
	}) {
		return (
			Math.round(
				(this.voteNoCount(vote.votes) / this.numberOfBuyerSellers) * 100
			) + '%'
		);
	}

	logoURL(property: any) {
		let color = 'green';
		if (property?.issueCount > 0) color = 'yellow';
		if (property?.id == 'unknown') color = 'red';
		if (property?.isPlaceHolder) color = 'red';
		if (property?.postCode == 'No Postcode') color = 'red';
		return `/assets/colorIcons/${color}.svg`;
	}

	fnTrackBy(index: number, item: any) {
		return item?.id || index;
	}

	arrayToDate(_date: [number, number, number] | undefined): Date | undefined {
		if (!_date) return undefined;
		let newDate = new Date();
		newDate.setFullYear(_date[0]);
		newDate.setMonth(_date[1]);
		newDate.setDate(_date[2]);
		return newDate;
	}
}
