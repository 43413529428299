import {
	Directive,
	EventEmitter,
	HostListener,
	Input,
	Output,
} from '@angular/core';
import {
	TaskBackendService,
	TaskDocument,
} from '../services/task-backend.service';
import { UploadFilesService } from '../services/upload-files.service';
import { ConfirmDialogService } from '../services/confirm-dialog.service';

@Directive({
	selector: '[appDropFiles]',
})
export class DropFilesDirective {
	_url = '';
	@Output() filesUploaded = new EventEmitter<TaskDocument>();
	@Input() taskId? = '';
	@Input() propertyId? = '';
	@Input() location: 'task' | 'issue' | 'material' = 'task';
	@Input() issueId? = '';
	@Input() disabled? = false;
	@Input() acceptExtensions?: string[];

	constructor(
		public uploadFilesService: UploadFilesService,
		public taskBackend: TaskBackendService,
		public confirmDialog: ConfirmDialogService
	) {}

	async url(file: File) {
		if (this._url) {
			return { url: this._url, id: '', fields: {} };
		}
		if (this.taskId) {
			let r = await this.taskBackend.getDocumentUploadUrl({
				taskId: this.taskId,
				issueId: this.issueId,
				fileName: file.name,
				contentType: file.type,
			});

			return r;
		}
		return {
			url: '',
			id: '',
			fields: {},
		};
	}

	@HostListener('drop', ['$event'])
	async dropFiles(event: DragEvent) {
		event.preventDefault();
		if (this.disabled) return;
		const files = event?.dataTransfer?.files;
		//console.log(files);
		let acceptExtensionsReg: RegExp | undefined;
		if (this.acceptExtensions && this.acceptExtensions.length > 0) {
			acceptExtensionsReg = new RegExp(
				`(${this.acceptExtensions.join('|')})$`,
				'i'
			);
		}
		if (files && files.length > 0) {
			for (let i = 0; i < files.length; i++) {
				const file = files[i];
				if (acceptExtensionsReg && !acceptExtensionsReg.test(file.name)) {
					alert(`File ${file.name} has an invalid extension`);
					let r = await this.confirmDialog.confirm({
						title: 'Invalid file',
						message: `File ${
							file.name
						} has an invalid extension. Only files with the following extensions are allowed: ${(
							this.acceptExtensions || []
						).join(`, `)}`,
						cancelText: false,
						confirmText: 'Ok',
					});

					continue;
				}
				let p: Promise<string | undefined> | undefined;
				if (this.location == 'material' && this.propertyId)
					p = this.uploadFilesService.uploadMaterialFile({
						file,
						propertyId: this.propertyId,
					});
				if (this.location == 'task' && this.taskId)
					p = this.uploadFilesService.uploadFile({
						file,
						taskId: this.taskId,
					});
				if (p)
					p.then((id) => {
						if (id)
							this.filesUploaded.emit({
								id: id || '',
								name: file.name,
								date: new Date(),
							});
					});
			}
		}

		event.preventDefault();
		event.stopPropagation();
	}

	@HostListener('dragover', ['$event'])
	onDropOver(event: any): any {
		event.preventDefault();
	}
}
