import { coerceBooleanProperty } from '@angular/cdk/coercion';
import {
	ChangeDetectionStrategy,
	Component,
	ElementRef,
	forwardRef,
	HostBinding,
	HostListener,
	Input,
	OnInit,
	Optional,
	ViewChild,
	Self,
} from '@angular/core';
import {
	ControlValueAccessor,
	NgControl,
	NG_VALUE_ACCESSOR,
} from '@angular/forms';
import { MatFormFieldControl } from '@angular/material/form-field';
import { Subject } from 'rxjs';
import { ConfirmDialogService } from 'src/app/services/confirm-dialog.service';
import {
	TaskBackendService,
	TaskDocument,
} from 'src/app/services/task-backend.service';
import { UploadFilesService } from 'src/app/services/upload-files.service';
import { SelectFileButtonComponent } from '../select-file-button/select-file-button.component';

@Component({
	selector: 'app-documents-input',
	templateUrl: './documents-input.component.html',
	styleUrls: ['./documents-input.component.css'],
	providers: [
		// {
		// 	provide: NG_VALUE_ACCESSOR,
		// 	useExisting: forwardRef(() => DocumentsInputComponent),
		// 	multi: true,
		// },
		{ provide: MatFormFieldControl, useExisting: DocumentsInputComponent },
	],
	//changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DocumentsInputComponent implements OnInit, ControlValueAccessor {
	_disabled = false;
	set disabled(val: boolean) {
		this._disabled = coerceBooleanProperty(val);
		// this.stateChanges.next();
		//this.ngControl.control?.disable();
	}

	get disabled() {
		return this.ngControl.control?.disabled || false;
		//return this._disabled;
	}

	documents: TaskDocument[] = [];

	set value(val: TaskDocument[]) {
		this.documents = val;
		this.stateChanges.next();
	}

	get value() {
		return this.documents;
	}

	@Input() currentDocs: TaskDocument[] = [];

	private _placeholder!: string;
	@Input()
	get placeholder() {
		return this._placeholder;
	}
	set placeholder(plh: string) {
		this._placeholder = plh;
		this.stateChanges.next();
	}

	//ngControl: NgControl | null = null;

	static nextId = 0;

	controlType = 'documents-input';
	@HostBinding() id = `documents-input-${DocumentsInputComponent.nextId++}`;

	onChange: any = () => {};
	onTouched: any = () => {};

	@Input() taskId = '';

	@ViewChild('selectFileButton') selectFileButton:
		| SelectFileButtonComponent
		| undefined;

	stateChanges = new Subject<void>();

	constructor(
		@Optional() @Self() public ngControl: NgControl,
		public _elementRef: ElementRef,
		public uploadFilesService: UploadFilesService,
		public taskBackend: TaskBackendService,
		public confirmDialog: ConfirmDialogService
	) {
		if (this.ngControl != null) {
			// Setting the value accessor directly (instead of using
			// the providers) to avoid running into a circular import.
			this.ngControl.valueAccessor = this;
		}
	}

	focused = false;
	touched = false;

	onFocusIn(event: FocusEvent) {
		if (!this.focused) {
			this.focused = true;
			this.stateChanges.next();
		}
	}

	onFocusOut(event: FocusEvent) {
		if (
			!this._elementRef.nativeElement.contains(event.relatedTarget as Element)
		) {
			this.touched = true;
			this.focused = false;
			this.onTouched();
			this.stateChanges.next();
		}
	}

	onContainerClick(event: MouseEvent) {
		if ((event.target as Element).tagName.toLowerCase() != 'input') {
			this._elementRef.nativeElement.querySelector('input').focus();
		}
	}

	get empty() {
		return !this.documents || this.documents.length === 0;
	}

	@HostBinding('class.floating')
	get shouldLabelFloat() {
		return this.focused || !this.empty;
	}

	ngOnInit(): void {}

	ngOnDestroy() {
		this.stateChanges.complete();
	}

	writeValue(value: TaskDocument[]): void {
		this.value = value;
	}

	registerOnChange(fn: any): void {
		this.onChange = fn;
	}

	registerOnTouched(fn: any): void {
		this.onTouched = fn;
	}

	setDisabledState?(isDisabled: boolean): void {
		this.disabled = isDisabled;
	}

	@Input('aria-describedby') userAriaDescribedBy: string | undefined;
	setDescribedByIds(ids: string[]) {
		const controlElement =
			this._elementRef.nativeElement.querySelector('.docs-list')!;
		controlElement.setAttribute('aria-describedby', ids.join(' '));
	}

	@Input()
	get required() {
		return this._required;
	}
	set required(req) {
		this._required = coerceBooleanProperty(req);
		this.stateChanges.next();
	}
	private _required = false;

	async removeDocument(index: number) {
		if (this.disabled) return;
		let doc = this.documents[index];

		let canRemove = await this.confirmDialog.confirm({
			title: 'Remove document',
			message: `Are you sure you want to remove the "${doc.name}" document?`,
		});

		if (!canRemove) return;

		this.documents.splice(index, 1);
		this.newValue();
	}

	newDocs = [];
	async addDocuments(files: FileList | null) {
		if (this.disabled) return;
		if (files && files.length > 0) {
			for (let i = 0; i < files.length; i++) {
				const file = files[i];

				this.uploadFilesService
					.uploadFile({
						file,
						taskId: this.taskId,
					})
					.then((id) => {
						if (!this.documents) this.documents = [];
						if (id)
							this.documents.push({
								id: id,
								name: file.name,
								date: new Date(),
							});

						this.newValue();
					});
			}
		}
	}

	log(val: any) {
		console.log(val);
	}

	docIcon(fileName: string) {
		if (fileName.endsWith('.pdf')) return 'picture_as_pdf';
		if (fileName.endsWith('.png')) return 'image';
		if (fileName.endsWith('.jpg')) return 'image';
		if (fileName.endsWith('.doc') || fileName.endsWith('.docx'))
			return 'description';
		return 'insert_drive_file';
	}

	openDocument(doc: TaskDocument) {
		if (!this.currentDoc(doc)) return;
		if (this.disabled) return;
		this.taskBackend.getDocumentDownloadUrl(this.taskId, doc.id).then((r) => {
			let link = document.createElement('a');
			document.body.appendChild(link);
			link.download = doc.name;
			link.href =
				r.data +
				`&&response-content-disposition=attachment;filename=${doc.name}`;
			link.click();
			document.body.removeChild(link);
		});
	}

	newValue() {
		this.documents = [...this.documents];
		this.onChange(this.documents);
		this.stateChanges.next();
		this.ngControl?.control?.markAsDirty();
		this.ngControl?.control?.markAsTouched();
		this.ngControl?.control?.updateValueAndValidity();
		this.ngControl?.control?.parent?.markAsDirty();
		this.ngControl?.control?.parent?.markAsTouched();
	}

	currentDoc(doc: TaskDocument) {
		return !!this.currentDocs?.find((d) => d.id == doc.id);
	}

	@HostBinding('class.no-docs') get noDocs() {
		return !this.documents || this.documents.length == 0;
	}

	@HostListener('click', ['$event']) onClick(event: MouseEvent) {
		if (this.disabled) return;
		if (this.noDocs) {
			this.selectFileButton?.startSelect();
		}
	}
}
