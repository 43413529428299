import { CommonModule } from '@angular/common';
import {
	ChangeDetectionStrategy,
	Component,
	HostBinding,
	Input,
	type OnInit,
} from '@angular/core';
import { TaskStatus } from 'src/app/services/task-backend.service';

@Component({
	selector: 'app-group-title',
	templateUrl: './group-title.component.html',
	styleUrls: ['./group-title.component.css'],
})
export class GroupTitleComponent implements OnInit {
	@Input() title: string = '';
	@Input() status?: TaskStatus;
	@Input() set errors(val: boolean) {
		this.errorsClass = val;
	}
	get errors() {
		return this.errorsClass;
	}
	get canceled() {
		return this.status === TaskStatus.cancelled;
	}
	get completed() {
		return this.status === TaskStatus.completed;
	}
	get disabled() {
		return this.status === TaskStatus.disabled;
	}
	ngOnInit(): void {}

	@HostBinding('class.errors') errorsClass: boolean = false;
}
