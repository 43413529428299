import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from '../services/auth.service';
import { ChainsBackendService } from '../services/chains-backend.service';
import { TitleService } from '../services/title.service';
//import { AuthenticatorService } from '@aws-amplify/ui-angular';
//import { Auth } from 'aws-amplify';

@Component({
	templateUrl: './login.component.html',
	styleUrls: ['./login.component.css'],
})
export class LoginComponent implements OnInit, OnDestroy {
	user: any = {};

	$state = this.AuthService.$loggedIn.subscribe(async (state) => {
		if (state === 'signIn') {
			//check is they have any chains
			let numberOfChains = await this.ChainsBackendService.count();
			//if not go to create chain
			if (numberOfChains.data == 0) {
				this.Router.navigate(['/setup']);
			}
			//if so go to home
			//go to home
			else this.Router.navigate(['/']);
		}
	});

	$prams: any;
	state = '';
	code = '';

	public formFields = {
		signUp: {
			username: {
				order: 1,
				label: 'Email*',
				placeholder: 'Enter Your Email Here',
				isRequired: true,
			},
			given_name: {
				order: 2,
				label: 'First Name*',
				placeholder: 'Enter Your First Name Here',
				isRequired: true,
			},
			family_name: {
				order: 3,
				label: 'Last Name*',
				placeholder: 'Enter Your Last Name Here',
				isRequired: true,
			},
			phone_number: {
				order: 4,
				label: 'Mobile Phone Number (Confirmed with SMS Text)*',
				// placeholder: 'Enter Your Mobile Phone Here',
				dialCode: '+44',
				isRequired: true,
			},
			password: {
				order: 5,
			},
			confirm_password: {
				order: 6,
			},
		},
	};

	constructor(
		public AuthService: AuthService,
		public Router: Router,
		public route: ActivatedRoute,
		public ChainsBackendService: ChainsBackendService,
		public titleService: TitleService
	) {}

	initSate: 'signIn' | 'signUp' = 'signIn';
	ngOnInit(): void {
		this.titleService.title = 'Sign In';
		this.$prams = this.route.params.subscribe((params) => {
			this.state = params['state'];
			this.code = params['code'];

			console.log(this.state, this.code);
		});
		this.route.snapshot.queryParams['state'] == 'signUp'
			? (this.initSate = 'signUp')
			: (this.initSate = 'signIn');
	}

	ngOnDestroy(): void {
		this.$state.unsubscribe();
		this.$prams?.unsubscribe();
	}

	signOut() {
		this.AuthService.signOut();
	}

	// services = {
	// 	async handleSignUp(formData: Record<string, any>) {
	// 	  let { username, password, attributes } = formData;
	// 	  // custom username
	// 	  username = username.toLowerCase();
	// 	  attributes.email = attributes.email.toLowerCase();
	// 	  return this._auth.signUp({
	// 		username,
	// 		password,
	// 		attributes,
	// 		autoSignIn: {
	// 		  enabled: true,
	// 		},
	// 	  });
	// 	},
}
