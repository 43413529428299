import { Injectable, Injector } from '@angular/core';
import { FormGroup } from '@angular/forms';

var allFormComponents = new Set<any>();

@Injectable({
	providedIn: 'root',
})
export class FormsService {
	get allFormComponents() {
		return allFormComponents;
	}

	private blackout = false;
	private blackoutTimer: any;

	public get dirty() {
		let result = false;
		for (const c of this.allFormComponents) {
			if (c.__isDirty()) {
				result = true;
				break;
			}
		}

		if (result) {
			if (this.blackout) result = false;
			this.blackout = true;
			if (this.blackoutTimer) clearTimeout(this.blackoutTimer);
			this.blackoutTimer = setTimeout(() => {
				this.blackout = false;
				this.blackoutTimer = undefined;
			}, 1000);
		}
		return result;
	}

	constructor() {
		addEventListener(
			'beforeunload',
			(event) => {
				//console.log('beforeunload');
				if (this.dirty) {
					event.preventDefault();
					event.returnValue =
						'Are you sure you want to leave? You have unsaved changes.';
				}
			},
			{ capture: true }
		);
	}
}

export function ConfirmExitFrom() {
	return (target: any, key: string) => {
		if (!target.__myFormsInitd) {
			target.__myFormsInitd = true;
			target.originOnDestroy = target.ngOnDestroy;
			target.__formsToConfirm = new Set<string>();

			target.ngOnDestroy = function () {
				//let _FormsService = self;

				//@ts-ignore

				allFormComponents.delete(this);

				//if (target.originOnDestroy) target.originOnDestroy?.call(target);
				if (target.originOnDestroy) target.originOnDestroy?.call(this);
			};

			target.originOnInit = target.ngOnInit;
			target.ngOnInit = function () {
				if (target.originOnInit) target.originOnInit?.call(this);
				allFormComponents.add(this);
			};

			target.__isDirty = function () {
				for (const key of this.__formsToConfirm) {
					if (this[key]?.dirty) return true;
				}
				return false;
			};
		}

		target.__formsToConfirm.add(key);

		// function get(): FormGroup {
		// 	//@ts-ignore
		// 	return (<any>this)['__' + key];
		// }

		// function set(newValue: FormGroup) {
		// 	let _FormsService = self;

		// 	//@ts-ignore
		// 	if (this['__' + key]) _FormsService?.allForms.delete(this['__' + key]);
		// 	if (newValue) _FormsService?.allForms.add(newValue);

		// 	//@ts-ignore
		// 	this['__' + key] = newValue;
		// }

		// let init = (getter: boolean) => {
		// 	return function (newValue: any) {
		// 		//@ts-ignore
		// 		Object.defineProperty(this, key, {
		// 			get: get,
		// 			set: set,
		// 		});

		// 		//@ts-ignore
		// 		if (getter) return (<any>this)['__' + key];
		// 		else {
		// 			let _FormsService = self;

		// 			//@ts-ignore
		// 			if (!this.__myForms) this.__myForms = new Set<FormGroup>();

		// 			//@ts-ignore
		// 			if (this['__' + key]) {
		// 				//@ts-ignore
		// 				_FormsService?.allForms.delete(this['__' + key]);
		// 				//@ts-ignore
		// 				this.__myForms.delete(this['__' + key]);
		// 			}

		// 			if (newValue) {
		// 				_FormsService?.allForms.add(newValue);
		// 				//@ts-ignore
		// 				this.__myForms.add(newValue);
		// 			}

		// 			//@ts-ignore
		// 			this['__' + key] = newValue;
		// 		}
		// 	};
		// };

		// Object.defineProperty(target, key, {
		// 	get: init(true) as any,
		// 	set: init(false) as any,
		// });
	};
}
