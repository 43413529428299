import { NgModule } from '@angular/core';
import { ExtraOptions, RouterModule, Routes } from '@angular/router';
import { AboutComponent } from './about/about.component';
import { BuyerSellerComponent } from './buyer-seller/buyer-seller.component';
import { NewBuyerSellerComponent } from './buyer-seller/new-buyer-seller/new-buyer-seller.component';
import { ChainNoSelectionComponent } from './chains/chain/chain-no-selection/chain-no-selection.component';
import { ChainComponent } from './chains/chain/chain.component';
import { ChainsComponent } from './chains/chains.component';
import { ContactComponent } from './contact/contact.component';
import { AuthGuard } from './guard/auth.guard';
import { FormsDirtyGuard } from './guard/forms-dirty.guard';
import { HomeComponent } from './home/home.component';
import { LoginComponent } from './login/login.component';
import { PageNotFoundComponent } from './page-not-found/page-not-found.component';
import { NewPropertyComponent } from './properties/new-property/new-property.component';
import { PropertiesComponent } from './properties/properties.component';
import { PropertyComponent } from './properties/property/property.component';
import { RequestsComponent } from './requests/requests.component';
import { SettingsComponent } from './settings/settings.component';
import { SetupComponent } from './setup/setup.component';
import { TaskComponent } from './task-lists/task/task.component';
import { TasksComponent } from './tasks/tasks.component';
import { FAQComponent } from './faq/faq.component';
import { PayComponent } from './pay/pay.component';
import { LandingSwitchComponent } from './landing-switch/landing-switch.component';
import { LandingBuyerSellerComponent } from './landing-buyer-seller/landing-buyer-seller.component';
import { LandingOtherPartyComponent } from './landing-other-party/landing-other-party.component';
import { InboxComponent } from './inbox/inbox.component';
import { EmailComponent } from './inbox/email/email.component';
import { AdminInboxComponent } from './admin-inbox/admin-inbox.component';
import { SortEmailComponent } from './admin-inbox/sort-email/sort-email.component';
import { TemplatePreferencesComponent } from './settings/templatePrefrences/templatePreferences.component';
import { OfferComponent } from './offer/offer.component';
import { OfferSubmittedComponent } from './offer/offer-submitted/offer-submitted.component';
import { MaterialFormComponent } from './materialInformation/materialForm/materialForm.component';
import { MaterialInformationComponent } from './materialInformation/materialInformation.component';
import { DocsViewerComponent } from './materialInformation/docs-viewer/docs-viewer.component';
import { AMLComponent } from './AML/AML.component';
import { AMLpermissionsComponent } from './AML/AMLpermissions/AMLpermissions.component';
import { AMLReportComponent } from './AML/AML-Report/AML-Report.component';
import { UnsubscribeComponent } from './unsubscribe/unsubscribe.component';
import { AdminReportsComponent } from './admin-reports/admin-reports.component';

const routerOptions: ExtraOptions = {
	useHash: false,
	anchorScrolling: 'enabled',
	// ...any other options you'd like to use
};

const routes: Routes = [
	{
		path: 'unsubscribe',
		component: UnsubscribeComponent,
		title: 'Unsubscribe',
		data: { tabOrder: 0 },
	},
	{
		path: 'adminReport',
		component: AdminReportsComponent,
		title: 'adminReport',
		data: { tabOrder: 0 },
		canActivate: [AuthGuard],
	},
	{
		path: 'aml',
		component: AMLComponent,
		title: 'Anti-Money Laundering',
		data: { tabOrder: 0 },
		canActivate: [AuthGuard],
	},
	{
		path: 'AMLpermissions',
		component: AMLpermissionsComponent,
		title: 'Anti-Money Laundering Permissions',
		data: { tabOrder: 0 },
	},
	{
		path: 'AMLReport/:amlId',
		component: AMLReportComponent,
		title: 'Anti-Money Laundering Report',
		data: { tabOrder: 0 },
		canActivate: [AuthGuard],
	},
	{
		path: 'make-an-offer/:propertyId',
		component: OfferComponent,
		title: 'Make An Offer',
		data: { tabOrder: 0 },
	},
	{
		path: 'material-information/:propertyId',
		component: MaterialInformationComponent,
		title: 'Material Information',
		data: { tabOrder: 0 },
	},
	{
		path: 'material-document/:propertyId/:materialId/:docId',
		component: DocsViewerComponent,
		title: 'Material Information Document',
		data: { tabOrder: 0 },
	},
	{
		path: 'material-information/:propertyId/setup',
		component: MaterialFormComponent,
		canActivate: [AuthGuard],
		title: 'Material Information Setup',
		data: { tabOrder: 0 },
	},
	{
		path: 'make-an-offer/:propertyId/submitted',
		component: OfferSubmittedComponent,
		title: 'Offer Submitted',
		data: { tabOrder: 0 },
	},
	{
		path: 'welcome',
		component: LandingSwitchComponent,
		title: 'Welcome to Clear Chain',
		data: { tabOrder: 0 },
	},
	{
		path: 'welcome/buyers-sellers',
		component: LandingBuyerSellerComponent,
		title: 'Clear Chain Buyer/Seller',
		data: { tabOrder: -1 },
	},
	{
		path: 'welcome/industry',
		component: LandingOtherPartyComponent,
		title: 'Clear Chain',
		data: { tabOrder: -1 },
	},

	{
		path: 'login',
		component: LoginComponent,
		title: 'Clear Chain Login',
		data: { tabOrder: 1 },
	},
	{
		path: '',
		// pathMatch: 'full',
		component: HomeComponent,
		title: 'Clear Chain',
		canActivate: [AuthGuard],
		data: { tabOrder: 0 },
	},
	{
		path: 'setup',
		component: SetupComponent,
		title: 'Clear Chain Setup',
		canActivate: [AuthGuard],
		canDeactivate: [FormsDirtyGuard],
		data: { tabOrder: 7 },
	},
	{
		path: 'setup/:inviteId',
		component: SetupComponent,
		title: 'Clear Chain Setup',
		canActivate: [AuthGuard],
		canDeactivate: [FormsDirtyGuard],
		data: { tabOrder: 7 },
	},
	{
		path: 'chains',
		component: ChainsComponent,
		title: 'Clear Chain',
		canActivate: [AuthGuard],
		canDeactivate: [FormsDirtyGuard],
		data: { tabOrder: 3 },
	},
	{
		path: 'chain/:chainId',
		component: ChainComponent,
		title: 'Clear Chain',
		canActivate: [AuthGuard],
		canDeactivate: [FormsDirtyGuard],
		data: { tabOrder: 12 },
		children: [
			{
				path: 'overview',
				component: ChainNoSelectionComponent,
				title: 'Clear Chain',
				pathMatch: 'full',
				canDeactivate: [FormsDirtyGuard],
			},
			{
				path: 'property/:propertyId',
				component: PropertyComponent,
				canDeactivate: [FormsDirtyGuard],
				title: 'Clear Chain',
			},
			{
				path: 'buyer-seller/:buyerSellerId',
				component: BuyerSellerComponent,
				canDeactivate: [FormsDirtyGuard],
				title: 'Clear Chain',
			},
			{
				path: 'new-buyer-seller/:propertyId/:userType',
				component: NewBuyerSellerComponent,
				canDeactivate: [FormsDirtyGuard],
				title: 'Clear Chain',
			},
			{
				path: 'new-property/:buyerSellerId/:userType',
				component: NewPropertyComponent,
				canDeactivate: [FormsDirtyGuard],
				title: 'Clear Chain',
			},
			{
				path: 'property/:propertyId/milestone/:taskId',
				component: TaskComponent,
				canDeactivate: [FormsDirtyGuard],
				title: 'Clear Chain',
			},
			{
				path: 'property/:propertyId/milestone/:milestoneId/task/:taskId',
				component: TaskComponent,
				canDeactivate: [FormsDirtyGuard],
				title: 'Clear Chain',
			},
			{
				path: 'property/:propertyId/milestone/:milestoneId/task/:parentTaskId/subtask/:taskId',
				component: TaskComponent,
				canDeactivate: [FormsDirtyGuard],
				title: 'Clear Chain',
			},
			{
				path: 'buyer-seller/:buyerSellerId/milestone/:taskId',
				component: TaskComponent,
				canDeactivate: [FormsDirtyGuard],
				title: 'Clear Chain',
			},
			{
				path: 'buyer-seller/:buyerSellerId/milestone/:milestoneId/task/:taskId',
				component: TaskComponent,
				canDeactivate: [FormsDirtyGuard],
				title: 'Clear Chain',
			},
			{
				path: 'buyer-seller/:buyerSellerId/milestone/:milestoneId/task/:parentTaskId/subtask/:taskId',
				component: TaskComponent,
				canDeactivate: [FormsDirtyGuard],
				title: 'Clear Chain',
			},
			{ path: '**', redirectTo: 'overview' },
		],
	},
	{
		path: 'properties',
		component: PropertiesComponent,
		title: 'Clear Chain',
		canActivate: [AuthGuard],
		canDeactivate: [FormsDirtyGuard],
		data: { tabOrder: 4 },
	},
	{
		path: 'tasks',
		component: TasksComponent,
		title: 'Clear Chain',
		canActivate: [AuthGuard],
		canDeactivate: [FormsDirtyGuard],
		data: { tabOrder: 5 },
	},
	{
		path: 'about',
		component: AboutComponent,
		canActivate: [AuthGuard],
		canDeactivate: [FormsDirtyGuard],
		data: { tabOrder: 8 },
	},
	{
		path: 'contact',
		component: ContactComponent,
		canActivate: [AuthGuard],
		canDeactivate: [FormsDirtyGuard],
		data: { tabOrder: 9 },
	},
	{
		path: 'faq',
		component: FAQComponent,
		canActivate: [AuthGuard],
		canDeactivate: [],
		data: { tabOrder: 10 },
	},
	{
		path: 'requests',
		component: RequestsComponent,
		canActivate: [AuthGuard],
		canDeactivate: [FormsDirtyGuard],
		data: { tabOrder: 6 },
	},
	{
		path: 'inbox',
		component: InboxComponent,
		canActivate: [AuthGuard],
		canDeactivate: [FormsDirtyGuard],
		data: { tabOrder: 6.5 },
	},
	{
		path: 'inbox/:emailId',
		component: EmailComponent,
		canActivate: [AuthGuard],
		canDeactivate: [FormsDirtyGuard],
		data: { tabOrder: 6.6 },
	},
	{
		path: 'Admin/Inbox',
		component: AdminInboxComponent,
		canActivate: [AuthGuard],
		canDeactivate: [FormsDirtyGuard],
		data: { tabOrder: 100 },
	},
	{
		path: 'Admin/Inbox/:emailId',
		component: SortEmailComponent,
		canActivate: [AuthGuard],
		canDeactivate: [FormsDirtyGuard],
		data: { tabOrder: 100 },
	},
	{
		path: 'settings',
		component: SettingsComponent,
		canActivate: [AuthGuard],
		canDeactivate: [FormsDirtyGuard],
		data: { tabOrder: 11 },
	},
	{
		path: 'settings/templates',
		component: TemplatePreferencesComponent,
		canActivate: [AuthGuard],
		canDeactivate: [FormsDirtyGuard],
		data: { tabOrder: 12 },
	},
	{
		path: 'payment',
		component: PayComponent,
		title: 'Payment Needed',
		canActivate: [AuthGuard],
		canDeactivate: [],
		data: { tabOrder: 20 },
	},
	{ path: 'error', component: PageNotFoundComponent, canActivate: [AuthGuard] },
	{ path: '**', component: PageNotFoundComponent, canActivate: [AuthGuard] },
];

@NgModule({
	imports: [RouterModule.forRoot(routes, routerOptions)],
	exports: [RouterModule],
})
export class AppRoutingModule {}
