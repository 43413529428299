<h1 mat-dialog-title>
	{{ _data.actions == "edit" ? "Edit" : "Add" }} Heat Source
</h1>
<mat-dialog-content style="max-width: 600px">
	<form
		[formGroup]="form"
		style="
			display: flex;
			justify-content: center;
			flex-wrap: wrap;
			max-width: 600px;
			padding: 1rem;
			gap: 0 1rem;
		">
		<mat-form-field appearance="outline" floatLabel="always">
			<mat-label>Source Type</mat-label>
			<mat-select formControlName="type">
				<mat-option value="Electricity">Electricity</mat-option>
				<mat-option value="Natural Gas">Natural Gas</mat-option>
				<mat-option value="Oil">Oil</mat-option>
				<mat-option value="Diesel">Diesel</mat-option>
				<mat-option value="Propane">Propane</mat-option>
				<mat-option value="Wood">Wood</mat-option>
				<mat-option value="Peat">Peat</mat-option>
				<mat-option value="Coal">Coal</mat-option>
				<mat-option value="Other">Other</mat-option>
			</mat-select>
			<!-- [appMoneyInput] -->
		</mat-form-field>
		<mat-form-field
			appearance="outline"
			floatLabel="always"
			*ngIf="form.get('cost')">
			<mat-label>Annual Cost</mat-label>
			<span matTextPrefix>£&nbsp;</span>
			<input
				matInput
				type="string"
				[appMoneyInput]
				placeholder="I.E. 600"
				required
				formControlName="cost" />
			<!-- [appMoneyInput] -->
		</mat-form-field>
		<mat-form-field
			*ngIf="form.get('otherDetails')"
			appearance="outline"
			floatLabel="always"
			style="width: 100%">
			<mat-label>Description Of Heat Source</mat-label>
			<textarea
				style="width: 100%"
				matInput
				type="text"
				placeholder=""
				formControlName="otherDetails"></textarea>
		</mat-form-field>
	</form>
</mat-dialog-content>
<mat-dialog-actions>
	<button mat-button [mat-dialog-close]="false" type="button">Cancel</button>
	<button
		mat-button
		[mat-dialog-close]="form.value"
		[disabled]="form.disabled || form.invalid"
		type="button"
		cdkFocusInitial>
		Save
	</button>
</mat-dialog-actions>
