import { HttpClient } from '@angular/common/http';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { lastValueFrom } from 'rxjs';
import { environment } from 'src/environments/environment';
import { AuthService } from '../services/auth.service';
import {
	Chain,
	ChainsBackendService,
} from '../services/chains-backend.service';
import { TitleService } from '../services/title.service';
import { userType } from '../setup/httpTypes';

@Component({
	templateUrl: './chains.component.html',
	styleUrls: ['./chains.component.css'],
})
export class ChainsComponent implements OnInit, OnDestroy {
	loading = true;
	chains: Chain[] = [];
	user: any = { attributes: { email: '' } };
	constructor(
		public ChainsBackend: ChainsBackendService,
		public AuthService: AuthService,
		public Router: Router,
		public titleService: TitleService
	) {}

	ngOnInit(): void {
		this.getUser();
		this.getChains();
		this.titleService.title = 'Chains';
	}

	ngOnDestroy(): void {}

	async getUser() {
		this.user = await this.AuthService.getUser();
		console.log(this.user);
	}

	async getChains() {
		let r = await this.ChainsBackend.getList();

		this.chains = r.data.map((chain: any) => {
			chain.links.forEach((property: any) => {
				property.partOfProp = this.emailInProp(
					property,
					this.user?.attributes?.email || ''
				); //this.random()
			});
			return chain;
		});

		this.chains = this.chains.map((_chain: any) => {
			if (_chain?.links?.[0]) {
				let firstLink = _chain.links[0];
				// check if start of chain is a person or a property
				if (firstLink.linkType == 'property') {
					_chain.links.unshift({
						id: 'unknown',
						linkType: 'buyerSeller',
						propertyId: firstLink.id,
						userType: userType.buyer,
						people: [],
					});
				} else if (
					firstLink.sellingPropertyState != 'noProperty' &&
					firstLink.sellingPropertyState != 'noSelection'
				) {
					_chain.links.unshift({
						id: 'unknown',
						linkType: 'property',
						buyerSellerId: firstLink.id,
						userType: userType.buyer,
						agents: [],
					});
				}

				// check if end of chain is a person or a property
				let lastLink = _chain.links[_chain.links.length - 1];
				if (lastLink.linkType == 'property') {
					_chain.links.push({
						id: 'unknown',
						linkType: 'buyerSeller',
						propertyId: lastLink.id,
						userType: userType.seller,
						people: [],
					});
				} else if (
					lastLink.buyingPropertyState != 'noProperty' &&
					lastLink.buyingPropertyState != 'noSelection'
				) {
					_chain.links.push({
						id: 'unknown',
						linkType: 'property',
						buyerSellerId: lastLink.id,
						userType: userType.seller,
						agents: [],
					});
				}
			}

			return _chain;
		});
		this.loading = false;
	}

	random() {
		return true; //Math.random() > 0.5 ? true : false
	}

	propsFiltered(chain: any) {
		return chain.links.filter((property: any) => {
			return property.linkType == 'property'; //property.partOfProp === true
		});
	}

	emailInProp(link: any, email: string) {
		if (link?.persons?.find((_user: any) => _user.email == email)) return true;
		return false;
	}

	goto(chain: any) {
		this.Router.navigate(['/chain', chain.id]);
	}

	logoURL(property: any) {
		let color = 'green';
		if (property?.issueCount > 0) color = 'yellow';
		if (property?.isPlaceHolder) color = 'red';
		if (property?.postCode == 'No Postcode') color = 'red';
		return `/assets/colorIcons/${color}.svg`;
	}

	fnTrackBy(index: number, item: any) {
		return item?.id || index;
	}

	me(buyerSeller: any) {
		if (buyerSeller.linkType == 'buyerSeller') {
			return buyerSeller?.persons?.some(
				(person: any) => person.email == this.user?.attributes?.email
			);
		}
		return false;
	}

	likeUrl(chain: Chain, link: any) {
		if (link.linkType == 'property') {
			if (link.id == 'unknown') {
				//find next link
				let nextLink: any;
				let linkIndex = chain?.links?.indexOf(link) || 0;
				if (linkIndex == 0) nextLink = chain?.links?.[1];
				else nextLink = chain?.links?.[linkIndex - 1];

				let inPeople = nextLink?.persons?.some((person: any) => {
					return person.email == this.user.attributes.email;
				});

				if (inPeople)
					return ['new-property', link.buyerSellerId, link.userType];
				return null;
			}
			return ['property', link.id];
		} else {
			if (link.id == 'unknown') {
				//find next link
				let nextLink: any;
				let linkIndex = chain?.links?.indexOf(link) || 0;
				if (linkIndex == 0) nextLink = chain?.links?.[1];
				else nextLink = chain?.links?.[linkIndex - 1];
				if (
					nextLink?.persons?.some((person: any) => {
						return person.email == this.user.attributes.email;
					})
				)
					return ['new-buyer-seller', link.propertyId, link.userType];
				return null;
			} else return ['buyer-seller', link.id];
		}

		return [link.linkType == 'property' ? 'property' : 'buyer-seller', link.id];
	}
}
