import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component } from '@angular/core';
import { FormBuilder, FormControl, Validators } from '@angular/forms';

@Component({
	selector: 'app-send-offer-request',
	templateUrl: './SendOfferRequest.component.html',
	styleUrls: ['./SendOfferRequest.component.css'],
})
export class SendOfferRequestComponent {
	form = this.fb.group<{
		email: FormControl<string | null>;
		firstName: FormControl<string | null>;
		lastName: FormControl<string | null>;
		emailBody?: FormControl<string | null>;
	}>({
		email: this.fb.control('', [Validators.required]),
		firstName: this.fb.control('', [Validators.required]),
		lastName: this.fb.control('', [Validators.required]),
		emailBody: this.fb.control(null, []),
	});
	constructor(public fb: FormBuilder) {}
}
