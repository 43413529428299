<div class="container" style="gap: 1rem">
	<div
		*ngFor="let page of imgs; let i = index; trackBy: trackByFn"
		style="position: relative; max-width: 1600px">
		<img
			class="mat-elevation-z1"
			[src]="page.url"
			alt="No documents"
			style="max-width: 100%" />
		<div style="position: absolute; top: 1rem; right: 1rem">
			({{ page.page + 1 }} of {{ imgs.length + 1 }})
		</div>
	</div>
</div>
<div class="center-center" *ngIf="loading">
	<mat-spinner></mat-spinner>
	<div>This can take up to a minute to finnish processing.</div>
</div>
