import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import { FormBuilder, FormControl, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';

export interface parkingSpotData {
	type?: string | null;
	cost?: string | null;
	numberOfSpots?: number | string | null;
	notes?: string | null;
}

export interface parkingSpotDialogData {
	parkingSpotData: parkingSpotData;
	actions: 'edit' | 'add';
}

@Component({
	selector: 'app-edit-parking-spot',
	templateUrl: './editParkingSpot.component.html',
	styleUrls: ['./editParkingSpot.component.css'],
})
export class EditParkingSpotComponent {
	form = this.buildForm(this._data.parkingSpotData);

	types: string[] = [];

	constructor(
		@Inject(MAT_DIALOG_DATA)
		public _data: parkingSpotDialogData,
		public fb: FormBuilder
	) {}

	ngOnInit() {
		this.form = this.buildForm(this._data.parkingSpotData);
	}

	ngOnDestroy() {}

	buildForm(data?: parkingSpotData) {
		let fb = this.fb.group<{
			type: FormControl<string | null>;
			cost?: FormControl<string | null>;
			numberOfSpots?: FormControl<number | string | null>;
			notes?: FormControl<string | null>;
		}>({
			type: this.fb.control(null, [Validators.required]),
			cost: this.fb.control(null, [Validators.required]),
			numberOfSpots: this.fb.control(null, [Validators.required]),
			notes: this.fb.control(null, []),
		});

		if (data) {
			fb.patchValue(data);
		}

		return fb;
	}
}
