import { Component, Inject } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { InquiryBackendService } from 'src/app/services/inquiry-backend.service';
import { userType } from 'src/app/setup/httpTypes';

@Component({
	templateUrl: './new-inquiry.component.html',
	styleUrls: ['./new-inquiry.component.css'],
})
export class NewInquiryComponent {
	inquiryForm = this.fb.group({
		inquiry: ['', [Validators.required, Validators.minLength(5)]],
		userType: ['Select Who Should Respond', [Validators.required]],
	});
	saving: boolean = false;

	userTypes = [
		userType.BuyersConveyancer,
		userType.SellersConveyancer,
		userType.buyer,
		userType.seller,
		userType.sellersAgent,
		userType.mortgageBroker,
	];

	constructor(
		@Inject(MAT_DIALOG_DATA)
		public data: {
			taskId?: string;
		},
		public fb: FormBuilder,
		public inquiryBackend: InquiryBackendService,
		public dialogRef: MatDialogRef<NewInquiryComponent>
	) {
		this.inquiryForm = this.fb.group({
			inquiry: ['', [Validators.required, Validators.minLength(5)]],
			userType: ['Select Who Should Respond', [Validators.required]],
		});
	}

	async save() {
		this.saving = true;

		let response = await this.inquiryBackend.put({
			taskId: this.data.taskId,
			inquiry: (this.inquiryForm.value.inquiry || '').replace(
				/(\r\n|\n|\r)/gm,
				'\n'
			),
			userType: this.inquiryForm.value.userType as userType,
		});

		this.inquiryForm.reset();

		this.saving = false;

		this.dialogRef.close();
	}
}
