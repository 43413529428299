import { HttpClient, HttpEventType } from '@angular/common/http';
import { EventEmitter, Injectable } from '@angular/core';
import {
	MatBottomSheet,
	MatBottomSheetRef,
} from '@angular/material/bottom-sheet';
import { lastValueFrom, tap } from 'rxjs';
import { UploadProgressComponent } from '../form-fields/upload-progress/upload-progress.component';
import { TaskBackendService } from './task-backend.service';
import { MaterialInfoBackendService } from './materialInfoBackend.service';

@Injectable({
	providedIn: 'root',
})
export class UploadFilesService {
	que = new Map<
		symbol,
		{
			p: Promise<any>;
			step?: 'url' | 'uploading' | 'done' | 'error';
			progress?: number;
			name: string;
			id?: string;
		}
	>();

	updates$: EventEmitter<
		Map<
			symbol,
			{
				p: Promise<any>;
				step?: 'url' | 'uploading' | 'done' | 'error';
				progress?: number;
				name: string;
				id?: string;
			}
		>
	> = new EventEmitter();

	progress = 0;

	bottomSheetRef?: MatBottomSheetRef;

	constructor(
		public Http: HttpClient,
		public bottomSheet: MatBottomSheet,
		public taskBackend: TaskBackendService,
		public materialInfoBackend: MaterialInfoBackendService
	) {}

	uploadFile(fileInfo: {
		//url: string;
		//fields: { key: any };
		file: File;
		//id: string;
		taskId: string;
	}) {
		const id = Symbol();

		let p = this.taskBackend
			.getDocumentUploadUrl({
				taskId: fileInfo.taskId,
				fileName: fileInfo.file.name,
				contentType: fileInfo.file.type,
			})
			.then((r) => {
				// this.uploadFilesService
				// 	.uploadFile({
				// 		url: r.url,
				// 		file,
				// 		id: r.id,
				// 		fields: r.fields as any,
				// 	})

				let _info = this.que.get(id);
				if (_info) {
					_info.step = 'uploading';
					_info.id = r.data.id;
					_info.progress = 5;
				}
				const formData = new FormData();
				Object.entries(r.data.fields).forEach(([key, value]) => {
					formData.append(key, value);
				});
				formData.append('file', fileInfo.file);
				this.calcProgress();
				return lastValueFrom(
					this.Http.post(r.data.url, formData, {
						reportProgress: true,
						observe: 'events',
						//withCredentials: true,
						// headers: {
						// 	Accept: 'application/xml',
						// },
					}).pipe(
						tap((event) => {
							//console.log(event);
							if (event.type === HttpEventType.UploadProgress) {
								let percentDone =
									5 + Math.round((95 * event.loaded) / (event.total || 100));
								(this.que.get(id) || { progress: 0 }).progress = percentDone;
							}

							this.updates$.emit(this.que);

							this.calcProgress();
						})
					)
				).then((r) => {
					let _info = this.que.get(id);
					this.que.delete(id);

					this.calcProgress();
					return _info?.id;
				});
			})
			.catch((e) => {
				let _info = this.que.get(id);
				if (_info) {
					_info.step = 'error';
				}
				this.que.delete(id);
				this.calcProgress();
				throw e;
			});

		this.que.set(id, {
			p,
			step: 'url',
			name: fileInfo.file.name,
			progress: 0,
		});

		this.calcProgress();

		return p;
	}

	uploadMaterialFile(fileInfo: {
		//url: string;
		//fields: { key: any };
		file: File;
		//id: string;
		propertyId: string;
	}) {
		const id = Symbol();

		let p = this.materialInfoBackend
			.getDocumentUploadUrl({
				propertyId: fileInfo.propertyId,
				fileName: fileInfo.file.name,
				contentType: fileInfo.file.type,
			})
			.then((r) => {
				// this.uploadFilesService
				// 	.uploadFile({
				// 		url: r.url,
				// 		file,
				// 		id: r.id,
				// 		fields: r.fields as any,
				// 	})

				let _info = this.que.get(id);
				if (_info) {
					_info.step = 'uploading';
					_info.id = r.data.id;
					_info.progress = 5;
				}
				const formData = new FormData();
				Object.entries(r.data.fields).forEach(([key, value]) => {
					formData.append(key, value);
				});
				formData.append('file', fileInfo.file);
				this.calcProgress();
				return lastValueFrom(
					this.Http.post(r.data.url, formData, {
						reportProgress: true,
						observe: 'events',
						//withCredentials: true,
						// headers: {
						// 	Accept: 'application/xml',
						// },
					}).pipe(
						tap((event) => {
							//console.log(event);
							if (event.type === HttpEventType.UploadProgress) {
								let percentDone =
									5 + Math.round((95 * event.loaded) / (event.total || 100));
								(this.que.get(id) || { progress: 0 }).progress = percentDone;
							}

							this.updates$.emit(this.que);

							this.calcProgress();
						})
					)
				).then((r) => {
					let _info = this.que.get(id);
					this.que.delete(id);

					this.calcProgress();
					return _info?.id;
				});
			})
			.catch((e) => {
				let _info = this.que.get(id);
				if (_info) {
					_info.step = 'error';
				}
				this.que.delete(id);
				this.calcProgress();
				throw e;
			});

		this.que.set(id, {
			p,
			step: 'url',
			name: fileInfo.file.name,
			progress: 0,
		});

		this.calcProgress();

		return p;
	}

	calcProgress() {
		let total = 0;
		let count = 0;
		this.que.forEach((v) => {
			if (v.progress) {
				total += v.progress;
				count++;
			}
		});
		if (count) {
			this.progress = total / count;
		} else this.progress = 0;

		if (this.que.size == 0 && this.bottomSheetRef) {
			this.bottomSheetRef.dismiss();
			this.bottomSheetRef = undefined;
		} else if (!this.bottomSheetRef && this.que.size > 0) {
			this.bottomSheetRef = this.bottomSheet.open(UploadProgressComponent, {
				hasBackdrop: false,
				closeOnNavigation: false,
				restoreFocus: true,
			});
		}

		//console.log(this.progress);
	}
}
