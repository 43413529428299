<mat-card>
	<mat-card-header>
		<mat-card-title>Who am I?</mat-card-title>
	</mat-card-header>
	<mat-card-content class="buttons">
		<button mat-raised-button color="primary" [routerLink]="['buyers-sellers']">
			Buyer/Seller
		</button>
		<button mat-raised-button color="primary" [routerLink]="['industry']">
			Estate Agent
		</button>
		<button mat-raised-button color="primary" [routerLink]="['industry']">
			Conveyancer
		</button>
		<button mat-raised-button color="primary" [routerLink]="['industry']">
			Mortgage Broker
		</button>
		<button mat-flat-button [routerLink]="['login']">Login/Sign Up</button>
	</mat-card-content>
</mat-card>

<!-- <div class="question">Who am I?</div> -->
