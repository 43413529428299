import { Component } from '@angular/core';
import { Email, EmailBackendService } from '../services/email-backend.service';
import { TitleService } from '../services/title.service';
import { Router } from '@angular/router';

@Component({
	templateUrl: './inbox.component.html',
	styleUrls: ['./inbox.component.css'],
})
export class InboxComponent {
	loading = false;
	emails: Email[] = [];
	filter: 'All' | 'SENT' | 'RECEIVED' = 'All';
	pool?: any;
	lastKey?: string | undefined;
	_lastKey?: string | undefined;

	constructor(
		public emailBackend: EmailBackendService,
		public titleService: TitleService,
		public router: Router
	) {}

	async ngOnInit() {
		this.titleService.title = 'Inbox';
		await this.loadEMails();
		// this.pool = setInterval(async () => {
		// 	await this.loadEMails(false);
		// }, 1000 * 10);
	}

	ngOnDestroy() {
		//clearInterval(this.pool);
	}

	async loadEMails(showLoading = true) {
		if (showLoading) this.emails = [];
		if (showLoading) this.loading = true;
		let emailR = await this.emailBackend.getList(this.filter, this.lastKey);
		if (!this.lastKey) this.emails = emailR.data || [];
		else this.emails = [...this.emails, ...(emailR.data || [])];
		this._lastKey = emailR.lastKey;
		if (showLoading) this.loading = false;
	}

	openEmail(email: Email) {
		this.router.navigate(['/', 'inbox', email.passthroughEmailId]);
	}

	async setFilter(ev: any) {
		this.filter = ev.value;
		this._lastKey = undefined;
		this.lastKey = undefined;
		await this.loadEMails();
	}

	emailTrackBy(index: number, email: Email) {
		return email.id;
	}

	async loadMore() {
		//clearInterval(this.pool);
		this.lastKey = this._lastKey;
		await this.loadEMails(false);
	}
}
