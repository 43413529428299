import { HttpClient } from '@angular/common/http';
import {
	ChangeDetectorRef,
	Component,
	Input,
	OnDestroy,
	OnInit,
} from '@angular/core';
import {
	AbstractControl,
	AsyncValidatorFn,
	FormBuilder,
	FormControl,
	FormGroup,
	NG_VALIDATORS,
	NG_VALUE_ACCESSOR,
	ValidationErrors,
	ValidatorFn,
	Validators,
} from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import {
	filter,
	lastValueFrom,
	pairwise,
	startWith,
	Subscription,
	switchMap,
	tap,
} from 'rxjs';
import { NewRequestDialogComponent } from 'src/app/requests/new-request-dialog/new-request-dialog.component';
import { ChainsBackendService } from 'src/app/services/chains-backend.service';
import { ConfirmDialogService } from 'src/app/services/confirm-dialog.service';
import { db } from 'src/app/services/db.service';
import { PropertiesBackendService } from 'src/app/services/properties-backend.service';
import { RequestsBackendService } from 'src/app/services/requests-backend.service';
import { userType } from 'src/app/setup/httpTypes';

interface addressResponse {
	addressline1: string;
	addressline2: string;
	summaryline: string;
	number: string;
	premise: string;
	street: string;
	county: string;
	postcode: string;
	townname: string;
	latitude: string;
	longitude: string;
	grideasting: string;
	gridnorthing: string;
	localauthoritycode: string;
	uprn: string;
	usrn: string;
	changecode: string;
	toid: string;
	classificationcode: string;
	rpc: string;
	gsscode: string;
	lastupdatedate: string;
}

@Component({
	selector: 'app-address',
	templateUrl: './address.component.html',
	styleUrls: ['./address.component.css'],
	providers: [
		{
			provide: NG_VALUE_ACCESSOR,
			multi: true,
			useExisting: AddressComponent,
		},
		{
			provide: NG_VALIDATORS,
			multi: true,
			useExisting: AddressComponent,
		},
	],
})
export class AddressComponent implements OnInit, OnDestroy {
	_form: FormGroup = this.fb.group({
		postcode: [null, [Validators.required], [this.validPostcode()]],
		address: [{ value: null, disabled: true }, [Validators.required]],
		addressline1: [null],
		addressline2: [null],
		number: [null],
		premise: [null],
		street: [null],
		posttown: [null],
		county: [null],
		UPRN: [null, [Validators.required], [this.propertyInUse()]],
		longitude: [null],
		latitude: [null],
		usrn: [null],
	});
	@Input() set form(value: FormGroup) {
		if (value) this._form = value;
		if (!this._form.get('postcode')?.hasAsyncValidator(this.validPostcode()))
			this._form.get('postcode')?.setAsyncValidators([this.validPostcode()]);
		if (!this._form.get('UPRN')?.hasAsyncValidator(this.propertyInUse()))
			this._form.get('UPRN')?.setAsyncValidators([this.propertyInUse()]);
		let postcode = this._form.get('postcode')?.value;
		if (postcode?.length > 4) {
			this.loading = true;
			this.form.get('address')?.disable();
			if (postcode != postcode.toUpperCase())
				this.form.get('postcode')?.setValue(postcode.toUpperCase());

			this.getAddress(postcode).then((value: any) => {
				//console.log(value);
				let addresses: addressResponse[] = value;
				if (addresses?.length > 0) {
					this.addresses = addresses.map((address) => {
						return {
							value: address.uprn,
							label:
								address.addressline1 +
								(address.addressline2?.length > 0
									? ' ' + address.addressline2
									: ''),
							address: address.summaryline,
							addressline1: address.addressline1,
							addressline2: address.addressline2,
							number: address.number,
							premise: address.premise,
							street: address.street,
							posttown: address.townname,
							county: address.county,
							postCode: address.postcode,
							UPRN: address.uprn,
						};
					});
					this.form.get('UPRN')?.enable();
				} else this.addresses = [];
				this.loading = false;
			});
		} else {
			this.form.get('UPRN')?.disable();
		}

		if (this.postcode$?.unsubscribe) this.postcode$.unsubscribe();
		this.postcode$ = this.form
			.get('postcode')
			?.valueChanges.pipe(
				filter((value) => {
					return value.length > 4;
				}),
				tap((value: string) => {
					this.loading = true;
					this.form.get('address')?.disable();
					if (value != value.toUpperCase())
						this.form.get('postcode')?.setValue(value.toUpperCase(), {
							emitEvent: false,
							emitModelToViewChange: true,
							emitViewToModelChange: false,
						});
				}),
				switchMap((value) => {
					return this.getAddress(value);
				}),
				tap(() => (this.loading = false))
			)
			.subscribe((addresses) => {
				//console.log(value);
				if (addresses?.length > 0) {
					this.addresses = addresses
						.map((address) => {
							return {
								value: address.uprn,
								label:
									address.addressline1 +
									(address.addressline2?.length > 0
										? ' ' + address.addressline2
										: ''),
								address: address.summaryline,
								addressline1: address.addressline1,
								addressline2: address.addressline2,
								number: address.number,
								premise: address.premise,
								street: address.street,
								posttown: address.townname,
								county: address.county,
								postCode: address.postcode,
								UPRN: address.uprn,
								longitude: address.longitude,
								latitude: address.latitude,
								usrn: address.usrn,
							};
						})
						.sort((a, b) => {
							return a.label.localeCompare(b.label, undefined, {
								numeric: true,
							});
						});
					this.form.get('UPRN')?.enable();
				} else this.addresses = [];
			});

		if (this.address$?.unsubscribe) this.address$.unsubscribe();

		this.address$ = this.form
			.get('UPRN')
			?.valueChanges.pipe(startWith(undefined as any), pairwise())
			.subscribe((value) => {
				if (value[0] != value[1] && value[1]) {
					let address = this.addresses.find((a) => a.value == value[1]);

					if (address) this.form.patchValue(address as any);
				}
			});

		this.cd.detectChanges();
	}

	get form() {
		return this._form;
	}

	@Input() canMerge: false | 'seller' | 'buyer' = false;
	@Input() chainId?: string | undefined;
	@Input() buyerSellerId?: string | undefined;
	@Input() requirePropertyNotInUse = true;

	addresses: {
		value: string;
		label: string;
		address?: string;
		addressline1?: string;
		addressline2?: string;
		number?: string;
		premise?: string;
		street?: string;
		posttown?: string;
		county?: string;
		postCode?: string;
		UPRN?: string;
		longitude?: string;
		latitude?: string;
		usrn?: string;
	}[] = [];
	loading = false;

	postcode$: Subscription | undefined;

	address$: Subscription | undefined;

	constructor(
		private fb: FormBuilder,
		public Http: HttpClient,
		public cd: ChangeDetectorRef,
		public propertiesBackend: PropertiesBackendService,
		public chainsBackend: ChainsBackendService,
		public requestBackend: RequestsBackendService,
		public dialog: MatDialog,
		public snackbar: MatSnackBar,
		public router: Router,
		public confirmDialog: ConfirmDialogService
	) {}

	ngOnInit(): void {}

	onTouched: Function = () => {};

	onChangeSubs: Subscription[] = [];

	ngOnDestroy() {
		for (let sub of this.onChangeSubs) {
			sub.unsubscribe();
		}
		if (this.postcode$?.unsubscribe) this.postcode$.unsubscribe();
	}

	registerOnChange(onChange: any) {
		const sub = this.form.valueChanges.subscribe(onChange);
		this.onChangeSubs.push(sub);
	}

	registerOnTouched(onTouched: Function) {
		this.onTouched = onTouched;
	}

	setDisabledState(disabled: boolean) {
		if (disabled) {
			this.form.disable();
		} else {
			this.form.enable();
		}
	}

	writeValue(value: any) {
		if (value) {
			this.form.setValue(value, { emitEvent: false });
		}
	}

	validate(control: AbstractControl) {
		if (this.form.valid) {
			return null;
		}

		let errors: any = {};

		errors = this.addControlErrors(errors, 'postcode');
		errors = this.addControlErrors(errors, 'address');

		return errors;
	}

	addControlErrors(allErrors: any, controlName: string) {
		const errors = { ...allErrors };

		const controlErrors = this.form.controls[controlName].errors;

		if (controlErrors) {
			errors[controlName] = controlErrors;
		}

		return errors;
	}

	async getAddress(postcode: string): Promise<addressResponse[]> {
		let cash = await db.postCodeAddresses.get(postcode);
		if (cash && cash.addresses) {
			return cash.addresses;
		}
		let r = (await lastValueFrom(
			this.Http.get(
				`https://ws.postcoder.com/pcw/PCWN2-9UYMQ-ZBZ64-ZXMT9/addressbase/${postcode}?include=posttown,postcode&nyb=true&postcodeonly=true&lines=2`
			)
		)
			.then(async (value) => {
				await db.postCodeAddresses.put({
					postCode: postcode,
					addresses: value as addressResponse[],
				});
				return value;
			})
			.catch((error) => {
				return [];
			})) as Promise<addressResponse[]>;

		return r;
	}

	validPostcode(): AsyncValidatorFn {
		return (control: AbstractControl): Promise<ValidationErrors | null> => {
			return this._validPostcode(control);
		};
	}

	async _validPostcode(
		control: AbstractControl
	): Promise<ValidationErrors | null> {
		if (!control.value || control.value.length < 5)
			return { invalidPostcode: true };
		let r: addressResponse[] = await this.getAddress(control.value);

		if (r?.length > 0) {
			return null;
		} else {
			return { invalidPostcode: true };
		}
	}

	propertyInUse(): AsyncValidatorFn {
		return (control: AbstractControl): Promise<ValidationErrors | null> => {
			//control.parent?.get('UPRN')?.setValue(control.value);
			if (
				this.requirePropertyNotInUse &&
				control?.value &&
				control.value.length > 0
			)
				return this.propertiesBackend.checkIfInuse(control?.value).then((r) => {
					return r.data
						? { propertyInUse: r.data, noAccessToProperty: !r.canEdit }
						: null;
				});
			else return Promise.resolve(null);
		};
	}

	async requestAccess(UPRN: string) {
		let r: {
			userType: userType;
			message: string;
		} = await lastValueFrom(
			this.dialog
				.open(NewRequestDialogComponent, {
					data: {
						UPRN,
					},
					width: '800px',
					maxWidth: '95vw',
				})
				.afterClosed()
		);

		if (r?.userType) {
			let _r = await this.requestBackend.put({
				...r,
				UPRN,
				fromChainId: this.chainId,
				fromBuyerSellerId: this.buyerSellerId,
			});

			if (_r.data) {
				let r = await this.snackbar
					.open(
						'Your Access Request has been sent to the relevant parties on the property you requested.',
						'Open Access Requests',
						{
							duration: 5000,
						}
					)
					.afterDismissed()
					.toPromise();

				if (r?.dismissedByAction) {
					this.router.navigate(['/requests']);
				}
			}
		}
	}

	async mergeChains(chainId: string) {
		if (!this.canMerge || !this.chainId) return;
		let confirmed = await this.confirmDialog.confirm({
			title: 'Merge Chains',
			message: `Are you sure you want to merge these two chains?`,
			confirmText: 'Merge',
			cancelText: 'Cancel',
		});
		if (!confirmed) return;

		let chain1Id: string = '';
		let chain2Id: string = '';

		if (this.canMerge == 'buyer') {
			chain1Id = this.chainId;
			chain2Id = chainId;
		} else {
			chain1Id = chainId;
			chain2Id = this.chainId;
		}

		let s = this.snackbar.open('Merging Chains...', undefined, {
			duration: 100000,
		});

		let r = await this.chainsBackend.merge({
			chain1Id,
			chain2Id,
		});

		if (r.data?.id) {
			this.form.markAsPristine();
			this.form.markAsUntouched();

			s.dismiss();
			this.router.navigate(['/chain', r.data.id]);
		}
	}
}
