<mat-card class="card">
	<!-- <mat-card-header>
		<mat-card-title>Buyers / Sellers</mat-card-title>
	</mat-card-header> -->
	<mat-card-content>
		<div class="hero pink">
			<div class="hero-text width-40" style="flex-grow: 1">
				<h1>Get early access to Clear Chain</h1>
				<h3>FREE when you setup your property chain by 31st of August 2023</h3>
				<div>
					<button mat-raised-button [routerLink]="['/login']" color="primary">
						Sign up with Google
					</button>
				</div>
				<div class="sub-text">
					* Other parties involved in your sale or purchase may include, your
					estate agent, mortgage broker and your conveyancers, as well as other
					people buying or selling the property with you. To qualify for this
					offer you need to add the details about the property you are buying
					and selling before 31st August 2023.
				</div>
			</div>
			<div
				class="hero-image width-55"
				style="flex-grow: 1; margin-bottom: -5px">
				<img src="/assets/AdobeStock_115505973.jpg" style="max-width: 100%" />
			</div>
		</div>

		<div class="big-hero">
			<h1>What is Clear Chain?</h1>
			<h3 style="max-width: 65%">
				Clear Chain is a property chain tool that takes the uncertainty out of
				buying and selling properties by connecting each party in the chain in
				one place and keeping them up to date.
			</h3>
		</div>
		<!-- <div class="hero">
			<div class="hero-text">
				<h1>Changing the way we buy and sell properties.</h1>
			</div>
			<div class="hero-image">
				<img src="/assets/AdobeStock_603088231.jpg" />
			</div>
		</div> -->
		<div class="hero">
			<div class="hero-text width-45" style="flex-grow: 1">
				<h2 class="pink-text">
					Changing the way we buy and sell properties. For good.
				</h2>
				<ul style="list-style-type: none; padding: 0">
					<li>
						<div class="list-top-line">
							<mat-icon class="check">check_box</mat-icon> Buy and sell your
							property sooner
						</div>
						<div class="list-bottom-line">
							Buy and sell your property sooner by identifying and resolving
							issues fast
						</div>
					</li>
					<li>
						<div class="list-top-line">
							<mat-icon class="check">check_box</mat-icon> See the status of
							purchase or sale, in real-time.
						</div>
						<div class="list-bottom-line">
							View your entire property chain and what stage everyone is at as
							it happens, in real-time.
						</div>
					</li>
					<li>
						<div class="list-top-line">
							<mat-icon class="check">check_box</mat-icon> Chase less
						</div>
						<div class="list-bottom-line">
							Find out as soon as things change and get alerts, such as when a
							chain is at risk of breaking down.
						</div>
					</li>
					<li>
						<div class="list-top-line">
							<mat-icon class="check">check_box</mat-icon> Your personal project
							manager, in an app
						</div>
						<div class="list-bottom-line">
							Clear Chain saves you time by guiding you through each stage of
							the process and notifying you on what you need to do and when. By
							automating manual tasks - like chasing for updates, managing
							documents and coordinating with your estate agent - you can buy
							and sell your property sooner.
						</div>
					</li>
					<li>
						<div class="list-top-line">
							<mat-icon class="check">check_box</mat-icon> A smarter way to buy
							and sell properties
						</div>
						<div class="list-bottom-line">
							Clear Chain automates manual tasks and helps you plan. For
							example, Clear Chain will continually calculate your predicted
							exchange and completion dates, based on how things are progressing
							within the chain.
						</div>
					</li>
					<li>
						<div class="list-top-line">
							<mat-icon class="check">check_box</mat-icon> Transparency
						</div>
						<div class="list-bottom-line">
							See what stage each party is in the buying and selling process,
							without anyone seeing information they shouldn't.
						</div>
					</li>
				</ul>
			</div>
			<div
				class="hero-image width-45"
				style="
					flex-grow: 1;
					display: flex;
					align-items: center;
					justify-content: center;
					overflow: hidden;
				">
				<img src="/assets/P1290491.jpg" />
			</div>
		</div>

		<div class="hero pink better">
			<div class="hero-image width-45" style="flex-grow: 1">
				<img
					src="/assets/clay-banks-POzx_amnWJw-unsplash.jpg"
					style="max-width: 100%" />
			</div>
			<div class="hero-text width-45" style="flex-grow: 1">
				<div>
					<h2>
						Add details about your property chain by 31st August 2023 and get
						Clear Chain for FREE! RRP £120
					</h2>
					<p2>
						As a beta user you'll be one of the first people to use an app
						designed to revolutionise - and simplify - the way we buy and sell
						homes in England. For a limited time only you can sign up and add
						the details about your property chain and get access to Clear Chain
						beta for free.
					</p2>
					<h4>What you'll get</h4>
					<h4>*FREE* Unlimited access</h4>
					<p2>
						Get unlimited access to Clear Chain and all of its features, until
						your transaction is complete.
					</p2>
					<h4>*FREE* Access for everyone involved in your purchase or sale.</h4>
					<p2>
						Invite your estate agent, conveyancer and mortgage broker and
						they'll get it for free too.
					</p2>
					<h4>*FREE* Get the most out of Clear Chain</h4>
					<p2>
						Get a free one-to-one onboarding call with a property expert. You'll
						get to explore its extensive functionality, how to use it, and get
						the most out of it.
					</p2>
					<div>
						<br />
						<button mat-raised-button [routerLink]="['/login']">Sign up</button>
					</div>
				</div>
			</div>
		</div>

		<div class="hero" style="padding: 2rem">
			<div
				style="
					display: flex;
					justify-content: center;
					flex-direction: column;
					align-items: center;
				">
				<h1>
					<span class="pink-text">Get set up in </span>
					<span class="blue-text"> 3 steps</span>
				</h1>
				<div style="max-width: 30rem; text-align: start">
					<div class="step">
						<div>
							<img class="number" src="/assets/circle-1.png" />
						</div>
						<div class="step-text">
							Simply sign up using your email or login with Google
						</div>
					</div>
					<div class="step">
						<div>
							<img class="number" src="/assets/circle-2.png" />
						</div>
						<div class="step-text">
							Add details about the property you plan to buy and/or sell
						</div>
					</div>
					<div class="step" style="margin-bottom: 2rem">
						<div>
							<img class="number" src="/assets/circle-3.png" />
						</div>
						<div class="step-text">
							When you hit the payment page, a discount code will automatically
							populate. This means you don't pay anything*
						</div>
					</div>
				</div>
				<div style="margin-bottom: 2rem">
					* A buyer and or seller must complete their setup reaching the payment
					page and clicking "Pay Now" before the end of August, no payment will
					be required at any point (now or going forward) to use Clear Chain for
					that particular property transaction.
				</div>
				<div>
					<button mat-raised-button color="accent" [routerLink]="['/login']">
						Sign up
					</button>
				</div>
			</div>
		</div>

		<div class="hero blue" style="gap: 0.5rem">
			<div class="small-hero-text width-40" style="flex-grow: 1; margin: 1rem">
				<h1>Our Mission</h1>
				<p2>
					To provide a way for all parties to manage their property chain in a
					centralised system giving all buyers and sellers the ability to see
					what is happening whilst protecting their identities and private
					information. By walking buyers and sellers through the buying and
					selling process step by step we aim to ensure that they can quickly
					progress through it with confidence and transparency.
				</p2>
				<div>
					<br />
					<p2> Aidan and Rose</p2>
				</div>
				<div>
					<p2 style="font-weight: 600">Clear Chain Co-Founders </p2>
				</div>
				<div>
					<br />
					<a
						mat-raised-button
						href="https://www.clearchain.house/our-story"
						target="_blank"
						>Our Story</a
					>
				</div>
			</div>
			<div class="hero-image width-55" style="flex-grow: 1">
				<img src="/assets/AdobeStock_603088231.jpg" style="max-width: 100%" />
			</div>
		</div>

		<div class="hero connected" style="flex-direction: column">
			<h4 class="pink-text" style="max-width: 65%; text-align: center">
				Your feedback matters to us
			</h4>
			<p style="max-width: 65%">
				At Clear Chain we are on a mission to make life easier for people buying
				and selling properties in England, so what you think really matters to
				us. We'd love to know what you like, what we could do better and ideas
				on features that you'd like to see.
			</p>
		</div>
	</mat-card-content>
</mat-card>
