import { Injectable } from '@angular/core';
import {
	ActivatedRouteSnapshot,
	CanDeactivate,
	RouterStateSnapshot,
	UrlTree,
} from '@angular/router';
import { Observable } from 'rxjs';
import { ConfirmDialogService } from '../services/confirm-dialog.service';
import { FormsService } from '../services/forms.service';

@Injectable({
	providedIn: 'root',
})
export class FormsDirtyGuard implements CanDeactivate<unknown> {
	constructor(
		public FormsService: FormsService,
		public ConfirmDialog: ConfirmDialogService
	) {}
	async canDeactivate(
		component: unknown,
		currentRoute: ActivatedRouteSnapshot,
		currentState: RouterStateSnapshot,
		nextState?: RouterStateSnapshot
	): Promise<boolean | UrlTree> {
		return this.FormsService.dirty
			? await this.ConfirmDialog.confirm({
					title: 'Unsaved Work?',
					message: 'You have unsaved work. Are you sure you want to leave?',
			  })
			: true;
	}
}
