import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import { FormBuilder, FormControl, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';

export interface spaceData {
	indoorOutdoor?: string;
	type?: string;
	width?: number;
	length?: number;
	ceilingHeight?: number;
	ceilingType?: string;
	shape?: string;
	shower?: boolean;
	bath?: boolean;
	toilet?: boolean;
	name?: string;
}

export interface spaceDialogData {
	spaceData: spaceData;
	actions: 'edit' | 'add';
}

@Component({
	selector: 'app-edit-space',
	templateUrl: './edit-space.component.html',
	styleUrls: ['./edit-space.component.css'],
})
export class EditSpaceComponent {
	form = this.buildForm(this._data.spaceData);

	indoorTypes = [
		'Living Room',
		'Kitchen',
		'Bedroom',
		'Bathroom',
		'Hallway',
		'Open Plan Area',
		'Office',
		'Attic',
		'Basement',
		'Closet',
		'Other',
	];
	outdoorTypes = [
		'Garden',
		'Balcony',
		'Patio',
		'Terrace',
		'Roof Terrace',
		'Garage',
		'Shed',
		'Other',
	];

	types: string[] = [];

	constructor(
		@Inject(MAT_DIALOG_DATA)
		public _data: spaceDialogData,
		public fb: FormBuilder
	) {}

	ngOnInit() {
		this.form = this.buildForm(this._data.spaceData);
	}

	ngOnDestroy() {
		if (this.$indoorOutdoor) this.$indoorOutdoor.unsubscribe();
		if (this.$type) this.$type.unsubscribe();
	}

	$indoorOutdoor: any;
	$type: any;
	buildForm(data?: spaceData) {
		let fb = this.fb.group<{
			indoorOutdoor: FormControl<string | null>;
			type: FormControl<string | null>;
			width: FormControl<number | null>;
			length: FormControl<number | null>;
			ceilingHeight?: FormControl<number | null>;
			ceilingType?: FormControl<string | null>;
			shape: FormControl<string | null>;
			shower?: FormControl<boolean | null>;
			bath?: FormControl<boolean | null>;
			toilet?: FormControl<boolean | null>;
			name: FormControl<string | null>;
		}>({
			indoorOutdoor: this.fb.control(null, [Validators.required]),
			type: this.fb.control(null, [Validators.required]),
			width: this.fb.control(null, [Validators.required]),
			length: this.fb.control(null, [Validators.required]),
			//ceilingHeight: this.fb.control(null, []),
			//ceilingType: this.fb.control(null, []),
			shape: this.fb.control(null, [Validators.required]),
			// shower: this.fb.control(null, []),
			// bath: this.fb.control(null, []),
			// toilet: this.fb.control(null, []),
			name: this.fb.control(null, []),
		});

		if (this.$indoorOutdoor) this.$indoorOutdoor.unsubscribe();
		this.$indoorOutdoor = fb
			.get('indoorOutdoor')
			?.valueChanges.subscribe((value) => {
				if (value === 'indoor') {
					this.types = this.indoorTypes;
					fb.get('type')?.enable();
					fb.addControl(
						'ceilingHeight',
						this.fb.control(null, [Validators.required])
					);
					fb.addControl(
						'ceilingType',
						this.fb.control(null, [Validators.required])
					);
				} else if (value === 'outdoor') {
					this.types = this.outdoorTypes;
					fb.get('type')?.enable();
					fb.removeControl('ceilingHeight');
					fb.removeControl('ceilingType');
				} else {
					this.types = [];
					fb.get('type')?.setValue(null);
					fb.get('type')?.disable();
					fb.removeControl('ceilingHeight');
					fb.removeControl('ceilingType');
				}
			});

		if (this.$type) this.$type.unsubscribe();
		this.$type = fb.get('type')?.valueChanges.subscribe((value) => {
			if (value === 'Bathroom') {
				fb.addControl('shower', this.fb.control(null, [Validators.required]));
				fb.addControl('bath', this.fb.control(null, [Validators.required]));
				fb.addControl('toilet', this.fb.control(null, [Validators.required]));
			} else {
				fb.removeControl('shower');
				fb.removeControl('bath');
				fb.removeControl('toilet');
			}
		});

		if (data) {
			fb.patchValue(data);
		}

		return fb;
	}
}
