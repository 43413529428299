<div class="container">
	<h1 class="header">AML-Report</h1>
	<!-- <div class="center-center" *ngIf="loading">
		<mat-spinner></mat-spinner>
	</div> -->
	<app-loading-tombstone *ngIf="loading"></app-loading-tombstone>
	<mat-card
		*ngIf="!loading"
		style="flex-wrap: nowrap; margin-top: 1rem; flex-grow: 1"
		class="chain-card-wrap">
		<mat-card-content
			class="chain-card"
			style="flex-grow: 1; display: flex; flex-direction: row">
			<object
				[data]="url | safe"
				[data-filename]="fileName"
				[title]="fileName"
				type="application/pdf"
				width="100%"
				height="100%">
				<pram name="title" [value]="fileName"></pram>
				alt :
				<a [href]="url | safe">{{ fileName }}</a>
			</object>
		</mat-card-content>
	</mat-card>
</div>
