<h1 mat-dialog-title>Suggest A New {{ data.name }}</h1>
<mat-dialog-content style="display: flex; justify-content: center">
	<mat-form-field
		appearance="outline"
		floatLabel="always"
		style="margin: 2rem 0">
		<mat-label>Choose a date</mat-label>
		<input
			matInput
			[matDatepicker]="picker"
			(dateInput)="changeNewDate($event.target.value)" />
		<mat-hint>DD/MM/YYYY</mat-hint>
		<mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
		<mat-datepicker #picker></mat-datepicker>
	</mat-form-field>
</mat-dialog-content>
<mat-dialog-actions style="justify-content: end">
	<button mat-button [mat-dialog-close]="false" type="button">Cancel</button>
	<button
		mat-button
		[mat-dialog-close]="newDate"
		[disabled]="!newDate"
		type="button"
		cdkFocusInitial>
		Submit
	</button>
</mat-dialog-actions>
