<div class="container">
	<h1 class="header full-row">Email</h1>
	<app-loading-tombstone
		class="full-row"
		*ngIf="loading"></app-loading-tombstone>
	<mat-card
		appearance="outlined"
		class="full-row"
		*ngIf="(email?.properties || []).length > 0">
		<mat-card-content>
			<h2>Properties</h2>
			<div *ngFor="let property of email?.properties">
				<img
					[matTooltip]="property.nickName || ''"
					style="width: 1.5rem"
					[ngClass]="{
						'unknown-prop': property.id == 'unknown',
						'yellow-prop': propColor(property) == 'yellow'
					}"
					[src]="logoURL(property)"
					alt="house" />
				<a
					[routerLink]="[
						'/',
						'chain',
						property.chainId,
						'property',
						property.id
					]">
					<div>{{ property?.nickName }}</div>
					<div>{{ property.address || "" }}</div>
				</a>
			</div>
		</mat-card-content>
	</mat-card>
	<mat-card appearance="outlined" class="full-row" *ngIf="!loading && email">
		<mat-card-content>
			<h2>
				{{ email.subject }}
			</h2>
			<div class="to-from-row">
				<div>
					<div class="to-from-line">
						From: <span class="name">{{ email.from.name }}</span>
					</div>
					<div class="to-from-line">
						To:
						<span class="name" *ngFor="let to of email?.to; let i = index">{{
							to?.name
						}}</span>
					</div>
					<div class="to-from-line" *ngIf="(email?.cc || []).length > 0">
						CC:
						<span class="name" *ngFor="let to of email?.cc; let i = index">{{
							to?.name
						}}</span>
					</div>
				</div>
				<div>
					<div class="to-from-line" style="text-align: end">
						{{ email.sent | date : "medium"
						}}<mat-icon
							style="
								font-size: 1rem;
								height: 1rem;
								width: 1rem;
								margin-top: 0rem;
							"
							>send</mat-icon
						>
					</div>
					<div
						class="to-from-line"
						*ngIf="email.opens[0]"
						style="text-align: end">
						{{ email.opens[0].timestamp | date : "medium"
						}}<mat-icon
							style="
								font-size: 1rem;
								height: 1rem;
								width: 1rem;
								margin-top: 0rem;
							"
							>visibility</mat-icon
						>
					</div>
				</div>
			</div>
			<iframe
				style="border: none; margin: 1rem -6px"
				*ngIf="email?.html"
				[srcdoc]="email.html || ''"
				width="100%"
				(load)="resizeIframe($event)"
				sandbox="allow-same-origin"
				height="100%"></iframe>
		</mat-card-content>
	</mat-card>
	<mat-card class="full-row" *ngIf="(email?.attachments || []).length > 0">
		<mat-card-content>
			<h2>Attachments</h2>
			<div *ngFor="let attachment of email?.attachments">
				{{ attachment?.filename }}
			</div>
		</mat-card-content>
	</mat-card>
	<mat-card class="open-click" *ngIf="(email?.clicks || []).length > 0">
		<mat-card-content>
			<h2>Clicks</h2>
			<div class="item-row" *ngFor="let click of email?.clicks">
				<span>{{ click?.timestamp | date : "medium" }}</span>
				<span>{{ click?.link }}</span>
			</div>
		</mat-card-content>
	</mat-card>
	<mat-card class="open-click" *ngIf="(email?.opens || []).length > 0">
		<mat-card-content>
			<h2>Opens</h2>
			<div class="item-row" *ngFor="let open of email?.opens">
				<span>
					{{ open?.timestamp | date : "medium" }}
				</span>
				<span>{{ open?.source }}</span>
				<span>{{ open?.ip }}</span>
			</div>
		</mat-card-content>
	</mat-card>

	<!-- <pre>{{ email | json }}</pre> -->
</div>
