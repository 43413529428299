import { HttpClient } from '@angular/common/http';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { lastValueFrom } from 'rxjs';
import { environment } from 'src/environments/environment';
import { Router } from '@angular/router';
import {
	PropertiesBackendService,
	Property,
} from '../services/properties-backend.service';
import { TitleService } from '../services/title.service';

@Component({
	templateUrl: './properties.component.html',
	styleUrls: ['./properties.component.css'],
})
export class PropertiesComponent implements OnInit, OnDestroy {
	properties: Property[] = [];
	loading = true;
	constructor(
		public PropertiesBackend: PropertiesBackendService,
		public Router: Router,
		public titleService: TitleService
	) {}

	async ngOnInit() {
		this.titleService.title = 'Properties';
		this.loading = true;
		let r = await this.PropertiesBackend.getList();
		this.properties = r?.data;
		this.properties.sort((a, b) => {
			return (a.address || '').localeCompare(b.address || '', undefined, {
				numeric: true,
			});
		});
		this.loading = false;
	}

	ngOnDestroy(): void {}

	logoURL(property: any) {
		let color = 'green';
		if (property?.issueCount > 0) color = 'yellow';
		if (property?.isPlaceHolder) color = 'red';
		if (property?.postCode == 'No Postcode') color = 'red';
		return `/assets/colorIcons/${color}.svg`;
	}

	goto(property: any) {
		this.Router.navigate(['/chain', property.chainId, 'property', property.id]);
	}
}
