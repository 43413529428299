import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import { FormBuilder, FormControl, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';

export interface heatData {
	type?: string | null;
	cost?: string | null;
	otherDetails?: string | null;
}

export interface heatDialogData {
	heatData: heatData;
	actions: 'edit' | 'add';
}

@Component({
	selector: 'app-edit-heat',
	templateUrl: './edit-heat.component.html',
	styleUrls: ['./edit-heat.component.css'],
})
export class EditHeatComponent {
	form = this.buildForm(this._data.heatData);

	types: string[] = [];

	constructor(
		@Inject(MAT_DIALOG_DATA)
		public _data: heatDialogData,
		public fb: FormBuilder
	) {}

	ngOnInit() {
		this.form = this.buildForm(this._data.heatData);
	}

	ngOnDestroy() {
		if (this.$type?.unsubscribe) this.$type.unsubscribe();
	}

	$type: any;
	buildForm(data?: heatData) {
		let fb = this.fb.group<{
			type: FormControl<string | null>;
			cost?: FormControl<string | null>;
			otherDetails?: FormControl<string | null>;
		}>({
			type: this.fb.control(null, [Validators.required]),
		});

		if (this.$type?.unsubscribe) this.$type.unsubscribe();
		this.$type = fb.get('type')?.valueChanges.subscribe((value) => {
			if (value != 'Electricity') {
				fb.addControl('cost', this.fb.control(null, [Validators.required]));
			} else {
				fb.removeControl('cost');
			}
			if (value == 'Other') {
				fb.addControl(
					'otherDetails',
					this.fb.control(null, [Validators.required])
				);
			} else {
				fb.removeControl('otherDetails');
			}
		});

		if (data) {
			fb.patchValue(data);
		}

		return fb;
	}
}
