<div class="docs-list">
	<div
		class="docs-list-item"
		[class.disabled]="disabled"
		*ngFor="let doc of documents; let i = index">
		<div (click)="openDocument(doc)">
			<mat-icon
				class="doc-icon"
				[class.accessibly]="currentDoc(doc)"
				[class.unaccessible]="!currentDoc(doc)"
				>{{ docIcon(doc.name) }}</mat-icon
			>
		</div>
		<div
			class="docs-name"
			[class.accessibly]="currentDoc(doc)"
			[class.unaccessible]="!currentDoc(doc)"
			(click)="openDocument(doc)">
			{{ doc.name }}
		</div>
		<div class="docs-actions">
			<button
				mat-icon-button
				(click)="removeDocument(i)"
				[disabled]="disabled"
				type="button">
				<mat-icon>delete_forever</mat-icon>
			</button>
		</div>
	</div>
</div>
<app-select-file-button
	[disabled]="disabled"
	#selectFileButton
	(newFiles)="addDocuments($event)"></app-select-file-button>
