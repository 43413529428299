<div class="container">
	<div *ngIf="loading" class="center-center">
		<mat-spinner diameter="24"></mat-spinner>
	</div>

	<div class="center-center" *ngIf="requests.length === 0 && !loading">
		<p>
			Any Parties that do not yet have access to the chain may request it and
			these will show up here for you to approve.
		</p>
	</div>

	<div
		class="row card"
		style="flex-wrap: nowrap"
		*ngFor="let request of requests">
		<mat-icon
			[matTooltip]="request.property?.nickname || 'No nickname'"
			style="align-self: baseline; margin-right: 1rem"
			>accessibility_new</mat-icon
		>
		<div>
			<div>
				<span *ngIf="canAccept(request)">
					{{ request.firstName }} {{ request.lastName }} -
				</span>
				<span> {{ request.requestAction }} </span>
			</div>
			<div>{{ request.property?.nickname }}</div>
			<div>{{ request.property?.address }}</div>
		</div>
		<div class="spacer"></div>
		<!-- <div class="menu"> -->

		<div>
			<button
				mat-button
				*ngIf="canAccept(request)"
				(click)="deleteRequest(request)">
				Reject
			</button>
		</div>
		<div>
			<button
				mat-button
				*ngIf="canAccept(request)"
				(click)="acceptRequest(request)">
				Accept
			</button>
		</div>

		<!-- </div> -->
	</div>
</div>
