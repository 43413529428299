<h1 mat-dialog-title>{{ _data.actions == "edit" ? "Edit" : "Add" }} Works</h1>
<mat-dialog-content
	style="
		display: flex;
		justify-content: center;
		flex-wrap: wrap;
		max-width: 600px;
		padding: 1rem;
		gap: 0 1rem;
	">
	<form [formGroup]="form" style="margin-top: 1rem">
		<mat-form-field appearance="outline" floatLabel="always">
			<mat-label>Date The Works Were Completed</mat-label>
			<input
				matInput
				[matDatepicker]="picker"
				formControlName="completionDate" />
			<mat-hint>MM/DD/YYYY</mat-hint>
			<mat-datepicker-toggle
				matIconSuffix
				[for]="picker"></mat-datepicker-toggle>
			<mat-datepicker #picker></mat-datepicker>
		</mat-form-field>
		<mat-form-field
			appearance="outline"
			floatLabel="always"
			style="width: 100%">
			<mat-label>Description Of Works</mat-label>
			<textarea
				style="width: 100%"
				matInput
				type="text"
				placeholder="Loft Conversion, Extension, etc."
				formControlName="description"></textarea>
		</mat-form-field>
	</form>
</mat-dialog-content>
<mat-dialog-actions>
	<button mat-button [mat-dialog-close]="false" type="button">Cancel</button>
	<button
		mat-button
		[mat-dialog-close]="form.value"
		[disabled]="form.disabled || form.invalid"
		type="button"
		cdkFocusInitial>
		Save
	</button>
</mat-dialog-actions>
