import {
	ChangeDetectorRef,
	Component,
	EventEmitter,
	OnDestroy,
	OnInit,
	Output,
} from '@angular/core';
import {
	FormArray,
	FormBuilder,
	FormControl,
	FormGroup,
	Validators,
} from '@angular/forms';
import { atLeastOneRequired } from 'src/app/form-fields/form-validators';
import { ConfirmExitFrom } from 'src/app/services/forms.service';
import { environment } from 'src/environments/environment';
import { userType } from '../httpTypes';
import { PeopleBackendService } from 'src/app/services/people-backend.service';

type booleanString = 'Y' | 'N' | '' | null | undefined;

@Component({
	selector: 'app-selling-agent-setup',
	templateUrl: './selling-agent-setup.component.html',
	styleUrls: ['./selling-agent-setup.component.css'],
})
export class SellingAgentSetupComponent implements OnInit, OnDestroy {
	@Output() data = new EventEmitter();
	@Output() submit = new EventEmitter();
	@Output() back = new EventEmitter();

	@ConfirmExitFrom() propertyInfoForm = this.buildAddressForm();

	propertyInfoForm$: any;

	//--------------------------------------------------------------------------------

	@ConfirmExitFrom() sellerInfoForm = this.fb.group<{
		seller: FormArray<any>;
		verified?: FormControl<booleanString>;
		doesSellerHaveConveyancer: FormControl<booleanString>;
		conveyancer?: FormArray<any>;
	}>({
		seller: this.buildPersonFormArray(),
		verified: this.fb.control('', []),
		doesSellerHaveConveyancer: this.fb.control('', [Validators.required]),
	});

	get doesSellerHaveConveyancer() {
		return this.sellerInfoForm?.get('doesSellerHaveConveyancer');
	}

	doesSellerHaveConveyancer$: any;
	sellerInfoForm$: any;

	//--------------------------------------------------------------------------------

	@ConfirmExitFrom() buyerInfoForm = this.fb.group<{
		acceptedOffer: FormControl<booleanString>;
		buyer?: FormArray<any>;
		dateOfferAccepted?: FormControl<string | null>;
		agreedSellingPrice?: FormControl<number | null>;
		doesBuyerHaveConveyancer?: FormControl<booleanString>;
		conveyancer?: FormArray<any>;
	}>({
		acceptedOffer: this.fb.control('', [Validators.required]),
	});

	get acceptedOffer() {
		return this.buyerInfoForm?.get('acceptedOffer');
	}

	get doesBuyerHaveConveyancer() {
		return this.buyerInfoForm?.get('doesBuyerHaveConveyancer');
	}

	acceptedOffer$: any;
	doesBuyerHaveConveyancer$: any;
	agreedSellingPrice$: any;
	buyerInfoForm$: any;

	//--------------------------------------------------------------------------------

	constructor(
		public fb: FormBuilder,
		public cd: ChangeDetectorRef,
		public peopleBackend: PeopleBackendService
	) {}

	ngOnInit(): void {
		this.propertyInfoForm$ = this.propertyInfoForm.valueChanges.subscribe(
			(value) => {
				this.submitBuild();
			}
		);

		this.doesSellerHaveConveyancer$ =
			this.doesSellerHaveConveyancer?.valueChanges.subscribe((value) => {
				if (value == 'Y') {
					if (!this.sellerInfoForm.get('conveyancer')) {
						this.sellerInfoForm.addControl(
							'conveyancer',
							this.buildPersonFormArray()
						);
					}
				} else {
					if (this.sellerInfoForm.get('conveyancer')) {
						this.sellerInfoForm.removeControl('conveyancer');
					}
				}
			});

		this.sellerInfoForm$ = this.sellerInfoForm.valueChanges.subscribe(
			(value) => {
				this.submitBuild();
			}
		);

		this.acceptedOffer$ = this.acceptedOffer?.valueChanges.subscribe(
			(value) => {
				if (value == 'Y') {
					if (!this.buyerInfoForm.get('buyer')) {
						this.buyerInfoForm.addControl('buyer', this.buildPersonFormArray());
					}
					if (!this.buyerInfoForm.get('doesBuyerHaveConveyancer')) {
						this.buyerInfoForm.addControl(
							'doesBuyerHaveConveyancer',
							this.fb.control(null, [Validators.required])
						);
					}

					if (this.doesBuyerHaveConveyancer$?.unsubscribe)
						this.doesBuyerHaveConveyancer$.unsubscribe();
					this.doesBuyerHaveConveyancer$ =
						this.doesBuyerHaveConveyancer?.valueChanges.subscribe((value) => {
							if (value == 'Y') {
								if (!this.buyerInfoForm.get('conveyancer')) {
									this.buyerInfoForm.addControl(
										'conveyancer',
										this.buildPersonFormArray()
									);
								}
							} else {
								if (this.buyerInfoForm.get('conveyancer')) {
									this.buyerInfoForm.removeControl('conveyancer');
								}
							}
						});

					if (!this.buyerInfoForm.get('dateOfferAccepted')) {
						this.buyerInfoForm.addControl(
							'dateOfferAccepted',
							this.fb.control(null, [Validators.required])
						);
					}
					if (!this.buyerInfoForm.get('agreedSellingPrice')) {
						this.buyerInfoForm.addControl(
							'agreedSellingPrice',
							this.fb.control(null, [Validators.required])
						);
					}

					// if (this.agreedSellingPrice$?.unsubscribe)
					// 	this.agreedSellingPrice$.unsubscribe();
					// this.agreedSellingPrice$ = this.buyerInfoForm
					// 	.get('agreedSellingPrice')
					// 	?.valueChanges.subscribe((value) => {
					// 		if (value) {
					// 			let _value = value + '';
					// 			let formattedValue = new Intl.NumberFormat('en-GB', {
					// 				style: 'decimal',
					// 				maximumFractionDigits: 2,
					// 			}).format(parseFloat(_value.replace(/,/g, '')));
					// 			if (_value.charAt(_value.length - 1) == '.') {
					// 				formattedValue += '.';
					// 			}

					// 			this.buyerInfoForm
					// 				.get('agreedSellingPrice')
					// 				?.setValue(formattedValue as any, {
					// 					emitEvent: false,
					// 					emitModelToViewChange: true,
					// 					emitViewToModelChange: false,
					// 				});
					// 		}
					// 	});
				} else {
					if (this.buyerInfoForm.get('buyer')) {
						this.buyerInfoForm.removeControl('buyer');
					}
					if (this.buyerInfoForm.get('doesBuyerHaveConveyancer')) {
						this.buyerInfoForm.removeControl('doesBuyerHaveConveyancer');
					}
					if (this.buyerInfoForm.get('conveyancer')) {
						this.buyerInfoForm.removeControl('conveyancer');
					}
					if (this.buyerInfoForm.get('dateOfferAccepted')) {
						this.buyerInfoForm.removeControl('dateOfferAccepted');
					}
					if (this.buyerInfoForm.get('agreedSellingPrice')) {
						this.buyerInfoForm.removeControl('agreedSellingPrice');
					}
				}
			}
		);

		this.buyerInfoForm$ = this.buyerInfoForm.valueChanges.subscribe((value) => {
			this.submitBuild();
		});
	}

	ngOnDestroy(): void {
		if (this.doesSellerHaveConveyancer$?.unsubscribe)
			this.doesSellerHaveConveyancer$.unsubscribe();
		if (this.doesBuyerHaveConveyancer$?.unsubscribe)
			this.doesBuyerHaveConveyancer$.unsubscribe();
		if (this.acceptedOffer$?.unsubscribe) this.acceptedOffer$.unsubscribe();
		if (this.agreedSellingPrice$?.unsubscribe)
			this.agreedSellingPrice$.unsubscribe();
		if (this.propertyInfoForm$?.unsubscribe)
			this.propertyInfoForm$.unsubscribe();
		if (this.sellerInfoForm$?.unsubscribe) this.sellerInfoForm$.unsubscribe();
		if (this.buyerInfoForm$?.unsubscribe) this.buyerInfoForm$.unsubscribe();
	}

	buildAddressForm() {
		return this.fb.group({
			postcode: [environment.postcode, [Validators.required]],
			address: ['', [Validators.required]],
			addressline1: [null],
			addressline2: [null],
			number: [null],
			premise: [null],
			street: [null],
			posttown: [null],
			county: [null],
			UPRN: ['', [Validators.required]],
			longitude: [null],
			latitude: [null],
			usrn: [null],
		});
	}

	buildPersonFormArray() {
		return this.fb.array(
			[
				this.fb.group({
					firstName: [environment.firstName, [Validators.required]],
					lastName: [environment.lastName, [Validators.required]],
					email: [
						environment.email,
						[Validators.required, Validators.email],
						//[this.peopleBackend.validEmail()],
					],
					phoneCountryCode: ['+44', [Validators.required]],
					phoneInCountryNumber: [
						'',
						[Validators.required, Validators.pattern('^[1-9]\\d{9}')],
					],
					company: [null],
					dontSendEmail: [false],
				}),
			],
			[atLeastOneRequired]
		);
	}

	submitForm() {
		if (
			this.propertyInfoForm.invalid ||
			this.sellerInfoForm.invalid ||
			this.buyerInfoForm.invalid
		) {
			this.data.emit(null);
			return;
		}

		this.submitBuild();
		this.submit.emit();
	}

	submitBuild() {
		if (
			this.propertyInfoForm.invalid ||
			this.sellerInfoForm.invalid ||
			this.buyerInfoForm.invalid
		) {
			this.data.emit(null);
			return;
		}

		let sellingAgentData: {
			userType: userType.sellersAgent;
			address?: string | null;
			addressline1?: string | null;
			addressline2?: string | null;
			number?: string | null;
			premise?: string | null;
			street?: string | null;
			posttown?: string | null;
			county?: string | null;
			postCode?: string | null;
			UPRN?: string | null;
			longitude?: string | null;
			latitude?: string | null;
			usrn?: string | null;
			seller?: {
				verified?: boolean;
				owners?: any[];
				conveyancer?: any[];
			};
			buyer?: {
				buyers?: any[];
				conveyancer?: any[];
				agreedPrice?: number | null;
				offerDate?: string | null;
			};
		} = {
			userType: userType.sellersAgent,
		};

		sellingAgentData.address = this.propertyInfoForm.get('address')?.value;
		sellingAgentData.addressline1 =
			this.propertyInfoForm.get('addressline1')?.value;
		sellingAgentData.addressline2 =
			this.propertyInfoForm.get('addressline2')?.value;
		sellingAgentData.number = this.propertyInfoForm.get('number')?.value;
		sellingAgentData.premise = this.propertyInfoForm.get('premise')?.value;
		sellingAgentData.street = this.propertyInfoForm.get('street')?.value;
		sellingAgentData.posttown = this.propertyInfoForm.get('posttown')?.value;
		sellingAgentData.county = this.propertyInfoForm.get('county')?.value;
		sellingAgentData.postCode = this.propertyInfoForm.get('postcode')?.value;
		sellingAgentData.UPRN = this.propertyInfoForm.get('UPRN')?.value;
		sellingAgentData.longitude = this.propertyInfoForm.get('longitude')?.value;
		sellingAgentData.latitude = this.propertyInfoForm.get('latitude')?.value;
		sellingAgentData.usrn = this.propertyInfoForm.get('usrn')?.value;

		sellingAgentData.seller = {
			verified: this.sellerInfoForm.get('verified')?.value == 'Y',
			owners: (this.sellerInfoForm.get('seller') as FormArray).controls.map(
				(control) => {
					return {
						firstName: control.get('firstName')?.value,
						lastName: control.get('lastName')?.value,
						email: control.get('email')?.value,
						phone: control.get('phone')?.value,
						phoneCountryCode: control.get('phoneCountryCode')?.value,
						phoneInCountryNumber: control.get('phoneInCountryNumber')?.value,
						company: control.get('company')?.value,
						dontSendEmail: control.get('dontSendEmail')?.value,
					};
				}
			),
		};

		if (this.doesSellerHaveConveyancer?.value == 'Y') {
			sellingAgentData.seller.conveyancer = (
				this.sellerInfoForm.get('conveyancer') as FormArray
			).controls.map((control) => {
				return {
					firstName: control.get('firstName')?.value,
					lastName: control.get('lastName')?.value,
					email: control.get('email')?.value,
					phone: control.get('phone')?.value,
					phoneCountryCode: control.get('phoneCountryCode')?.value,
					phoneInCountryNumber: control.get('phoneInCountryNumber')?.value,
					company: control.get('company')?.value,
					dontSendEmail: control.get('dontSendEmail')?.value,
				};
			});
		}

		if (this.acceptedOffer?.value == 'Y') {
			sellingAgentData.buyer = {
				buyers: (this.buyerInfoForm.get('buyer') as FormArray).controls.map(
					(control) => {
						return {
							firstName: control.get('firstName')?.value,
							lastName: control.get('lastName')?.value,
							email: control.get('email')?.value,
							phone: control.get('phone')?.value,
							phoneCountryCode: control.get('phoneCountryCode')?.value,
							phoneInCountryNumber: control.get('phoneInCountryNumber')?.value,
							company: control.get('company')?.value,
							dontSendEmail: control.get('dontSendEmail')?.value,
						};
					}
				),
				agreedPrice: this.buyerInfoForm.get('agreedSellingPrice')?.value,
				offerDate: this.buyerInfoForm.get('dateOfferAccepted')?.value,
			};
		}

		if (sellingAgentData.buyer && this.doesBuyerHaveConveyancer?.value == 'Y') {
			sellingAgentData.buyer.conveyancer = (
				this.buyerInfoForm.get('conveyancer') as FormArray
			).controls.map((control) => {
				return {
					firstName: control.get('firstName')?.value,
					lastName: control.get('lastName')?.value,
					email: control.get('email')?.value,
					phone: control.get('phone')?.value,
					phoneCountryCode: control.get('phoneCountryCode')?.value,
					phoneInCountryNumber: control.get('phoneInCountryNumber')?.value,
					company: control.get('company')?.value,
					dontSendEmail: control.get('dontSendEmail')?.value,
				};
			});
		}

		this.data.emit(sellingAgentData);
	}
}
