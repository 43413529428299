<amplify-authenticator
	[initialState]="initSate"
	[socialProviders]="['google']"
	[formFields]="formFields"
	[services]="$any(AuthService)">
	<ng-template
		amplifySlot="authenticated"
		let-user="user"
		let-signOut="signOut">
		<!-- <h1>Welcome {{ user.name }}!</h1>
		<button (click)="signOut()">Sign Out</button> -->
		<app-route-me></app-route-me>
	</ng-template>
	<ng-template amplifySlot="sign-up-form-fields">
		<!-- Re-use default `Authenticator.SignUp.FormFields` -->
		<amplify-sign-up-form-fields></amplify-sign-up-form-fields>
		<!-- <div>
			<div style="color: black">Password Requirements:</div>
			<ul style="color: black">
				<li>At least 8 characters</li>
				<li>At least 1 uppercase letter</li>
				<li>At least 1 lowercase letter</li>
				<li>At least 1 number</li>
				<li>At least 1 special character</li>
			</ul>
		</div> -->

		<!-- Append & require Terms & Conditions field to sign up -->
		<!-- <amplify-checkbox
			[errorMessage]="validationErrors.acknowledgement"
			[hasError]="!!validationErrors.acknowledgement"
			name="acknowledgement"
			value="yes">
			I agree with the Terms & Conditions
		</amplify-checkbox> -->
	</ng-template>

	<ng-template amplifySlot="confirm-sign-in-footer">
		<div>
			If you have any issues please reach out to us at
			<a
				href="mailto:info@clearchain.house?subject=Customer%20Service%20Inquiry"
				>info@clearchain.house</a
			>
			or by phone at 0203 8300 411
		</div>
	</ng-template>
</amplify-authenticator>
