<div class="question">
	<div
		class="bar"
		[class.should-be-responding]="shouldYouBeResponding"
		[class.resolved]="inquiry?.status == 'resolved'"></div>
	<div class="question-details">
		<div class="question-text">
			{{ inquiry?.filtered ? "Censored" : inquiry?.inquiry }}
			<span class="days"> &#183; {{ getTimeUntilDate(inquiry?.created) }}</span>
		</div>
		<div class="question-users">
			<span class="user-name" *ngIf="!inquiry?.filtered"
				>{{ inquiry?.createdByFirstName }}
				{{ inquiry?.createdByLastName }}</span
			>
			<span class="user-type"> ({{ inquiry?.createdByUserType }}) </span>
			<span class="for"> inquiring of the </span>
			<span class="user-type"> {{ inquiry?.userType }} </span>
		</div>
	</div>
</div>

<!-- <label class="box-label"
	>{{ inquiry?.createdByFirstName }} {{ inquiry?.createdByLastName }} ({{
		inquiry?.userType
	}})</label
>
<label class="box-label-right">{{ inquiry?.status | uppercase }}</label>
<div class="box-area">
	<label class="box-label">{{ inquiry?.createdBy | uppercase }}</label>
	<label class="box-label-right">{{ inquiry?.created | date : "short" }}</label>
	<div style="white-space: pre-wrap">
		{{ inquiry?.inquiry }}
	</div>
</div> -->

<div class="responses">
	<div
		style="margin: 0.5rem 0; text-align: center"
		*ngIf="(inquiry?.responses?.length || 0) > 1">
		<button mat-flat-button (click)="showAllResponses = !showAllResponses">
			{{
				showAllResponses
					? "Hide Old Responses"
					: "Show All Responses (" +
					  ((inquiry?.responses?.length || 0) - 1) +
					  ")"
			}}
		</button>
	</div>

	<div
		style="margin: 1rem 0"
		class="response"
		*ngFor="let response of responses; trackBy: trackByFn; let i = index">
		<div>
			<span class="user-name" *ngIf="!inquiry?.filtered"
				>{{ response?.userFirstName }} {{ response?.userLastName }}</span
			>
			<span class="user-type"> ({{ response?.userType }}) </span>
			<span class="days"> &#183; {{ getTimeUntilDate(response?.date) }}</span>
			<span *ngIf="i == responses.length - 1"> (latest)</span>
		</div>
		<div class="question-text">
			{{ inquiry?.filtered ? "Censored" : response.response }}
		</div>
	</div>
	<div
		*ngIf="!inquiry?.filtered && inquiry?.status == 'unresolved'"
		class="response"
		style="margin: 1rem 0"
		ngForm
		[formGroup]="newResourceForm">
		<div>
			<mat-form-field
				appearance="outline"
				floatLabel="always"
				style="width: 100%">
				<mat-label>New Response</mat-label>
				<textarea
					matInput
					placeholder="Your Response..."
					name="response"
					formControlName="response"
					required></textarea>
			</mat-form-field>
		</div>
		<div class="actions">
			<button mat-raised-button color="primary" (click)="respond()">
				Respond
			</button>
		</div>
	</div>
</div>

<div
	style="margin: 1rem 0"
	class="actions"
	*ngIf="canEdit && !inquiry?.filtered">
	<button
		*ngIf="inquiry?.status == 'unresolved' && canEdit"
		mat-flat-button
		(click)="delete()">
		Delete
	</button>

	<button
		*ngIf="inquiry?.status == 'unresolved' && canEdit"
		color="primary"
		mat-raised-button
		(click)="resolve()">
		Mark As Resolved
	</button>
	<button
		*ngIf="inquiry?.status == 'resolved' && canEdit"
		mat-flat-button
		(click)="unResolve()">
		Mark As Unresolved
	</button>
</div>
<mat-divider></mat-divider>
