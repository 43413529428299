import {
	ChangeDetectorRef,
	Component,
	EventEmitter,
	OnDestroy,
	OnInit,
	Output,
} from '@angular/core';
import {
	FormArray,
	FormBuilder,
	FormControl,
	FormGroup,
	Validators,
} from '@angular/forms';
import { atLeastOneRequired } from 'src/app/form-fields/form-validators';
import { ConfirmExitFrom } from 'src/app/services/forms.service';
import { environment } from 'src/environments/environment';
import { userType } from '../httpTypes';
import { PeopleBackendService } from 'src/app/services/people-backend.service';

type booleanString = 'Y' | 'N' | '' | null | undefined;

@Component({
	selector: 'app-mortgage-broker-setup',
	templateUrl: './mortgage-broker-setup.component.html',
	styleUrls: ['./mortgage-broker-setup.component.css'],
})
export class MortgageBrokerSetupComponent implements OnInit, OnDestroy {
	@Output() data = new EventEmitter();
	@Output() submit = new EventEmitter();
	@Output() back = new EventEmitter();

	@ConfirmExitFrom() buyerInfoForm = this.fb.group<{
		buyers: FormArray<any>;
		doesBuyerHaveConveyancer: FormControl<booleanString>;
		conveyancer?: FormArray<any>;
	}>({
		buyers: this.buildPersonFormArray(),
		doesBuyerHaveConveyancer: this.fb.control('', [Validators.required]),
	});

	get doesBuyerHaveConveyancer() {
		return this.buyerInfoForm?.get('doesBuyerHaveConveyancer');
	}

	doesBuyerHaveConveyancer$: any;
	buyersForm$: any;

	//--------------------------------------------------------------------------------

	@ConfirmExitFrom() propertyInfoForm = this.fb.group<{
		offerAccepted: FormControl<booleanString>;
		address?: FormGroup;
		sellingAgentKnown?: FormControl<booleanString>;
		sellingAgent?: FormArray<any>;
		dateOfferAccepted?: FormControl<string | null>;
		agreedSellingPrice?: FormControl<string | null>;
	}>({
		offerAccepted: this.fb.control('', [Validators.required]),
	});

	offerAccepted$: any;
	sellingAgentKnown$: any;
	agreedSellingPrice$: any;
	propertyInfoForm$: any;

	//--------------------------------------------------------------------------------

	@ConfirmExitFrom() mortgageInfoForm = this.fb.group<{
		preApproved?: FormControl<booleanString>;
		preApprovedAmount?: FormControl<string | null>;
		preApprovedDate?: FormControl<string | null>;
		preApprovedExpiry?: FormControl<string | null>;
		preApprovedLender?: FormControl<string | null>;
	}>({
		preApproved: this.fb.control('', [Validators.required]),
	});

	preApproved$: any;
	preApprovedAmount$: any;
	mortgageInfoForm$: any;

	constructor(
		public fb: FormBuilder,
		public cd: ChangeDetectorRef,
		public peopleBackend: PeopleBackendService
	) {}

	ngOnInit(): void {
		this.propertyInfoForm$ = this.propertyInfoForm.valueChanges.subscribe(
			(value) => {
				this.submitBuild();
			}
		);

		this.doesBuyerHaveConveyancerChange();

		this.buyersForm$ = this.buyerInfoForm.valueChanges.subscribe((value) => {
			this.submitBuild();
		});

		this.offerAcceptedChange();

		this.preApprovedChange();

		this.mortgageInfoForm$ = this.mortgageInfoForm.valueChanges.subscribe(
			(value) => {
				this.submitBuild();
			}
		);
	}

	ngOnDestroy(): void {
		if (this.propertyInfoForm$?.unsubscribe)
			this.propertyInfoForm$.unsubscribe();
		if (this.doesBuyerHaveConveyancer$?.unsubscribe)
			this.doesBuyerHaveConveyancer$.unsubscribe();
		if (this.buyersForm$?.unsubscribe) this.buyersForm$.unsubscribe();
		if (this.offerAccepted$?.unsubscribe) this.offerAccepted$.unsubscribe();
		if (this.preApproved$?.unsubscribe) this.preApproved$.unsubscribe();
		if (this.mortgageInfoForm$?.unsubscribe)
			this.mortgageInfoForm$.unsubscribe();
		if (this.sellingAgentKnown$?.unsubscribe)
			this.sellingAgentKnown$.unsubscribe();
		if (this.agreedSellingPrice$?.unsubscribe)
			this.agreedSellingPrice$.unsubscribe();
		if (this.preApprovedAmount$?.unsubscribe)
			this.preApprovedAmount$.unsubscribe();
	}

	doesBuyerHaveConveyancerChange() {
		if (this.doesBuyerHaveConveyancer$?.unsubscribe)
			this.doesBuyerHaveConveyancer$.unsubscribe();
		this.doesBuyerHaveConveyancer$ =
			this.doesBuyerHaveConveyancer?.valueChanges.subscribe((value) => {
				if (value === 'Y') {
					if (!this.buyerInfoForm.get('conveyancer'))
						this.buyerInfoForm.addControl(
							'conveyancer',
							this.buildPersonFormArray()
						);
				} else {
					this.buyerInfoForm.removeControl('conveyancer');
				}
			});
	}

	offerAcceptedChange() {
		if (this.offerAccepted$?.unsubscribe) this.offerAccepted$.unsubscribe();
		this.offerAccepted$ = this.propertyInfoForm
			?.get('offerAccepted')
			?.valueChanges.subscribe((value) => {
				if (value === 'Y') {
					if (!this.propertyInfoForm.get('address'))
						this.propertyInfoForm.addControl(
							'address',
							this.buildAddressForm()
						);
					if (!this.propertyInfoForm.get('sellingAgentKnown'))
						this.propertyInfoForm.addControl(
							'sellingAgentKnown',
							this.fb.control(null, [Validators.required])
						);
					if (!this.propertyInfoForm.get('dateOfferAccepted'))
						this.propertyInfoForm.addControl(
							'dateOfferAccepted',
							this.fb.control(null, [])
						);
					if (!this.propertyInfoForm.get('agreedSellingPrice'))
						this.propertyInfoForm.addControl(
							'agreedSellingPrice',
							this.fb.control(null, [Validators.required])
						);
					this.agreedSellingPriceChange();
					this.knownSellingAgentChange();
				} else {
					if (this.propertyInfoForm.get('address'))
						this.propertyInfoForm.removeControl('address');
					if (this.propertyInfoForm.get('sellingAgentKnown'))
						this.propertyInfoForm.removeControl('sellingAgentKnown');
					if (this.propertyInfoForm.get('dateOfferAccepted'))
						this.propertyInfoForm.removeControl('dateOfferAccepted');
					if (this.propertyInfoForm.get('agreedSellingPrice'))
						this.propertyInfoForm.removeControl('agreedSellingPrice');
				}
			});
	}

	knownSellingAgentChange() {
		if (this.sellingAgentKnown$?.unsubscribe)
			this.sellingAgentKnown$.unsubscribe();
		this.sellingAgentKnown$ = this.propertyInfoForm
			.get('sellingAgentKnown')
			?.valueChanges.subscribe((value) => {
				if (value === 'Y') {
					if (!this.propertyInfoForm.get('sellingAgent'))
						this.propertyInfoForm.addControl(
							'sellingAgent',
							this.buildPersonFormArray()
						);
				} else {
					if (this.propertyInfoForm.get('sellingAgent'))
						this.propertyInfoForm.removeControl('sellingAgent');
				}
			});
	}

	agreedSellingPriceChange() {
		// if (this.agreedSellingPrice$?.unsubscribe)
		// 	this.agreedSellingPrice$.unsubscribe();
		// this.agreedSellingPrice$ = this.propertyInfoForm
		// 	.get('agreedSellingPrice')
		// 	?.valueChanges.subscribe((value) => {
		// 		if (value) {
		// 			let _value = value + '';
		// 			let formattedValue = new Intl.NumberFormat('en-GB', {
		// 				style: 'decimal',
		// 				maximumFractionDigits: 2,
		// 			}).format(parseFloat(_value.replace(/,/g, '')));
		// 			if (_value.charAt(_value.length - 1) == '.') {
		// 				formattedValue += '.';
		// 			}
		// 			this.propertyInfoForm
		// 				.get('agreedSellingPrice')
		// 				?.setValue(formattedValue as any, {
		// 					emitEvent: false,
		// 					emitModelToViewChange: true,
		// 					emitViewToModelChange: false,
		// 				});
		// 		}
		// 	});
	}

	preApprovedChange() {
		if (this.preApproved$?.unsubscribe) this.preApproved$.unsubscribe();
		this.preApproved$ = this.mortgageInfoForm
			.get('preApproved')
			?.valueChanges.subscribe((value) => {
				if (value === 'Y') {
					if (!this.mortgageInfoForm.get('preApprovedAmount'))
						this.mortgageInfoForm.addControl(
							'preApprovedAmount',
							this.fb.control(null, [Validators.required])
						);
					if (!this.mortgageInfoForm.get('preApprovedDate'))
						this.mortgageInfoForm.addControl(
							'preApprovedDate',
							this.fb.control(null, [])
						);
					if (!this.mortgageInfoForm.get('preApprovedExpiry'))
						this.mortgageInfoForm.addControl(
							'preApprovedExpiry',
							this.fb.control(null, [])
						);
					if (!this.mortgageInfoForm.get('preApprovedLender'))
						this.mortgageInfoForm.addControl(
							'preApprovedLender',
							this.fb.control(null, [])
						);
					this.preApprovedAmountChange();
				} else {
					if (this.mortgageInfoForm.get('preApprovedAmount'))
						this.mortgageInfoForm.removeControl('preApprovedAmount');
					if (this.mortgageInfoForm.get('preApprovedDate'))
						this.mortgageInfoForm.removeControl('preApprovedDate');
					if (this.mortgageInfoForm.get('preApprovedExpiry'))
						this.mortgageInfoForm.removeControl('preApprovedExpiry');
					if (this.mortgageInfoForm.get('preApprovedLender'))
						this.mortgageInfoForm.removeControl('preApprovedLender');
				}
			});
	}

	preApprovedAmountChange() {
		// if (this.preApprovedAmount$?.unsubscribe)
		// 	this.preApprovedAmount$.unsubscribe();
		// this.preApprovedAmount$ = this.mortgageInfoForm
		// 	.get('preApprovedAmount')
		// 	?.valueChanges.subscribe((value) => {
		// 		if (value) {
		// 			let _value = value + '';
		// 			let formattedValue = new Intl.NumberFormat('en-GB', {
		// 				style: 'decimal',
		// 				maximumFractionDigits: 2,
		// 			}).format(parseFloat(_value.replace(/,/g, '')));
		// 			if (_value.charAt(_value.length - 1) == '.') {
		// 				formattedValue += '.';
		// 			}
		// 			this.mortgageInfoForm
		// 				.get('preApprovedAmount')
		// 				?.setValue(formattedValue as any, {
		// 					emitEvent: false,
		// 					emitModelToViewChange: true,
		// 					emitViewToModelChange: false,
		// 				});
		// 		}
		// 	});
	}

	buildAddressForm() {
		return this.fb.group({
			postcode: [environment.postcode, [Validators.required]],
			address: ['', [Validators.required]],
			addressline1: [null],
			addressline2: [null],
			number: [null],
			premise: [null],
			street: [null],
			posttown: [null],
			county: [null],
			UPRN: ['', [Validators.required]],
			longitude: [null],
			latitude: [null],
			usrn: [null],
		});
	}

	buildPersonFormArray() {
		return this.fb.array(
			[
				this.fb.group({
					firstName: [environment.firstName, [Validators.required]],
					lastName: [environment.lastName, [Validators.required]],
					email: [
						environment.email,
						[Validators.required, Validators.email],
						//[this.peopleBackend.validEmail()],
					],
					phoneCountryCode: ['+44', [Validators.required]],
					phoneInCountryNumber: [
						'',
						[Validators.required, Validators.pattern('^[1-9]\\d{9}')],
					],
					company: [null],
					dontSendEmail: [false],
				}),
			],
			[atLeastOneRequired]
		);
	}

	submitForm() {
		if (
			this.propertyInfoForm.invalid ||
			this.buyerInfoForm.invalid ||
			this.mortgageInfoForm.invalid
		) {
			this.data.emit(null);
			return;
		}

		this.submitBuild();
		this.submit.emit();
	}

	submitBuild() {
		if (
			this.propertyInfoForm.invalid ||
			this.buyerInfoForm.invalid ||
			this.mortgageInfoForm.invalid
		) {
			this.data.emit(null);
			return;
		}

		let mortgageBrokerData: {
			userType: userType.mortgageBroker;
			buyer: {
				buyers: any[];
				conveyancer?: any[];
			};
			property?: {
				address?: string | null;
				addressline1?: string;
				addressline2?: string;
				number?: string;
				premise?: string;
				street?: string;
				posttown?: string;
				county?: string;
				postCode?: string | null;
				UPRN?: string | null;
				sellingAgent?: any[];
				agreedPrice?: number | null;
				offerDate?: number | null;
				longitude: string | null;
				latitude: string | null;
				usrn: string | null;
			};
			mortgage?: {
				preApprovedAmount?: number | null;
				preApprovedDate?: number | null;
				preApprovedExpiry?: number | null;
				preApprovedLender?: string | null;
			};
		} = {
			userType: userType.mortgageBroker,
			buyer: {
				buyers: (this.buyerInfoForm.get('buyers') as FormArray).controls.map(
					(c) => {
						return {
							firstName: c.get('firstName')?.value,
							lastName: c.get('lastName')?.value,
							email: c.get('email')?.value,
							phone: c.get('phone')?.value,
							phoneCountryCode: c.get('phoneCountryCode')?.value,
							phoneInCountryNumber: c.get('phoneInCountryNumber')?.value,
							company: c.get('company')?.value,
							dontSendEmail: c.get('dontSendEmail')?.value,
						};
					}
				),
			},
		};

		if (this.buyerInfoForm.get('conveyancer')) {
			mortgageBrokerData.buyer.conveyancer = (
				this.buyerInfoForm.get('conveyancer') as FormArray
			).controls.map((c) => {
				return {
					firstName: c.get('firstName')?.value,
					lastName: c.get('lastName')?.value,
					email: c.get('email')?.value,
					phone: c.get('phone')?.value,
					phoneCountryCode: c.get('phoneCountryCode')?.value,
					phoneInCountryNumber: c.get('phoneInCountryNumber')?.value,
					company: c.get('company')?.value,
					dontSendEmail: c.get('dontSendEmail')?.value,
				};
			});
		}

		if (this.propertyInfoForm.get('address')) {
			mortgageBrokerData.property = {
				address: this.propertyInfoForm.get('address')?.get('address')?.value,
				addressline1: this.propertyInfoForm.get('address')?.get('addressline1')
					?.value,
				addressline2: this.propertyInfoForm.get('address')?.get('addressline2')
					?.value,
				number: this.propertyInfoForm.get('address')?.get('number')?.value,
				premise: this.propertyInfoForm.get('address')?.get('premise')?.value,
				street: this.propertyInfoForm.get('address')?.get('street')?.value,
				posttown: this.propertyInfoForm.get('address')?.get('posttown')?.value,
				county: this.propertyInfoForm.get('address')?.get('county')?.value,
				postCode: this.propertyInfoForm.get('address')?.get('postcode')?.value,
				UPRN: this.propertyInfoForm.get('address')?.get('UPRN')?.value,
				longitude: this.propertyInfoForm.get('address')?.get('longitude')
					?.value,
				latitude: this.propertyInfoForm.get('address')?.get('latitude')?.value,
				usrn: this.propertyInfoForm.get('address')?.get('usrn')?.value,
			};
		}

		if (
			mortgageBrokerData.property &&
			this.propertyInfoForm.get('agreedSellingPrice')
		) {
			mortgageBrokerData.property.agreedPrice = parseFloat(
				(this.propertyInfoForm.get('agreedSellingPrice')?.value || '')?.replace(
					/,/g,
					''
				)
			);
		}

		if (
			mortgageBrokerData.property &&
			this.propertyInfoForm.get('dateOfferAccepted')?.value
		) {
			let offerDate = new Date(
				this.propertyInfoForm.get('dateOfferAccepted')?.value || ''
			);
			mortgageBrokerData.property.offerDate = dateToJool(offerDate);
		}

		if (
			mortgageBrokerData.property &&
			this.propertyInfoForm.get('sellingAgent')
		) {
			mortgageBrokerData.property.sellingAgent = (
				this.propertyInfoForm.get('sellingAgent') as FormArray
			).controls.map((c) => {
				return {
					firstName: c.get('firstName')?.value,
					lastName: c.get('lastName')?.value,
					email: c.get('email')?.value,
					phone: c.get('phone')?.value,
					phoneCountryCode: c.get('phoneCountryCode')?.value,
					phoneInCountryNumber: c.get('phoneInCountryNumber')?.value,
					company: c.get('company')?.value,
					dontSendEmail: c.get('dontSendEmail')?.value,
				};
			});
		}

		if (this.mortgageInfoForm.get('preApprovedAmount')) {
			mortgageBrokerData.mortgage = {
				preApprovedAmount: parseFloat(
					(
						this.mortgageInfoForm.get('preApprovedAmount')?.value || ''
					)?.replace(/,/g, '')
				),
			};
		}

		if (
			mortgageBrokerData.mortgage &&
			this.mortgageInfoForm.get('preApprovedDate')?.value
		) {
			let preApprovedDate = new Date(
				this.mortgageInfoForm.get('preApprovedDate')?.value || ''
			);
			mortgageBrokerData.mortgage.preApprovedDate = dateToJool(preApprovedDate);
		}

		if (
			mortgageBrokerData.mortgage &&
			this.mortgageInfoForm.get('preApprovedExpiry')?.value
		) {
			let preApprovedExpiry = new Date(
				this.mortgageInfoForm.get('preApprovedExpiry')?.value || ''
			);
			mortgageBrokerData.mortgage.preApprovedExpiry =
				dateToJool(preApprovedExpiry);
		}

		if (
			mortgageBrokerData.mortgage &&
			this.mortgageInfoForm.get('preApprovedLender')?.value
		) {
			mortgageBrokerData.mortgage.preApprovedLender =
				this.mortgageInfoForm.get('preApprovedLender')?.value;
		}

		this.propertyInfoForm.markAsPristine();
		this.buyerInfoForm.markAsPristine();
		this.mortgageInfoForm.markAsPristine();

		this.data.emit(mortgageBrokerData);
	}
}

function dateToJool(_date: Date): number {
	return (
		_date.getFullYear() * 10000 + (_date.getMonth() + 1) * 100 + _date.getDate()
	);
}
