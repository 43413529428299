import { Injectable } from '@angular/core';
import {
	HttpRequest,
	HttpHandler,
	HttpEvent,
	HttpInterceptor,
} from '@angular/common/http';
import { from, lastValueFrom, Observable } from 'rxjs';
import { AuthService } from './auth.service';

@Injectable()
export class AuthInterInterceptor implements HttpInterceptor {
	constructor(public AuthService: AuthService) {}

	intercept(
		request: HttpRequest<unknown>,
		next: HttpHandler
	): Observable<HttpEvent<unknown>> {
		if (!request.withCredentials) return next.handle(request);

		return from(this.handle2(request, next));
	}

	async handle2(req: HttpRequest<any>, next: HttpHandler) {
		let token = await this.AuthService.getToken();

		let headers: any = {
			Authorization: 'Bearer ' + token,
		};
		if (this.AuthService.spoofAs)
			headers['x-spoof-as'] = this.AuthService.spoofAs;
		const authReq = req.clone({
			setHeaders: headers,
		});

		return await lastValueFrom(next.handle(authReq));
	}

	async handle(req: HttpRequest<any>, next: HttpHandler) {
		//if(!req.withCredentials) return await lastValueFrom(next.handle(req));
		if (!req.withCredentials) return next.handle(req);

		let token = await this.AuthService.getToken();
		let headers: any = {
			Authorization: 'Bearer ' + token,
		};
		if (this.AuthService.spoofAs)
			headers['x-spoof-as'] = this.AuthService.spoofAs;
		const authReq = req.clone({
			setHeaders: headers,
		});

		// return await lastValueFrom(next.handle(authReq));
		return next.handle(authReq);
	}
}
