<mat-card
	appearance="outlined"
	[ngClass]="{
		disabled: state == 'disabled',
		completed: state == 'completed'
	}">
	<mat-card-header>
		<mat-card-title>
			Anti-Money Laundering ID Checks ({{ AMLs?.length || 0 }})
		</mat-card-title>
	</mat-card-header>
	<mat-card-content>
		<div
			*ngFor="let aml of AMLs; let i = index"
			style="
				display: flex;
				flex-direction: row;
				justify-content: space-between;
			">
			<div>
				{{ aml.firstName }} {{ aml.lastName }} -
				{{ aml.userEmail }}
			</div>
			<div>
				{{ aml.status }}
			</div>
			<div *ngIf="aml.status == 'complete'">
				<span matTooltip="Identity Verifications">
					<mat-icon
						[ngClass]="
							aml.identityVerifications == credasStatuses.pass
								? 'green-text'
								: 'red-text'
						"
						>{{
							aml.identityVerifications == credasStatuses.pass
								? "check_circle"
								: "warning_amber"
						}}</mat-icon
					>
				</span>
				<span matTooltip="Address Mortality Checks">
					<mat-icon
						[ngClass]="
							aml.addressMortalityChecks == credasStatuses.pass
								? 'green-text'
								: 'red-text'
						"
						>{{
							aml.addressMortalityChecks == credasStatuses.pass
								? "check_circle"
								: "warning_amber"
						}}</mat-icon
					>
				</span>
				<span matTooltip="Sanction Pep Checks">
					<mat-icon
						[ngClass]="
							aml.sanctionPepChecks == credasStatuses.pass
								? 'green-text'
								: 'red-text'
						"
						>{{
							aml.sanctionPepChecks == credasStatuses.pass
								? "check_circle"
								: "warning_amber"
						}}</mat-icon
					>
				</span>
			</div>
			<div *ngIf="aml.permission && aml.status == 'pending' && aml.url">
				<a [href]="aml.url | safe" target="_blank">Do Check Now</a>
			</div>
			<div *ngIf="!aml.permission">Permission: {{ aml.permissionState }}</div>
			<div *ngIf="aml.reportKey && aml.permission">
				<a [routerLink]="['/', 'AMLReport', aml.id]">View Report</a>
			</div>
			<div>EXP:{{ aml.ExpDate | date : "medium" }}</div>
		</div>
	</mat-card-content>
</mat-card>
