<div class="container">
	<h1 class="header">Offer Submitted</h1>
	<mat-card>
		<mat-card-content>
			<p>
				Your offer has been submitted successfully. You will receive an email
				confirmation shortly. The Estate Agent will be notified and communicate
				the offer to the property owners. It can take upto a few business days
				before you hear back.
			</p>
		</mat-card-content>
	</mat-card>
</div>
