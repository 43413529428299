import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from '../services/auth.service';
import { ConfirmDialogService } from '../services/confirm-dialog.service';
import {
	RequestsBackendService,
	Request,
} from '../services/requests-backend.service';
import { TitleService } from '../services/title.service';

@Component({
	templateUrl: './requests.component.html',
	styleUrls: ['./requests.component.css'],
})
export class RequestsComponent implements OnInit {
	loading = true;
	requests: Request[] = [];
	deletedRequests: {
		[id: string]: true;
	} = {};
	user: any;
	constructor(
		public Router: Router,
		public authService: AuthService,
		public confirmDialogService: ConfirmDialogService,
		public requestBackend: RequestsBackendService,
		public titleService: TitleService
	) {}

	async ngOnInit() {
		this.titleService.title = 'Access Requests';
		this.loading = true;
		let r = await this.requestBackend.getList();
		this.requests = r?.data || [];
		this.loading = false;

		this.user = await this.authService.getUser();
		console.log(this.user);
	}

	logoURL(property: any) {
		let color = 'green';
		if (property?.issueCount > 0) color = 'yellow';
		if (property?.isPlaceHolder) color = 'red';
		if (property?.postCode == 'No Postcode') color = 'red';
		return `/assets/colorIcons/${color}.svg`;
	}

	goto(property: any) {
		this.Router.navigate(['/chain', property.chainId, 'property', property.id]);
	}

	canAccept(request: any) {
		if (this.user?.attributes?.email == request?.email) return false;
		return true;
	}

	async deleteRequest(request: any) {
		if (
			!(await this.confirmDialogService.confirm({
				title: 'Delete Request?',
				message: 'Are you sure you want to delete this request?',
			}))
		)
			return;
		this.deletedRequests[request.id] = true;
		await this.requestBackend.delete(request.id);

		this.requests = this.requests.filter((r: any) => r.id != request.id);
	}

	async acceptRequest(request: any) {
		if (
			!(await this.confirmDialogService.confirm({
				title: 'Accept Request?',
				message: 'Are you sure you want to accept this request?',
			}))
		)
			return;
		this.deletedRequests[request.id] = true;
		await this.requestBackend.accept(request.id);
		this.requests = this.requests.filter((r: any) => r.id != request.id);
	}
}
