import { Component, Input, OnInit } from '@angular/core';

@Component({
	selector: 'app-loading-tombstone',
	templateUrl: './loading-tombstone.component.html',
	styleUrls: ['./loading-tombstone.component.css'],
})
export class LoadingTombstoneComponent implements OnInit {
	@Input() morePadding?: boolean = false;
	constructor() {}

	ngOnInit(): void {}
}
