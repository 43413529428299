import { ChangeDetectionStrategy, Component, type OnInit } from '@angular/core';
import {
	AMLBackendService,
	AMLcheckResponse,
	credasStatus,
} from '../services/AML-backend.service';
import { MatDialog } from '@angular/material/dialog';
import { NewAMLDialog } from './newAML/newAML.dialog';
import { lastValueFrom } from 'rxjs';
import { TitleService } from '../services/title.service';

@Component({
	selector: 'app-aml',
	templateUrl: './AML.component.html',
	styleUrls: ['./AML.component.css'],
	//changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AMLComponent implements OnInit {
	loading = true;
	AMLchecks: AMLcheckResponse[] = [];
	credasStatuses = credasStatus;
	constructor(
		public AMLBackend: AMLBackendService,
		public dialog: MatDialog,
		public titleService: TitleService
	) {}
	ngOnInit(): void {
		this.titleService.title = 'AML Checks';
		this.AMLBackend.getList().then((res) => {
			this.AMLchecks = res.data;
			this.loading = false;
		});
	}

	async createAMLcheck() {
		let r = await lastValueFrom(
			this.dialog.open(NewAMLDialog, {}).afterClosed()
		);
		//console.log(r);
		if (r) {
			this.loading = true;
			await this.AMLBackend.put(r);
			this.AMLchecks = (await this.AMLBackend.getList()).data;
			this.loading = false;
		}
	}
}
