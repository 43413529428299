import { Component } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { ActivatedRoute, Route, Router } from '@angular/router';
import {
	Email,
	EmailBackendService,
} from 'src/app/services/email-backend.service';
import { TaskStatus } from 'src/app/services/task-backend.service';

@Component({
	selector: 'app-email',
	templateUrl: './email.component.html',
	styleUrls: ['./email.component.css'],
})
export class EmailComponent {
	emailId: string = this.route.snapshot.params['emailId'];
	email: Email | undefined;
	$prams: any;
	loading = false;

	constructor(
		public route: ActivatedRoute,
		public emailBackend: EmailBackendService,
		public sanitizer: DomSanitizer
	) {}

	async ngOnInit() {
		this.route.params.subscribe(async (params) => {
			this.loading = true;
			this.emailId = params['emailId'];
			let emailR = await this.emailBackend.get(this.emailId);
			this.email = emailR.data;
			if (this.email)
				this.email.html = (
					this.sanitizer.bypassSecurityTrustHtml(this.email.html || '') as any
				)['changingThisBreaksApplicationSecurity'];

			this.loading = false;
		});
	}

	ngOnDestroy() {
		if (this.$prams) this.$prams.unsubscribe();
	}

	resizeIframe(event: any) {
		event.srcElement.style.height =
			event.srcElement.contentWindow.document.body.scrollHeight + 25 + 'px';
	}

	propColor(property: any) {
		let color = 'green';
		if (property?.isLate) color = 'yellow';
		if (property?.issueCount > 0) color = 'yellow';
		if (property?.id == 'unknown') color = 'red';
		if (property?.isPlaceHolder) color = 'red';
		if (property?.postCode == 'No Postcode') color = 'red';
		return color;
	}

	logoURL(property: any) {
		let color = this.propColor(property);
		return `/assets/colorIcons/${color}.svg`;
	}
}
