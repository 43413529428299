import {
	AfterViewInit,
	ChangeDetectorRef,
	Component,
	HostListener,
	OnInit,
	ViewChild,
} from '@angular/core';

import { Clipboard } from '@angular/cdk/clipboard';
import { FormControl, FormGroup } from '@angular/forms';
import { AuthService } from './services/auth.service';
import { MatSidenav } from '@angular/material/sidenav';
import {
	Router,
	NavigationEnd,
	NavigationStart,
	ChildrenOutletContexts,
} from '@angular/router';
import { SwUpdate } from '@angular/service-worker';
import {
	MatSnackBar,
	MatSnackBarHorizontalPosition,
	MatSnackBarVerticalPosition,
} from '@angular/material/snack-bar';
import { environment } from 'src/environments/environment';
import {
	trigger,
	state,
	style,
	animate,
	transition,
	query,
	animateChild,
	group,
} from '@angular/animations';
import { filter } from 'rxjs';
import { FormsService } from './services/forms.service';
import { MediaMatcher } from '@angular/cdk/layout';
import { BugsService } from './services/bugs.service';
import { TitleService } from './services/title.service';
import { userType } from './setup/httpTypes';

declare function cwr(operation: string, payload: any): void;

const topHight = environment.branch == 'app' ? '64px' : '88px';

@Component({
	selector: 'app-root',
	templateUrl: './app.component.html',
	styleUrls: ['./app.component.css'],
	animations: [
		trigger('routeAnimations', [
			transition(':decrement', [
				style({ position: 'relative' }),
				query(':enter, :leave', [
					style({
						position: 'absolute',
						top: topHight,
						left: 0,
						width: '100%',
						height: '100%',
						overflow: 'hidden',
					}),
				]),
				query(':enter', [style({ left: '-100%', top: topHight, opacity: 0 })]),
				query(':leave', animateChild()),
				group([
					query(':leave', [
						animate(
							'300ms ease-out',
							style({ left: '100%', top: topHight, opacity: 0 })
						),
					]),
					query(':enter', [
						animate(
							'300ms ease-out',
							style({ left: '0%', top: topHight, opacity: 1 })
						),
					]),
				]),
			]),
			transition(':increment', [
				style({ position: 'relative' }),
				query(':enter, :leave', [
					style({
						position: 'absolute',
						top: topHight,
						left: 0,
						width: '100%',
						height: '100%',
						overflow: 'hidden',
					}),
				]),
				query(':enter', [style({ left: '100%', opacity: 0, top: topHight })]),
				query(':leave', animateChild()),
				group([
					query(':leave', [
						animate(
							'300ms ease-out',
							style({ left: '-100%', opacity: 0, top: topHight })
						),
					]),
					query(':enter', [
						animate(
							'300ms ease-out',
							style({ left: '0%', opacity: 1, top: topHight })
						),
					]),
				]),
			]),
		]),
	],
})
export class AppComponent implements AfterViewInit, OnInit {
	@ViewChild('drawer') drawer: MatSidenav | undefined;

	user: any = {};
	jwt: string = '';
	attr: any;
	isAgent = false;
	version: string = environment.version;
	prod = environment.production;
	branch = environment.branch;
	mobile = false;

	formGroup = new FormGroup({
		phone: new FormControl(''),
	});

	title = '';

	update$: any;
	hasLoggedIn = false;
	hideSideNav = false;

	versionUpdateFailed = false;
	constructor(
		public clipboard: Clipboard,
		public AuthService: AuthService,
		public router: Router,
		public SwUpdate: SwUpdate,
		public cd: ChangeDetectorRef,
		private _snackBar: MatSnackBar,
		private contexts: ChildrenOutletContexts,
		private formService: FormsService,
		public MediaMatcher: MediaMatcher,
		public bugsService: BugsService,
		public titleService: TitleService
	) {}
	ngOnInit() {
		this.titleService.$title.subscribe((val) => {
			this.title = val;
			this.cd.detectChanges();
		});

		let forms = this.formService;
		this.router.events.subscribe((event) => {
			if (event instanceof NavigationEnd) {
				//console.log(event);
				cwr('recordPageView', this.router.url);
				this.bugsService.addToHistory(this.router.url);
				let prams = this.router.parseUrl(this.router.url).queryParams;
				if (prams['hideSide'] == 'true') {
					this.hideSideNav = true;
					this.drawer?.close();
				} else {
					this.hideSideNav = false;
					if (!this.mobile) this.drawer?.open();
				}
			}
		});

		// this.AuthService.getUser()
		// 	.then((user) => {
		// 		this.user = user;
		// 		if (user?.attributes) this.hasLoggedIn = true;
		// 		//console.log(user);
		// 	})
		// 	.catch((err) => {});

		// this.AuthService.getUserAttributes().then((attributes) => {
		// 	if (attributes['custom:user_type'] == userType.sellersAgent) {
		// 		this.isAgent = true;
		// 	}
		// });

		this.checkForUserUpdates();

		this.AuthService.$loggedIn.subscribe((val) => {
			this.checkForUserUpdates();
		});

		// this.router.events
		// 	.pipe(filter((event) => event instanceof NavigationStart))
		// 	.subscribe((event) => {
		// 		console.log(event);
		// 	});

		this.update$ = this.SwUpdate.versionUpdates.subscribe((event) => {
			console.log('current version is', event.type);
			console.log('available version is', event);

			if (event.type == 'VERSION_DETECTED') {
				if (!this.versionUpdateFailed)
					this.SwUpdate.activateUpdate().then(() => {
						setTimeout(() => {
							let snackBarRef = this._snackBar.open(
								'There is a new version of Clear Chain available!!',
								'Update',
								{
									horizontalPosition: 'right',
									verticalPosition: 'top',
								}
							);

							snackBarRef.onAction().subscribe(() => {
								(window.location as any).reload(true);
							});
						}, 5000);
					});
			}
			if (event.type == 'VERSION_INSTALLATION_FAILED') {
				console.log('VERSION_INSTALLATION_FAILED', event.error);
				this.versionUpdateFailed = true;
			}
		});

		let savedTheme = localStorage.getItem('theme');
		if (!!savedTheme) this.theme = savedTheme as any;
		else
			this.theme = this.MediaMatcher.matchMedia('(prefers-color-scheme: dark)')
				.matches
				? 'dark-theme'
				: 'light-theme';
		if (
			!document.body.classList.contains('dark-theme') &&
			this.theme === 'dark-theme'
		) {
			this.selectDarkTheme();
		}
		if (
			!document.body.classList.contains('light-theme') &&
			this.theme === 'light-theme'
		) {
			this.selectLightTheme();
		}
		this.MediaMatcher.matchMedia(
			'(prefers-color-scheme: dark)'
		).addEventListener('change', (e) => {
			if (e.matches) {
				document.body.classList.add('dark-theme');
				document.body.classList.remove('light-theme');
				this.theme = 'dark-theme';
			} else {
				document.body.classList.remove('dark-theme');
				document.body.classList.add('light-theme');
				this.theme = 'light-theme';
			}
		});
	}
	ngAfterViewInit() {
		this.onResize({
			target: window,
		});
	}

	getRouteAnimationData() {
		return this.contexts.getContext('primary')?.route?.snapshot?.data?.[
			'tabOrder'
		];
	}

	@HostListener('window:resize', ['$event'])
	onResize(event: any) {
		if (event?.target?.innerWidth < 800) {
			if (this.drawer) {
				this.drawer.mode = 'over';
				this.drawer.close();
			}
			this.mobile = true;
		} else if (event?.target?.innerWidth >= 800) {
			if (this.drawer) {
				this.drawer.mode = 'side';
				if (!this.hideSideNav) this.drawer.open();
			}
			this.mobile = false;
		}
		this.cd.detectChanges();
	}

	closeDrawer() {
		if (this.drawer && window?.innerWidth < 500) this.drawer.close();
		this.cd.detectChanges();
	}
	async getToken() {
		this.jwt = await this.AuthService.getToken();
		this.clipboard.copy(this.jwt);
		return this.jwt;
	}

	signOut() {
		this.AuthService.signOut();
	}

	async checkForUserUpdates() {
		this.AuthService.getUser()
			.then((user) => {
				this.user = user;
				if (user?.attributes) this.hasLoggedIn = true;
				//console.log(user);
			})
			.catch((err) => {});

		this.AuthService.getUserAttributes().then((attributes) => {
			if (
				attributes &&
				attributes['custom:user_type'] &&
				attributes['custom:user_type'] == userType.sellersAgent
			) {
				this.isAgent = true;
			}
		});
	}

	async checkForUpdate() {
		await this.SwUpdate.checkForUpdate();
	}

	theme: 'light-theme' | 'dark-theme' | undefined;

	public selectDarkTheme(): void {
		document.body.classList.add('dark-theme');
		this.theme = 'dark-theme';
	}

	public selectLightTheme(): void {
		document.body.classList.remove('light-theme');
		this.theme = 'light-theme';
	}

	async newBug() {
		await this.bugsService.newBug();
	}

	async newFeature() {
		await this.bugsService.newBug('feature');
	}
}
