<div class="container">
	<h1 class="header">Template Override Settings</h1>
	<mat-card *ngIf="!templates || templates?.length == 0">
		<mat-card-content>
			Loading <app-loading-dots></app-loading-dots>
		</mat-card-content>
	</mat-card>
	<mat-card
		appearance="outlined"
		style="margin: 3rem 0"
		*ngFor="let milestone of templates; let i = index">
		<mat-card-header>
			<mat-card-title>{{ milestone.title }}</mat-card-title>
		</mat-card-header>
		<mat-card-content>
			<div style="margin-left: 2rem">
				<div *ngFor="let task of milestone.children; let i = index">
					<div
						style="display: flex; flex-direction: row; width: 100%"
						*ngIf="task.children?.length == 0">
						<div style="flex-grow: 1">
							{{ task.title }}
						</div>
						<div *ngIf="task.children?.length == 0">
							A default of {{ task.estimatedBizDaysToComplete }} business days
							to complete.
							<mat-form-field
								style="width: 310px"
								appearance="outline"
								floatLabel="always">
								<mat-label>Preferred Number Of Days To Complete</mat-label>
								<input
									matInput
									type="number"
									[placeholder]="
										'default of ' + $any(task.estimatedBizDaysToComplete)
									"
									[value]="task.preferences?.estimatedBizDaysToComplete"
									(blur)="updateEstimatedTime(task, $event.target.value)" />
							</mat-form-field>
						</div>
					</div>
					<mat-card
						appearance="outlined"
						style="margin: 3rem 0"
						*ngIf="$any(task.children?.length) > 0">
						<mat-card-header>
							<mat-card-title>{{ task.title }}</mat-card-title>
						</mat-card-header>
						<mat-card-content style="margin-left: 2rem">
							<div *ngFor="let subtask of task.children; let i = index">
								<div style="display: flex; flex-direction: row; width: 100%">
									<div style="flex-grow: 1">
										{{ subtask.title }}
									</div>
									<div>
										A default of
										{{ subtask.estimatedBizDaysToComplete }} business days to
										complete.
										<mat-form-field
											style="width: 310px"
											appearance="outline"
											floatLabel="always">
											<mat-label
												>Preferred Number Of Days To Complete</mat-label
											>
											<input
												matInput
												type="number"
												[placeholder]="
													'default of ' +
													$any(subtask.estimatedBizDaysToComplete)
												"
												[value]="
													subtask.preferences?.estimatedBizDaysToComplete
												"
												(blur)="
													updateEstimatedTime(subtask, $event.target.value)
												" />
										</mat-form-field>
									</div>
								</div>
							</div>
						</mat-card-content>
					</mat-card>
				</div>
			</div>
		</mat-card-content>
	</mat-card>
</div>
