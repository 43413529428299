<mat-spinner *ngIf="loadingPeople"></mat-spinner>
<mat-card appearance="outlined" *ngIf="!loadingPeople">
	<mat-card-header>
		<mat-card-title>
			<h3>Add {{ userType }} To The End Of The Chain</h3>
		</mat-card-title>
	</mat-card-header>
	<mat-card-content>
		<form [formGroup]="buyerSellerForm" (ngSubmit)="savePeopleForm()">
			<div class="row row-bottom-padding">
				<app-people
					[form]="$any(buyerSellerForm.get('people'))"
					[atLeastOne]="true"
					[title]="userType + '/s'"></app-people>
			</div>
			<div class="actions">
				<button
					type="button"
					[disabled]="buyerSellerForm.pristine || loadingPeople"
					mat-raised-button
					color=""
					(click)="resetPeopleFormGroup()">
					Reset
				</button>
				<button
					class="save-button"
					[disabled]="
						buyerSellerForm.pristine || loadingPeople || buyerSellerForm.invalid
					"
					mat-raised-button
					color="primary"
					type="submit">
					<span *ngIf="!loadingPeople">Add {{ userType }}</span>
					<span *ngIf="loadingPeople"
						>Adding<app-loading-dots></app-loading-dots
					></span>
				</button>
			</div>
		</form>
	</mat-card-content>
</mat-card>
