<!-- <div class="container">
	<div class="row">
		<app-logo></app-logo>
	</div>
	<p>Welcome to Clear Chain.</p>
	<p>
		Enabling you to clearly see how your chain is progressing, identify risks
		early and speed up the closing process.
	</p>
	<div class="center">
		<button mat-raised-button color="primary" routerLink="/setup">
			Start A New Property Or Chain
		</button>
	</div>
</div> -->
<!-- <div class="dash-container"> -->

<div class="dashboard">
	<!-- <div class="side-actions">
		<div class="action-item" (click)="gotoNewChain()">
			<app-logo style="margin-bottom: 1rem"></app-logo>
			<button
				style="width: 100%"
				mat-raised-button
				color="primary"
				routerLink="/setup">
				New Chain
			</button>
		</div>
		<div class="action-item" (click)="gotoFAQ()">
			<img src="assets/FAQ1.png" alt="FAQ" style="width: 100%" />
			<button
				style="width: 100%"
				mat-raised-button
				color="primary"
				routerLink="/faq">
				FAQ
			</button>
		</div>
		<div class="action-item" (click)="gotoProperties()">
			<app-logo
				style="margin-bottom: 1rem"
				matBadgeSize="medium"
				matBadgeOverlap="true"
				[matBadge]="propertyCount"></app-logo>
			<button
				style="width: 100%"
				mat-raised-button
				color="primary"
				routerLink="/properties">
				My Properties
			</button>
		</div>
	</div> -->
	<div class="cards">
		<div class="welcome" *ngIf="user.attributes?.given_name">
			<h1>Hello, {{ user.attributes?.given_name }}</h1>
			<div class="spacer"></div>
			<div>
				<button
					mat-fab
					extended
					color="accent"
					routerLink="/setup"
					aria-label="button to start a new chain">
					<mat-icon>add</mat-icon>
					New Chain
				</button>
			</div>
		</div>
		<mat-card class="notifications">
			<mat-card-header>
				<mat-card-title>
					<span class="notification-header">
						<span class="icon">
							<img src="/assets/figma-icons/akar-icons_bell.svg" />
						</span>
						Notifications
					</span>
				</mat-card-title>
				<mat-card-subtitle
					><span *ngIf="loadingNotifications"
						>Loading<app-loading-dots></app-loading-dots></span
				></mat-card-subtitle>
			</mat-card-header>
			<mat-card-content>
				<div
					*ngIf="(notifications?.length || 0) < 1 && !loadingNotifications"
					class="no-items">
					You have no new notifications.
				</div>
				<div *ngFor="let n of notifications" class="notification">
					<div class="icon">
						<img src="/assets/figma-icons/akar-icons_bell.svg" />
					</div>
					<div class="link">
						<a
							style="
								width: 100%;
								font-weight: bold;
								margin-bottom: 0.5rem;
								display: block;
							"
							[routerLink]="notificationUrlArray(n)">
							{{ n.notice }}</a
						>
					</div>
				</div>
			</mat-card-content>
		</mat-card>

		<mat-card class="properties">
			<mat-card-header>
				<div class="more-button">
					<button mat-icon-button [routerLink]="['/', 'properties']">
						<mat-icon>more_horiz</mat-icon>
					</button>
				</div>
				<mat-card-title>
					<span>
						<span class="property-header-icon">
							<img src="/assets/figma-icons/akar-icons_home-alt1.svg" />
						</span>
						My Properties
					</span>
				</mat-card-title>
				<mat-card-subtitle
					><span *ngIf="loadingProperties"
						>Loading<app-loading-dots></app-loading-dots></span
					><span *ngIf="!loadingProperties" style="margin-left: 3.5rem"
						><a [routerLink]="['/', 'properties']"
							>{{ propertyCount }} Properties</a
						></span
					></mat-card-subtitle
				>
			</mat-card-header>
			<mat-card-content class="properties-container">
				<div
					*ngFor="let property of properties"
					class="property"
					[routerLink]="['chain', property.chainId, 'property', property.id]">
					<div class="property-icon">
						<img src="/assets/figma-icons/akar-icons_home-alt1.svg" />
					</div>
					<div>
						<div class="nickname">{{ property.address || "" }}</div>
						<div class="property-name">
							{{ property.nickName || "" }}
						</div>
					</div>
				</div>
			</mat-card-content>
		</mat-card>

		<mat-card class="todo-list">
			<mat-card-header>
				<div class="more-button">
					<button mat-icon-button [routerLink]="['/', 'tasks']">
						<mat-icon>more_horiz</mat-icon>
					</button>
				</div>
				<mat-card-title>
					<span>
						<span class="task-header-icon">
							<mat-icon class="icon-not-started" mat-list-icon>task </mat-icon>
						</span>
						<span style="vertical-align: text-bottom"> To Do </span>
					</span>
				</mat-card-title>
				<mat-card-subtitle
					><span *ngIf="loadingTasks"
						>Loading<app-loading-dots></app-loading-dots></span
					><span *ngIf="!loadingTasks" style="margin-left: 3.5rem"
						><a [routerLink]="['/', 'tasks']"
							>{{ taskCount }} Tasks in your todo list.</a
						></span
					></mat-card-subtitle
				>
			</mat-card-header>
			<mat-card-content class="properties-container">
				<div *ngIf="!loadingTasks && tasks.length == 0" class="no-items">
					You have no tasks to complete right now.
				</div>
				<div (click)="goto(task)" *ngFor="let task of tasks; let i = index">
					<div class="task">
						<div class="task-icon">
							<mat-icon
								[ngClass]="{
									'icon-completed': task.status == TaskStatus.completed,
									'icon-canceled': task.status == TaskStatus.cancelled,
									'icon-not-started': task.status == TaskStatus.notStarted,
									'icon-in-progress': task.status == TaskStatus.inProgress,
									'icon-disabled': task.status == TaskStatus.disabled
								}"
								mat-list-icon
								>{{
									task.taskType == TaskType.decider
										? "signpost"
										: task.taskType == TaskType.milestone
										? "beenhere"
										: "task"
								}}</mat-icon
							>
						</div>

						<div>
							<div class="task-title">{{ task.title }}</div>
							<div class="task-subtitle">
								Due {{ getTimeUntilDate(task.estimatedCompletionDate) }} -
								{{ task.propertyNickname }}
							</div>
						</div>
					</div>
				</div>
			</mat-card-content>
		</mat-card>

		<mat-card class="issues">
			<mat-card-header>
				<mat-card-title>
					<span>
						<span class="property-header-icon">
							<img src="/assets/figma-icons/Exclamation - Blue.png" />
						</span>
						Issues
					</span>
				</mat-card-title>
			</mat-card-header>
			<mat-card-content>
				<div class="no-items">You have no issues to resolve right now.</div>
			</mat-card-content>
		</mat-card>

		<mat-card class="chain-dates">
			<mat-card-header>
				<mat-card-title>
					<span>
						<span class="task-header-icon">
							<mat-icon mat-list-icon>event</mat-icon>
						</span>
						<span style="vertical-align: text-bottom"> Chain Dates </span>
					</span>
				</mat-card-title>
				<mat-card-subtitle
					><span *ngIf="loadingDates"
						>Loading<app-loading-dots></app-loading-dots></span
				></mat-card-subtitle>
			</mat-card-header>
			<mat-card-content>
				<div class="no-items" *ngIf="dates.length == 0 && !loadingDates">
					You have no dates set on your chains for Exchange or Completion.
				</div>
				<div
					class="task"
					[routerLink]="['chain', date.chainId]"
					*ngFor="let date of dates; let i = index">
					<div class="task-icon">
						<mat-icon>event</mat-icon>
					</div>

					<div class="date">
						<div class="task-title">
							{{ date.type == "exchange" ? "Exchange" : "Completion" }}
							{{ date.date | date : "dd/MM/yyyy" }}
						</div>
						<div
							class="task-subtitle"
							*ngFor="let prop of date.properties; let i = index">
							{{ prop.address }}
						</div>
					</div>
				</div>
			</mat-card-content>
		</mat-card>
	</div>
</div>
<!-- </div> -->
