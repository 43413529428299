import { animate, style, transition, trigger } from '@angular/animations';
import { Component, Input, OnInit } from '@angular/core';
import { FormArray, FormControl, FormGroup } from '@angular/forms';
import { ConfirmDialogService } from 'src/app/services/confirm-dialog.service';
import {
	TaskBackendService,
	TaskDocument,
} from 'src/app/services/task-backend.service';
import { UploadFilesService } from 'src/app/services/upload-files.service';

@Component({
	selector: 'app-documents-form',
	templateUrl: './documents-form.component.html',
	styleUrls: ['./documents-form.component.css'],
	animations: [
		trigger('inOutAnimation', [
			transition(':enter', [
				style({ height: 0, opacity: 0, overflow: 'hidden' }),
				animate('300ms ease-out', style({ height: '*', opacity: 1 })),
			]),
			transition(':leave', [
				style({ height: '*', opacity: 1, overflow: 'hidden' }),
				animate('300ms ease-in', style({ height: 0, opacity: 0 })),
			]),
		]),
	],
})
export class DocumentsFormComponent implements OnInit {
	_documents: TaskDocument[] = [];
	@Input() set documents(val: TaskDocument[]) {
		this._documents = val;
		this.buildFrom();
	}
	get documents() {
		return this._documents;
	}
	_canEdit: boolean = false;
	@Input() set canEdit(val: boolean) {
		this._canEdit = val;
		this.buildFrom();
	}
	get canEdit() {
		return this._canEdit;
	}

	@Input() disabled: boolean = false;

	@Input() taskId?: string = '';
	@Input() propertyId?: string = '';

	@Input() state?:
		| 'completed'
		| 'inProgress'
		| 'notStarted'
		| 'disabled'
		| 'cancelled' = 'notStarted';

	@Input() location: 'task' | 'issue' | 'material' = 'task';
	@Input() docType?: string;

	formOpen = true;

	docsForm = new FormGroup({
		documents: new FormArray([]),
	});

	get docsArray() {
		return this.docsForm.get('documents') as FormArray;
	}

	constructor(
		public uploadFilesService: UploadFilesService,
		public confirmDialog: ConfirmDialogService,
		public taskBackend: TaskBackendService
	) {}

	ngOnInit(): void {}

	buildFrom() {
		this.docsForm.enable();
		this.docsForm = new FormGroup({
			documents: new FormArray([]),
		});
		this.documents.forEach((doc) => {
			const form = new FormGroup({
				id: new FormControl(doc.id),
				name: new FormControl(doc.name),
				date: new FormControl(doc.date),
				taskId: new FormControl(doc.taskId),
			});
			this.docsArray.controls.push(form);
		});

		if (!this.canEdit) this.docsForm.disable();
	}

	toggleForm() {
		this.formOpen = !this.formOpen;
	}

	currentDoc(doc: any) {
		return this.documents.some((d) => d.id === doc.get('id')?.value);
	}

	trackByFn(index: number, item: any) {
		return item.get('id')?.value || index;
	}

	openDocument(doc: any) {
		if (!this.canEdit || !this.currentDoc(doc)) return;
		if (this.disabled) return;
		let taskId = this.taskId;
		if (doc.get('taskId')?.value && doc.get('taskId')?.value?.length > 5) {
			taskId = doc.get('taskId')?.value;
		}
		this.taskBackend
			.getDocumentDownloadUrl(taskId || '', doc.get('id').value)
			.then((r) => {
				let link = document.createElement('a');
				document.body.appendChild(link);
				link.download = doc.get('name').value;
				link.href =
					r.data +
					`&&response-content-disposition=attachment;filename=${
						doc.get('name').value
					}`;
				link.click();
				document.body.removeChild(link);
			});
	}

	docIcon(fileName: string) {
		if (fileName.endsWith('.pdf')) return 'picture_as_pdf';
		if (fileName.endsWith('.png')) return 'image';
		if (fileName.endsWith('.jpg')) return 'image';
		if (fileName.endsWith('.doc') || fileName.endsWith('.docx'))
			return 'description';
		return 'insert_drive_file';
	}

	async removeDocument(index: number) {
		if (!this.canEdit || this.disabled) return;
		let doc = this.docsArray.at(index) as FormGroup;

		let canRemove = await this.confirmDialog.confirm({
			title: 'Remove document',
			message: `Are you sure you want to remove the "${
				doc.get('name')?.value
			}" document?`,
		});

		if (!canRemove) return;

		this.docsArray.removeAt(index);
		this.docsArray.markAsDirty();
	}

	async addDocuments(files: FileList | null) {
		if (!this.canEdit || this.disabled) return;
		if (files && files.length > 0) {
			for (let i = 0; i < files.length; i++) {
				const file = files[i];

				let p: Promise<string | undefined>;
				if (this.location == 'material' && this.propertyId) {
					p = this.uploadFilesService.uploadMaterialFile({
						file,
						propertyId: this.propertyId,
					});
				} else {
					p = this.uploadFilesService.uploadFile({
						file,
						taskId: this.taskId || '',
					});
				}

				p.then((id) => {
					if (!this.documents) this.documents = [];
					if (id) {
						const form = new FormGroup({
							id: new FormControl(id),
							name: new FormControl(file.name),
							date: new FormControl(new Date()),
						});
						this.docsArray.controls.push(form);
						form.markAsDirty();
						this.docsArray.markAsDirty();
					}

					//this.buildFrom();
				});
			}
		}
	}

	async dragAddDocuments(file: TaskDocument) {
		if (!this.canEdit || this.disabled) return;
		if (!file?.id) return;
		const form = new FormGroup({
			id: new FormControl(file.id),
			name: new FormControl(file.name),
			date: new FormControl(file.date),
		});
		this.docsArray.controls.push(form);
		form.markAsDirty();
		this.docsArray.markAsDirty();
	}

	saving = false;
	async save() {
		if (!this.canEdit || this.disabled) return;
		this.saving = true;
		this.docsForm.disable();
		let docs = this.docsArray.controls.map((c) => {
			return {
				id: c.get('id')?.value,
				name: c.get('name')?.value,
				date: c.get('date')?.value,
			};
		});

		let r = await this.taskBackend.patch(this.taskId || '', {
			documents: docs,
		});
		let newTask = r?.data;
		if (newTask?.documents) this.documents = newTask.documents;
		this.docsForm.enable();
		this.saving = false;
	}

	reset() {
		this.buildFrom();
	}
}
