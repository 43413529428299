import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component } from '@angular/core';

@Component({
	selector: 'app-offer-submitted',
	templateUrl: './offer-submitted.component.html',
	styleUrls: ['./offer-submitted.component.css'],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class OfferSubmittedComponent {}
