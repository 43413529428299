<div class="container">
	<h1 class="header">Anti-Money Laundering Id Check Permissions</h1>
	<!-- <div class="center-center" *ngIf="loading">
		<mat-spinner></mat-spinner>
	</div> -->
	<app-loading-tombstone *ngIf="loading"></app-loading-tombstone>
	<mat-card appearance="outlined" *ngIf="!loading && AMLchecks.length < 1">
		<mat-card-content>
			<div class="center">You have no Anti-Money Laundering Checks.</div>
		</mat-card-content>
	</mat-card>
	<mat-card
		style="flex-wrap: nowrap; margin-top: 1rem"
		class="chain-card-wrap"
		*ngFor="let amlCheck of AMLchecks; let i = index">
		<mat-card-content class="chain-card">
			<div class="chain-card-content">
				<div>
					{{ amlCheck.check.firstName }} {{ amlCheck.check.lastName }} -
					{{ amlCheck.check.userEmail }}
				</div>
				<div>
					{{ amlCheck.check.status }}
				</div>
				<div *ngIf="amlCheck.check.status == 'complete'">
					<span matTooltip="Identity Verifications">
						<mat-icon
							[ngClass]="
								amlCheck.check.identityVerifications == credasStatuses.pass
									? 'green-text'
									: 'red-text'
							"
							>{{
								amlCheck.check.identityVerifications == credasStatuses.pass
									? "check_circle"
									: "warning_amber"
							}}</mat-icon
						>
					</span>
					<span matTooltip="Address Mortality Checks">
						<mat-icon
							[ngClass]="
								amlCheck.check.addressMortalityChecks == credasStatuses.pass
									? 'green-text'
									: 'red-text'
							"
							>{{
								amlCheck.check.addressMortalityChecks == credasStatuses.pass
									? "check_circle"
									: "warning_amber"
							}}</mat-icon
						>
					</span>
					<span matTooltip="Sanction Pep Checks">
						<mat-icon
							[ngClass]="
								amlCheck.check.sanctionPepChecks == credasStatuses.pass
									? 'green-text'
									: 'red-text'
							"
							>{{
								amlCheck.check.sanctionPepChecks == credasStatuses.pass
									? "check_circle"
									: "warning_amber"
							}}</mat-icon
						>
					</span>
				</div>
				<div *ngIf="amlCheck.check.reportKey">
					<a [routerLink]="['/', 'AMLReport', amlCheck.check.id]"
						>View Report</a
					>
				</div>
				<div>EXP:{{ amlCheck.check.ExpDate | date : "medium" }}</div>
			</div>

			<table>
				<tr *ngFor="let permission of amlCheck.permissions; let i = index">
					<td>{{ permission.firstName }} {{ permission.lastName }}</td>
					<td>{{ permission.company || "" }}</td>
					<td style="text-align: center">{{ permission.status }}</td>
					<td style="text-align: right">
						<button
							mat-raised-button
							color="primary"
							(click)="
								updatePermissions(amlCheck.check.id, permission.id, 'granted')
							"
							*ngIf="
								permission.status === 'requested' ||
								permission.status === 'denied'
							">
							Grant Permission
						</button>
						<button
							mat-raised-button
							color="warn"
							(click)="
								updatePermissions(amlCheck.check.id, permission.id, 'denied')
							"
							*ngIf="permission.status === 'requested'">
							Deny Permission
						</button>
						<button
							mat-raised-button
							color="warn"
							(click)="
								updatePermissions(amlCheck.check.id, permission.id, 'denied')
							"
							*ngIf="permission.status === 'granted'">
							Revoke Permission
						</button>
					</td>
				</tr>
			</table>

			<!-- <div class="spacer"></div> -->
			<div class="menu"></div>
		</mat-card-content>
	</mat-card>
</div>
