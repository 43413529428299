export const environment = {
	production: true,
	apiUrl:
		process.env['NG_APP_API_URL'] ||
		'https://yguvur0ks8.execute-api.eu-west-1.amazonaws.com/',
	rootUrl: process.env['NG_APP_ROOT_URL'] || 'https://alpha.clearchain.house/',
	version: '0.0.316',
	branch: process.env['NG_APP_BRANCH'] || 'alpha',
	firstName: '',
	lastName: '',
	email: '',
	phone: '',
	postcode: '',
};
