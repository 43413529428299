import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import {
	TaskBackendService,
	Task,
	TaskStatus,
	TaskResult,
	TaskType,
	TaskParentType,
} from '../services/task-backend.service';
import {
	PropertiesBackendService,
	Property,
} from '../services/properties-backend.service';
import { AuthService } from '../services/auth.service';
import { TitleService } from '../services/title.service';
import { ChainsBackendService } from '../services/chains-backend.service';

@Component({
	selector: 'app-home',
	templateUrl: './home.component.html',
	styleUrls: ['./home.component.css'],
})
export class HomeComponent implements OnInit {
	tasks: Task[] = [];
	loadingTasks = true;
	taskCount = 0;
	loadingNotifications = true;
	propertyCount = 0;
	properties: Property[] = [];
	loadingProperties = true;
	user: any = { attributes: { email: '' } };
	notifications: any[] = [];
	loadingDates = true;
	dates: {
		chainId: string;
		properties: {
			id: string;
			address: string;
		}[];
		date?: Date;
		type: 'exchange' | 'completion';
	}[] = [];

	constructor(
		public taskBackend: TaskBackendService,
		public propertyBackend: PropertiesBackendService,
		public Router: Router,
		public AuthService: AuthService,
		public titleService: TitleService,
		public chainsBackend: ChainsBackendService
	) {}

	async ngOnInit() {
		this.titleService.title = 'Dashboard';

		this.loadingTasks = true;
		this.loadingNotifications = true;
		let qList = [
			this.taskBackend
				.getList({
					limit: 3,
				})
				.then((r) => {
					this.tasks = r.data;
					this.loadingTasks = false;
				}),
			this.taskBackend.getCount().then((r) => {
				this.taskCount = r.data;
			}),
			this.propertyBackend.getCount().then((r) => {
				this.propertyCount = r.data;
			}),
			this.propertyBackend.getList({ limit: 3 }).then((r) => {
				this.properties = r.data;
				this.loadingProperties = false;
			}),
			this.AuthService.getUser().then((r) => {
				this.user = r;
			}),
			this.AuthService.getNotifications().then((r) => {
				this.notifications = r.sort((a, b) => {
					if (b.status == 'seen' && a.status == 'unseen') return -1;
					if (b.status == 'unseen' && a.status == 'seen') return 1;
					return b.created - a.created;
				});
				this.loadingNotifications = false;
			}),
			this.chainsBackend.dates().then((r) => {
				this.loadingDates = false;
				this.dates = r.data;
			}),
		];

		await Promise.all(qList);
	}

	gotoNewChain() {
		this.Router.navigate(['/setup']);
	}

	gotoFAQ() {
		this.Router.navigate(['/faq']);
	}

	gotoProperties() {
		this.Router.navigate(['/properties']);
	}

	TaskStatus = TaskStatus;
	TaskResult = TaskResult;
	TaskType = TaskType;

	goto(task: Task) {
		if (task.taskType == TaskType.milestone) {
			if (task.parentType == TaskParentType.Property) {
				this.Router.navigate([
					'/chain',
					task.chainId,
					'property',
					task.parentId,
					'milestone',
					task.id,
				]);
				return;
			}
		}
		if (task.taskType != TaskType.milestone) {
			if (task.parentType == TaskParentType.Task) {
				if (task.parentId == task.milestoneId) {
					this.Router.navigate([
						'/chain',
						task.chainId,
						'property',
						task.propertyId || '',
						'milestone',
						task.parentId,
						'task',
						task.id,
					]);
					return;
				} else {
					this.Router.navigate([
						'/chain',
						task.chainId,
						'property',
						task.propertyId || '',
						'milestone',
						task.milestoneId,
						'task',
						task.parentId,
						'subtask',
						task.id,
					]);
					return;
				}
			}
		}
	}

	getTimeUntilDate(date: Date | any) {
		date = new Date(date);
		const now = new Date();
		const timeDiff = date.getTime() - now.getTime();
		const days = Math.floor(timeDiff / (1000 * 60 * 60 * 24));
		if (days < 0) return `${Math.abs(days)} day${days < -1 ? 's' : ''} ago`;
		if (days == 0) return `today`;
		if (days < 7) {
			return `in ${days} day${days > 1 ? 's' : ''}`;
		} else {
			const weeks = Math.floor(days / 7);
			return `in ${weeks} weeks`;
		}
	}

	notificationUrlArray(n: any): string[] {
		if (!n.chainId) return ['/'];
		if (n.propertyId && n.taskId)
			return [
				'chain',
				n.chainId,
				'property',
				n.propertyId,
				'milestone',
				n.taskId,
			];
		if (n.propertyId) return ['chain', n.chainId, 'property', n.propertyId];
		if (n.buyerSellerId)
			return ['chain', n.chainId, 'buyer-seller', n.buyerSellerId];
		return ['chain', n.chainId];
	}
}
