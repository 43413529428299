<div class="background">
	<div style="width: 100%; height: 100%; display: flex">
		<div
			*ngIf="left"
			[style.background-color]="leftColor"
			style="
				flex-grow: 1;
				justify-items: left;
				display: flex;
				justify-content: start;
				align-items: center;
				height: 100%;
			">
			<button
				mat-icon-button
				style="width: 20%; height: 100%"
				(click)="leftSelected()">
				<mat-icon class="text" [ngClass]="{ 'large-text': leftOption }">{{
					leftIcon
				}}</mat-icon>
			</button>
		</div>
		<div
			*ngIf="right"
			[style.background-color]="rightColor"
			style="
				flex-grow: 1;
				justify-items: right;
				display: flex;
				justify-content: end;
				align-items: center;
				height: 100%;
			">
			<button
				mat-icon-button
				style="width: 20%; height: 100%"
				(click)="rightSelected()">
				<mat-icon class="text" [ngClass]="{ 'large-text': rightOption }">{{
					rightIcon
				}}</mat-icon>
			</button>
		</div>
	</div>
	<div
		class="content"
		[ngClass]="{ 'animate-container': !_inMotion }"
		#content
		cdkDrag
		[cdkDragDisabled]="disabled"
		(cdkDragStarted)="startToMove($event)"
		(cdkDragReleased)="endMove($event)"
		(cdkDragMoved)="move($event)"
		[cdkDragConstrainPosition]="cdkDragConstrainPosition.bind(this)">
		<mat-card [ngClass]="{ 'car-not-card': !_inMotion }">
			<ng-content></ng-content>
		</mat-card>
	</div>
</div>
