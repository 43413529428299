import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { lastValueFrom } from 'rxjs';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { BugsService } from './bugs.service';

export interface BackendServiceOptions {
	ErrorMsg?: string;
	noError?: boolean;
	spoofAs?: string;
	noCredentials?: boolean;
}

interface IEvent {
	url: string;
	method: string;
	body?: any;
	result?: any;
}

@Injectable({
	providedIn: 'root',
})
export class BackendService {
	basePath = environment.apiUrl;
	apiHistory: IEvent[] = [];
	cashedRequests: Map<
		string,
		{
			time: number;
			data: any;
		}
	> = new Map<
		string,
		{
			time: number;
			data: any;
		}
	>();

	addToHistory(event: IEvent) {
		this.apiHistory.unshift(event);
		if (this.apiHistory.length > 5) {
			this.apiHistory.pop();
		}
	}

	constructor(
		public http: HttpClient,
		private _snackBar: MatSnackBar,
		private bugService: BugsService
	) {}

	async get(path: string, options?: BackendServiceOptions) {
		let cashed = this.cashedRequests.get(path);
		if (cashed && cashed.time > Date.now() - 300) {
			return cashed.data;
		}
		let event: IEvent = {
			url: `${this.basePath}${path}`,
			method: 'GET',
		};
		this.addToHistory(event);
		let _options: {
			withCredentials: boolean;
			headers?: {
				'x-spoof-as': string;
			};
		} = {
			withCredentials: options?.noCredentials ? false : true,
		};

		if (options?.spoofAs) {
			_options = {
				..._options,
				headers: {
					'x-spoof-as': options.spoofAs,
				},
			};
		}
		return await lastValueFrom(
			this.http.get(`${this.basePath}${path}`, _options)
		)
			.catch((e) => {
				event.result = e;
				this.errorHandle(e, 'GET', path, options);
			})
			.then((r) => {
				event.result = shortenResults(r as any);
				this.cashedRequests.set(path, {
					time: Date.now(),
					data: r,
				});
				return r;
			});
	}

	async post(path: string, body: any, options?: BackendServiceOptions) {
		let event: IEvent = {
			url: `${this.basePath}${path}`,
			body: body,
			method: 'POST',
		};
		this.addToHistory(event);
		let _options: {
			withCredentials: boolean;
			headers?: {
				'x-spoof-as': string;
			};
		} = {
			withCredentials: options?.noCredentials ? false : true,
		};

		if (options?.spoofAs) {
			_options = {
				..._options,
				headers: {
					'x-spoof-as': options.spoofAs,
				},
			};
		}
		return await lastValueFrom(
			this.http.post(`${this.basePath}${path}`, body, _options)
		)
			.catch((e) => {
				event.result = e;
				this.errorHandle(e, 'POST', path, options);
			})
			.then((r) => {
				event.result = shortenResults(r as any);
				return r;
			});
	}

	async put(path: string, body: any, options?: BackendServiceOptions) {
		let event: IEvent = {
			url: `${this.basePath}${path}`,
			body: body,
			method: 'PUT',
		};
		if (path != 'bugs') this.addToHistory(event);
		let _options: {
			withCredentials: boolean;
			headers?: {
				'x-spoof-as': string;
			};
		} = {
			withCredentials: options?.noCredentials ? false : true,
		};

		if (options?.spoofAs) {
			_options = {
				..._options,
				headers: {
					'x-spoof-as': options.spoofAs,
				},
			};
		}
		return await lastValueFrom(
			this.http.put(`${this.basePath}${path}`, body, _options)
		)
			.catch((e) => {
				event.result = e;
				this.errorHandle(e, 'PUT', path, options);
			})
			.then((r) => {
				event.result = shortenResults(r as any);
				return r;
			});
	}

	async patch(path: string, body: any, options?: BackendServiceOptions) {
		let event: IEvent = {
			url: `${this.basePath}${path}`,
			body: body,
			method: 'PATCH',
		};
		this.addToHistory(event);
		let _options: {
			withCredentials: boolean;
			headers?: {
				'x-spoof-as': string;
			};
		} = {
			withCredentials: options?.noCredentials ? false : true,
		};

		if (options?.spoofAs) {
			_options = {
				..._options,
				headers: {
					'x-spoof-as': options.spoofAs,
				},
			};
		}
		return await lastValueFrom(
			this.http.patch(`${this.basePath}${path}`, body, {
				withCredentials: _options.withCredentials,
			})
		)
			.catch((e) => {
				event.result = e;
				this.errorHandle(e, 'PATCH', path, options);
			})
			.then((r) => {
				event.result = shortenResults(r as any);
				return r;
			});
	}

	async delete(path: string, options?: BackendServiceOptions) {
		let event: IEvent = {
			url: `${this.basePath}${path}`,
			method: 'DELETE',
		};
		this.addToHistory(event);
		let _options: {
			withCredentials: boolean;
			headers?: {
				'x-spoof-as': string;
			};
		} = {
			withCredentials: options?.noCredentials ? false : true,
		};

		if (options?.spoofAs) {
			_options = {
				..._options,
				headers: {
					'x-spoof-as': options.spoofAs,
				},
			};
		}
		return await lastValueFrom(
			this.http.delete(`${this.basePath}${path}`, _options)
		)
			.catch((e) => {
				event.result = e;
				this.errorHandle(e, 'DELETE', path, options);
			})
			.then((r) => {
				event.result = shortenResults(r as any);
				return r;
			});
	}

	private errorHandle(
		e: any,
		mode: string,
		path: string,
		options?: BackendServiceOptions
	) {
		if (e.status == 402) throw e;
		if (!options?.noError) {
			let sb = this._snackBar.open(
				options?.ErrorMsg || `Error: ${mode} call to ${path} failed!`,
				'Report Bug',
				{
					duration: 1000 * 10,
				}
			);

			lastValueFrom(sb.onAction()).then(() => {
				//this.router.navigate(['/contact']);
				this.bugService.newBug();
			});
		}

		throw e;
	}
}

function shortenResults(results: backendResponse<any>) {
	if (!results) return results;
	if (!results.data) return results;
	if (Array.isArray(results.data)) {
		if (results.data.length > 1) {
			return {
				...results,
				data: [
					shortenObject(results.data[0]),
					`...${results.data.length - 1} more items`,
				],
			};
		}
	}
	return { ...results, data: shortenObject(results.data) };
}

function shortenObject(data: any) {
	if (typeof data == 'object') {
		let newR: any = {};
		for (let key in data) {
			if (typeof data[key] == 'object') newR[key] = { more: '...' };
			else if (Array.isArray(data[key]))
				newR[key] = [`...${data[key].length} item`];
			else newR[key] = data[key];
		}
		return newR;
	}
	return data;
}

export interface backendResponse<T> {
	data: T;
	canEdit: boolean;
	lastKey?: string;
}
