<div
	class="form"
	[formGroup]="$any(issueFrom)"
	[appDropFiles]
	(filesUploaded)="addDocuments($event)"
	[disabled]="issueFrom?.disabled"
	[taskId]="taskId || ''">
	<div class="row">
		<mat-form-field appearance="outline" floatLabel="always">
			<mat-label>Issue Type</mat-label>
			<mat-select formControlName="issueType">
				<!-- <mat-option disabled [value]="undefined">
					<span></span>
				</mat-option> -->
				<mat-option
					*ngFor="let issueType of issueTypes || []; let i = index"
					[value]="issueType">
					<span>{{ issueType }}</span>
				</mat-option>
				<mat-option [value]="'Other'">
					<span>Other</span>
				</mat-option>
			</mat-select>
		</mat-form-field>
		<mat-form-field
			appearance="outline"
			floatLabel="always"
			style="width: 100%">
			<mat-label>Issue</mat-label>
			<textarea
				matInput
				placeholder="This property is in a flood plane..."
				formControlName="message">
			</textarea>
		</mat-form-field>
		<mat-form-field appearance="outline" floatLabel="always">
			<mat-label>Issue Level</mat-label>
			<mat-select formControlName="level">
				<mat-option [value]="'warning'">
					<span>Warning</span>
				</mat-option>
				<mat-option [value]="'blocker'">
					<span>Blocker</span>
				</mat-option>
			</mat-select>
		</mat-form-field>
		<mat-form-field appearance="outline" floatLabel="always">
			<mat-label>Documents</mat-label>
			<app-documents-input
				formControlName="documents"
				[currentDocs]="$any(issueFrom).get('currentDocs')?.value || []"
				[taskId]="taskId || ''">
			</app-documents-input>
		</mat-form-field>
	</div>
	<div class="info">
		{{ issueFrom?.get("createdByEmail")?.value }}
		{{ issueFrom?.get("date")?.value | date : "medium" }}
	</div>
	<div class="actions">
		<button
			mat-button
			*ngIf="
				issueFrom?.get('level')?.value == 'warning' &&
				(!issueFrom?.get('dismissedBy')?.value ||
					issueFrom?.get('dismissedBy')?.value == 'false')
			"
			type="button"
			[disabled]="issueFrom?.disabled"
			(click)="acceptRisk()">
			Accept Issue Risks
		</button>
		<button
			mat-button
			*ngIf="
				issueFrom?.get('level')?.value == 'warning' &&
				!!issueFrom?.get('dismissedBy')?.value &&
				issueFrom?.get('dismissedBy')?.value != 'false'
			"
			type="button"
			[disabled]="issueFrom?.disabled"
			(click)="unAcceptRisk()">
			Un-Accept Issue Risks
		</button>
		<button
			mat-button
			(click)="delete.next(issueFrom?.get('id')?.value)"
			type="button"
			[disabled]="issueFrom?.disabled">
			Delete Issue
		</button>
	</div>
</div>
