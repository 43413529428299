import {
	CdkDrag,
	CdkDragEnd,
	CdkDragMove,
	CdkDragRelease,
	CdkDragStart,
	DragRef,
	Point,
} from '@angular/cdk/drag-drop';
import {
	AfterViewInit,
	OnInit,
	ChangeDetectorRef,
	Component,
	ElementRef,
	EventEmitter,
	HostListener,
	Input,
	NgZone,
	Output,
	ViewChild,
} from '@angular/core';
import { BreakpointObserver } from '@angular/cdk/layout';
import { Unsubscribe } from 'src/app/services/unsubscribe';

@Component({
	selector: 'app-swiper',
	templateUrl: './swiper.component.html',
	styleUrls: ['./swiper.component.css'],
})
export class SwiperComponent implements AfterViewInit, OnInit {
	@ViewChild('content') content?: ElementRef;
	@Output('left') leftEvent = new EventEmitter<any>();
	@Output('right') rightEvent = new EventEmitter<any>();
	_disabled = false;
	__disabled = false;
	@Input() set disabled(val: boolean) {
		this._disabled = val;
	}
	get disabled() {
		return this.__disabled || this._disabled;
	}
	@Input() leftDisable = false;
	@Input() rightDisable = false;
	@Input() leftIcon = 'chevron_left';
	@Input() rightIcon = 'chevron_right';
	@Input() leftColor = 'rgb(176, 176, 176)';
	@Input() rightColor = 'rgb(176, 176, 176)';

	@Unsubscribe() $isSmall?: any;

	constructor(
		private ref: ElementRef,
		public cd: ChangeDetectorRef,
		public ngZone: NgZone,
		public BreakpointObserver: BreakpointObserver
	) {}

	@HostListener('window:resize', ['$event'])
	onResize(event: any) {
		this.setSizes();
	}

	ngOnInit() {
		this.$isSmall = this.BreakpointObserver.observe(
			'(min-width: 800px)'
		).subscribe((res) => {
			this.__disabled = res.matches;
			this.backToStart();
		});
	}

	ngAfterViewInit() {
		this.setSizes();
	}

	maxMove = 100;
	contentWidth = 100;
	setSizes() {
		this.ref.nativeElement.style.setProperty(
			'height',
			this.content?.nativeElement.offsetHeight - 1 + 'px'
		);
		this.content?.nativeElement.style.setProperty(
			'width',
			this.ref?.nativeElement.offsetWidth + 'px'
		);
		this.contentWidth = this.content?.nativeElement.offsetWidth || 100;
		this.maxMove = Math.round(this.contentWidth * 0.2);
	}

	_inMotion = false;
	inMotion = false;
	drag?: CdkDrag;
	startToMove(event: CdkDragStart) {
		this.drag = event.source;
		this._inMotion = true;
		this.inMotion = true;
		// this.left = false;
		// this.right = false;
		// this.leftOption = false;
		// this.rightOption = false;
		this.percentMoved = 0;
		event.event.preventDefault();
		event.event.stopPropagation();
		event.event.stopImmediatePropagation();
	}

	percentMoved = 0;
	move(event: CdkDragMove) {
		let currentPos = event.source.getFreeDragPosition();
		this.percentMoved = currentPos.x / this.contentWidth;
		// if (Math.abs(this.percentMoved) > 0.2) {
		// 	if (this.percentMoved < 0) {
		// 		this.leftOption = true;
		// 		this.rightOption = false;
		// 	} else {
		// 		this.leftOption = false;
		// 		this.rightOption = true;
		// 	}
		// }
		if (this.percentMoved > 0) {
			this.left = true;
			this.right = false;
		} else {
			this.left = false;
			this.right = true;
		}
	}

	lockedOpen = false;
	endMove(event: CdkDragRelease) {
		//this.ngZone.run(() => {
		this._inMotion = false;
		//console.log('endMove', this.percentMoved);
		if (this.leftOption) {
			this.lockedOpen = true;
			// if (this.percentMoved < 0) {
			// 	this.leftOption = true;
			// 	this.rightOption = false;
			// 	//this.leftSelected();
			// } else {
			// 	this.leftOption = false;
			// 	this.rightOption = true;
			// 	//this.rightSelected();
			// }
		} else if (this.rightOption) {
			this.lockedOpen = true;
		} else {
			this.leftOption = false;
			this.rightOption = false;
			this.backToStart();
		}
		//});
	}

	backToStart() {
		// this.content?.nativeElement.style.setProperty(
		// 	'transform',
		// 	'translateX(0px)'
		// );
		this.drag?.setFreeDragPosition({ x: 0, y: 0 });
		setTimeout(() => {
			this.inMotion = false;
			this.cd.markForCheck();
		}, 100);
	}

	leftSelected() {
		//console.log('left selected');
		this.backToStart();

		this.leftEvent.emit();
		this.cd.markForCheck();
		this.cd.detectChanges();
	}

	rightSelected() {
		this.backToStart();

		this.rightEvent.emit();
		this.cd.markForCheck();
		this.cd.detectChanges();
	}

	left = false;
	right = false;
	leftOption = false;
	rightOption = false;

	cdkDragConstrainPosition(
		userPointerPosition: Point,
		dragRef: DragRef,
		dimensions: ClientRect,
		pickupPositionInElement: Point
	): Point {
		//console.log('userPointerPosition', userPointerPosition.x);
		//console.log('pickupPositionInElement', pickupPositionInElement.x);
		//dragRef.getFreeDragPosition();
		let x = userPointerPosition.x - pickupPositionInElement.x;

		//let freeDragPosition = dragRef.getFreeDragPosition();
		let d = this.ref?.nativeElement.getBoundingClientRect();
		let newX = d.x - x;

		if (Math.abs(newX) >= this.maxMove) {
			if (newX < 0) {
				x = d.x + this.maxMove;
				this.leftOption = true;
				this.rightOption = false;
			} else {
				x = d.x - this.maxMove;
				this.rightOption = true;
				this.leftOption = false;
			}
		} else {
			this.leftOption = false;
			this.rightOption = false;
			this.lockedOpen = false;
		}

		if (this.leftDisable && newX < 0) {
			x = d.left;
		}
		if (this.rightDisable && newX > 0) {
			x = d.left;
		}

		return {
			x: x,
			y: d.top,
		};
	}
}
