import { Injectable, EventEmitter } from '@angular/core';

@Injectable({
	providedIn: 'root',
})
export class TitleService {
	_title = 'Home';
	set title(val: string) {
		this._title = val;
		this.$title.emit(val);
	}
	get title() {
		return this._title;
	}
	$title: EventEmitter<string> = new EventEmitter();

	constructor() {}
}
