<div class="container">
	<h1 class="header">Tasks</h1>
	<!-- <div class="center-center" *ngIf="loading">
		<mat-spinner></mat-spinner>
	</div> -->
	<app-loading-tombstone *ngIf="loading"></app-loading-tombstone>
	<mat-card appearance="outlined" *ngIf="!loading && tasks?.length == 0">
		<mat-card-content>
			<div class="center">You Have No Tasks Right Now</div>
		</mat-card-content>
	</mat-card>

	<mat-card
		class="row task-card"
		style="
			flex-wrap: nowrap;
			flex-direction: column;
			cursor: pointer;
			margin-top: 1rem;
		"
		*ngFor="let task of tasks"
		(click)="goto(task)">
		<mat-card-content class="row">
			<mat-icon
				class="icon-disabled"
				*ngIf="task.status == TaskStatus.disabled"
				mat-list-icon
				>indeterminate_check_box</mat-icon
			>
			<mat-icon
				class="icon-in-progress"
				*ngIf="task.status == TaskStatus.inProgress"
				mat-list-icon
				>check_box_outline_blank</mat-icon
			>
			<mat-icon
				class="icon-not-started"
				*ngIf="task.status == TaskStatus.notStarted"
				mat-list-icon
				>check_box_outline_blank</mat-icon
			>
			<mat-icon
				class="icon-canceled"
				*ngIf="task.status == TaskStatus.cancelled"
				mat-list-icon
				>unpublished</mat-icon
			>
			<mat-icon
				class="icon-completed"
				*ngIf="task.status == TaskStatus.completed"
				mat-list-icon
				>done</mat-icon
			>

			<mat-icon
				[ngClass]="{
					'icon-completed': task.status == TaskStatus.completed,
					'icon-canceled': task.status == TaskStatus.cancelled,
					'icon-not-started': task.status == TaskStatus.notStarted,
					'icon-in-progress': task.status == TaskStatus.inProgress,
					'icon-disabled': task.status == TaskStatus.disabled
				}"
				mat-list-icon
				>{{
					task.taskType == TaskType.decider
						? "signpost"
						: task.taskType == TaskType.milestone
						? "beenhere"
						: "task"
				}}</mat-icon
			>
			<div>
				<div>{{ task.privetTitle || task.title }}</div>
				<div>{{ task.propertyNickname }}</div>
				<div>{{ task.propertyAddress }}</div>
				<div class="due-date-small">
					Due: {{ task.estimatedCompletionDate | date : "dd/MM/yyyy" }}
				</div>
			</div>
			<div class="spacer"></div>
			<div class="due-date-large">
				Due: {{ task.estimatedCompletionDate | date : "dd/MM/yyyy" }}
			</div>
			<div class="menu">
				<button mat-icon-button>
					<mat-icon>launch</mat-icon>
				</button>
			</div>
		</mat-card-content>
	</mat-card>
</div>
